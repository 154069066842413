import React from 'react';
import logo from '../../assets/images/trusteasy-logo.svg';

class Sitelogo extends React.Component {
  render() {
    return (
      <div className="sitelogo">
        <img src={logo} alt="TrustEasy" style={{ maxWidth: '300px' }} />
        {/* <span>Beta</span> */}
      </div>
    );
  }
}

export default Sitelogo;
