/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { Button, Form } from 'react-bootstrap';
import { API, get } from '../../config';
import { debounce } from 'lodash';

const { Option, OptGroup } = Select;

const SearchBar = ({ setIsVisible, setSelectedCustomer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [customerOptions, setCustomerOptions] = useState([]);
  const [refundOptions, setRefundOptions] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [searchValue, setSearchValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const setInitialOptions = () => {
    setCustomerOptions([]);
    setTotalCustomers(0);
    setRefundOptions([]);
    setTotalRefunds(0);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e);
    debouncedSave(e);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      handleSearch(nextValue);
    }, 400),
    []
  );

  const handleSearch = useCallback(async (value) => {
    try {
      if (!value) {
        setInitialOptions();
        return;
      }
      setIsLoading(true);
      const { data } = await get(`${API.GLOBAL_SEARCH}?search=${value}`);
      if (data?.data) {
        const {
          data: { customer, refundHistory }
        } = data;
        setCustomerOptions(customer?.data || []);
        setTotalCustomers(customer.total || 0);
        setRefundOptions(refundHistory?.data || []);
        setTotalRefunds(refundHistory.total || 0);
      } else {
        setInitialOptions();
      }
      setIsLoading(false);
    } catch (e) {
      setInitialOptions();
    }
  });

  const handleChange = (value) => {
    if (value === 'more') {
      localStorage.setItem('selectedMore', 'more');
      setSearchValue(null);
      setSelectedOption(null);
      setInitialOptions();
      setTimeout(() => {
        navigate(`/customer?page=1&search=${searchValue}`, {
          replace: true,
          state: { path: location.path }
        });
      }, 500);
    } else if (typeof value === 'string' && value.startsWith('refund')) {
      localStorage.setItem('selectedId', value.split(' ')[1]);
      setTimeout(() => {
        navigate(`/customer?page=1`, {
          replace: true,
          state: { path: location.pathname }
        });
      }, 500);
    } else {
      setIsVisible(true);
      setSelectedCustomer(value);
    }
  };
  const isDataAvailable = customerOptions?.length > 0 || refundOptions?.length > 0;

  return (
    <div className="searchbar">
      <Form>
        <Select
          placeholder="Global Search"
          showSearch
          onSearch={(e) => handleSearchChange(e)}
          searchValue={searchValue}
          style={{ width: 765 }}
          loading={isLoading}
          showArrow={false}
          filterOption={false}
          onChange={handleChange}
          value={selectedOption}
          dropdownStyle={isLoading ? {} : isDataAvailable ? { height: '400px' } : {}}
        >
          {isLoading ? (
            [
              <Select.Option key="loading">
                {'Loading'} {'...'}
              </Select.Option>
            ]
          ) : isDataAvailable ? (
            <>
              <OptGroup label={`Customers (${totalCustomers})`}>
                {customerOptions.map((customer) => (
                  <Option className="optionlist" key={customer?.id} value={customer?.id}>
                    {(customer?.first_name
                      ? customer?.first_name?.charAt(0) +
                        ' ' +
                        customer?.first_name +
                        ' ' +
                        customer?.last_name
                      : customer?.bussiness_name
                      ? customer?.bussiness_name?.charAt(0) + ' ' + customer?.bussiness_name
                      : customer?.last_name?.charAt(0) + ' ' + customer?.last_name) +
                      (customer?.withdrawal_account === 1 ? '(Withdrawal Account)' : '')}
                  </Option>
                ))}
                {totalCustomers > 5 ? (
                  <Option className="viewmore" key="more" value="more">
                    View More
                  </Option>
                ) : null}
              </OptGroup>
              <OptGroup label={`Refund History (${totalRefunds})`}>
                {refundOptions.map((opt) => (
                  <Option key={opt?.id} value={`refund ${opt?.customer_id}`}>
                    <i>
                      {opt?.first_name} {opt?.last_name}
                    </i>
                    <span>{opt?.description}</span>
                  </Option>
                ))}
              </OptGroup>
            </>
          ) : null}
        </Select>
        <Button className="fa fa-search">Search</Button>
      </Form>
    </div>
  );
};

export default SearchBar;
