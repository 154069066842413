import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faXmark } from '@fortawesome/free-solid-svg-icons';
import RoleDetailPopup from './RoleDetailPopup';
import RoleAdd from './RoleAdd';
// import RoleActive from './RoleActive';
import { Spin, Switch } from 'antd';
import { API, fileUpload } from '../../../config';
import { toast } from 'react-toastify';

const RoleActionButtons = ({ roleDetails, data, handleDelete, statusButton = false }) => {
  const [showView, setShowView] = useState(false);
  // const [showActive, setShowActive] = useState(false);
  // const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEditRole, setShowEditRole] = useState(false);
  const toggleEditModal = () => {
    setShowEditRole(!showEditRole);
  };

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  // const toggleShowActiveModal = () => {
  //   setShowActive(!showActive);
  // };
  // const toggleDeleteModal = () => {
  //   setShowDelete(!showDelete);
  // };

  const handleRoleActive = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('status', roleDetails?.status ? 0 : 1);
      formData.append('_method', 'put');
      await fileUpload(`${API.UPDATE_ROLE}/${data.id}`, formData);
      setLoading(false);
      // toast.success(response?.data?.message);
      handleDelete();
    } catch (e) {
      setLoading(false);
      handleDelete();
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {/* <FontAwesomeIcon title="View Role Details" onClick={toggleShowModal} icon={faEye} size="1x" /> */}
      {/* {parseInt(roleDetails?.id) !== 2 && ( */}
      {statusButton ? (
        <>
          {/* <Button >{data?.status ? "Deactive" : "Active"}</Button> */}
          <span className="status-switch-body">
            {loading && (
              <div className="spin-status">
                <Spin />
              </div>
            )}
            <Switch
              className="status-switch"
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={() => {
                handleRoleActive();
              }}
              disabled={loading}
              defaultChecked={roleDetails.status}
            />
          </span>
        </>
      ) : (
        <>
          {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp; */}
          <FontAwesomeIcon
            title="Edit Role"
            onClick={() => {
              toggleEditModal();
            }}
            icon={faEdit}
            size="1x"
          />
        </>
      )}
      {/* )} */}

      {/* <>
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          title="Archive Role"
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
        />
      </> */}

      {/* {showDelete && (
        <RoleRemove
          toggleDeleteModal={toggleDeleteModal}
          roleId={data.id}
          handleDelete={handleDelete}
        />
      )} */}
      {showView && <RoleDetailPopup roleId={data.id} toggleShowModal={toggleShowModal} />}
      {/* {showActive && (
        <RoleActive
          toggleActiveModal={toggleShowActiveModal}
          roleId={data.id}
          status={roleDetails?.status}
          handleDelete={handleDelete}
        />
      )} */}
      {showEditRole && (
        <RoleAdd
          edit={true}
          showAddRole={showEditRole}
          toggleRoleModal={toggleEditModal}
          fetchRoles={handleDelete}
          id={data.id}
        />
      )}
    </span>
  );
};

export default RoleActionButtons;
