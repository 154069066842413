import React, { useState, useEffect, useCallback } from 'react';
import { API, fileUpload, get, post } from '../../config.js';
import { Button, Modal } from 'antd';
import { toast } from 'react-toastify';
import '../users/users.module.css';
import CancelButton from '../../components/Form/CancelButton.js';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AuthorityLetterPopup from './AuthorityLetterPopup.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import AuthorityLetterModalContent from '../customer/AuthorityLetterModalContent.js';

const getContent = (html) => {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const value = EditorState.createWithContent(contentState) || null;
  return value;
};

const AuthorityLetter = () => {
  const [editorState, setEditorState] = useState(null);
  const [authorityData, setAuthorityData] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
  };
  const [showView, setShowView] = useState(false);
  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const togglePrintModal = () => {
    setAuthorityData(!authorityData);
  };

  const getConsentData = useCallback(async () => {
    try {
      const { data } = await get(API.GET_CONSENT);
      const consentObj = data;
      const html = consentObj?.data?.consent;
      let consentValue = null;
      if (html) {
        consentValue = getContent(html);
      }
      setEditorState(consentValue);
    } catch (e) {
      setEditorState(null);
    }
  }, []);

  useEffect(() => {
    getConsentData();
  }, [getConsentData]);

  const handleFinish = async () => {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    try {
      const formData = new FormData();
      formData.append('consent', value);
      const { data } = await fileUpload(API.UPDATE_CONSENT, formData, {});
      toast.success(data.message);
      getConsentData();
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const printAuthorityLetter = async () => {
    const { data } = await post(`${API.GET_AUTHORITY_LETTER}`, { type: 1 });
    setAuthorityData(data?.data);
  };

  return (
    <div className="content-details">
      <div className="full-card">
        <Editor
          editorState={editorState}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'remove', 'history']
          }}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />

        <div className="action-box">
          {/* <Button onClick={() => printLetter()} type="primary" style={{ marginRight: 'auto' }}>
            <FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
            Preview
          </Button> */}
          <Button
            onClick={() => printAuthorityLetter()}
            type="primary"
            style={{ marginRight: 'auto' }}
          >
            <FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
            Preview
          </Button>
          <Button type="primary" onClick={handleFinish}>
            Save
          </Button>
          <CancelButton />
        </div>
      </div>
      {showView && <AuthorityLetterPopup toggleShowModal={toggleShowModal} />}
      {authorityData && (
        <Modal
          title=""
          centered
          open
          footer={null}
          width={1170}
          closable
          className="modal-body"
          onCancel={() => {
            togglePrintModal(false);
          }}
        >
          <AuthorityLetterModalContent data={authorityData} />
        </Modal>
      )}
    </div>
  );
};
export default AuthorityLetter;
