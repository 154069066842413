import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, Select } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../components/Form/BackButton';
import SidebarNav from '../../components/Header/SidebarNav';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import AddEditOpeningBalance from './AddEditOpeningBalance';
import { openingBalanceColumns } from './OpeningBalanceHelper';
import useOpeningBalanceList from './useOpeningBalanceData';
import { formatNumber } from '../../APIConfig/Interceptor';
import { defaultPaginationLimit } from '../../config';
const OpeningBalance = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const {
    fetchOpeningBalance,
    openingBalanceList,
    isOpeningBalanceLoading,
    totalOpeningBalanceData,
    pagingData,
    onPageChange
  } = useOpeningBalanceList(currentPage);

  const [show, setShow] = useState(false);
  const [showFirm, setShowFirm] = useState(false);
  const [firmData, setFirmData] = useState({});
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
  );

  let total =
    formatNumber(Number(totalOpeningBalanceData?.firm?.opening_balance)) -
    formatNumber(Number(totalOpeningBalanceData?.running_balance));

  useEffect(() => {
    fetchOpeningBalance();
  }, [fetchOpeningBalance]);

  const handleAddNew = () => {
    setShow(true);
  };
  const handleDelete = () => {
    fetchOpeningBalance();
  };

  const handleClose = () => {
    setShow(false);
    fetchOpeningBalance();
  };

  const handleEditFirm = (data) => {
    setFirmData(data);
    setShowFirm(true);
  };
  const handleCloseFirm = () => {
    setShowFirm(false);
    fetchOpeningBalance();
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/opening-balance?page=1&limit=${value}`, {
      replace: true
    });
    fetchOpeningBalance(1, value);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Opening Balance
            {/* <DialogModal
          title="Dashboard"
          p1={helpCenterArray[19].text}
          p2={helpCenterArray[20].text}
          p3={helpCenterArray[21].text}
          p4={helpCenterArray[22].text}
          p5={helpCenterArray[23].text}
          p6={helpCenterArray[24].text}
          p7={helpCenterArray[25].text}
        /> */}
          </h1>
          <div className="content-details firm-trust opening-balance-body">
            <div className="firm-details">
              <div
                className={
                  formatNumber(totalOpeningBalanceData?.running_balance) ===
                  formatNumber(totalOpeningBalanceData?.firm?.opening_balance)
                    ? 'firm-summary opening-balance-card-matched'
                    : 'firm-summary opening-balance-card'
                }
              >
                <div className="customer-name opening-balance">
                  <h4>
                    {totalOpeningBalanceData?.firm?.business_name
                      ? totalOpeningBalanceData?.firm?.business_name
                      : ''}
                  </h4>
                  {/* <h6>
                    {totalOpeningBalanceData?.firm?.firm_email ? (
                      <a
                        className="mailto-tel"
                        href={`mailto:${totalOpeningBalanceData?.firm?.firm_email}`}
                      >
                        {totalOpeningBalanceData?.firm?.firm_email}
                      </a>
                    ) : (
                      ''
                    )}
                  </h6> */}
                </div>
                <div className="balance-block">
                  <div className="balance">
                    <h6>Firm Opening Balance</h6>
                    <h5>
                      {totalOpeningBalanceData?.firm?.opening_balance
                        ? '$' + formatNumber(totalOpeningBalanceData?.firm?.opening_balance)
                        : '$0.00'}
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="1x"
                        onClick={() => {
                          handleEditFirm(totalOpeningBalanceData?.firm);
                        }}
                      />
                    </h5>
                  </div>
                </div>

                <div className="balance-block">
                  <div className="running-balance">
                    <h6>Clients&apos; Cumulative Total</h6>
                    <h5>
                      {totalOpeningBalanceData?.running_balance
                        ? '$' + formatNumber(totalOpeningBalanceData?.running_balance)
                        : '$0.00'}
                    </h5>
                  </div>
                </div>
                <div className="balance-block">
                  <div className="running-balance">
                    <h6>Difference(Yet To Enter)</h6>
                    <h5>{total ? '$' + formatNumber(total) : '$0.00'}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="firm-details">
              <div className="firm-summary opening-balance">
                <div className="opening-balance-table">
                  <div className="table-top-btn">
                    <div className="table-btn">
                      <Button
                        variant="primary"
                        onClick={() => {
                          handleAddNew();
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                  <Table
                    columns={openingBalanceColumns(handleDelete)}
                    loading={isOpeningBalanceLoading}
                    dataSource={openingBalanceList || []}
                    pagination={{
                      defaultPageSize: pagingData.pageSize,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      itemRender: PaginationItemRenderer,
                      onChange: (page) => onPageChange(page, limit),
                      ...pagingData,
                      pageSize: limit
                    }}
                  />
                  {openingBalanceList?.length > 0 && (
                    <div className="col-md-12">
                      Showing&nbsp;
                      <Select
                        defaultValue={`${defaultPaginationLimit}`}
                        value={
                          query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
                        }
                        onChange={handleChange}
                        options={[
                          { value: '10', label: '10' },
                          { value: '25', label: '25' },
                          { value: '50', label: '50' },
                          { value: '100', label: '100' }
                        ]}
                      />
                      &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                      {pagingData?.to} from {pagingData?.total} Records
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="mid-modal supplier-modal opening-balance"
            id="opening_balance_modal"
            show={show}
            onHide={handleClose}
            dialogClassName="modal-50w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Add Opening Balance</Modal.Header>
            <Modal.Body>
              <AddEditOpeningBalance data={{}} type="customer" onClose={handleClose} />
            </Modal.Body>
          </Modal>
          <Modal
            className="mid-modal supplier-modal"
            id="opening_balance_modal"
            show={showFirm}
            onHide={handleCloseFirm}
            dialogClassName="modal-50w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Opening Balance</Modal.Header>
            <Modal.Body>
              <AddEditOpeningBalance data={firmData} type="firm" onClose={handleCloseFirm} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default OpeningBalance;
