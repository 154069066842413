import React from 'react';
import '../App.css';
import '../assets/css/style.css';
import SidebarNav from '../components/Header/SidebarNav';
import Footer from '../components/Footer/Index';
import BackButton from '../components/Form/BackButton';

class HelpCenter extends React.Component {
  render() {
    return (
      <div className="cms-page">
        <div className="page-content-block">
          <SidebarNav />
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Reports
            </h1>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default HelpCenter;
