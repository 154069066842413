import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Table } from 'antd';

import { defaultPaginationLimit, get } from '../../config';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';

const TableComponent = ({
  pagingData,
  dataSource,
  columns,
  nestedTableColumns,
  nestedRowEndpoint,
  isNested = false,
  startMonth,
  endMonth,
  isLedgerReport = false,
  onPageChange = () => {},
  limit,
  loading
}) => {
  const [expandedData, setExpandedData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isNestedDataLoading, setIsNestedDataLoading] = useState(false);

  const expandedRowRender = (record) => {
    return (
      <Table
        columns={nestedTableColumns}
        loading={isNestedDataLoading}
        dataSource={expandedData?.tableData}
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: expandedData?.defaultPageSize || defaultPaginationLimit,
          defaultCurrent: 1,
          showQuickJumper: true,
          itemRender: PaginationItemRenderer,
          onChange: (page) => setExpandedRowData(record, page),
          ...expandedData
        }}
      />
    );
  };

  const setExpandedRowData = async (record, page = 1) => {
    if (isLedgerReport) {
      const { data } = await get(
        `${nestedRowEndpoint}?end_month=${endMonth}&start_month=${startMonth}&page=${page}&limit=${defaultPaginationLimit}&export=0&chart_account_id=${record.id}`
      );
      const { total, current_page, per_page, data: tableData } = data.data.reports;
      setExpandedData({
        tableData,
        defaultPageSize: per_page,
        total,
        current: current_page
      });
    } else {
      const { data } = await get(`${nestedRowEndpoint}/${record.id}?page=${page}`);
      const { total, current_page, per_page, data: tableData } = data.data;
      setExpandedData({
        tableData,
        defaultPageSize: per_page,
        total,
        current: current_page
      });
    }
    return true;
  };

  const onExpand = async (expanded, record) => {
    try {
      const expandedRows = [];
      if (expanded) {
        setExpandedData({
          tableData: []
        });

        expandedRows.push(record.id ? record.id : record.key);
        setExpandedRows(expandedRows);
        setIsNestedDataLoading(true);
        if (nestedRowEndpoint) {
          await setExpandedRowData(record);
        } else {
          record.bank_statement?.bank_reconciliation?.length > 0 &&
            record.bank_statement?.bank_reconciliation.map((item) => {
              item.transaction_type = record?.bank_statement?.transaction_type;
              item.sourch = record?.bank_statement?.source;
              item.date = record?.bank_statement?.date;
            });
          setExpandedData({
            tableData: record.bank_statement?.bank_reconciliation,
            defaultPageSize: 50,
            total: 50,
            current: 1
          });
        }
        setIsNestedDataLoading(false);
      } else {
        setExpandedRows([]);
      }
    } catch (e) {
      setIsNestedDataLoading(false);
      setExpandedData({
        ...expandedData,
        tableData: []
      });
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  return (
    <Table
      rowKey={(record) => (record.id ? record.id : record.key)}
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      expandable={isNested ? { expandedRowRender, onExpand } : false}
      expandedRowKeys={isNested ? expandedRows : []}
      pagination={
        pagingData
          ? {
              hideOnSinglePage: true,
              defaultPageSize: pagingData?.pageSize || defaultPaginationLimit,
              defaultCurrent: 1,
              showQuickJumper: true,
              onChange: (page) => onPageChange(page),
              itemRender: PaginationItemRenderer,
              pageSize: limit,
              ...pagingData
            }
          : false
      }
    />
  );
};

export default TableComponent;
