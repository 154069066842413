import React from "react";
import { Button, Image } from 'antd';
import "../../components/Authentication/authentication.css"


const RegisterAuthentication = (props) => {
    const { setEnterCode, qrImage, secretKey } = props
    const url = localStorage.getItem("myna-auth-email")
    // const [ enterCode, setEnterCode ] = useState(false);
    // const [ qrImage, setQrImage ] = useState(null);


    const handleClick = () => {
        setEnterCode(true)
    }


    if (!qrImage) return null
    return (
        <div className="AntContent">
            <div className="step1"><h5 className="heading-step">Step 1:</h5><p className="content-step1"> Open your Google Authenticator app. </p></div>
            <div className="step2"><h5>Step 2:</h5><p> Add your account to the app by scanning this QR code. </p></div>
            {qrImage &&
                < Image
                    width={200}
                    src={qrImage.replace('https://www.google.com', 'https://chart.googleapis.com')}
                />
            }
            <div><b>OR</b></div>
            <p>Enter a setup key: <b>{secretKey}</b></p>
            <div className="step3"><h5>Step 3:</h5><p> {`Enter the 6-digit code found in your authenticator app under ${url}.`} </p></div>
            <Button onClick={handleClick}>Enter Code</Button>
        </div>
    );
};

export default RegisterAuthentication;
