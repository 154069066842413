import React, {
  useEffect,
  //  useMemo,
  useState
} from 'react';
import { Button, Image, Modal, Spin } from 'antd';
import { Link } from 'react-router-dom';
import {
  getCustomerDetail,
  getDisplayPostalAddress,
  getDisplayShippingAddress,
  getStatementData
} from './CustomersHelper';
import { imageDefaultPrefixPath } from '../../config';
import './customer.module.css';
import AuthorityLetterModal from './AuthorityLetterModal';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { getUserDetail } from '../users/UserConsoleHelper';
import { FullNameGetterWithoutChartAt, maskPostalCode } from '../../helper/fullNameGetter';
import { formatNumber } from '../../APIConfig/Interceptor';

const verificationMethods = [
  'Visual + Visual',
  'Visual + Source ATO',
  '3.Visual + Source DVS',
  'Source ATO + Source DVS'
];

const getVerifiedDate = (date) => {
  return moment(date).format('DD-MM-YYYY').toString();
};

const getVerifiedDateTime = (date) => {
  return moment(date).format('DD-MM-YYYY HH:mm:ss').toString();
};

const CustomerDetailPopup = ({
  //data,
  customerId,
  // customerData,
  toggleShowModal = false,
  isDeletedReportPage
}) => {
  const [customerDetail, setCustomerDetail] = useState(null);
  const [verifiedByUser, setVerifiedByUser] = useState(null);
  const [statementData, setStatementData] = useState(null);
  const [showView, setShowView] = useState(false);
  const [loading, setLoading] = useState(true);
  const togglePrintModal = () => {
    setShowView(!showView);
  };

  useEffect(() => {
    async function fetchData() {
      const customerDetailRes = await getCustomerDetail(customerId);
      setCustomerDetail(customerDetailRes);
      if (customerDetailRes?.verified_by) {
        const detail = await getUserDetail(parseInt(customerDetailRes.verified_by));
        setVerifiedByUser(detail);
      }
      if (!isDeletedReportPage) {
        const statementData = await getStatementData(customerId);
        setStatementData(statementData);
      }
      setLoading(false);
    }
    fetchData();
  }, [customerId]);

  // const customerStatus = useMemo(() => {
  //   if (typeof customerDetail?.status === 'object') {
  //     return 'N/A';
  //   }
  //   let status = 'Non Current';
  //   if (customerDetail?.status === '1') status = 'Prospect';
  //   if (customerDetail?.status === '2') status = 'Current';
  //   return status;
  // }, [customerDetail?.status]);

  // const clientType = useMemo(() => {
  //   if (typeof customerDetail?.customer_type === 'object') {
  //     return 'N/A';
  //   }
  //   return customerDetail?.customer_type === '1' ? 'Individual' : 'Non Individual';
  // }, [customerDetail?.customer_type]);

  return (
    <div>
      <Modal
        title=""
        centered
        open
        footer={null}
        width={1000}
        closable
        className="modal-body"
        onCancel={toggleShowModal}
      >
        {loading ? (
          <div className="centeralign spin-padding">
            <Spin />
          </div>
        ) : (
          Object.keys(customerDetail).length > 0 && (
            <>
              <div className="modal-content-details">
                <div className="profile">
                  {customerDetail?.profile_pic == null || customerDetail?.profile_pic == '' ? (
                    <span>
                      {customerDetail?.first_name
                        ? customerDetail?.first_name?.charAt(0)
                        : customerDetail?.last_name?.charAt(0)}
                    </span>
                  ) : (
                    <Image src={`${imageDefaultPrefixPath}${customerDetail?.profile_pic}`} />
                  )}
                  <h5>{FullNameGetterWithoutChartAt(customerDetail)}</h5>
                </div>
                <div className="customer-account">
                  <h4>Account Balance: ${formatNumber(statementData?.closing_balance) || 0}</h4>
                  {isDeletedReportPage ? null : (
                    <div className="action-block">
                      <Link
                        to={{
                          pathname: '/customer/edit',
                          search: `?id=${customerDetail.id}`
                        }}
                        className="btn update-customer"
                        onClick={toggleShowModal}
                      >
                        Update Client
                      </Link>
                      <Button onClick={togglePrintModal} className="btn small-btn">
                        Print Authority Letter
                      </Button>
                      <Link
                        to={{ pathname: `/customer-detail/${customerId}`, search: '?page=1' }}
                        className="btn small-btn"
                        onClick={toggleShowModal}
                      >
                        View Statement
                      </Link>
                      {/* <Link
                      to={{ pathname: '#' }}
                      className='btn small-btn'
                    >
                      Create Invoice
                    </Link> */}
                      {/* <Link
                        to={{
                          pathname: `/process-refund-individual/${customerDetail.id}`,
                          state: {
                            clientData: customerDetail
                          }
                        }}
                        state={{customerData: customerData, clientData: customerDetail}}
                        className="btn small-btn"
                        onClick={toggleShowModal}
                      >
                        Process Refund
                      </Link> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-content">
                <h3>Personal Details</h3>
                <div className="modal-content-details">
                  <div className="modal-content-left">
                    <p>
                      <strong>Title:</strong>{' '}
                      {customerDetail?.title ? customerDetail?.title : 'N/A'}
                    </p>
                    <p>
                      <strong>First Name:</strong>{' '}
                      {customerDetail?.first_name ? customerDetail?.first_name : 'N/A'}
                    </p>
                    <p>
                      <strong>Middle Name:</strong>{' '}
                      {customerDetail?.middle_name ? customerDetail?.middle_name : 'N/A'}
                    </p>

                    <p>
                      <strong>Last Name:</strong> {customerDetail?.last_name}
                    </p>
                    <p>
                      <strong>Phone Number:</strong>{' '}
                      {customerDetail?.phone == null || customerDetail?.phone == ''
                        ? 'N/A'
                        : customerDetail?.phone}
                    </p>
                    <p>
                      <strong>Mobile Number:</strong>
                      {customerDetail?.mobile == null || customerDetail?.mobile == ''
                        ? 'N/A'
                        : customerDetail?.mobile}
                    </p>
                    <p className="address-box">
                      <strong>Postal Address:</strong> {getDisplayPostalAddress(customerDetail)}
                    </p>
                    <p className="address-box">
                      <strong>Street Address:</strong> {getDisplayShippingAddress(customerDetail)}
                    </p>
                  </div>
                  <div className="modal-content-right">
                    <p>
                      <strong>Business:</strong>{' '}
                      {customerDetail?.bussiness_name == null ||
                      customerDetail?.bussiness_name == ''
                        ? 'N/A'
                        : customerDetail?.bussiness_name}
                    </p>
                    <p>
                      <strong>ABN:</strong>{' '}
                      {customerDetail?.customer_bank?.abn == null ||
                      customerDetail?.customer_bank?.abn == ''
                        ? 'N/A'
                        : customerDetail?.customer_bank?.abn}
                    </p>
                    <p>
                      {/* <strong>Client type:</strong> {clientType} */}
                      <strong>CRN:</strong>{' '}
                      {customerDetail?.crn == null || customerDetail?.crn == ''
                        ? 'N/A'
                        : customerDetail?.crn}
                    </p>
                    <p>
                      <strong>Email Address:</strong> {customerDetail?.email}
                    </p>
                    {customerDetail?.deceased == null ? (
                      ''
                    ) : (
                      <>
                        <p className="deceased">
                          <strong>Deceased: </strong> {getVerifiedDate(customerDetail?.deceased)}{' '}
                        </p>
                      </>
                    )}
                    <p>
                      <strong>Related to:</strong>{' '}
                      {customerDetail?.related_to_name == null ||
                      customerDetail?.related_to_name == ''
                        ? 'N/A'
                        : customerDetail?.related_to_name}
                    </p>
                    {/* <p>
                      <strong>Type:</strong> {customerStatus}
                    </p> */}
                    <p>
                      <strong>TFN:</strong>{' '}
                      {customerDetail?.customer_bank?.tfn == null ||
                      customerDetail?.customer_bank?.tfn == ''
                        ? 'N/A'
                        : customerDetail?.customer_bank?.tfn}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-content">
                <h3>Bank Details</h3>
                <div className="modal-content-details">
                  <div className="modal-content-left">
                    <p>
                      <strong>Account Name:</strong>{' '}
                      {customerDetail?.customer_bank?.account_name == null ||
                      customerDetail?.customer_bank?.account_name == ''
                        ? 'N/A'
                        : customerDetail?.customer_bank?.account_name}
                    </p>
                    <p>
                      <strong>Account Number:</strong>{' '}
                      {customerDetail?.customer_bank?.account_number == null ||
                      customerDetail?.customer_bank?.account_number == ''
                        ? 'N/A'
                        // : 'xxxx xxxx ' + customerDetail?.customer_bank?.account_number?.toString().slice(-4)}
                        : customerDetail?.customer_bank?.account_number?.toString()}
                    </p>
                  </div>
                  <div className="modal-content-right">
                    <p>&nbsp;</p>
                    <p>
                      <strong>BSB:</strong>{' '}
                      {customerDetail?.customer_bank?.bsb == null ||
                      customerDetail?.customer_bank?.bsb == ''
                        ? 'N/A'
                        // : 'xxx' + customerDetail?.customer_bank?.bsb?.toString().slice(-4)}
                        : customerDetail?.customer_bank?.bsb?.toString()}
                      <span className="refund_block">
                        {customerDetail?.customer_bank?.fee_from_refund == 1
                          ? 'Pre-Payment of Refund'
                          : ''}
                      </span>
                    </p>
                    {/* <p>
                      <strong>ABN:</strong> {customerDetail.customer_bank.abn}
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="modal-content">
                <h3>Office Details</h3>
                <div className="modal-content-details">
                  <div className="modal-content-left">
                    <p>
                      <strong>Staff:</strong>{' '}
                      {customerDetail?.office_detail?.staff == null ||
                      customerDetail?.office_detail?.staff == ''
                        ? 'N/A'
                        : customerDetail?.office_detail?.staff}
                    </p>
                    <p>
                      <strong>Office:</strong>{' '}
                      {customerDetail?.office_detail.office?.office_suburb == null ||
                      customerDetail?.office_detail.office?.office_suburb == ''
                        ? 'N/A'
                        : customerDetail?.office_detail?.office?.office_suburb + ', '}
                      {customerDetail?.office_detail?.office?.postal_code == null ||
                      customerDetail?.office_detail?.office?.postal_code == ''
                        ? ''
                        : maskPostalCode(Number(customerDetail?.office_detail?.office?.postal_code))}
                    </p>
                  </div>
                  <div className="modal-content-right">
                    <p>
                      <strong>Manager:</strong>{' '}
                      {customerDetail?.office_detail?.manager == null ||
                      customerDetail?.office_detail?.manager == ''
                        ? 'N/A'
                        : customerDetail?.office_detail?.manager}
                    </p>
                    <p>
                      <strong>Tag:</strong>{' '}
                      {customerDetail?.accounting_firm_customer?.tag == null ||
                      customerDetail?.accounting_firm_customer?.tag == ''
                        ? 'N/A'
                        : customerDetail?.accounting_firm_customer?.tag}
                    </p>
                  </div>
                </div>
              </div>
              {customerDetail?.import_from !== null && (
                <div className="modal-content">
                  <h3>Synchronisation</h3>
                  <div className="modal-content-details">
                    <div className="modal-content-left">
                      <p>
                        <strong>Integrated with:</strong>{' '}
                        {customerDetail?.import_from === 'sf' ? 'SalesForce' : 'Xero'}
                      </p>
                      {/* <p>
                        <strong>CRN:</strong> {customerDetail.import_user_id}
                      </p> */}
                    </div>
                    <div className="modal-content-right">
                      <p>
                        <strong>Last synchronised time:</strong>{' '}
                        {getVerifiedDateTime(customerDetail?.last_sync_date)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="modal-content">
                <h3>Uploaded ID Documents</h3>
                <div className="modal-content-details">
                  {customerDetail?.customer_document.map((doc) => (
                    <img
                      key={doc.path} //remove doc.path if any issue/conflict occur.
                      style={{
                        width: 'auto',
                        height: '100px',
                        padding: '0 10px 10px 0'
                      }}
                      src={`${imageDefaultPrefixPath}${doc.path}`}
                    ></img>
                  ))}
                  {customerDetail?.authority_letter == null ? (
                    ''
                  ) : (
                    <a
                      href={`${imageDefaultPrefixPath}${customerDetail?.authority_letter?.path}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        className="pdf-icon"
                        size="5x"
                        icon={faFilePdf}
                      ></FontAwesomeIcon>
                    </a>
                  )}
                  {customerDetail?.date_verified && (
                    <span style={{ paddingBottom: '15px' }}>
                      Verified By {verifiedByUser?.first_name} {verifiedByUser?.last_name} on{' '}
                      {getVerifiedDate(customerDetail?.date_verified)} via{' '}
                      {verificationMethods[parseInt(customerDetail?.verification_method)]} method.{' '}
                      <br />
                      Note: {customerDetail?.notes}
                    </span>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </Modal>
      {showView && (
        <AuthorityLetterModal
          id={customerId}
          togglePrintModal={togglePrintModal}
          screenView="customer"
        />
      )}
    </div>
  );
};

export default CustomerDetailPopup;
