import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'antd';
import { getAuthorityDetail } from './CustomersHelper';
import '../users/users.module.css';
import { toast } from 'react-toastify';
import AuthorityLetterModalContent from './AuthorityLetterModalContent';

const AuthorityLetterModal = ({ id, togglePrintModal, screenView }) => {
  const [authorityData, setauthorityDetail] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const authorityDetailRes = await getAuthorityDetail(id, screenView);
      setauthorityDetail(authorityDetailRes || {});
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }, [id, screenView]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!authorityData) return null;
  return (
    <Modal
      title=""
      centered
      open
      footer={null}
      width={1170}
      closable
      className="modal-body"
      onCancel={() => {
        togglePrintModal(false);
      }}
    >
      <AuthorityLetterModalContent data={authorityData} />
    </Modal>
  );
};
export default AuthorityLetterModal;
