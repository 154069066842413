import React, { useState, useEffect, useCallback } from 'react';
import { pickBy, identity } from 'lodash';
import InputMask from 'react-input-mask';
import SidebarNav from '../../components/Header/SidebarNav';
import BackButton from '../../components/Form/BackButton';
import Form from '@component/Form';
import moment from 'moment';
import { Button, Input, Select, Upload, Checkbox, Spin, DatePicker, InputNumber } from 'antd';
import {
  useStateList,
  useSuburbList,
  useSuburbList1,
  useManageList,
  useStaffList,
  getCustomerDetail,
  verificationMethods
} from './CustomersHelper';
import ImgCrop from 'antd-img-crop';
import CancelButton from '../../components/Form/CancelButton';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { imageDefaultPrefixPath, API, fileUpload, get } from '../../config.js';
import Footer from '../../components/Footer/Index';
import { toast } from 'react-toastify';
import { toInteger } from 'lodash';
import faqicon from '../../assets/images/bulb-icon.jpg';
import UserSelect from '../../components/User/UserSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { maskPostalCode } from '../../helper/fullNameGetter.js';

const { TextArea } = Input;

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};

const CustomerEdit = () => {
  const [form] = Form.useForm();
  const { stateLoading, stateList = [] } = useStateList();
  // const [bsbRule, setBSBRule] = useState([]);
  const [accountNumberRule, setAccountNumberRule] = useState([]);
  const [stateId, setStateId] = useState();
  const [stateId1, setStateId1] = useState();
  const {
    suburbLoading,
    suburbList = [],
    handleStateSelect,
    setSuburbList,
    selectedState
  } = useSuburbList(stateId);
  const {
    suburbLoading1,
    suburbList1 = [],
    handleStateSelect1,
    setSuburbList1,
    selectedState1
  } = useSuburbList1(stateId1);
  const { managerListLoading, managerList = [] } = useManageList(stateId);
  const { staffList = [] } = useStaffList(stateId);
  const [fileList, setFileList] = useState([]);
  const [primaryFileList, setPrimaryFileList] = useState([]);
  const [secondaryfileList, setSecondaryFileList] = useState([]);
  const [authorityLetter, setAuthorityLetter] = useState([]);
  const [userList, setUserList] = useState([]);
  const [TFNRules, setTFNRules] = useState([]);
  const [isVerificationRequired, setIsVerificationRequired] = useState(false);
  const [customerData, setCustomerData] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [reletedToSelect, setReletedToSelect] = useState();
  const [authuser, setAuthuser] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  var pastUrl = location.state ? location.state.from.pathname : '';
  const query = new URLSearchParams(location.search);
  const customerId = query.get('id');
  const [bankOptions, setBankOptions] = useState([]);
  const [bankLoading, setBankLoading] = useState(false);
  const [bankSearch, setBankSearch] = useState('');

  const getBankList = async (searchValue) => {
    try {
      setBankLoading(true);
      const { data } = await get(`${API.BANK_ABBREVIATION}?search=${searchValue}`);
      setBankLoading(false);
      setBankOptions(data.data);
    } catch (error) {
      setBankLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleBankSearch = (value) => {
    setBankSearch(value);
    if (value) {
      getBankList(value);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoadingData(true);
        await getUsers();
        await getBankList(bankSearch);
        const data = await getCustomerDetail(customerId);
        setReletedToSelect(data.related_to ? toInteger(data.related_to) : '');
        setCustomerData(data);
        let primaryDocumentFiles = [];
        let secondaryDocumentFiles = [];
        data.customer_document &&
          data.customer_document.length > 0 &&
          data.customer_document.forEach((document) => {
            if (document.is_primary >= 1 && document.is_primary < 2) {
              primaryDocumentFiles.push(document);
            } else if (document.is_primary >= 2) {
              secondaryDocumentFiles.push(document);
            }
          });
        const newPrimaryData =
          primaryDocumentFiles &&
          primaryDocumentFiles.length > 0 &&
          primaryDocumentFiles.map((item) => {
            return {
              ...item,
              url: `${imageDefaultPrefixPath}${item.path}`,
              status: 'done'
            };
          });
        const newSecondaryData =
          secondaryDocumentFiles &&
          secondaryDocumentFiles.length > 0 &&
          secondaryDocumentFiles.map((item) => {
            return {
              ...item,
              url: `${imageDefaultPrefixPath}${item.path}`,
              status: 'done'
            };
          });
        const profilePic = data.profile_pic && [
          {
            url: `${imageDefaultPrefixPath}${data.profile_pic}`,
            status: 'done'
          }
        ];
        const authorityLetterFile = data.authority_letter && [
          {
            url: `${imageDefaultPrefixPath}${data.authority_letter?.path}`,
            status: 'done'
          }
        ];
        setPrimaryFileList(newPrimaryData ? newPrimaryData : []);
        setSecondaryFileList(newSecondaryData ? newSecondaryData : []);
        setFileList(profilePic !== '' ? profilePic : []);
        setAuthorityLetter(authorityLetterFile !== null ? authorityLetterFile : []);
        setStateId(data.state_id);
        setStateId1(data.shipping_state_id);
        form.setFieldsValue({
          ...data,
          //mobile_number: data.mobile_number,
          deceased: data.deceased ? moment(data.deceased, 'YYYY-MM-DD') : null,
          mobile_number: data.mobile,
          related_to: toInteger(data.related_to) == 0 ? '' : toInteger(data.related_to),
          staff_id: data.office_detail.staff_id,
          manager_id: data.office_detail.manager_id,
          // office_id: data.office_detail.office.id,
          tag: data.accounting_firm_customer.tag,
          account_name: data.customer_bank ? data.customer_bank.account_name : '',
          bank_name: data.customer_bank ? data.customer_bank.bank_name : '',
          account_number: data.customer_bank?.account_number
            ? // ? 'XXXX XXXX ' + data?.customer_bank?.account_number?.toString().slice(-4)
              data?.customer_bank?.account_number?.toString()
            : '',
          business_name: data.bussiness_name,
          // bsb: data.customer_bank?.bsb ? 'XXX' + data?.customer_bank?.bsb?.toString().slice(-4) : '',
          bsb: data.customer_bank?.bsb ? data?.customer_bank?.bsb?.toString() : '',
          tfn: data.customer_bank ? data.customer_bank.tfn : '',
          abn: data.customer_bank ? data.customer_bank.abn : '',
          opening_balance: data.opening_balance,
          crn: data.crn,
          suburb_id: data.suburb_id,
          shipping_suburb_id: data.shipping_suburb_id,
          postal_code: data?.postal_code ? maskPostalCode(Number(data?.postal_code)) : '',
          shipping_postal_code: data?.shipping_postal_code
            ? maskPostalCode(Number(data?.shipping_postal_code))
            : '',
          fee_from_refund:
            (data.customer_bank && (data.customer_bank.fee_from_refund == 1 ? true : false)) ||
            false,
          date_verified: data.date_verified ? moment(data.date_verified, 'YYYY-MM-DD') : null,
          verification_method: data.verification_method
            ? parseInt(data.verification_method) + 1
            : null,
          verified_by: data.verified_by ? parseInt(data.verified_by) : authuser
        });
        setLoadingData(false);
      } catch (e) {
        setCustomerData(null);
        setLoadingData(false);
      }
    }
    fetchData();
  }, [customerId]);

  const getUsers = useCallback(async () => {
    try {
      const { data } = await get(`${API.GET_USER_LIST}?limit=-1`);
      setUserList(data?.data?.data);
    } catch (e) {
      setUserList([]);
    }
    return true;
  });

  useEffect(() => {
    async function suburb(stateId) {
      try {
        const { data } = await get(`${API.GET_SUBURB_LIST}/${stateId}`);
        setSuburbList(data.data);
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong.');
        }
      }
    }
    if (stateId) suburb(stateId);
  }, [stateId]);

  useEffect(() => {
    async function suburb1(stateId1) {
      try {
        const { data } = await get(`${API.GET_SUBURB_LIST}/${stateId1}`);
        setSuburbList1(data.data);
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong.');
        }
      }
    }
    if (stateId1) suburb1(stateId1);
  }, [stateId1]);

  useEffect(() => {
    // form.setFieldsValue({ verified_by: authuser });
    if (suburbList.length && selectedState) {
      form.setFieldsValue({ suburb_id: suburbList[0].id });
    }
    if (suburbList1.length && selectedState1) {
      form.setFieldsValue({ shipping_suburb_id: suburbList1[0].id });
    }
  }, [form, selectedState, suburbList, selectedState1, suburbList1]);

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  // const ClientType = [
  //   { id: '1', name: 'Individual' },
  //   { id: '2', name: 'Non Individual' }
  // ];

  // const ClientStatus = [
  //   { id: '1', name: 'Prospect' },
  //   { id: '2', name: 'Current' },
  //   { id: '3', name: 'Non Current' }
  // ];

  const ClientTitle = [
    { id: 'Mr.', name: 'Mr.' },
    { id: 'Mrs.', name: 'Mrs.' },
    { id: 'Ms.', name: 'Ms.' },
    { id: 'Dr.', name: 'Dr.' },
    { id: 'Other', name: 'Other' }
  ];

  const tagList = [
    { id: 'A', name: 'A' },
    { id: 'B', name: 'B' },
    { id: 'C', name: 'C' },
    { id: 'D', name: 'D' },
    { id: 'E', name: 'E' },
    { id: 'F', name: 'F' },
    { id: 'G', name: 'G' },
    { id: 'H', name: 'H' },
    { id: 'I', name: 'I' },
    { id: 'J', name: 'J' },
    { id: 'K', name: 'K' },
    { id: 'L', name: 'L' },
    { id: 'M', name: 'M' },
    { id: 'N', name: 'N' },
    { id: 'O', name: 'O' },
    { id: 'P', name: 'P' },
    { id: 'Q', name: 'Q' },
    { id: 'R', name: 'R' },
    { id: 'S', name: 'S' },
    { id: 'T', name: 'T' },
    { id: 'U', name: 'U' },
    { id: 'V', name: 'V' },
    { id: 'W', name: 'W' },
    { id: 'X', name: 'X' },
    { id: 'Y', name: 'Y' },
    { id: 'Z', name: 'Z' }
  ];
  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const reqObj = pickBy(values, identity);
      /* if (reqObj.account_name || reqObj.bsb) {
        if (reqObj.bank_name == '' || reqObj.bank_name == undefined) {
          setLoading(false);
          toast.error('Please select Bank Name');
          return false;
        }
      } */
      reqObj.abn = reqObj?.abn ? `${reqObj?.abn}`.replace(/ /g, '') : '';
      // if (!('business_name' in reqObj)) {
      //   reqObj.business_name = reqObj.last_name;
      // }
      if (!('related_to' in reqObj)) {
        reqObj.related_to = reqObj?.related_to ? reqObj?.related_to : '';
      }
      if (!('account_number' in reqObj)) {
        reqObj.account_number = '';
      }
      if (!('bsb' in reqObj)) {
        reqObj.bsb = '';
      }
      reqObj.related_to = reletedToSelect ? reletedToSelect : reqObj.related_to;
      const formData = new FormData();
      if (primaryFileList && primaryFileList.length > 0) {
        primaryFileList.forEach((primaryfile, i) => {
          if (primaryfile.originFileObj) {
            formData.append(`primary_document_${i + 1}`, primaryfile.originFileObj);
          }
          if (primaryfile.url) {
            const url = primaryfile.url.split('/public').pop();
            return formData.append(`primary_document_${i + 1}`, url);
          }
        });
      }
      if (secondaryfileList && secondaryfileList.length > 0) {
        secondaryfileList.forEach((secondaryfile, i) => {
          if (secondaryfile.originFileObj) {
            formData.append(`secondary_document_${i + 1}`, secondaryfile.originFileObj);
          }
          if (secondaryfile.url) {
            const url = secondaryfile.url.split('/public').pop();
            formData.append(`secondary_document_${i + 1}`, url);
          }
        });
      }
      if (fileList && fileList.length > 1) {
        const fileListUrl = fileList.slice(-1);
        if (fileListUrl[0].originFileObj)
          formData.append('profile_pic', fileListUrl[0]?.originFileObj);
      } else {
        if (fileList && fileList[0]?.originFileObj)
          formData.append('profile_pic', fileList[0]?.originFileObj);
        if (fileList && fileList[0]?.url) {
          const url = fileList[0]?.url.split('/public').pop();
          formData.append('profile_pic', url);
        }
      }
      Object.keys(reqObj).forEach((key) => {
        if (key == 'fee_from_refund') {
          formData.append([key], reqObj[key] == true ? 1 : 0);
        } else if (key == 'deceased' || key === 'date_verified') {
          formData.append([key], moment(reqObj[key]).format('YYYY-MM-DD'));
        } else if (key === 'verification_method') {
          formData.append([key], reqObj[key] - 1);
        } else if (key === 'bsb') {
          if (!reqObj[key].toString().includes('X')) {
            formData.append([key], reqObj[key]);
          }
        } else if (key === 'account_number') {
          if (!reqObj[key].toString().includes('X')) {
            formData.append([key], reqObj[key]);
          }
        } else {
          formData.append([key], reqObj[key]);
        }
      });
      if (authorityLetter && !authorityLetter.length) {
        formData.append('authority_letter', null);
      } else if (authorityLetter && authorityLetter[0].originFileObj) {
        formData.append('authority_letter', authorityLetter[0].originFileObj);
      } else if (authorityLetter && authorityLetter[0].url) {
        formData.append('authority_letter', authorityLetter[0].url);
      }
      formData.append('_method', 'put');
      const { data } = await fileUpload(`${API.GET_CUSTOMERLIST}/${customerId}`, formData);
      setLoading(false);
      toast.success(data?.message);

      if (pastUrl === '/nobank-client') {
        const detailsNotUpdated =
          !!values?.account_name && !!values?.account_number && !!values?.bsb;
        if (detailsNotUpdated) {
          navigate('/customer');
        } else {
          navigate('/nobank-client');
        }
      } else {
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPrimaryChange = ({ fileList: newFileList }) => {
    setPrimaryFileList(newFileList);
  };
  const onSeondaryChange = ({ fileList: newFileList }) => {
    setSecondaryFileList(newFileList);
  };
  const handleAuthorityLetterChange = ({ fileList: newFileList }) => {
    if (!newFileList.length) {
      setAuthorityLetter(newFileList);
    }
    if (newFileList[0]?.type === 'application/pdf') {
      setAuthorityLetter(newFileList);
    }
  };

  const handleFirstName = () => {
    const values = form.getFieldsValue();
    if (values.business_name === values.last_name) {
      form.setFieldsValue({
        business_name: ''
      });
    }
  };

  // const handleLastName = (event) => {
  //   const values = form.getFieldsValue();
  //   const { value } = event.target;
  //   if (
  //     !values.business_name ||
  //     values.business_name === null ||
  //     values.business_name === undefined ||
  //     value.includes(values.business_name)
  //   ) {
  //     if (values.first_name) {
  //       form.setFieldsValue({
  //         business_name: ''
  //       });
  //     } else {
  //       form.setFieldsValue({
  //         business_name: value
  //       });
  //     }
  //   }
  // };

  const handleLastName = (event) => {
    const values = form.getFieldsValue();
    const { value } = event.target;
    if (
      !values.business_name ||
      values.business_name === null ||
      values.business_name === undefined ||
      // value.includes(values.business_name)
      value === values.business_name
    ) {
      if (values.first_name) {
        form.setFieldsValue({
          business_name: ''
        });
      } else {
        form.setFieldsValue({
          business_name: value
        });
      }
    }
  };

  useEffect(async () => {
    const { data } = await get(API.GET_USER_PROFILE);
    setAuthuser(data.id);
  }, []);

  const handleBussinessName = (event) => {
    const values = form.getFieldsValue();
    const { value } = event.target;
    if (
      !values.last_name ||
      values.last_name === null ||
      values.last_name === undefined ||
      value === values.last_name
    ) {
      if (values.first_name) {
        // if (value === values.last_name) {
          form.setFieldsValue({
            business_name: ''
          });
        // }
      }
      form.setFieldsValue({
        last_name: value
      });
    }
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const onPrimaryPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const onSeondaryPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const onVerificationChange = () => {
    const date = form.getFieldValue('date_verified');
    const user = form.getFieldValue('verified_by');
    const method = form.getFieldValue('verification_method');
    const notes = form.getFieldValue('notes');
    if ((date || user || method || notes) && !isVerificationRequired) {
      setIsVerificationRequired(true);
    } else {
      if (!date && !user && !method && !notes) {
        setIsVerificationRequired(false);
      }
    }
  };

  const onReletedToSelect = (id) => {
    setReletedToSelect(id);
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList,
    listType: 'picture',
    beforeUpload: () => {
      return false;
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };
  function onSearch() {}
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
      </div>
      <div className="full-content-block">
        {!customerData || loadingData ? (
          <Spin />
        ) : (
          <>
            <h1 className="page-title">
              <BackButton />
              {customerData?.first_name} {customerData?.last_name}
              <Link to={{ pathname: '/help' }}>
                <img src={faqicon} />
              </Link>
            </h1>
            <div className="content-details">
              <div className="entry-section">
                <Form
                  form={form}
                  initialValues={{
                    ...customerData,
                    //mobile_number: customerData.mobile_number,
                    deceased: customerData.deceased
                      ? moment(customerData.deceased, 'YYYY-MM-DD')
                      : null,
                    mobile_number: customerData.mobile,
                    related_to:
                      toInteger(customerData.related_to) == 0
                        ? ''
                        : toInteger(customerData.related_to),
                    staff_id: customerData.office_detail.staff_id,
                    manager_id: customerData.office_detail.manager_id,
                    // office_id: customerData.office_detail.office.id,
                    tag: customerData.accounting_firm_customer.tag,
                    account_name: customerData.customer_bank
                      ? customerData.customer_bank.account_name
                      : '',
                    bank_name: customerData.customer_bank
                      ? customerData.customer_bank.bank_name
                      : '',
                    account_number: customerData.customer_bank?.account_number
                      ? // ? 'XXXX XXXX ' + customerData?.customer_bank?.account_number?.toString().slice(-4)
                        customerData?.customer_bank?.account_number?.toString()
                      : '',
                    business_name: customerData.bussiness_name,
                    // bsb: customerData.customer_bank?.bsb ? 'XXX' + customerData?.customer_bank?.bsb?.toString().slice(-4) : '',
                    bsb: customerData.customer_bank?.bsb
                      ? customerData?.customer_bank?.bsb?.toString()
                      : '',
                    tfn: customerData.customer_bank ? customerData.customer_bank.tfn : '',
                    abn: customerData.customer_bank ? customerData.customer_bank.abn : '',
                    opening_balance: customerData.opening_balance,
                    crn: customerData.crn,
                    suburb_id: customerData.suburb_id,
                    shipping_suburb_id: customerData.shipping_suburb_id,
                    postal_code: customerData?.postal_code
                      ? maskPostalCode(Number(customerData?.postal_code))
                      : '',
                    shipping_postal_code: customerData?.shipping_postal_code
                      ? maskPostalCode(Number(customerData?.shipping_postal_code))
                      : '',
                    fee_from_refund:
                      (customerData.customer_bank &&
                        (customerData.customer_bank.fee_from_refund == 1 ? true : false)) ||
                      false,
                    date_verified: customerData.date_verified
                      ? moment(customerData.date_verified, 'YYYY-MM-DD')
                      : null,
                    verification_method: customerData.verification_method
                      ? parseInt(customerData.verification_method) + 1
                      : null,
                    verified_by: customerData.verified_by
                      ? parseInt(customerData.verified_by)
                      : authuser
                  }}
                  name="customerForm"
                  onFinish={handleFinish}
                  layout="vertical"
                >
                  <div className="entry-card">
                    <h3>Personal Details</h3>
                    <div className="form-box">
                      <Form.Item className="half-width title-label" label="Title" name="title">
                        <Select
                          showSearch={false}
                          optionFilterProp="children"
                          onSearch={onSearch}
                          placeholder="Please select Title"
                        >
                          {ClientTitle.map((type) => (
                            <Select.Option key={type.id} value={type.id}>
                              {type.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className="tri-width fname-label"
                        label="First Name"
                        name="first_name"
                        rules={[
                          {
                            max: 32,
                            message: 'Must be less than 32 character'
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z\s]*$/),
                            message: 'First name can contain letters & spaces.'
                          }
                        ]}
                      >
                        <Input onChange={handleFirstName} placeholder="First Name" />
                      </Form.Item>
                      <Form.Item
                        className="tri-width mname-label"
                        label="Middle Name"
                        name="middle_name"
                        rules={[
                          {
                            max: 150,
                            message: 'Must be less than 150 character'
                          },
                          {
                            pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&]*$/),
                            message:
                              'Middle name can contain letters, numbers, ampersand(&), dot(.), comma(,) & spaces.'
                          }
                        ]}
                      >
                        <Input placeholder="Middle Name" />
                      </Form.Item>
                      <Form.Item
                        className="tri-width lname-label"
                        label="Last Or Trading Name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Last name'
                          },
                          {
                            max: 150,
                            message: 'Must be less than 150 character'
                          },
                          {
                            pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&'()]*$/),
                            message:
                              "Last name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')"
                          }
                        ]}
                      >
                        <Input
                          // onChange={handleLastName}
                          onBlur={handleLastName}
                          placeholder="Last Name"
                        />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="TFN"
                        name="tfn"
                        onChange={() => {
                          setTFNRules([
                            {
                              required: true,
                              message: 'Please enter TFN'
                            },
                            {
                              pattern: new RegExp(/^([0-9]+$)([ .-]?)/),
                              message: 'please enter valid TFN'
                            },
                            {
                              min: 8,
                              message: 'Minimum 8 digits are required in TFN'
                            },
                            {
                              max: 9,
                              message: 'Maximum 9 digits are allowed in TFN'
                            },
                            {
                              validator: (_, value) => {
                                if (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) {
                                  return Promise.reject(
                                    'Please enter valid TFN Number. All digits of same number are not allowed.'
                                  );
                                } else {
                                  if (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) {
                                    return Promise.reject(
                                      'Please enter valid TFN Number. All digits of same number are not allowed.'
                                    );
                                  }
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]);
                        }}
                        rules={TFNRules}
                      >
                        <Input placeholder="123412345" />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Email"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'Please enter valid Email'
                          },
                          {
                            required: true,
                            message: 'Please enter Email'
                          }
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Phone Number"
                        name="phone"
                        // rules={[
                        //   {
                        //     pattern: new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/),
                        //     message:
                        //       'Please enter valid Phone number & minimum 9 digits are required.'
                        //   },
                        //   {
                        //     max: 16,
                        //     message: 'Maximum 16 digits are allowed in Phone number'
                        //   }
                        // ]}
                      >
                        <InputMask
                          mask="99 9999 9999"
                          name="phone"
                          placeholder="12 1234 1234"
                          className="ant-input"
                        />
                        {/* <Input placeholder="Phone Number" /> */}
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Mobile Number"
                        name="mobile"
                        // rules={[
                        //   {
                        //     pattern: new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/),
                        //     message:
                        //       'Please enter valid Mobile number & minimum 10 digits are required.'
                        //   },
                        //   {
                        //     max: 13,
                        //     message: 'Maximum 13 digits are allowed in Mobile Number'
                        //   }
                        // ]}
                      >
                        <InputMask
                          mask="9999 999 999"
                          name="mobile"
                          placeholder="1234 123 123"
                          className="ant-input"
                        />
                        {/* <Input
                          placeholder="Mobile Number"
                          // prefix={<span>+61</span>}
                        /> */}
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Business Name"
                        name="business_name"
                        rules={[
                          {
                            max: 150,
                            message: 'Must be less than 150 character'
                          },
                          {
                            pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&'-()]*$/),
                            message: `Business name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                          }
                        ]}
                      >
                        <Input
                          // onChange={handleBussinessName}
                          onBlur={handleBussinessName}
                          placeholder="Business Name"
                        />
                      </Form.Item>

                      <Form.Item
                        className="half-width"
                        label="ABN"
                        name="abn"
                        // rules={[
                        //   {
                        //     len: 11,
                        //     message: 'Must be 11 digit number'
                        //   },
                        //   {
                        //     pattern: new RegExp(/^[0-9]+$/),
                        //     message: 'Value should be number'
                        //   }
                        // ]}
                      >
                        <InputMask
                          mask="99 999 999 999"
                          name="abn"
                          placeholder="12 123 123 123"
                          className="ant-input"
                        />
                        {/* <Input placeholder="12345678911" /> */}
                      </Form.Item>
                      {/* <Form.Item className="half-width" label="Client Type" name="customer_type">
                        <Select
                          showSearch={false}
                          optionFilterProp="children"
                          onSearch={onSearch}
                          placeholder="Please select Client Type"
                        >
                          {ClientType.map((type) => (
                            <Select.Option key={type.id} value={type.id}>
                              {type.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> */}
                      {/* <Form.Item className="half-width" label="Type" name="status">
                        <Select
                          showSearch={false}
                          optionFilterProp="children"
                          onSearch={onSearch}
                          placeholder="Please select Type"
                        >
                          {ClientStatus.map((type) => (
                            <Select.Option key={type.id} value={type.id}>
                              {type.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> */}
                      <Form.Item className="half-width" label="Related to" name="related_to">
                        <UserSelect
                          formStyle={{ width: '100%' }}
                          customerId={reletedToSelect ? reletedToSelect : ''}
                          onCustomerSelectionChange={(id) => {
                            onReletedToSelect(id);
                          }}
                          placeholder="Please select Related to"
                          className="customer-select"
                        />
                      </Form.Item>
                      <Form.Item className="half-width" label="Deceased" name="deceased">
                        <DatePicker
                          value=""
                          format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Opening Balance"
                        name="opening_balance"
                      >
                        <InputNumber placeholder="0.00" />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="CRN"
                        name="crn"
                        rules={[
                          {
                            pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                            message: 'Value should be Alphanumberic'
                          }
                        ]}
                      >
                        <Input placeholder="Client Reference Number" />
                      </Form.Item>

                      <div className="address-box">
                        <h5>Postal Address</h5>
                        <Form.Item
                          className="half-width"
                          label="Address"
                          name="street"
                          rules={[
                            {
                              pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                              message: `Address can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
                            }
                          ]}
                        >
                          <Input placeholder="Street" />
                        </Form.Item>
                        <Form.Item className="half-width" label="Select State" name="state_id">
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            onChange={handleStateSelect}
                            placeholder="Please select State"
                            loading={stateLoading}
                          >
                            {stateList.map((item, i) => (
                              <Select.Option key={i} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item className="half-width" label="Select Suburb" name="suburb_id">
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            loading={suburbLoading}
                            placeholder="Please select Suburb"
                          >
                            {suburbList.length &&
                              suburbList.map((item, i) => (
                                <Select.Option key={i} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Post Code"
                          name="postal_code"
                          rules={[
                            {
                              pattern: new RegExp(/^\d{4}$/),
                              message: 'Must be 4 digit number'
                            }
                          ]}
                        >
                          <Input placeholder="Post Code" type="number" />
                        </Form.Item>
                      </div>
                      <div className="address-box">
                        <h5>Street Address</h5>
                        <Form.Item
                          className="half-width"
                          label="Address"
                          name="shipping_street"
                          rules={[
                            {
                              pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                              message: `Address can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
                            }
                          ]}
                        >
                          <Input placeholder="Street" />
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Select State"
                          name="shipping_state_id"
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            onChange={handleStateSelect1}
                            loading={stateLoading}
                            placeholder="Please select State"
                          >
                            {stateList.map((item, i) => (
                              <Select.Option key={i} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Select Suburb"
                          name="shipping_suburb_id"
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={onSearch}
                            loading={suburbLoading1}
                            placeholder="Please select Suburb"
                          >
                            {suburbList1.length &&
                              suburbList1.map((item, i) => (
                                <Select.Option key={i} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Post Code"
                          name="shipping_postal_code"
                          rules={[
                            {
                              pattern: new RegExp(/^\d{4}$/),
                              message: 'Must be 4 digit number'
                            }
                          ]}
                        >
                          <Input placeholder="Post Code" type="number" />
                        </Form.Item>
                      </div>
                      {customerData?.beneficiaries && customerData?.beneficiaries?.length > 0 && (
                        <div className="address-box">
                          <h5>
                            Beneficiaries
                            <span>
                              {' '}
                              <FontAwesomeIcon
                                icon={faPlus}
                                title="Add Beneficiary"
                                onClick={() => {
                                  navigate(`/settings?tab=beneficiaries&client_id=${customerId}`);
                                }}
                              />
                            </span>
                          </h5>
                          {customerData?.beneficiaries?.map((item) => {
                            return (
                              <>
                                <Form.Item className="half-width beneficiary">
                                  <Input
                                    value={item?.account_name ? item?.account_name : ''}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item className="half-width beneficiary">
                                  <Input value={item?.bsb ? item?.bsb : ''} disabled />
                                </Form.Item>
                                <Form.Item className="half-width beneficiary">
                                  <Input
                                    value={item?.account_number ? item?.account_number : ''}
                                    disabled
                                  />
                                </Form.Item>

                                <Form.Item className="half-width beneficiary">
                                  <Input value={item?.bank_name ? item?.bank_name : ''} disabled />
                                </Form.Item>
                              </>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="entry-card">
                    <h3>Bank Details</h3>
                    <div className="form-box">
                      <Form.Item
                        className="half-width"
                        label="Account Name"
                        name="account_name"
                        rules={[
                          {
                            max: 200,
                            message: 'Must be less than 200 character'
                          },
                          {
                            pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-'()]*$/),
                            message: `Account name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                          }
                        ]}
                      >
                        <Input placeholder="Account Name" />
                      </Form.Item>
                      {/* <div className="half-width"></div> */}
                      <Form.Item
                        label="Bank Name"
                        name="bank_name"
                        className="half-width"
                        validateTrigger={['onBlur']}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: 'Please select Bank Name'
                        //   }
                        // ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onSearch={handleBankSearch}
                          loading={bankLoading}
                          placeholder="Please select Bank"
                        >
                          {bankOptions.map((item, i) => (
                            <Select.Option key={i} value={item.abbreviation}>
                              {item.abbreviation} - {item.bank_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="BSB"
                        name="bsb"
                        // onChange={() => {
                        //   setBSBRule([
                        //     {
                        //       len: 7,
                        //       message: 'Must be 6 digit number'
                        //     },
                        //     {
                        //       pattern: new RegExp(/^([0-9\\-]+$)/),
                        //       message: 'Value should be number'
                        //     }
                        //   ])
                        // }}
                        // onBlur={() => {
                        //   setBSBRule([
                        //     {
                        //       len: 7,
                        //       message: 'Must be 6 digit number'
                        //     },
                        //     {
                        //       pattern: new RegExp(/^([X0-9\\-]+$)/),
                        //       message: 'Value should be number'
                        //     }
                        //   ])
                        // }}
                        // rules={bsbRule}
                      >
                        {/* <InputMask
                          mask="***-***"
                          name="bsb"
                          placeholder="123-456"
                          className="ant-input"
                        /> */}
                        <InputMask
                          mask="999-999"
                          name="bsb"
                          placeholder="123-456"
                          className="ant-input"
                        />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Account Number"
                        name="account_number"
                        validateTrigger={['onBlur']}
                        onChange={() => {
                          setAccountNumberRule([
                            {
                              pattern: new RegExp(/^\d{0,9}$/),
                              // pattern: new RegExp(/^([0-9 ]+$)/),
                              message: 'Must be less than 10 digit number'
                            },
                            {
                              validator: (_, value) => {
                                if (
                                  (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                                  (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                                  (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                                  (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                                  (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                                  (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                                  (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                                  (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                                ) {
                                  return Promise.reject(
                                    'Please enter valid Account Number. All digits of same number are not allowed.'
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]);
                        }}
                        onBlur={() => {
                          setAccountNumberRule([
                            {
                              // pattern: new RegExp(/^([X0-9 ]+$)/),
                              pattern: new RegExp(/^\d{0,9}$/),
                              message: 'Must be less than 10 digit number'
                            },
                            {
                              validator: (_, value) => {
                                if (
                                  (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                                  (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                                  (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                                  (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                                  (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                                  (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                                  (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                                  (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                                ) {
                                  return Promise.reject(
                                    'Please enter valid Account Number. All digits of same number are not allowed.'
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]);
                        }}
                        rules={accountNumberRule}
                      >
                        <Input placeholder="xxxxxxxx1234" />
                      </Form.Item>
                      <Form.Item
                        className="full-width"
                        name="fee_from_refund"
                        valuePropName="checked"
                        wrapperCol={{
                          span: 16
                        }}
                      >
                        <Checkbox>Pre-Payment of Refund</Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="entry-card">
                    <h3>Proof of Id for Client Verification (KYC)</h3>
                    <div className="form-box photos-section">
                      <div className="image-box" style={{ maxWidth: '16%' }}>
                        <ImgCrop grid>
                          <Upload
                            {...props}
                            // customRequest={(f) => dummyRequest(f)}
                            onChange={onChange}
                            onPreview={onPreview}
                          >
                            Edit
                          </Upload>
                        </ImgCrop>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'wrap',
                          width: '61%',
                          gridGap: '10px',
                          justifyContent: 'center'
                        }}
                      >
                        <div className="crop-block" style={{ maxWidth: 'calc(50% - 10px)' }}>
                          Primary ID
                          {/* <ImgCrop rotate> */}
                          <Upload
                            listType="picture-card"
                            fileList={primaryFileList}
                            onChange={onPrimaryChange}
                            beforeUpload={() => {
                              return false;
                            }}
                            onPreview={onPrimaryPreview}
                            accept="image/*"
                          >
                            {primaryFileList.length < 2 && '+ Upload'}
                          </Upload>
                          {/* </ImgCrop> */}
                        </div>
                        <div className="crop-block" style={{ maxWidth: 'calc(50% - 10px)' }}>
                          Secondary ID
                          {/* <ImgCrop rotate> */}
                          <Upload
                            listType="picture-card"
                            fileList={secondaryfileList}
                            onChange={onSeondaryChange}
                            beforeUpload={() => {
                              return false;
                            }}
                            onPreview={onSeondaryPreview}
                            accept="image/*"
                          >
                            {secondaryfileList.length < 2 && '+ Upload'}
                          </Upload>
                          {/* </ImgCrop> */}
                        </div>
                        <p style={{ fontSize: '10px' }}>
                          The ATO and the TPB do not recommend retaining id docs
                        </p>
                      </div>
                      <div className="crop-block" style={{ maxWidth: '17%' }}>
                        Authority Letter
                        <Upload
                          listType="picture-card"
                          id="authority"
                          fileList={authorityLetter}
                          beforeUpload={() => {
                            return false;
                          }}
                          onChange={handleAuthorityLetterChange}
                          accept="application/pdf"
                        >
                          {authorityLetter.length < 1 && '+ Upload'}
                        </Upload>
                      </div>
                      <div style={{ display: 'flex', gridGap: '30px', width: '100%' }}>
                        <Form.Item
                          className="half-width-div"
                          label="Verification Date"
                          name="date_verified"
                          rules={[
                            {
                              required: !!isVerificationRequired,
                              message: 'Please select date'
                            }
                          ]}
                        >
                          <DatePicker
                            format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                            disabledDate={disabledDate}
                            defaultValue={moment()}
                            onChange={onVerificationChange}
                          />
                        </Form.Item>

                        <Form.Item
                          className="half-width"
                          label="Verified By"
                          name="verified_by"
                          rules={[
                            {
                              required: !!isVerificationRequired,
                              message: 'Please select user'
                            }
                          ]}
                        >
                          <Select
                            optionFilterProp="children"
                            placeholder="Please select user"
                            onChange={onVerificationChange}
                          >
                            {userList.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.first_name} {item.last_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="half-width"
                          label="Method"
                          name="verification_method"
                          rules={[
                            {
                              required: !!isVerificationRequired,
                              message: 'Please select method'
                            }
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Please select method"
                            onChange={onVerificationChange}
                          >
                            {verificationMethods.map((ele, idx) => (
                              <Select.Option key={idx} value={ele.value}>
                                {ele.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <Form.Item
                        label="Note"
                        name="notes"
                        style={{ width: '100%' }}
                        rules={[
                          {
                            required: !!isVerificationRequired,
                            message: 'Please add note'
                          },
                          {
                            pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                            message:
                              'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                          },
                          {
                            min: 5,
                            message: 'The notes must be at least 5 characters.'
                          }
                        ]}
                      >
                        <TextArea
                          rows={4}
                          name="notes"
                          placeholder="You must verify 2 separate proof of identity docs.
                          How did you verify Individual's Full Name AND Either Residential Address OR Date of Birth?
                          Or Outline the reason (for eg. well-established client) Or Add Notes"
                          style={{ borderRadius: '10px' }}
                          onChange={onVerificationChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="entry-card">
                    <h3>Office Details</h3>
                    <div className="form-box">
                      <Form.Item className="half-width" label="Staff" name="staff_id">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onSearch={onSearch}
                          loading={stateLoading}
                        >
                          {staffList.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.first_name} {item.last_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item className="half-width" label="Manager" name="manager_id">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onSearch={onSearch}
                          loading={managerListLoading}
                        >
                          {managerList.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.first_name} {item.last_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {/* <Form.Item
                        className='half-width'
                        label='Office'
                        name='office_id'
                      >
                        <Select
                          showSearch
                          optionFilterProp='children'
                          onSearch={onSearch}
                          loading={officeListLoading}
                        >
                          {officeList.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.street} , {item.office_suburb}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> */}
                      <Form.Item className="half-width" label="Tag" name="tag">
                        <Select showSearch optionFilterProp="children" onSearch={onSearch}>
                          {tagList.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="action-box">
                    <Button
                      type="primary"
                      loading={loading}
                      disabled={loading}
                      className="saveBtn"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                    <CancelButton />
                  </div>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CustomerEdit;
