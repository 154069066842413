import React from 'react';
import { Button, Modal, Spin } from 'antd';

const PostConfirmationModal = ({
  handlePost,
  finalPostData,
  handleClosePostModal,
  postLoading
}) => {
  return (
    <div>
      <Modal
        className="remove-popup confirmModal"
        width={500}
        closable
        centered
        open
        footer={null}
        onCancel={handleClosePostModal}
      >
        <div className="remove-content">
          <Spin spinning={postLoading}>
            <p>Are your sure you want to proceed with this transaction?</p>
            <div className="modal-action">
              <Button className="cancel" onClick={handleClosePostModal}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handlePost(finalPostData);
                }}
              >
                OK
              </Button>
            </div>
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

export default PostConfirmationModal;
