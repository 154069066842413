import React, { useState, useEffect, useCallback } from 'react';
import { Divider, Select, Space } from 'antd';
import MynaSelect from '../../components/Select';
import useCustomerList from '../../pages/customer/useCustomerList';
import { debounce } from 'lodash';

const UserSelectWithNewUser = (props) => {
  const {
    onCustomerSelectionChange,
    isLoadingCustomerDetail,
    customerId,
    formProps,
    formStyle,
    placeholder,
    disabled,
    handleAddNewClient,
    onClear
  } = props;

  const {
    customersList,
    isCustomerLoading,
    pagingData,
    setSearchParam,
    searchParam,
    fetchCustomers
  } = useCustomerList(0, customerId, undefined, false);
  // const [ isLoadingCustomerDetail, setIsLoadingCustomerDetail ] = useState(false);
  const [selectInput, setSelectInput] = useState([]);
  const [customers, setCustomers] = useState(customersList);

  useEffect(() => {
    if (!isCustomerLoading) {
      fetchCustomerData(pagingData.current, customerId ? customerId : searchParam);
      customerId && searchParam && fetchCustomerData();
    }
  }, []);

  useEffect(() => {
    const response = customers.map((item) => (
      <Select.Option key={Number(item.id)} value={Number(item.id)} email={item.email}>
        {item.first_name} {item.last_name} {item?.crn ? '('+ item.crn +")" : ''}
      </Select.Option>
    ));
    if (isCustomerLoading) {
      response.push(
        <Select.Option key="loading">
          {'Loading'} {'...'}
        </Select.Option>
      );
    }

    setSelectInput([...response]);
  }, [customers, isCustomerLoading]);

  const handleSearchChange = (e) => {
    debouncedSave(e);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      onSearch(nextValue);
    }, 400),
    []
  );


  const onSearch = async (val) => {
    // if (!isCustomerLoading) {
    // setIsCustomerLoading(true);
    // if (searchParam !== val)
    // setCustomers([])
    await fetchCustomerData(1, val);
    setSearchParam(val);
    // const data = await fetchCustomers(1, val);
    // setCustomers(data.data);
    // setIsCustomerLoading(false);
    // setPagingData({ ...pagingData, current: 1 });
    // }
  };

  const fetchCustomerData = async (page, searchKey) => {
    // const data = await fetchCustomers(pagingData.current, searchParam);
    // // setPagingData({ current: data.current_page, total: data.total, pageSize: data.per_page });

    // if (data)
    //   setCustomers([ ...customers, ...data.data ]);

    const data = await fetchCustomers(page, searchKey);

    if (data && data?.data.length > 0) {
      // let pagingData = {}
      // if (flag == "INITIAL") {
      //   pagingData = { current: data.current_page, total: data.total, pageSize: data.per_page }
      // } else {
      //   pagingData = { ...pagingData, current: data.current_page }
      // }
      // setPagingData(pagingData);
      if (page == 1) {
        setCustomers(data.data);
      } else {
        setCustomers([...customers, ...data.data]);
      }
    }
  };

  const onScroll = async (event) => {
    var target = event.target;
    if (
      !isCustomerLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      (!pagingData?.pageSize || pagingData.pageSize * pagingData.current == customers.length)
    ) {
      if (pagingData.total > customers.length) {
        target.scrollTo(0, target.scrollHeight);
        await fetchCustomerData(pagingData.current + 1, searchParam);
      }
    }
  };

  // const onCustomerSelectionChange = async (value) => {
  //     setHasBankDetail(true);
  //     setIsLoadingCustomerDetail(true);
  //     const data = await getCustomerDetail(value);
  //     const {
  //         customer_bank: { account_name, account_number, bsb },
  //     } = data;
  //     const hasDetail = !!account_name && !!account_number && !!bsb;
  //     setHasBankDetail(hasDetail);
  //     setIsLoadingCustomerDetail(false);
  // };

  return (
    <MynaSelect
      onSearch={(e) => handleSearchChange(e)}
      loading={isCustomerLoading}
      placeholder={placeholder ? placeholder : 'Select Client Name'}
      onPopupScroll={onScroll}
      formStyleWidth={formStyle}
      onSelect={(e) => {
        onSearch('');
        onCustomerSelectionChange(e);
      }}
      onClear={onClear}
      // onSelect={onCustomerSelectionChange}
      onBlur={() => onSearch('')}
      disabled={isLoadingCustomerDetail || disabled}
      selectValueList={selectInput}
      defaultValue={customerId}
      value={customerId}
      formProps={formProps}
      filterOption={(input, option) => {
        return (
          (option.props.children &&
            option.props.children.join('').toLowerCase().indexOf(String(input).toLowerCase()) >=
              0) ||
          (option.props.email && option.props.email.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        );
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0'
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px'
            }}
          >
            <span
              type="text"
              onClick={() => {
                handleAddNewClient();
              }}
            >
              <strong>+ Add New Client</strong>
            </span>
          </Space>
        </>
      )}
    />
  );
};

export default UserSelectWithNewUser;
