import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, Input, Button, Select } from 'antd';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer/Index';
import useABAList from './useABAList';
import { getTableColumns } from './ABAHelper';
import { API, defaultPaginationLimit, fileUpload, get } from '../../config.js';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import GenerateAbaApprovalModal from '../../components/modal/GenerateAbaApprovalModal';
import '../../App.css';
import '../../assets/css/style.css';
import { Modal, Button as BSButton } from 'react-bootstrap';
import { formatNumber } from '../../APIConfig/Interceptor.js';

const ABA = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(defaultPaginationLimit);
  const search = query.get('search');
  const selectedTab = query.get('tab');
  const currentPage = query.get('page');
  const { ABAs, isDataLoading, fetchABA, pagingData, onPageChange, onChange, searchParam } =
    useABAList(currentPage, search, limit);
  const [localABAs, setLocalABAs] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [approvalModal, setApprovalModal] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [grossAmountTotal, setGrossAmountTotal] = useState(0);
  const [showUndo, setShowUndo] = useState(false);
  const [undoId, setUndoId] = useState(null);
  // const [isPageChange, setIsPageChange] = useState(false);

  useEffect(() => {
    setLocalABAs(ABAs);
  }, [ABAs]);

  const handleModalClose = () => {
    setApprovalModal(false);
  };

  useEffect(() => {
    setLimit(query.get('limit') ?? defaultPaginationLimit);
  }, [query.get('limit')]);

  useEffect(() => {
    if (selectedTab === 'generate-aba') fetchABA();
  }, [selectedTab]);

  const handleGenerateAba = async (type) => {
    if (!selectedRowIds.length) {
      setError('Please select rows');
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('aba_ids', selectedRowIds);
      if (type == 1) {
        formData.append('type', type);
      }
      const { data } = await fileUpload(API.GENERATE_ABA, formData, {});
      setSelectedRowIds([]);
      setGrossAmountTotal();
      setApprovalModal(false);
      setLoading(false);
      toast.success(data.message);
      if (data) {
        const { data } = await get(`${API.GET_ABA_LIST}?page=1&limit=${limit}`);
        if (data.data.data?.length == 0) {
          props.onTabClick(2);
        } else {
          fetchABA();
        }
      }
    } catch (e) {
      setLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const generateFile = async () => {
    if (!selectedRowIds.length) {
      setError('Please select rows');
      return;
    }
    setApprovalModal(true);
  };

  const handleUndo = async (id) => {
    try {
      const { data } = await get(`${API.UNDO_ABA}/${id}`);
      toast.success(data.message);
      toggleUndoModal();
      fetchABA();
    } catch (e) {
      toggleUndoModal();
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const toggleUndoModal = (id) => {
    if (showUndo) {
      setUndoId();
    } else {
      if (id) {
        setUndoId(id);
      } else {
        setUndoId();
      }
    }
    setShowUndo(!showUndo);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowIds,
    onChange: (selectedRowKeys) => {
      // if (isPageChange) {
      //   setSelectedRowIds([...selectedRowIds, ...selectedRowKeys]);
      //   setIsPageChange(false);
      // } else {
      // }
      let grossAmount = 0;
      selectedRowKeys?.map(
        (item) =>
          (grossAmount =
            grossAmount + localABAs?.find((localItem) => localItem?.id === item)?.gross_amount)
      );
      setGrossAmountTotal(grossAmount);
      setSelectedRowIds([...selectedRowKeys]);
      setError(null);
    }
  };

  const handleChange = async (value) => {
    setLimit(value);
    const { data } = await get(`${API.GET_ABA_LIST}?page=1&limit=${value}`);
    if (data.data.data?.length == 0) {
      props.onTabClick(2);
    } else {
      navigate(`/aba?page=1&tab=generate-aba&limit=${value}`, {
        replace: true
      });
    }
  };

  return (
    <>
      <div className="cms-page aba-page">
        <div className="table-top-btn">
          <div className="search-section">
            <Input placeholder="Search Client" onChange={onChange} value={searchParam} />
          </div>
          <div className="aba-record-count">Selected Record: {selectedRowIds.length}</div>
          <div className="aba-record-count">Gross Amount: {formatNumber(grossAmountTotal)}</div>
          <div className="table-btn">
            <Button variant="primary" onClick={generateFile}>
              Generate .ABA
            </Button>
          </div>
        </div>
        {error && <p className="text-danger">{error}</p>}
        <div className='aba-table'>
          <Table
            rowKey={(record) => record.id}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            columns={getTableColumns(toggleUndoModal)}
            dataSource={localABAs}
            loading={isDataLoading}
            pagination={{
              hideOnSinglePage: true,
              defaultCurrent: 1,
              showQuickJumper: true,
              onChange: (page) => {
                onPageChange(page, limit);
                // setIsPageChange(true);
              },
              itemRender: PaginationItemRenderer,
              pageSize: limit,
              ...pagingData
            }}
          />
          {localABAs?.length > 0 && (
            <div className="mt-2">
              <div className="col-md-12">
                Showing&nbsp;
                <Select
                  defaultValue={`${defaultPaginationLimit}`}
                  value={limit}
                  onChange={handleChange}
                  options={[
                    { value: '10', label: '10' },
                    { value: '25', label: '25' },
                    { value: '50', label: '50' },
                    { value: '100', label: '100' }
                  ]}
                />
                &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                {pagingData?.to} from {pagingData?.total} Records
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
      <GenerateAbaApprovalModal
        showModal={approvalModal}
        handleModalClose={handleModalClose}
        handleSubmit={handleGenerateAba}
        loading={loading}
      />
      {showUndo && (
        <Modal
          className="small-modal remove-popup"
          show={showUndo}
          onHide={toggleUndoModal}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton={toggleUndoModal}></Modal.Header>
          <Modal.Body>
            <div className="remove-content">
              <p>Are you sure do you want to undo this refund?</p>
              <div className="modal-action">
                <BSButton className="cancel" onClick={toggleUndoModal}>
                  Cancel
                </BSButton>
                <BSButton onClick={() => handleUndo(undoId)}>OK</BSButton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ABA;
