import { useEffect, useState, useCallback } from 'react';
import { API, post } from '../../config.js';
import { sortData } from './DashboardHelper.js';

const useDashboardList = (currentPage, filter, sortValue) => {
  const [returnLodged, setReturnLodged] = useState([]);
  const [ATOList, setATOList] = useState([]);
  const [returnAssessed, setReturnAssessed] = useState([]);
  const [underReview, setUnderReview] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [amountData, setAmountData] = useState('');
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });

  const fetchDashboardData = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const { data } = await post(`${API.DASHBOARD}?page=${pagingData.current}`, filter);
      const {
        data: { tax_return_lodged, orphanATOs, tax_return_assessed, under_review, processed }
      } = data;
      const { returnLodgedData, atoData, returnAssessedData, underReviewData, completedData } = sortData(
        sortValue,
        tax_return_lodged,
        orphanATOs,
        tax_return_assessed,
        under_review,
        processed
      );
      setReturnLodged({ ...tax_return_lodged, data: returnLodgedData });
      setATOList({...orphanATOs, data: atoData})
      setReturnAssessed({ ...tax_return_assessed, data: returnAssessedData });
      setUnderReview({ ...under_review, data: underReviewData });
      setCompleted({ ...processed, data: completedData });
      setIsDataLoading(false);
      setAmountData({
        processedFeesAmount: data.data.processedFeesAmount,
        processedGrossAmount: data.data.processedGrossAmount,
        processedRemittanceAmount: data.data.processedRemittanceAmount
      });
    } catch (e) {
      setReturnLodged([]);
      setATOList([]);
      setReturnAssessed([]);
      setUnderReview([]);
      setCompleted([]);
      setIsDataLoading(false);
      setPagingData({ current: 0 });
      setAmountData('');
    }
  }, [pagingData.current, filter.filter, filter.start_date, filter.end_date]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  return {
    fetchDashboardData,
    returnLodged,
    ATOList,
    returnAssessed,
    underReview,
    completed,
    isDataLoading,
    pagingData,
    setCompleted,
    setUnderReview,
    setReturnAssessed,
    setReturnLodged,
    setATOList,
    amountData
  };
};

export default useDashboardList;
