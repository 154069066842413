import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import AddEditBeneficiaries from './AddEditBeneficiaries';
import BeneficiaryDeleteModal from './BeneficiaryDeleteModal';

const BeneficiActionButton = ({ getBeneficiaryData, clientId, record }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleEdit = () => {
    setShowEdit(!showEdit);
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => {
          handleEdit();
        }}
        size="1x"
      />
      &nbsp;&nbsp;/&nbsp;&nbsp;
      <FontAwesomeIcon
        icon={faTrash}
        onClick={() => {
          toggleDeleteModal();
        }}
        size="1x"
      />
      {showDelete && (
        <BeneficiaryDeleteModal
          getBeneficiaryData={getBeneficiaryData}
          toggleDeleteModal={toggleDeleteModal}
          // record={record}
          clientId={clientId}
          beneficiaryId={record?.id}
        />
      )}
      <Modal
        className="mid-modal supplier-modal"
        id="supplier_modal"
        show={showEdit}
        onHide={handleEdit}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Edit beneficiaries</Modal.Header>
        <Modal.Body>
          <AddEditBeneficiaries
            // beneficiaries={beneficiaries}
            getBeneficiaryData={getBeneficiaryData}
            onClose={handleEdit}
            clientId={clientId}
            setShow={setShowEdit}
            record={record}
            beneficiaryId={record?.id}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BeneficiActionButton;
