import React, { useEffect, useState } from 'react';
import SubscriptionDeatils from './SubscriptionDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import CurrentSubscription from './CurrentSubscription';
import { API, get } from '../../../config.js';
import { toast } from 'react-toastify';
// import { GetCookies } from '../../../APIConfig/Interceptor';
import { SetCookie } from '../../../APIConfig/Interceptor';
const { TabPane } = Tabs;
export default function Subscription({ setBalanceInfo }) {
  const tabsKey = {
    'current-subscription': 1,
    'more-packages': 2
  };
  // let subscription = GetCookies('_subscription');

  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const selectedTab =
    // subscription ?
    query.get('childTab') || 'more-packages';
  // (subscription ? 'current-subscription' : 'more-packages');
  const defaultActiveKey = tabsKey[selectedTab];
  const [subscriptionDetail, setsubscriptionDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [detail, setDetails] = useState([]);
  const [supportPlan, setSupportPlan] = useState([]);

  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName == 'current-subscription') {
      getsubscription();
    }
    if (tabName == 'more-packages') {
      getsubscription();
      setsubscriptiondata();
    }
    if (tabName) {
      navigate(`/settings?tab=subscription&childTab=${tabName}`, {
        replace: true
      });
    }
  };

  const getsubscription = async () => {
    try {
      setIsLoading(true);
      const { data } = await get(API.GET_SUBSCRIPTION);
      if (data) {
        SetCookie('_subscription', 1);
        setsubscriptionDetail(data?.data?.customer ? data?.data?.customer : {});
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      const errors = error?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const setsubscriptiondata = async () => {
    setIsLoading(true);
    const { data } = await get(API.Subscription);
    let arr = [
      { type: 'supportPlan', plan: [] },
      { type: 'month', plan: [] },
      { type: 'year', plan: [] }
    ];
    data.data.map((item) => {
      if (item.metadata.type == 'month') {
        arr[1].plan.push(item);
      } else {
        arr[2].plan.push(item);
      }
    });

    if (data?.supportPlans?.metadata?.type == 'support_plan') {
      arr[0].plan.push(data?.supportPlans);
    }

    // Sorting Based on Price (Month)
    arr[1].plan.sort(function (a, b) {
      return a?.prices?.data[0]?.price - b?.prices?.data[0]?.price;
    });

    // Sorting Based on Price (Year)
    arr[2].plan.sort(function (a, b) {
      return a?.prices?.data[0]?.price - b?.prices?.data[0]?.price;
    });

    // Sorting Based on Price (Year)
    arr[0].plan.sort(function (a, b) {
      return a.prices.data[0].price - b.prices.data[0].price;
    });
    setCoupon(data.coupons);
    setDetails(arr);
    setSupportPlan(data?.supportPlans);
    setIsLoading(false);
  };

  useEffect(() => {
    getsubscription();
    setsubscriptiondata();
  }, []);

  return (
    <>
      <Tabs
        defaultActiveKey={defaultActiveKey.toString()}
        activeKey={defaultActiveKey.toString()}
        size="large"
        style={{ marginBottom: 32, paddingTop: '30px' }}
        onTabClick={onTabClick}
      >
        <TabPane
          forceRender={true}
          tab="Current Subscription"
          key="1"
          // disabled={subscription == 1 ? false : true}
        >
          <CurrentSubscription
            setBalanceInfo={setBalanceInfo}
            subscriptionDetail={subscriptionDetail}
            isLoading={isLoading}
          />
        </TabPane>
        <TabPane tab="More Packages" key="2">
          <SubscriptionDeatils
            detail={detail}
            coupon={coupon}
            setBalanceInfo={setBalanceInfo}
            subscriptionDetail={subscriptionDetail}
            isLoading={isLoading}
            supportPlan={supportPlan}
          />
        </TabPane>
      </Tabs>
    </>
  );
}
