import { Spin } from 'antd';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const GenerateAbaApprovalModal = (props) => {
  const { showModal, handleModalClose, handleSubmit, loading } = props;

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleModalClose}
      dialogClassName="modal-50w medium-popup aba-approve-mail-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>Send Email Confirmation!</Modal.Header>
      <Modal.Body>
        {loading && <Spin />}
        <div className="content-details aba-approve-mail-body">
          <p>Do you want to send receipt via email to client(s)?</p>
        </div>
      </Modal.Body>
      <div className="action-box aba-approve-mail-action">
        <Button type="primary" htmlType="submit" disabled={loading} onClick={() => handleSubmit(1)}>
          Yes
        </Button>
        <Button
          variant="cancel"
          className="reset"
          disabled={loading}
          onClick={() => handleSubmit(0)}
        >
          No
        </Button>
      </div>
    </Modal>
  );
};

export default GenerateAbaApprovalModal;
