import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Table, Spin } from 'antd';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, get } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import { clientWithoutBankColumns } from './reportHelper';
import { toast } from 'react-toastify';
import faqicon from '../../assets/images/bulb-icon.jpg';

const ClientWithoutBankDetailsReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [noBankClientData, setNoBankClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });

  const getNoBankClientReportData = useCallback(
    async (currentPageValue) => {
      try {
        setIsLoading(true);
        const { data } = await get(
          `${API.CLIENT_WITHOUT_BANK}?page=${
            currentPageValue ? currentPageValue : pagingData.current
          }`
        );
        setIsLoading(false);
        const { total, current_page, per_page } = data.data;
        setNoBankClientData(data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page
        });
      } catch (e) {
        const { data } = e.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
        setNoBankClientData({});
        setIsLoading(false);
        setPagingData({ current: currentPage || 1 });
      }
    },
    [pagingData.current]
  );

  useEffect(() => {
    getNoBankClientReportData();
  }, [getNoBankClientReportData]);

  const onPageChange = (page) => {
    setPagingData({ ...pagingData, current: page });
    navigate(`/nobank-client?page=${page}`, {
      replace: true
    });
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Clients Without Bank Details
            <Link to={{ pathname: '/help' }}>
              <img src={faqicon} />
            </Link>
          </h1>
          {isLoading ? (
            <Spin />
          ) : (
            <div className="content-details">
              <Table
                columns={clientWithoutBankColumns(navigate, location)}
                dataSource={noBankClientData?.data || []}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: pagingData.pageSize,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page),
                  itemRender: PaginationItemRenderer,
                  ...pagingData
                }}
              />
            </div>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default ClientWithoutBankDetailsReport;
