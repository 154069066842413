import React from 'react';
import { Upload } from 'antd';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const UploadFile = ({
  isMultiple = false,
  errorMsg,
  isLoading,
  handleUpload,
  handleFileChange,
  handleCancel,
  acceptFileFormat
}) => {
  const { Dragger } = Upload;

  const props = {
    name: 'file',
    multiple: isMultiple,
    headers: {
      authorization: 'authorization-text'
    },
    accept: acceptFileFormat
      ? acceptFileFormat
      : '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      handleFileChange(info);
    }
  };

  return (
    <div className="upload-div">
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <FontAwesomeIcon icon={faUpload} size="3x" />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
      {errorMsg && <span className="pt-2 error">{errorMsg}</span>}
      <div className="upload-btn-div">
        <Button
          disabled={isLoading}
          variant="primary"
          onClick={() => handleUpload()}
          className="upload"
        >
          {isLoading ? 'Loading...' : 'Upload'}
        </Button>
        <Button variant="secondary" onClick={() => handleCancel()} className="cancel-btn">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UploadFile;
