import React from 'react';

const BankList = () => {
  return (
    <div className="bank-list-ul">
      <ul>
        <li>Amigo</li>
        <li>AMP</li>
        <li>ANZ</li>
        <li>ANZ Plus</li>
        <li>Arab Bank Australia</li>
        <li>Aussie home loan</li>
        <li>Australian Miliary Bank</li>
        <li>Australian Mutual Bank</li>
        <li>Australian Unity Bank</li>
        <li>Auswide</li>
        <li>Bank Australia</li>
        <li>Bank First</li>
        <li>Bank of China</li>
        <li>Bank of Melbourne</li>
        <li>Bank of Queensland</li>
        <li>Bank of Sydney</li>
        <li>Bank of Us</li>
        <li>BankSA</li>
        <li>BankVic</li>
        <li>Bankwest</li>
        <li>BCU</li>
        <li>Bell Potter</li>
        <li>Bendigo Bank</li>
        <li>Beyond Bank</li>
        <li>BHCCU</li>
        <li>BOQ Specialist</li>
        <li>Cairns Bank</li>
        <li>CardServices</li>
        <li>Central Murray CU</li>
        <li>Central West CU</li>
        <li>Challenger</li>
        <li>Citi</li>
        <li>Coastline CU</li>
        <li>Coles Financial Services</li>
        <li>CommBiz</li>
        <li>Commonwealth</li>
        <li>Commonwealth Business</li>
        <li>Community FCU</li>
        <li>Credit Union SA</li>
        <li>DDH Graham</li>
        <li>Defence Bank</li>
        <li>Dnister</li>
        <li>Easy Street</li>
        <li>Family First</li>
        <li>Fire Services CU</li>
        <li>Firefighters Mutual Bank</li>
        <li>First Choice CU</li>
        <li>First Option Bank</li>
        <li>G&C Mutual Bank</li>
        <li>Gateway Bank</li>
        <li>Geelong Bank</li>
        <li>Goulburn Murray CU</li>
        <li>Great Southern Bank</li>
        <li>Health Professionals Bank</li>
        <li>Heritage</li>
        <li>Hiver Bank</li>
        <li>Horizon Bank</li>
        <li>HSBC</li>
        <li>HSBC Bank Wholesale Banking</li>
        <li>Hume Bank</li>
        <li>Illawarra Credit Union</li>
        <li>IMB</li>
        <li>ING</li>
        <li>Judo Bank</li>
        <li>Kogan Money Credit Cards</li>
        <li>Laboratories CU</li>
        <li>Macquarie Bank</li>
        <li>Maitland Mutual Limited</li>
        <li>Me Bank</li>
        <li>Move Bank</li>
        <li>Myer</li>
        <li>MyState Bank</li>
        <li>NAB</li>
        <li>Newcastle Permanent</li>
        <li>Orange Credit Union</li>
        <li>Paypal Australia</li>
        <li>People&apos;s choice</li>
        <li>PN Bank</li>
        <li>Police CU</li>
        <li>Qantas Premier Credit Cards</li>
        <li>QBank</li>
        <li>Qudos Bank</li>
        <li>Queensland Country</li>
        <li>Rabo Bank Farm Business</li>
        <li>Rabo Bank Online Savings</li>
        <li>RACQ Bank</li>
        <li>RAMS</li>
        <li>Regional Australia Bank</li>
        <li>Reliance Bank</li>
        <li>RSL Money</li>
        <li>Southern Cross CU</li>
        <li>St.George Bank</li>
        <li>Summerland CU</li>
        <li>Suncorp</li>
        <li>SWSCU</li>
        <li>Teachers Mutual Bank</li>
        <li>The Capricornian</li>
        <li>The MAC</li>
        <li>Thriday</li>
        <li>Transport Mutual CU</li>
        <li>Tyro Payments</li>
        <li>Ubank</li>
        <li>UniBank</li>
        <li>Unity Bank</li>
        <li>Unloan</li>
        <li>Up Bank</li>
        <li>Virgin Money</li>
        <li>Warwick CU</li>
        <li>WAW</li>
        <li>Westpac</li>
        <li>Westpac Business</li>
        <li>Woolworths</li>
        <li>Woolworths Team Bank</li>
      </ul>
    </div>
  );
};

export default BankList;
