/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
// import { debounce } from 'lodash';
import moment from 'moment';
import '../../App.css';
import '../../assets/css/style.css';
import './BankReconciliation.module.css';
import { Button, Col, Container, Form, Row, Dropdown } from 'react-bootstrap';
import { DatePicker, Select, Spin, Table, Tabs } from 'antd';
import { API, defaultPaginationLimit, deleteCall, fileUpload, get } from '../../config';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumber } from '../../APIConfig/Interceptor';
import {
  faEllipsisV,
  faMoneyBillTransfer,
  faSearch,
  faPaperPlane,
  faTrash
  // faSave
} from '@fortawesome/free-solid-svg-icons';
import ATOSearch from '../ato/ATOSearch';
import { useLocation } from 'react-router';
import InfinityScrollComponent from '../dashboard/InfinityScrollComponent';
import { getNextPageData, getUnderReviewedNextPageData } from './BankReconciliationHelper';
import AddNewContactModal from './AddNewContactModal';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import ATOSearchForList from '../ato/ATOSearchForList';
import { clientNameGetter, FullNameGetterWithoutChartAt } from '../../helper/fullNameGetter';
import { Input } from 'antd';
// import splitIcon from '../../assets/images/split.svg';
import ClientSelect from '../../components/Client/ClientSelect';
import { fetchBasiqData, fetchYodleeData } from '../general-setting/Integration/IntegrationHelper';
import numbro from 'numbro';
import RefreshTransactionModal from '../firm-trust/components/RefreshTransactionModal';

const { TextArea } = Input;
const { TabPane } = Tabs;

const BankReconciliation = ({ setBalanceInfo, isUnderReviewed, setDateFilter, dateFilter }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  const [data, setData] = useState([]);
  const [latestStatement, setLatestStatement] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [unSelectedItems, setUnSelectedItems] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const { Option } = Select;
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  // const [contactOptions, setContactOptions] = useState([]);
  // const [contactOptionsReplica, setContactOptionsReplica] = useState([]);
  // const [contactLoading, setContactLoading] = useState(false);
  // const [supplierOptions, setSupplierOptions] = useState([]);
  // const [supplierLoading, setSupplierLoading] = useState(false);
  // const [supplierOptionsReplica, setSupplierOptionsReplica] = useState([]);
  const [showSearchIndexs, setShowSearchIndexs] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastElement, setLastElement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [activeTab, setActiveTab] = useState('grid');
  const currentPageForList = query.get('page') ? parseInt(query.get('page')) : 1;
  const [limit, setLimit] = useState(
    query.get('limit') ? parseInt(query.get('limit')) : defaultPaginationLimit
  );
  const [pagingData, setPagingData] = useState({ current: currentPageForList || 1 });
  const [loadingForList, setLoadingForList] = useState(false);
  const [dataForList, setDataForList] = useState([]);
  const [showSearchIndexsForList, setShowSearchIndexsForList] = useState([]);
  const [searchRecord, setSearchRecord] = useState();
  const [expandedId, setExpandedId] = useState();

  const [addNewContact, setAddNewContact] = useState(false);
  const [newContactItem, setNewContactItem] = useState();
  const [showNewIndexs, setShowNewIndexs] = useState(-1);
  const [transactionIndex, setTransactionIndex] = useState(-1);
  const [newContactItemForList, setNewContactItemForList] = useState();
  const [addNewContactForList, setAddNewContactForList] = useState(false);
  const [showNewIndexsForList, setShowNewIndexsForList] = useState(-1);
  const [transactionIndexForList, setTransactionIndexForList] = useState(-1);

  const [basiqData, setBasiqData] = useState(null);
  const [yodleeData, setYodleeData] = useState(null);
  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState('ASC');

  const [accountFetchOptions, setAccountFetchOptions] = useState();
  const [accountId, setAccountId] = useState('');
  const [showRefresh, setShowRefresh] = useState(false);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const getDefaultDates = (from, to) => {
    if (from && to) {
      return { start_date: from, end_date: to };
    } else {
      // const previousYear = new Date();
      // previousYear.setMonth(previousYear.getMonth() - 6);
      // const pmonth = previousYear.getMonth() + 1;
      // const pday = previousYear.getDate();
      const previousYear =
        new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
      const previousYearDate = `01-04-${previousYear}`;
      const currentTime = new Date();
      const month = currentTime.getMonth() + 1;
      const day = currentTime.getDate();
      const year = currentTime.getFullYear();
      const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
        Number(month),
        2
      )}-${year}`;
      return { start_date: previousYearDate, end_date: currentDate };
    }
  };

  const [dateValue, setDateValue] = useState(() => getDefaultDates());

  const sortOptions = [
    {
      label: 'Oldest First',
      value: 'ASC',
      field: 'date'
    },
    {
      label: 'Latest First',
      value: 'DESC',
      field: 'date'
    }
  ];

  const handleSorting = (item) => {
    setSortField(item?.field);
    setSortOrder(item?.value);
  };
  // const [showRefresh, setShowRefresh] = useState(false);

  const { RangePicker } = DatePicker;
  // const dateFormat = 'DD-MM-YYYY';
  const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const handleChange = async (value) => {
    setLimit(value);
  };

  var matchedRecords = [];

  const getBankStatementReconcileData = () => {
    setLoading(true);
    let URL = `${API.BANK_RECONCILIATION_LIST}?limit=${currentPage * 10}&page=${1}&sort_column=${
      sortField ? sortField : ''
    }&sort_order=${sortOrder ? sortOrder : ''}&from=${dateFilter.start_date}&to=${
      dateFilter.end_date
    }`;
    if (isUnderReviewed) {
      URL = `${API.BANK_RECONCILIATION_LIST}?limit=${currentPage * 10}&page=${1}&sort_column=${
        sortField ? sortField : ''
      }&sort_order=${sortOrder ? sortOrder : ''}&from=${dateFilter.start_date}&to=${
        dateFilter.end_date
      }&status=2`;
    }
    get(URL)
      .then((response) => {
        const { data } = response;

        const balanceDetails = {};
        balanceDetails['firm_bank'] = data.firm_bank;
        balanceDetails['firm_balance'] = data.firm_balance;
        balanceDetails['statement_balance'] = data.statement_balance;
        balanceDetails['date'] = data?.latest_statement?.date;
        setBalanceInfo(balanceDetails);

        let accountActiveList = [];
        if (data?.connectedAccounts) {
          accountActiveList = data?.connectedAccounts?.filter((i) => i?.accountStatus === 1);
        }
        const accountActiveStatusList =
          accountActiveList.length > 0 &&
          accountActiveList.map((item) => {
            const list = {
              label:
                item?.yodlee_provider?.providerName +
                (item?.accountNumber
                  ? '-' +
                    item?.accountNumber +
                    (item?.accountStatus === 0 ? '(Inactive)' : '(Active)')
                  : ''),
              value: item?.accountId,
              mfa: item?.yodlee_provider?.isRealTimeMFA,
              accountId: item?.yodlee_provider?.providerAccountId
            };
            return list;
          });
        setAccountId(accountActiveStatusList[0]?.value);
        setAccountFetchOptions(accountActiveStatusList);

        if (data) {
          if (data?.latest_statement?.date) {
            let lastDate = data?.latest_statement?.date?.split(' ')?.[0]?.split('-');
            let startDate = `${padWithLeadingZeros(Number(lastDate[2]), 2)}-${padWithLeadingZeros(
              Number(lastDate[1]),
              2
            )}-${lastDate[0]}`;

            const currentTime = new Date();
            const month = currentTime.getMonth() + 1;
            const day = currentTime.getDate();
            const year = currentTime.getFullYear();
            const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
              Number(month),
              2
            )}-${year}`;
            setDateValue({ start_date: startDate, end_date: currentDate });
          } else if (data?.accounting_firm?.transaction_start_date) {
            let lastDate = data?.accounting_firm?.transaction_start_date?.split('-');
            let startDate = `${padWithLeadingZeros(Number(lastDate[2]), 2)}-${padWithLeadingZeros(
              Number(lastDate[1]),
              2
            )}-${lastDate[0]}`;

            const currentTime = new Date();
            const month = currentTime.getMonth() + 1;
            const day = currentTime.getDate();
            const year = currentTime.getFullYear();
            const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
              Number(month),
              2
            )}-${year}`;
            setDateValue({ start_date: startDate, end_date: currentDate });
          }
        }

        if (response.data.data.per_page <= 10) {
          setCurrentPage(response.data.data.current_page);
        }
        setData(response?.data?.data);
        setLatestStatement(response?.data?.latest_statement);
        const isMoreDataExist =
          response.data.data.data.length < response.data.data.total &&
          response.data.data?.next_page_url !== '' &&
          response.data.data?.next_page_url !== null;
        setHasMore(isMoreDataExist);

        setLoading(false);
      })
      .catch((e) => {
        setData([]);
        setLoading(false);
        const errorMessage = e.response?.data?.errors?.myna_error[0];
        if (errorMessage) {
          toast.error(errorMessage);
        }
      });
  };

  const getBankStatementReconcileDataForList = useCallback(
    async (currentPageValue) => {
      setLoadingForList(true);
      let URL = `${API.BANK_RECONCILIATION_LIST}?page=${
        currentPageValue ? currentPageValue : pagingData.current
      }&limit=${limit ? limit : defaultPaginationLimit}&sort_column=${
        sortField ? sortField : ''
      }&sort_order=${sortOrder ? sortOrder : ''}&from=${dateFilter.start_date}&to=${
        dateFilter.end_date
      }`;
      if (isUnderReviewed) {
        URL = `${API.BANK_RECONCILIATION_LIST}?page=${
          currentPageValue ? currentPageValue : pagingData.current
        }&limit=${limit ? limit : defaultPaginationLimit}&sort_column=${
          sortField ? sortField : ''
        }&sort_order=${sortOrder ? sortOrder : ''}&from=${dateFilter.start_date}&to=${
          dateFilter.end_date
        }&status=2`;
      }
      get(URL)
        .then((response) => {
          const { data } = response;

          const balanceDetails = {};
          balanceDetails['firm_bank'] = data.firm_bank;
          balanceDetails['firm_balance'] = data.firm_balance;
          balanceDetails['statement_balance'] = data.statement_balance;
          balanceDetails['date'] = data?.latest_statement?.date;
          setBalanceInfo(balanceDetails);

          let accountActiveList = [];
          if (data?.connectedAccounts) {
            accountActiveList = data?.connectedAccounts?.filter((i) => i?.accountStatus === 1);
          }
          const accountActiveStatusList =
            accountActiveList.length > 0 &&
            accountActiveList.map((item) => {
              const list = {
                label:
                  item?.yodlee_provider?.providerName +
                  (item?.accountNumber
                    ? '-' +
                      item?.accountNumber +
                      (item?.accountStatus === 0 ? '(Inactive)' : '(Active)')
                    : ''),
                value: item?.accountId,
                mfa: item?.yodlee_provider?.isRealTimeMFA,
                accountId: item?.yodlee_provider?.providerAccountId
              };
              return list;
            });
          setAccountId(accountActiveStatusList[0]?.value);

          if (data) {
            if (data?.latest_statement?.date) {
              let lastDate = data?.latest_statement?.date?.split(' ')?.[0]?.split('-');
              let startDate = `${padWithLeadingZeros(Number(lastDate[2]), 2)}-${padWithLeadingZeros(
                Number(lastDate[1]),
                2
              )}-${lastDate[0]}`;

              const currentTime = new Date();
              const month = currentTime.getMonth() + 1;
              const day = currentTime.getDate();
              const year = currentTime.getFullYear();
              const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
                Number(month),
                2
              )}-${year}`;
              setDateValue({ start_date: startDate, end_date: currentDate });
            } else if (data?.accounting_firm?.transaction_start_date) {
              let lastDate = data?.accounting_firm?.transaction_start_date?.split('-');
              let startDate = `${padWithLeadingZeros(Number(lastDate[2]), 2)}-${padWithLeadingZeros(
                Number(lastDate[1]),
                2
              )}-${lastDate[0]}`;

              const currentTime = new Date();
              const month = currentTime.getMonth() + 1;
              const day = currentTime.getDate();
              const year = currentTime.getFullYear();
              const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
                Number(month),
                2
              )}-${year}`;
              setDateValue({ start_date: startDate, end_date: currentDate });
            }
          }

          setAccountFetchOptions(accountActiveStatusList);

          setPagingData({
            total: response.data.data.total,
            current: response.data.data.current_page,
            defaultPageSize: response.data.data.per_page,
            from: response.data.data.from,
            to: response.data.data.to
          });
          setDataForList(response?.data?.data);
          setLatestStatement(response?.data?.latest_statement);
          setLoadingForList(false);
        })
        .catch((e) => {
          setDataForList([]);
          setLoadingForList(false);
          setPagingData({ current: currentPageForList || 1 });
          const errorMessage = e.response?.data?.errors?.myna_error[0];
          if (errorMessage) {
            toast.error(errorMessage);
          }
        });
    },
    [pagingData.current, limit]
  );

  useEffect(() => {
    if (selectedTab === 'reconcile' || selectedTab === 'under-review') {
      activeTab === 'grid' && getBankStatementReconcileData();
      activeTab === 'list' && getBankStatementReconcileDataForList();
      handleAccountChange();
      // handleContactList();
      // handleSupplierList();
    }
  }, [activeTab, selectedTab, sortField, sortOrder, dateFilter, limit]);

  useEffect(() => {
    if (activeTab === 'grid') {
      data.data?.length > 0 &&
        data.data.forEach((element) => {
          let total = 0;
          const checkBankDetails =
            element?.bank_reconciliation &&
            element?.bank_reconciliation.length > 0 &&
            element?.bank_reconciliation.map((elementData) => {
              total += elementData?.amount ? parseFloat(elementData?.amount) : 0;
              if (
                accountOptions?.length > 0 &&
                accountOptions?.find((val) => val?.id === elementData?.chart_account_id) &&
                elementData?.chart_account_id &&
                (elementData?.customer_id || elementData?.supplier_id) &&
                (elementData?.ato_id ||
                  elementData?.receipt_id ||
                  elementData?.payment_id ||
                  elementData?.recon_id) &&
                elementData?.description
              ) {
                return true;
              }
            });
          checkBankDetails[0] === true &&
            parseFloat(total?.toFixed(2)) === parseFloat(element?.amount) &&
            matchedRecords.push(element.id);
        });
    } else {
      dataForList.data?.length > 0 &&
        dataForList.data.forEach((element) => {
          let total = 0;
          const checkBankDetails =
            element?.bank_reconciliation &&
            element?.bank_reconciliation.length > 0 &&
            element?.bank_reconciliation.map((elementData) => {
              total += elementData?.amount ? parseFloat(elementData?.amount) : 0;
              if (
                accountOptions?.length > 0 &&
                accountOptions?.find((val) => val?.id === elementData?.chart_account_id) &&
                elementData?.chart_account_id &&
                (elementData?.customer_id || elementData?.supplier_id) &&
                (elementData?.ato_id ||
                  elementData?.receipt_id ||
                  elementData?.payment_id ||
                  elementData?.recon_id) &&
                elementData?.description
              ) {
                return true;
              }
            });
          checkBankDetails[0] === true &&
            parseFloat(total?.toFixed(2)) === parseFloat(element?.amount) &&
            matchedRecords.push(element.id);
        });
    }

    let selectedRecordCount = 0;
    let selectedRecord = [];
    for (let matchDataIndex = 0; matchDataIndex < matchedRecords?.length; matchDataIndex++) {
      if (!unSelectedItems?.find((item) => item === matchedRecords[matchDataIndex])) {
        selectedRecord.push(matchedRecords[matchDataIndex]);
        selectedRecordCount++;
      }
    }
    selectedRecord?.length > 0 && selectedRecord?.length === selectedRecordCount
      ? setAllChecked(true)
      : setAllChecked(false);
    setSelectedItems(selectedRecord);
  }, [data, dataForList, accountOptions, activeTab, unSelectedItems]);

  const onPageChange = (page) => {
    setPagingData({ ...pagingData, current: page });
    getBankStatementReconcileDataForList(page);
  };

  const deleteTransaction = (id) => {
    deleteCall(`${API.DELETE_BANK_STATEMENT}${id}`)
      .then((response) => {
        toast.success(response?.data?.message);
        if (response?.data?.errors?.myna_error) {
          toast.error(response?.data?.errors?.myna_error[0]);
        }
        if (response?.status === 200) {
          activeTab === 'grid' && getBankStatementReconcileData();
          activeTab === 'list' && getBankStatementReconcileDataForList();
          // getBankStatementReconcileData();
        }
      })
      .catch((e) => {
        toast.error(e?.data?.message);
      });
  };

  const handleAccountChange = () => {
    setAccountLoading(true);
    get(`${API.GET_CHARTACCOUNT}?limit=-1`)
      .then((response) => {
        setAccountOptions(response?.data?.data?.data ? response?.data?.data?.data : []);
        response && setAccountLoading(false);
      })
      .catch((e) => {
        setAccountLoading(false);
        toast.error(e?.data?.message);
      });
  };

  const handleAllCheckBox = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;

    if (itemName === 'checkall') {
      setAllChecked(checked);
      if (checked) {
        const allData = [];

        if (activeTab === 'grid' && data?.data?.length > 0) {
          data?.data?.forEach((item) => {
            if (!handleReconcileDisabled(item?.bank_reconciliation, item)) {
              allData.push(item.id);
            }
          });
        }

        if (activeTab === 'list' && dataForList?.data?.length > 0) {
          dataForList?.data?.forEach((item) => {
            if (!handleReconcileDisabled(item?.bank_reconciliation, item)) {
              allData.push(item.id);
            }
          });
        }

        setSelectedItems(allData);
      } else {
        setSelectedItems([]);
      }
    }
  };

  const handleSearchClose = (isUpdate = false) => {
    activeTab === 'grid' && setShowSearchIndexs([-1]);
    if (isUpdate) {
      activeTab === 'grid' && getBankStatementReconcileData();
    }
  };

  const handleSearchCloseForList = (isUpdate = false) => {
    activeTab === 'list' && setShowSearchIndexsForList([-1]);
    if (isUpdate) {
      activeTab === 'list' && getBankStatementReconcileDataForList();
    }
  };

  const handleBlurDescription = async (bankId, bank, Item) => {
    try {
      const formData = new FormData();
      formData.append('description', bank?.description ? bank?.description : Item?.description);
      const response = await fileUpload(`${API.UPDATE_BANK_RECONCILIATION}${bankId}`, formData, {});
      if (response.status === 200) {
        activeTab === 'grid' && getBankStatementReconcileData();
        activeTab === 'list' && getBankStatementReconcileDataForList();
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleBlurNewAmount = async (bankId, bank, Item) => {
    try {
      const formData = new FormData();
      if (bank?.amount > 0) {
        formData.append('description', bank?.description ? bank?.description : Item?.description);
        formData.append('amount', bank?.amount ? bank?.amount : 0);
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}${bankId}`,
          formData,
          {}
        );
        if (response.status === 200) {
          activeTab === 'grid' && getBankStatementReconcileData();
          activeTab === 'list' && getBankStatementReconcileDataForList();
        }
      } else {
        toast.error('Please enter amount greter than 0');
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const sendReview = async (ItemID, Item) => {
    try {
      const formData = new FormData();
      formData.append('send_for_review', 1);
      formData.append('description', Item?.description ? Item?.description : '');
      const response = await fileUpload(`${API.UPDATE_BANK_RECONCILIATION}${ItemID}`, formData, {});
      if (response.status === 200) {
        toast.success(response.data.message);
        activeTab === 'grid' && getBankStatementReconcileData();
        activeTab === 'list' && getBankStatementReconcileDataForList();
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  // const handleContactList = (search = '') => {
  //   setContactLoading(true);
  //   get(`${API.GET_CUSTOMERLIST}?search=${search}&limit=-1`)
  //     .then((response) => {
  //       let options = response?.data?.data?.data.map((element) => {
  //         return { id: element.id, name: FullNameGetterWithoutChartAt(element) };
  //       });
  //       setContactOptions(options ? options : []);
  //       // setContactOptionsReplica(options ? options : []);
  //       response && setContactLoading(false);
  //     })
  //     .catch((e) => {
  //       setContactLoading(false);
  //       toast.error(e?.data?.message);
  //     });
  // };

  // const handleSupplierList = (search = '') => {
  //   setSupplierLoading(true);
  //   get(`${API.SUPPLIERS}?search=${search}&limit=-1`)
  //     .then((response) => {
  //       let options = response?.data?.data?.data.map((element) => {
  //         return { id: element.id, name: element.name };
  //       });
  //       setSupplierOptions(options ? options : []);
  //       // setSupplierOptionsReplica(options ? options : []);
  //       response && setSupplierLoading(false);
  //     })
  //     .catch((e) => {
  //       setSupplierLoading(false);
  //       toast.error(e?.data?.message);
  //     });
  // };

  const getClassName = useCallback(
    (bankData, Item) => {
      let total = 0;
      if (bankData && bankData?.length > 0) {
        bankData?.map((item) => {
          total += item?.amount ? parseFloat(item?.amount) : 0;
          return 0;
        });
      }
      const checkData =
        bankData &&
        bankData.length > 0 &&
        bankData?.map((element) => {
          if (
            accountOptions?.length > 0 &&
            accountOptions?.find((val) => val?.id === element?.chart_account_id) &&
            element?.chart_account_id &&
            (element?.customer_id || element?.supplier_id) &&
            element?.amount &&
            element?.description &&
            (element?.ato_id || element?.receipt_id || element?.payment_id || element?.recon_id) &&
            formatNumber(parseFloat(total)) === formatNumber(parseFloat(Item?.amount))
          ) {
            return false;
          } else {
            return true;
          }
        });

      return Array.isArray(checkData) && checkData?.find((item) => item === true)
        ? 'refund-process-box red'
        : bankData && bankData.length > 0
        ? 'refund-process-box matched'
        : 'refund-process-box red';
    },
    [accountOptions]
  );

  const handleNewAccountSelection = async (id, bankId, bank, Item, index, bankIndex) => {
    if (bankId && handleCheck(Item?.bank_reconciliation)?.length < 1) {
      try {
        const formData = new FormData();
        formData.append('chart_account_id', id);
        formData.append('description', bank?.description ? bank?.description : Item?.description);
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}${bankId}`,
          formData,
          {}
        );
        if (response.status === 200) {
          activeTab === 'grid' && getBankStatementReconcileData();
          activeTab === 'list' && getBankStatementReconcileDataForList();
        }
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
      }
    } else {
      let newBankReconciliationData = [...data.data[index].bank_reconciliation];
      let newData = [...data.data];

      if (bankIndex > -1) {
        newBankReconciliationData[bankIndex] = {
          ...newBankReconciliationData[bankIndex],
          chart_account_id: id
        };
      }

      if (index > -1) {
        newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
        setData({ ...data, data: newData });
      }
    }
  };

  const handleNewContactSelection = async (id, optionValue, bank, Item, index, bankIndex) => {
    if (bank.id && handleCheck(Item?.bank_reconciliation)?.length < 1) {
      try {
        const formData = new FormData();
        if (optionValue.type === 1) {
          formData.append('customer_id', id);
        } else {
          formData.append('supplier_id', id);
        }
        formData.append('description', bank?.description ? bank?.description : Item?.description);
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}${bank.id}`,
          formData,
          {}
        );
        if (response.status === 200) {
          activeTab === 'grid' && getBankStatementReconcileData();
          activeTab === 'list' && getBankStatementReconcileDataForList();
        }
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
      }
    } else {
      if (optionValue.group === 'supplier') {
        let newBankReconciliationData = [...data.data[index].bank_reconciliation];
        let newData = [...data.data];

        if (bankIndex > -1) {
          newBankReconciliationData[bankIndex] = {
            ...newBankReconciliationData[bankIndex],
            supplier_id: id
          };
        }

        if (index > -1) {
          newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
          setData({ ...data, data: newData });
        }
      } else {
        let newBankReconciliationData = [...data.data[index].bank_reconciliation];
        let newData = [...data.data];

        if (bankIndex > -1) {
          newBankReconciliationData[bankIndex] = {
            ...newBankReconciliationData[bankIndex],
            customer_id: id
          };
        }

        if (index > -1) {
          newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };

          setData({ ...data, data: newData });
        }
      }
    }
  };

  const handleNewDescription = (e, index, bankIndex) => {
    const value = e.target.value;

    let newBankReconciliationData = [...data.data[index].bank_reconciliation];
    let newData = [...data.data];

    if (bankIndex > -1) {
      newBankReconciliationData[bankIndex] = {
        ...newBankReconciliationData[bankIndex],
        description: value
      };
    }

    if (index > -1) {
      newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
      setData({ ...data, data: newData });
    }
  };

  const handleNewAmount = (e, index, bankIndex) => {
    const value = e.target.value;

    let newBankReconciliationData = [...data.data[index].bank_reconciliation];
    let newData = [...data.data];

    if (bankIndex > -1) {
      newBankReconciliationData[bankIndex] = {
        ...newBankReconciliationData[bankIndex],
        amount: value
      };
    }

    if (index > -1) {
      newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
      setData({ ...data, data: newData });
    }
  };

  const handleReconcile = async (ItemID) => {
    try {
      const selectedItem = data.data.find((item) => item.id === ItemID);
      const formData = new FormData();
      formData.append('reconcile', 1);
      formData.append('description', selectedItem?.description ? selectedItem?.description : '');
      const response = await fileUpload(`${API.UPDATE_BANK_RECONCILIATION}${ItemID}`, formData, {});
      if (response.status === 200) {
        toast.success(response.data.message);
        activeTab === 'grid' && getBankStatementReconcileData();
        activeTab === 'list' && getBankStatementReconcileDataForList();
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        const errors = error?.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleCheckBox = (id) => {
    const oldIndex = [...selectedItems];
    const makeUnselected = [...unSelectedItems];

    if (selectedItems.indexOf(id) === -1) {
      oldIndex.push(id);
      makeUnselected.splice(unSelectedItems.indexOf(id), 1);
    } else {
      makeUnselected.push(id);
      oldIndex.splice(selectedItems.indexOf(id), 1);
    }
    if (oldIndex.length === data.data.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setSelectedItems(oldIndex);
    setUnSelectedItems(makeUnselected);
  };

  const handleReconcileAll = () => {
    if (selectedItems.length == 0) {
      toast.warning('Please select atleast one bank statement concilation');
    } else {
      const selectedFinalData = selectedItems.map((item) => {
        const dataItem = data.data.find((ele) => ele.id == item);
        if (dataItem) {
          return dataItem;
        } else {
          toast.error('Something went wrong');
        }
      });
      if (selectedFinalData.length > 0) {
        let isFormDataValidate = true;
        const formData = new FormData();
        selectedFinalData.map((itemData) => {
          // if ((itemData.customer_id || itemData.supplier_id) && itemData.chart_account_id) {
          formData.append('recon_ids[]', itemData?.id);
          formData.append('recon_desc[]', itemData?.description ? itemData?.description : '');
          // }
          // else {
          //   toast.error(
          //     `Please select account or Client Name field in ${moment(itemData?.date).moment(
          //       'DD-MM-YYYY'
          //     )} entry`
          //   );
          //   isFormDataValidate = false;
          // }
        });
        if ([...formData.entries()].length && isFormDataValidate) {
          fileUpload(API.BANK_RECONCILIATION_BULK, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.data.status == '200') {
                toast.success(response.data.message);
                activeTab === 'grid' && getBankStatementReconcileData();
                activeTab === 'list' && getBankStatementReconcileDataForList();
                // getBankStatementReconcileData();
              }
            })
            .catch((error) => {
              const { data } = error.response;
              toast.error(data?.message);
              toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
            });
        }
      }
    }
  };

  const handleReconcileDisabled = (bankData, Item) => {
    let total = 0;
    bankData &&
      bankData?.length > 0 &&
      bankData?.map((item) => {
        total += item?.amount ? parseFloat(item?.amount) : 0;
        return 0;
      });

    const checkData = bankData?.map((element) => {
      if (
        accountOptions?.length > 0 &&
        accountOptions?.find((val) => val?.id === element?.chart_account_id) &&
        element?.chart_account_id &&
        (element?.customer_id || element?.supplier_id) &&
        element?.amount &&
        element?.description &&
        (element?.ato_id || element?.receipt_id || element?.payment_id || element?.recon_id) &&
        formatNumber(parseFloat(total)) === formatNumber(parseFloat(Item?.amount))
      ) {
        return false;
      } else {
        return true;
      }
    });
    return checkData?.find((item) => item === true)
      ? true
      : bankData && bankData?.length > 0
      ? false
      : true;
  };

  // const handleSearch = (value) => {
  //   if (!value || value === '') {
  //     handleSupplierList();
  //     handleContactList();
  //   }
  //   const contacts = [...contactOptionsReplica];
  //   const suppliers = [...supplierOptionsReplica];
  //   var i;
  //   var updatedContactData = [];
  //   var updatedSupplierData = [];
  //   for (i = 0; i < contacts.length; i++) {
  //     if (contacts[i].name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
  //       updatedContactData.push(contacts[i]);
  //     }
  //   }
  //   for (i = 0; i < suppliers.length; i++) {
  //     if (suppliers[i].name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
  //       updatedSupplierData.push(suppliers[i]);
  //     }
  //   }
  //   setContactOptions(updatedContactData);
  //   setSupplierOptions(updatedSupplierData);
  // };

  const loadMore = async () => {
    setIsLoading(true);
    const nextPageData = isUnderReviewed
      ? await getUnderReviewedNextPageData(currentPage + 1, dateFilter, sortField, sortOrder)
      : await getNextPageData(currentPage + 1, dateFilter, sortField, sortOrder);

    const newObj = {
      ...nextPageData,
      data: [...data.data, ...nextPageData.data]
    };

    setData(newObj);
    setCurrentPage(newObj.current_page);
    setIsLoading(false);
    const isMoreDataExist =
      newObj.data.length < newObj.total &&
      nextPageData?.next_page_url !== '' &&
      nextPageData?.next_page_url !== null;
    setHasMore(isMoreDataExist);
  };

  const handleNewContact = (item) => {
    setAddNewContact(true);
    setNewContactItem(item);
  };

  const handleNewContactNameSelection = async (
    contact,
    contactType,
    newContactItem,
    transactionIndex,
    showNewIndexs
  ) => {
    if (newContactItem?.id) {
      try {
        const formData = new FormData();
        if (contactType === 'supplier') {
          formData.append('supplier_id', contact?.id);
        } else {
          formData.append('customer_id', contact?.id);
        }
        formData.append(
          'description',
          newContactItem?.description ? newContactItem?.description : ''
        );
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}${newContactItem?.id}`,
          formData,
          {}
        );
        if (response.status === 200) {
          activeTab === 'grid' && getBankStatementReconcileData();
          activeTab === 'list' && getBankStatementReconcileDataForList();
        }
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
      }
    } else {
      setLoading(true);
      if (contactType === 'supplier') {
        let newBankReconciliationData = [...data.data[transactionIndex].bank_reconciliation];
        let newData = [...data.data];

        if (showNewIndexs > -1) {
          newBankReconciliationData[showNewIndexs] = {
            ...newBankReconciliationData[showNewIndexs],
            supplier: contact,
            supplier_id: contact?.id
          };
        }

        if (transactionIndex > -1) {
          newData[transactionIndex] = {
            ...newData[transactionIndex],
            bank_reconciliation: newBankReconciliationData
          };
          setData({ ...data, data: newData });
        }
      } else {
        let newBankReconciliationData = [...data.data[transactionIndex].bank_reconciliation];
        let newData = [...data.data];

        if (showNewIndexs > -1) {
          newBankReconciliationData[showNewIndexs] = {
            ...newBankReconciliationData[showNewIndexs],
            customer: contact,
            customer_id: contact?.id
          };
        }

        if (showNewIndexs > -1) {
          newData[showNewIndexs] = {
            ...newData[showNewIndexs],
            bank_reconciliation: newBankReconciliationData
          };
          setData({ ...data, data: newData });
        }
      }
      setLoading(false);
    }
  };

  const handleNewContactNameSelectionForList = async (
    contact,
    contactType,
    newContactItem,
    transactionIndex,
    showNewIndexs
  ) => {
    if (newContactItem?.id) {
      try {
        const formData = new FormData();
        if (contactType === 'supplier') {
          formData.append('supplier_id', contact?.id);
        } else {
          formData.append('customer_id', contact?.id);
        }
        formData.append(
          'description',
          newContactItem?.description ? newContactItem?.description : ''
        );
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}${newContactItem?.id}`,
          formData,
          {}
        );
        if (response.status === 200) {
          activeTab === 'grid' && getBankStatementReconcileData();
          activeTab === 'list' && getBankStatementReconcileDataForList();
        }
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
      }
    } else {
      setLoadingForList(true);
      if (contactType === 'supplier') {
        let newBankReconciliationData = [...dataForList.data[transactionIndex].bank_reconciliation];
        let newData = [...dataForList.data];

        if (showNewIndexs > -1) {
          newBankReconciliationData[showNewIndexs] = {
            ...newBankReconciliationData[showNewIndexs],
            supplier: contact,
            supplier_id: contact?.id
          };
        }

        if (transactionIndex > -1) {
          newData[transactionIndex] = {
            ...newData[transactionIndex],
            bank_reconciliation: newBankReconciliationData
          };
          setDataForList({ ...dataForList, data: newData });
        }
      } else {
        let newBankReconciliationData = [...dataForList.data[transactionIndex].bank_reconciliation];
        let newData = [...dataForList.data];

        if (showNewIndexs > -1) {
          newBankReconciliationData[showNewIndexs] = {
            ...newBankReconciliationData[showNewIndexs],
            customer: contact,
            customer_id: contact?.id
          };
        }

        if (showNewIndexs > -1) {
          newData[showNewIndexs] = {
            ...newData[showNewIndexs],
            bank_reconciliation: newBankReconciliationData
          };
          setDataForList({ ...dataForList, data: newData });
        }
      }
      setLoadingForList(false);
    }
  };

  const handleAddNewContact = (bank, index, bankIndex) => {
    handleNewContact(bank);
    setShowNewIndexs(bankIndex);
    setTransactionIndex(index);
  };

  const handleNewContactForList = (item) => {
    setAddNewContactForList(true);
    setNewContactItemForList(item);
  };

  const handleAddNewContactForList = (bank, record, bankIndex) => {
    const index = dataForList?.data.findIndex((item) => item?.id === record?.id);
    handleNewContactForList(bank);
    setShowNewIndexsForList(bankIndex);
    setTransactionIndexForList(index);
  };

  const getBankReconciliationTableColumns = () => {
    return [
      {
        title: '',
        key: 'checkbox',
        // className: 'fullname',
        render: (record) => (
          <Form.Check
            type="checkbox"
            disabled={handleReconcileDisabled(record?.bank_reconciliation, record)}
            checked={selectedItems.indexOf(record.id) >= 0}
            onChange={() => {
              handleCheckBox(record.id);
            }}
          ></Form.Check>
        )
        // width: '1%'
      },
      {
        title: 'Date',
        key: 'date',
        render: (record) => (
          <div>{record?.date ? moment(record?.date).format('DD-MM-YYYY') : ''}</div>
        )
      },
      {
        title: 'Description',
        key: 'description',
        render: (record) => <div>{record?.description ? record?.description : ''}</div>
      },
      // {
      //   title: 'Credit',
      //   key: 'credit',
      //   render: (record) =>
      //     record?.transaction_type === 1 && record?.amount ? `${formatNumber(record?.amount)}` : ''
      //   // width: '15%'
      // },
      // {
      //   title: 'Debit',
      //   key: 'debit',
      //   render: (record) =>
      //     record?.transaction_type !== 1 && record?.amount ? `${formatNumber(record?.amount)}` : ''
      //   // width: '15%'
      // },
      {
        title: 'Amount',
        // key: 'amount',
        className: (record) => `${record?.transaction_type === 1 ? 'rag-green' : 'rag-red'}`,
        render: (record) => (
          <div className={`${record?.transaction_type === 1 ? 'rag-green' : 'rag-red'}`}>
            {record?.amount ? `${formatNumber(record?.amount)}` : ''}
          </div>
        )
        // width: '15%'
      },
      {
        title: 'Account',
        key: 'account',
        render: (record) => (
          <Select
            showSearch
            style={{ width: '50%', margin: '0' }}
            loading={accountOptions.length === 0 ? true : false}
            // onChange={(id) => handleAccountSelection(id, record.id, record)}
            onChange={(id) => {
              handleNewAccountSelectionForList(
                id,
                record?.bank_reconciliation[0]?.id,
                record?.bank_reconciliation[0],
                record,
                // index,
                0
              );
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // value={record?.chart_account_id}
            value={
              record?.bank_reconciliation[0]?.chart_account_id
                ? record?.bank_reconciliation[0]?.chart_account_id
                : accountOptions?.find((val) => val?.code == '3030')?.id
            }
          >
            {accountOptions &&
              accountOptions.map((option) => {
                return (
                  <Option key={option.id} value={option.id}>
                    {option?.name +
                      (option?.account_type?.name ? ' (' + option?.account_type?.name + ')' : '') +
                      (option?.code ? ' - ' + option?.code.toString() : '') +
                      (option?.sub_code ? '.' + option?.sub_code.toString() : '')}
                  </Option>
                );
              })}
          </Select>
        )
        // width: '16%'
      },
      {
        title: 'Client Name',
        key: 'contact_name',
        render: (record) => (
          <>
            {/* {record && !loadingForList && ( */}
            <ClientSelect
              formStyle={{ width: '100%' }}
              customerId={
                record?.bank_reconciliation[0]?.customer
                  ? clientNameGetter(
                      FullNameGetterWithoutChartAt(record?.bank_reconciliation[0]?.customer),
                      1
                    )
                  : record?.bank_reconciliation[0]?.supplier &&
                    clientNameGetter(record?.bank_reconciliation[0]?.supplier?.name, 2)
              }
              onCustomerSelectionChange={(e) => handlecontactChange(e)}
              bank={record?.bank_reconciliation[0]?.customer_id}
              onChange={(id, optionValue) => {
                handleNewContactForListSelection(
                  id,
                  optionValue,
                  record?.bank_reconciliation[0],
                  record,
                  0
                );
              }}
              handleAddNewContact={() => {
                handleAddNewContactForList(record?.bank_reconciliation[0], record, 0);
              }}
              // disabled={!record?.bank_reconciliation[0]?.chart_account_id}
              search={() => {
                const oldIndex = [...showSearchIndexsForList];
                if (showSearchIndexsForList.indexOf(record.id) === -1) {
                  oldIndex.push(record.id);
                } else {
                  oldIndex.splice(showSearchIndexsForList.indexOf(record.id), 1);
                }
                setShowSearchIndexsForList(oldIndex);
                setSearchRecord(record);
              }}
              data={dataForList}
              placeholder="Select Client"
              className="client-select"
            />
            {/* )} */}
          </>
        )
      },

      {
        title: 'Comment',
        key: 'description',
        render: (record) => (
          <TextArea
            required
            // type="text"
            id={record.id}
            placeholder="Add Description"
            value={
              record?.bank_reconciliation[0]?.description
                ? record?.bank_reconciliation[0]?.description
                : ''
            }
            onChange={(e) => {
              handleDescriptionForList(e, 0, record);
            }}
            onBlur={() => {
              handleCheck(record?.bank_reconciliation)?.length < 1 &&
                record?.bank_reconciliation[0]?.id &&
                handleBlurDescription(
                  record?.bank_reconciliation[0]?.id,
                  record?.bank_reconciliation[0],
                  record
                );
            }}
            rules={[
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
              }
            ]}
            style={{
              border: '1px solid #d9d9d9',
              padding: '5px 15px',
              fontSize: '14px'
              // width: 'calc(100% - 140px)'
            }}
          />
        )
        // width: '16%'
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (record) => (
          <Form.Control
            required
            type="number"
            id={record?.bank_reconciliation[0]?.id}
            placeholder="Add Amount"
            value={
              record?.bank_reconciliation[0]?.amount
                ? Number(record?.bank_reconciliation[0]?.amount).toFixed(2)
                : ''
            }
            onChange={(e) => {
              handleAmountForList(e, 0, record);
            }}
            onBlur={() => {
              handleCheck(record?.bank_reconciliation)?.length < 1 &&
                record?.bank_reconciliation[0]?.id &&
                handleBlurNewAmount(
                  record?.bank_reconciliation[0]?.id,
                  record?.bank_reconciliation[0],
                  record
                );
            }}
            style={{
              border: '1px solid #d9d9d9',
              padding: '5px 10px',
              fontSize: '14px',
              width: '100%'
            }}
          />
        )

        // width: '16%'
      },

      {
        title: 'Reconcile',
        key: 'reconcile',
        className: 'centeralign',
        render: (record) => (
          <div className="table-action-btn">
            {handleReconcileDisabled(record?.bank_reconciliation, record) === true ? (
              <FontAwesomeIcon
                title="Reconcile"
                size="5x"
                className="disabled-font-awesome"
                icon={faMoneyBillTransfer}
              />
            ) : (
              <FontAwesomeIcon
                title="Reconcile"
                size="5x"
                onClick={() => {
                  handleReconcile(record.id);
                }}
                icon={faMoneyBillTransfer}
              />
            )}
          </div>
        )
      },

      {
        title: 'Action',
        key: 'action',
        className: 'centeralign',
        render: (record) => (
          <div className="table-action-btn" style={{ whiteSpace: 'nowrap' }}>
            <FontAwesomeIcon
              title="Send For Review"
              icon={faPaperPlane}
              onClick={() => {
                // showCommentbox(item.id);
                sendReview(record.id, record);
              }}
            />
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              title="Search"
              onClick={() => {
                const oldIndex = [...showSearchIndexsForList];
                if (showSearchIndexsForList.indexOf(record.id) === -1) {
                  oldIndex.push(record.id);
                } else {
                  oldIndex.splice(showSearchIndexsForList.indexOf(record.id), 1);
                }
                setShowSearchIndexsForList(oldIndex);
                setSearchRecord(record);
              }}
              icon={faSearch}
            />
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              title="Delete"
              onClick={() => {
                deleteTransaction(record?.id);
                // sendReview(item.id);
              }}
              icon={faTrash}
            />
          </div>
        )
        // width: '4%'
      }
    ];
  };

  // eslint-disable-next-line no-unused-vars
  const hanldeAddNewAccount = (index, Item) => {
    let ca_id = '';
    Item &&
      Item.bank_reconciliation.map((item) => {
        if (item?.chart_account?.code == '3030') {
          ca_id = item?.chart_account?.id;
        }
      });
    const defaultNewBankData = {
      id: '',
      chart_account_id: ca_id ? ca_id : '',
      customer_id: '',
      supplier_id: '',
      amount: '',
      description: Item?.description ? Item?.description : ''
    };

    const newBankReconciliation = [...data.data[index].bank_reconciliation, defaultNewBankData];

    let newData = [...data.data];

    if (index > -1) {
      newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliation };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const hanldeAddNewAccountForList = (Item) => {
    let ca_id = '';
    Item &&
      Item.bank_reconciliation.map((item) => {
        if (item?.chart_account?.code == '3030') {
          ca_id = item?.chart_account?.id;
        }
      });

    const index = dataForList?.data.findIndex((item) => item.id === Item?.id);
    const defaultNewBankData = {
      id: '',
      chart_account_id: ca_id ? ca_id : '',
      customer_id: '',
      supplier_id: '',
      amount: '',
      description: Item?.description ? Item?.description : ''
    };

    const newBankReconciliation = [
      ...dataForList.data[index].bank_reconciliation,
      defaultNewBankData
    ];

    let newData = [...dataForList.data];

    if (index > -1) {
      newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliation };
      setDataForList({ ...data, ...{ data: newData } });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const hanldeDeleteNewAccount = (index, Item, bankIndex) => {
    data.data[index]?.bank_reconciliation.splice(bankIndex, 1);

    let newData = [...data.data];

    if (index > -1) {
      newData[index] = {
        ...newData[index],
        bank_reconciliation: [...data.data[index].bank_reconciliation]
      };
      setData({ ...data, ...{ data: newData } });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const hanldeDeleteNewAccountForList = (Item, bankIndex) => {
    const index = dataForList?.data.findIndex((item) => item.id === Item?.id);
    dataForList.data[index]?.bank_reconciliation.splice(bankIndex, 1);

    let newData = [...dataForList.data];

    if (index > -1) {
      newData[index] = {
        ...newData[index],
        bank_reconciliation: [...dataForList.data[index].bank_reconciliation]
      };
      setDataForList({ ...dataForList, ...{ data: newData } });
    }
  };

  const handleCheckSave = (bankData, Item) => {
    let total = 0;
    bankData &&
      bankData?.length > 0 &&
      bankData?.map((item) => {
        total += item?.amount ? parseFloat(item?.amount) : 0;
        return 0;
      });

    const checkData = bankData?.map((element) => {
      if (
        element?.chart_account_id &&
        (element?.customer_id || element?.supplier_id) &&
        element?.amount &&
        element?.description &&
        parseFloat(total) === parseFloat(Item?.amount)
      ) {
        return false;
      } else {
        return true;
      }
    });
    return checkData?.find((item) => item === true) ? true : false;
  };

  const handleAddBankDetails = useCallback(async (finalData, id) => {
    let oldData = [];
    let newData = [];
    let msg = 0;
    finalData?.length > 0 &&
      finalData?.forEach((item) => {
        if (parseInt(item?.amount) > 0) {
          if (item?.id === '') {
            newData.push(item);
          } else {
            oldData.push(item);
          }
        } else {
          msg = 1;
        }
      });
    if (msg) toast.error('Please enter amount greter than 0');

    for (let i = 0; i < oldData?.length; i++) {
      try {
        const formData = new FormData();
        formData.append(
          'chart_account_id',
          oldData[i]?.chart_account_id ? oldData[i]?.chart_account_id : ''
        );
        if (oldData[i]?.supplier_id) {
          formData.append('supplier_id', oldData[i]?.supplier_id);
        }
        if (oldData[i]?.customer_id) {
          formData.append('customer_id', oldData[i]?.customer_id);
        }
        formData.append('amount', oldData[i]?.amount ? oldData[i]?.amount : '');

        formData.append('description', oldData[i]?.description ? oldData[i]?.description : '');
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}${oldData[i]?.id}`,
          formData,
          {}
        );
        if (response?.status === 200) {
          if (i + 1 === oldData?.length) {
            try {
              const finalNewData = { bank_reconciliation: newData ? newData : [] };

              const formData = new FormData();
              formData.append(
                'bank_reconciliations',
                finalNewData ? JSON.stringify(finalNewData) : ''
              );
              formData.append('bank_statement_id', id ? id : '');

              fileUpload(`${API.NEW_BANK_RECONCILIATION}`, formData, {});
            } catch (error) {
              const { data } = error.response;
              if (data) {
                toast.error(data?.message);
                toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
              } else {
                toast.error('Something went wrong');
              }
            }
          }
          continue;
        } else {
          break;
        }
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
        break;
      }
    }

    if (activeTab === 'grid') {
      getBankStatementReconcileData();
    } else {
      getBankStatementReconcileDataForList();
    }
  });

  const handleCheck = (bankData) => {
    let newData = [];
    bankData?.length > 0 &&
      bankData?.forEach((item) => {
        if (item?.id === '') {
          newData.push(item);
        }
      });
    return newData;
  };

  const handleDescriptionForList = (e, bankIndex, Item) => {
    const index = dataForList?.data.findIndex((item) => item.id === Item?.id);
    const value = e.target.value;

    let newBankReconciliationData = [...dataForList.data[index].bank_reconciliation];
    let newData = [...dataForList.data];

    if (bankIndex > -1) {
      newBankReconciliationData[bankIndex] = {
        ...newBankReconciliationData[bankIndex],
        description: value
      };
    }

    if (index > -1) {
      newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
      setDataForList({ ...dataForList, data: newData });
    }
  };

  const handleAmountForList = (e, bankIndex, Item) => {
    const index = dataForList?.data.findIndex((item) => item.id === Item?.id);
    const value = e.target.value;

    let newBankReconciliationData = [...dataForList.data[index].bank_reconciliation];
    let newData = [...dataForList.data];

    if (bankIndex > -1) {
      newBankReconciliationData[bankIndex] = {
        ...newBankReconciliationData[bankIndex],
        amount: value
      };
    }

    if (index > -1) {
      newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
      setDataForList({ ...dataForList, data: newData });
    }
  };

  const handleNewAccountSelectionForList = async (id, bankId, bank, Item, bankIndex) => {
    const index = dataForList?.data.findIndex((item) => item.id === Item?.id);
    if (bankId && handleCheck(Item?.bank_reconciliation)?.length < 1) {
      try {
        const formData = new FormData();
        formData.append('chart_account_id', id);
        formData.append('description', bank?.description ? bank?.description : Item?.description);
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}${bankId}`,
          formData,
          {}
        );
        if (response.status === 200) {
          activeTab === 'grid' && getBankStatementReconcileData();
          activeTab === 'list' && getBankStatementReconcileDataForList();
        }
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
      }
    } else {
      let newBankReconciliationData = [...dataForList.data[index].bank_reconciliation];
      let newData = [...dataForList.data];

      if (bankIndex > -1) {
        newBankReconciliationData[bankIndex] = {
          ...newBankReconciliationData[bankIndex],
          chart_account_id: id
        };
      }

      if (index > -1) {
        newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
        setDataForList({ ...dataForList, data: newData });
      }
    }
  };

  const handleNewContactForListSelection = async (id, optionValue, bank, Item, bankIndex) => {
    const index = dataForList?.data.findIndex((item) => item.id === Item?.id);
    if (bank.id && handleCheck(Item?.bank_reconciliation)?.length < 1) {
      try {
        const formData = new FormData();
        if (optionValue.group === 'supplier') {
          formData.append('supplier_id', id);
        } else {
          formData.append('customer_id', id);
        }
        formData.append('description', bank?.description ? bank?.description : Item?.description);
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}${bank.id}`,
          formData,
          {}
        );
        if (response.status === 200) {
          activeTab === 'grid' && getBankStatementReconcileData();
          activeTab === 'list' && getBankStatementReconcileDataForList();
        }
      } catch (error) {
        const { data } = error.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
      }
    } else {
      if (optionValue.group === 'supplier') {
        let newBankReconciliationData = [...dataForList.data[index].bank_reconciliation];
        let newData = [...dataForList.data];

        if (bankIndex > -1) {
          newBankReconciliationData[bankIndex] = {
            ...newBankReconciliationData[bankIndex],
            supplier_id: id
          };
        }

        if (index > -1) {
          newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
          setDataForList({ ...dataForList, data: newData });
        }
      } else {
        let newBankReconciliationData = [...dataForList.data[index].bank_reconciliation];
        let newData = [...dataForList.data];

        if (bankIndex > -1) {
          newBankReconciliationData[bankIndex] = {
            ...newBankReconciliationData[bankIndex],
            customer_id: id
          };
        }

        if (index > -1) {
          newData[index] = { ...newData[index], bank_reconciliation: newBankReconciliationData };
          setDataForList({ ...dataForList, data: newData });
        }
      }
    }
  };

  const handlecontactChange = () => {};

  // eslint-disable-next-line no-unused-vars
  const getExpandedBankReconciliationTableColumns = (bankData, reconciledData) => {
    return [
      { title: '' },
      { title: '' },
      {
        title: 'Date',
        key: 'date'
      },
      {
        title: 'Description',
        key: 'description'
      },
      {
        title: 'Credit',
        key: 'credit'
      },
      {
        title: 'Debit',
        key: 'debit'
      },
      {
        title: 'Account',
        key: 'account',
        render: (record, reconciled, index) => (
          <Select
            showSearch
            style={{ width: '50%', margin: '0' }}
            loading={accountOptions.length === 0 ? true : false}
            onChange={(id) => {
              handleNewAccountSelectionForList(
                id,
                record.id,
                record,
                bankData,
                // index,
                index + 1
              );
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={
              accountOptions?.length > 0 &&
              accountOptions?.find((val) => val?.id === record?.chart_account_id) &&
              record?.chart_account_id
            }
          >
            {accountOptions &&
              accountOptions.map((option) => {
                return (
                  <Option key={option.id} value={option.id}>
                    {option?.name +
                      (option?.account_type?.name ? ' (' + option?.account_type?.name + ')' : '') +
                      (option?.code ? ' - ' + option?.code.toString() : '') +
                      (option?.sub_code ? '.' + option?.sub_code.toString() : '')}
                  </Option>
                );
              })}
          </Select>
        )
      },
      {
        title: 'Client Name',
        key: 'contact_name',
        render: (record, reconciled, index) => (
          <>
            {/* {record && !loadingForList && ( */}
            <ClientSelect
              formStyle={{ width: '100%' }}
              customerId={
                record?.customer
                  ? record?.customer
                    ? clientNameGetter(FullNameGetterWithoutChartAt(record?.customer), 1)
                    : record?.customer_id
                  : record?.supplier
                  ? clientNameGetter(record?.supplier?.name, 2)
                  : record?.supplier_id
              }
              onCustomerSelectionChange={(e) => handlecontactChange(e)}
              onChange={(id, optionValue) => {
                handleNewContactForListSelection(id, optionValue, record, bankData, index + 1);
              }}
              bank={record?.id}
              handleAddNewContact={() => {
                handleAddNewContactForList(record, bankData, 0);
              }}
              // disabled={!record?.chart_account_id}
              search={() => {
                const oldIndex = [...showSearchIndexsForList];
                if (showSearchIndexsForList.indexOf(record.id) === -1) {
                  oldIndex.push(record.id);
                } else {
                  oldIndex.splice(showSearchIndexsForList.indexOf(record.id), 1);
                }
                setShowSearchIndexsForList(oldIndex);
                setSearchRecord(record);
              }}
              data={dataForList}
              placeholder="Select Client"
              className="client-select"
            />
            {/* )} */}
          </>
        )
      },
      {
        title: 'Comment',
        key: 'description',
        render: (record, reconciled, index) => (
          <TextArea
            required
            // type="text"
            id={record.id}
            placeholder="Add Description"
            value={record?.description ? record?.description : ''}
            onChange={(e) => {
              handleDescriptionForList(e, index + 1, bankData);
            }}
            onBlur={() => {
              handleCheck(record?.bank_reconciliation)?.length < 1 &&
                record.id &&
                handleBlurDescription(record.id, record, bankData);
            }}
            rules={[
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
              }
            ]}
            style={{
              border: '1px solid #d9d9d9',
              padding: '5px 15px',
              fontSize: '14px'
              // width: 'calc(100% - 140px)'
            }}
          />
        )
      },
      {
        title: 'Amount',
        key: 'amount',
        render: (record, reconciled, index) => (
          <Form.Control
            required
            type="number"
            id={record?.id}
            placeholder="Add Amount"
            value={record?.amount ? Number(record?.amount).toFixed(2) : ''}
            onChange={(e) => {
              handleAmountForList(e, index + 1, bankData);
            }}
            onBlur={() => {
              handleCheck(record?.bank_reconciliation)?.length < 1 &&
                record.id &&
                handleBlurNewAmount(record.id, record, bankData);
            }}
            style={{
              border: '1px solid #d9d9d9',
              padding: '5px 10px',
              fontSize: '14px',
              width: '100%'
            }}
          />
        )
      },
      {
        title: 'Reconcile',
        key: 'reconcile',
        className: 'centeralign'
      },
      {
        title: 'Action',
        key: 'action',
        className: 'centeralign'
        // render: (record, reconciled, index) => (
        //   <div className="table-action-btn">
        //     {reconciledData?.length === index + 1 && (
        //       <b>
        //         <span
        //           className="reconcile-item-list-split"
        //           size="sm"
        //           title="Split"
        //           onClick={() => {
        //             hanldeAddNewAccountForList(bankData);
        //           }}
        //         >
        //           <img src={splitIcon} alt="" style={{ height: '16px' }} />
        //         </span>
        //       </b>
        //     )}
        //     {record?.id === '' && reconciledData?.length && (
        //       <>
        //         {index + 1 === reconciledData?.length && (
        //           <>
        //             <span></span>
        //             &nbsp;&nbsp;/&nbsp;&nbsp;{' '}
        //           </>
        //         )}

        //         <FontAwesomeIcon
        //           title="Add New"
        //           icon={faTrash}
        //           onClick={() => {
        //             hanldeDeleteNewAccountForList(bankData, index + 1);
        //           }}
        //         />

        //         {index + 1 === reconciledData?.length && (
        //           <>
        //             <span></span>
        //             &nbsp;&nbsp;/&nbsp;&nbsp;
        //             {handleCheckSave(bankData?.bank_reconciliation, bankData) ? (
        //               <FontAwesomeIcon
        //                 title="Save"
        //                 icon={faSave}
        //                 className="disabled-font-awesome"
        //               />
        //             ) : (
        //               <FontAwesomeIcon
        //                 title="Save"
        //                 icon={faSave}
        //                 onClick={() => {
        //                   handleAddBankDetails(bankData?.bank_reconciliation, bankData?.id);
        //                 }}
        //               />
        //             )}
        //           </>
        //         )}
        //       </>
        //     )}
        //   </div>
        // )
      }
    ];
  };

  const expandedRowRender = (record) => {
    return (
      <>
        <Table
          className="bankreconcilelist-table inner-table"
          rowKey={(record) => record.id}
          columns={getExpandedBankReconciliationTableColumns(
            record,
            record?.bank_reconciliation?.slice(1)
          )}
          dataSource={record?.bank_reconciliation?.slice(1) || []}
          pagination={false}
        />
      </>
    );
  };

  const handleExpanded = (expandedRows) => {
    const expandedData = dataForList?.data.find((item) => item.id === expandedRows[0]);
    expandedRows?.length > 0 && setExpandedId(expandedRows[0]);
    expandedData?.bank_reconciliation?.length === 1 && hanldeAddNewAccountForList(expandedData);
    if (expandedRows?.length < 1) {
      const closingData = dataForList?.data.find((item) => item.id === expandedId);

      if (closingData?.bank_reconciliation?.length > 0) {
        let newData = [];
        closingData?.bank_reconciliation?.length > 0 &&
          closingData?.bank_reconciliation?.forEach((item) => {
            if (item?.id === '') {
              newData.push(item);
            }
          });
        if (newData?.length > 0) {
          let someArray = closingData?.bank_reconciliation;

          let removeArray = newData;

          someArray = someArray.filter(function (obj) {
            return !this.has(obj.id);
          }, new Set(removeArray.map((obj) => obj.id)));

          const index = dataForList?.data.findIndex((item) => item.id === closingData?.id);

          let newBankReconciliationData = [...dataForList.data[index].bank_reconciliation];
          let newData1 = [...dataForList.data];

          // if (bankIndex > -1) {
          newBankReconciliationData = someArray;
          // }

          if (index > -1) {
            newData1[index] = {
              ...newData1[index],
              bank_reconciliation: newBankReconciliationData
            };
            setDataForList({ ...dataForList, data: newData1 });
          }
        }
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await fetchBasiqData();
        const yodlee = await fetchYodleeData();
        setYodleeData(yodlee);
        setBasiqData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // const getBankAccountsDetail = async () => {
  //   try {
  //     setLoading(true);
  //     const { data } = await fileUpload(`${API.GET_BANK_ACCOUNTS}`);
  //     setLoading(false);
  //     return data;
  //   } catch (e) {
  //     const { data } = e.response;
  //     if (data) {
  //       toast.error(data?.message);
  //       toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
  //     }
  //     setLoading(false);
  //     return null;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const toggleRefreshModal = () => {
  //   setShowRefresh(!showRefresh);
  // };

  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilter({
        start_date: startDate,
        end_date: endDate
      });
    } else {
      setDateFilter({
        start_date: '',
        end_date: ''
      });
    }
  };

  // const handleRefresh = () => {
  //   async function fetchData() {
  //     if (basiqData?.connection_status === 0 && yodleeData?.connection_status === 0) {
  //       toast.warning('Please connect with bank feeds.');
  //     } else {
  //       // setLoading(true);
  //       // const userDetailData = await getBankAccountsDetail();
  //       // setLoading(false);
  //       // if (userDetailData?.data?.accounts && userDetailData?.data?.accounts?.length > 0) {
  //       // const data = userDetailData?.data?.accounts?.map((item) => {
  //       //   return {
  //       //     account_id: item?.id,
  //       //     institution_name: item?.intitution_shortName,
  //       //     accountNo: item?.accountNo
  //       //   };
  //       // });
  //       try {
  //         // const formData = new FormData();
  //         // formData.append('accounts', data ? JSON.stringify(data) : '');
  //         // const { start_date, end_date } = dateFilter;
  //         // if (start_date && end_date) {
  //         setLoading(true);
  //         // toggleRefreshModal();
  //         // const response = await get(
  //         //   `${API.YODLEE_GET_TRANSACTIONS}?start_date=${start_date ? start_date : ''}&end_date=${
  //         //     end_date ? end_date : ''
  //         //   }`,
  //         //   {
  //         //     headers: { 'Content-Type': 'multipart/form-data' }
  //         //   }
  //         // );
  //         const response = await get(`${API.YODLEE_GET_TRANSACTIONS}`, {
  //           headers: { 'Content-Type': 'multipart/form-data' }
  //         });
  //         if (response?.data?.message) {
  //           toast.success(response?.data?.message);
  //         }
  //         if (activeTab === 'grid') {
  //           getBankStatementReconcileData();
  //         } else {
  //           getBankStatementReconcileDataForList();
  //         }
  //         // } else {
  //         //   toast.error('Start Date and End Date both are required');
  //         // }
  //       } catch (e) {
  //         setLoading(false);
  //         const errors = e?.response?.data?.errors;
  //         Object.keys(errors).forEach((key) => {
  //           toast.error(errors[key][0]);
  //         });
  //         // toggleRefreshModal();
  //       } finally {
  //         setLoading(false);
  //       }
  //       // }
  //     }
  //   }
  //   fetchData();
  // };

  const numberformat = (value) => {
    try {
      let format = numbro(value).format({
        // thousandSeparated: true,
        // negative: 'parenthesis',
        mantissa: 2
      });
      return format;
    } catch {
      return '';
    }
  };

  const handleYodleeAccountChange = (item) => {
    setAccountId(item?.value);
  };

  const toggleRefreshModal = () => {
    setShowRefresh(!showRefresh);
    if (!showRefresh) {
      if (accountId && accountFetchOptions) {
        if (accountFetchOptions?.find((i) => i.value === accountId)) {
          setAccountId(accountId);
        }
      }
    }
  };

  const refreshBankTransactions = async (startDate, endDate, accountId) => {
    if (basiqData?.connection_status === 0 && yodleeData?.connection_status === 0) {
      toast.warning('Please connect with bank feeds.');
    } else {
      try {
        const { data } = await get(
          `${
            API.YODLEE_GET_TRANSACTIONS
          }?from_date=${startDate}&to_date=${endDate}&yodlee_account_id=${
            accountId ? accountId : ''
          }`
        );
        return data;
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    // setLoadingForList(true);
    if (dateValue?.start_date && dateValue?.end_date) {
      const refreshTransaction = await refreshBankTransactions(
        dateValue?.start_date,
        dateValue?.end_date,
        accountId
      );
      setRefreshLoading(false);
      toggleRefreshModal();
      if (refreshTransaction?.status === 200) {
        toast.success(refreshTransaction?.message);
        getBankStatementReconcileData();
        getBankStatementReconcileDataForList();
      }
    } else {
      toast.error('Start Date and End Date both are required');
    }
    // setLoadingForList(false);
  };

  return (
    <>
      <Spin spinning={loading || loadingForList || accountLoading}>
        <InfinityScrollComponent
          hasMore={hasMore}
          isLoading={isLoading}
          loadMore={loadMore}
          loadOnMount={true}
          lastItemRef={lastElement}
        >
          <div className="cms-page">
            <div className="page-content-block">
              <div className="content-details refund-process">
                <div className="table-top-btn">
                  <div className="table-btn reconcile-desc">
                    <div>
                      <h2>Total Transactions ready to be reconciled: {data?.data?.length || 0}</h2>
                      {latestStatement?.created_at ? (
                        <p>
                          Your last bank transaction import occurred on{' '}
                          {moment(latestStatement?.created_at, 'DD-MM-YYYY HH:mm:ss').format(
                            'Do MMM YYYY'
                          )}
                        </p>
                      ) : (
                        <p>You have not imported any transactions.</p>
                      )}
                    </div>
                    {(basiqData?.connection_status !== 0 ||
                      yodleeData?.connection_status !== 0) && (
                      <RefreshTransactionModal
                        dateValue={dateValue}
                        setDateValue={setDateValue}
                        accountFetchOptions={accountFetchOptions}
                        loading={refreshLoading}
                        showRefresh={showRefresh}
                        toggleRefreshModal={toggleRefreshModal}
                        accountId={accountId}
                        handleAccountChange={handleYodleeAccountChange}
                        handleRefresh={handleRefresh}
                      />
                    )}
                  </div>

                  <div className="table-btn">
                    {/* <Button
                        variant="primary"
                        onClick={() => {
                          // toggleRefreshModal();
                          handleRefresh();
                        }}
                      >
                        Refresh
                      </Button> */}
                    <Button
                      variant="primary"
                      disabled={!data.data?.length}
                      onClick={() => {
                        handleReconcileAll();
                      }}
                    >
                      Reconcile
                    </Button>
                  </div>
                </div>
                <div style={{ width: '100%', height: '89%' }}>
                  <div id="grid-wrapper" style={{ width: '100%', height: '100%' }}>
                    <div id="myGrid" className="ag-theme-alpine ato-grid bank-reconcile-grid">
                      <Form>
                        <div className="oldest-sorting-body">
                          <Form.Check
                            type="checkbox"
                            label={
                              'Select All ' +
                              (selectedItems.length > 0 ? `(${selectedItems.length})` : '(0)')
                            }
                            name="checkall"
                            id="checkall"
                            checked={allChecked}
                            onChange={(e) => {
                              handleAllCheckBox(e);
                            }}
                          ></Form.Check>
                          <div style={{ marginBottom: '20px' }}>
                            <RangePicker
                              defaultValue={[
                                dateFilter.start_date
                                  ? moment(dateFilter.start_date, dateFormat[0])
                                  : '',
                                dateFilter.end_date
                                  ? moment(dateFilter.end_date, dateFormat[0])
                                  : ''
                              ]}
                              style={{ borderRadius: '30px' }}
                              onChange={handleDateChange}
                              disabledDate={disabledDate}
                              format={dateFormat}
                            />
                          </div>
                          <Select
                            placeholder="Select Sort"
                            defaultValue={sortOrder === 'DESC' ? sortOptions[1] : sortOptions[0]}
                            onChange={(e, item) => {
                              handleSorting(item);
                            }}
                            style={{ marginBottom: '20px', zIndex: '5' }}
                            classNamePrefix="account_select_bank"
                            options={sortOptions}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: 'grey'
                              }
                            })}
                          />
                        </div>

                        <Tabs
                          size="large"
                          style={{ marginBottom: 32 }}
                          activeKey={activeTab}
                          onTabClick={(key) => setActiveTab(key)}
                          className="reconcile-tab"
                        >
                          <TabPane tab="Grid View" className="grid-icon" key="grid">
                            <div className="form-box">
                              {data?.data &&
                                data?.data?.length > 0 &&
                                data?.data?.map((item, index) => {
                                  return (
                                    <div
                                      id={item.id}
                                      ref={setLastElement}
                                      key={index}
                                      className={getClassName(item?.bank_reconciliation, item)}
                                    >
                                      <Container>
                                        <Row className="refund-top-details">
                                          <Col xs={6} md={2} className="refund-checkbox">
                                            <Form.Check
                                              type="checkbox"
                                              // disabled={handleReconcileDisable(item)}
                                              disabled={handleReconcileDisabled(
                                                item?.bank_reconciliation,
                                                item
                                              )}
                                              checked={selectedItems.indexOf(item.id) >= 0}
                                              onChange={() => {
                                                handleCheckBox(item.id);
                                              }}
                                            ></Form.Check>
                                          </Col>
                                        </Row>

                                        <Container>
                                          <Row className="refund-card-box">
                                            <Col xs={6} md={4}>
                                              <div className="refund-card">
                                                {item && (
                                                  <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                      <FontAwesomeIcon
                                                        icon={faEllipsisV}
                                                        size="1x"
                                                      />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() => {
                                                          deleteTransaction(item?.id);
                                                          // sendReview(item.id);
                                                        }}
                                                      >
                                                        Delete
                                                      </Dropdown.Item>
                                                      {item && (
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            // showCommentbox(item.id);
                                                            sendReview(item.id, item);
                                                          }}
                                                        >
                                                          Send For Review
                                                        </Dropdown.Item>
                                                      )}
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                )}
                                                <label>Bank Transaction</label>
                                                <div className="refund-card-content">
                                                  <div className="left-block">
                                                    <p
                                                      className="name"
                                                      style={{ flexDirection: 'row' }}
                                                    >
                                                      <strong>Date:</strong>{' '}
                                                      {moment(item?.date).format('DD-MM-YYYY')}
                                                    </p>
                                                    {item?.transaction_type === 1 ? (
                                                      <p>
                                                        <strong>Credit:</strong>
                                                        <span className="rag-green">
                                                          ${`${formatNumber(item?.amount)}`}
                                                        </span>
                                                      </p>
                                                    ) : (
                                                      <p>
                                                        <strong>Debit:</strong>{' '}
                                                        <span className="rag-red">
                                                          ${`${formatNumber(item?.amount)}`}
                                                        </span>
                                                      </p>
                                                    )}

                                                    <p>
                                                      <strong>Description:</strong>
                                                      <span>
                                                        {item?.description ? item?.description : ''}
                                                      </span>{' '}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                            <Col xs={6} md={1} className="matching-btn-section">
                                              <Button
                                                variant="secondary"
                                                className="matching-btn"
                                                disabled={handleReconcileDisabled(
                                                  item?.bank_reconciliation,
                                                  item
                                                )}
                                                size="sm"
                                                onClick={() => {
                                                  handleReconcile(item.id);
                                                }}
                                              >
                                                {' '}
                                                Reconcile{' '}
                                              </Button>
                                            </Col>
                                            <Col xs={6} md={7}>
                                              <div className="refund-card">
                                                <Dropdown>
                                                  <Dropdown.Toggle id="dropdown-basic">
                                                    <FontAwesomeIcon icon={faEllipsisV} size="1x" />
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        const oldIndex = [...showSearchIndexs];
                                                        if (
                                                          showSearchIndexs.indexOf(item.id) === -1
                                                        ) {
                                                          oldIndex.push(item.id);
                                                        } else {
                                                          oldIndex.splice(
                                                            showSearchIndexs.indexOf(item.id),
                                                            1
                                                          );
                                                        }
                                                        setShowSearchIndexs(oldIndex);
                                                      }}
                                                    >
                                                      Search
                                                    </Dropdown.Item>

                                                    {/* <Dropdown.Item>Generate Invoice</Dropdown.Item> */}
                                                    {item && (
                                                      <Dropdown.Item
                                                        onClick={() => {
                                                          // showCommentbox(item.id);
                                                          sendReview(item.id, item);
                                                        }}
                                                      >
                                                        Send For Review
                                                      </Dropdown.Item>
                                                    )}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                                <label>Allocation to Clients</label>
                                                <div className="refund-card-content">
                                                  <div className="refund-card-content-label refund-card-title row col-md-12">
                                                    <h6
                                                      className={
                                                        item?.bank_reconciliation?.length === 1
                                                          ? 'col-md-4'
                                                          : 'col-md-3'
                                                      }
                                                    >
                                                      Account
                                                    </h6>
                                                    <h6
                                                      className={
                                                        item?.bank_reconciliation?.length === 1
                                                          ? 'col-md-4'
                                                          : 'col-md-3'
                                                      }
                                                    >
                                                      Client Name
                                                    </h6>
                                                    {item?.bank_reconciliation?.length > 0 &&
                                                      item?.bank_reconciliation?.length !== 1 && (
                                                        <h6 className="col-md-2">Amount</h6>
                                                      )}
                                                    <h6 className="col-md-4">Description</h6>
                                                    {/* <h6 className="col-md-1"></h6> */}
                                                  </div>
                                                  <div className="refund-card-content-row">
                                                    {item?.bank_reconciliation?.length > 0 &&
                                                      item?.bank_reconciliation?.map(
                                                        (bank, bankIndex) => {
                                                          return (
                                                            <div
                                                              key={bankIndex}
                                                              className="refund-card-content-label row col-md-12"
                                                            >
                                                              <div
                                                                className={
                                                                  item?.bank_reconciliation
                                                                    ?.length === 1
                                                                    ? 'col-md-4 refund-column'
                                                                    : 'col-md-3 refund-column'
                                                                }
                                                              >
                                                                <Select
                                                                  showSearch
                                                                  style={{
                                                                    width: '100%',
                                                                    margin: '0'
                                                                  }}
                                                                  loading={accountLoading}
                                                                  onChange={(id) =>
                                                                    handleNewAccountSelection(
                                                                      id,
                                                                      bank?.id,
                                                                      bank,
                                                                      item,
                                                                      index,
                                                                      bankIndex
                                                                    )
                                                                  }
                                                                  filterOption={(input, option) =>
                                                                    option.children
                                                                      .toLowerCase()
                                                                      .indexOf(
                                                                        input.toLowerCase()
                                                                      ) >= 0
                                                                  }
                                                                  value={
                                                                    accountOptions?.length > 0 &&
                                                                    accountOptions?.find(
                                                                      (val) =>
                                                                        val?.id ===
                                                                        bank?.chart_account_id
                                                                    ) &&
                                                                    bank?.chart_account_id
                                                                      ? bank?.chart_account_id
                                                                      : accountOptions?.find(
                                                                          (value) =>
                                                                            value.code == '3030'
                                                                        )?.id
                                                                  }
                                                                >
                                                                  {accountOptions &&
                                                                    accountOptions.map((option) => {
                                                                      return (
                                                                        <Option
                                                                          key={option.id}
                                                                          value={option.id}
                                                                        >
                                                                          {option?.name +
                                                                            (option?.account_type
                                                                              ?.name
                                                                              ? ' (' +
                                                                                option?.account_type
                                                                                  ?.name +
                                                                                ')'
                                                                              : '') +
                                                                            (option?.code
                                                                              ? ' - ' +
                                                                                option?.code.toString()
                                                                              : '') +
                                                                            (option?.sub_code
                                                                              ? '.' +
                                                                                option?.sub_code.toString()
                                                                              : '')}
                                                                        </Option>
                                                                      );
                                                                    })}
                                                                </Select>
                                                              </div>

                                                              <div
                                                                className={
                                                                  item?.bank_reconciliation
                                                                    ?.length === 1
                                                                    ? 'col-md-4 refund-column'
                                                                    : 'col-md-3 refund-column'
                                                                }
                                                              >
                                                                <ClientSelect
                                                                  formStyle={{ width: '100%' }}
                                                                  customerId={
                                                                    bank?.customer
                                                                      ? bank?.customer
                                                                        ? clientNameGetter(
                                                                            FullNameGetterWithoutChartAt(
                                                                              bank?.customer
                                                                            ),
                                                                            1
                                                                          )
                                                                        : bank?.customer_id
                                                                      : bank?.supplier
                                                                      ? clientNameGetter(
                                                                          bank?.supplier?.name,
                                                                          2
                                                                        )
                                                                      : bank?.supplier_id
                                                                  }
                                                                  data={data}
                                                                  bank={bank?.id}
                                                                  handleAddNewContact={() => {
                                                                    handleAddNewContact(
                                                                      bank,
                                                                      index,
                                                                      bankIndex
                                                                    );
                                                                  }}
                                                                  onCustomerSelectionChange={(e) =>
                                                                    handlecontactChange(e)
                                                                  }
                                                                  onChange={(id, optionValue) => {
                                                                    handleNewContactSelection(
                                                                      id,
                                                                      optionValue,
                                                                      bank,
                                                                      item,
                                                                      index,
                                                                      bankIndex
                                                                    );
                                                                  }}
                                                                  search={() => {
                                                                    const oldIndex = [
                                                                      ...showSearchIndexs
                                                                    ];
                                                                    if (
                                                                      showSearchIndexs.indexOf(
                                                                        item.id
                                                                      ) === -1
                                                                    ) {
                                                                      oldIndex.push(item.id);
                                                                    } else {
                                                                      oldIndex.splice(
                                                                        showSearchIndexs.indexOf(
                                                                          item.id
                                                                        ),
                                                                        1
                                                                      );
                                                                    }

                                                                    setShowSearchIndexs(oldIndex);
                                                                  }}
                                                                  // disabled={!bank?.chart_account_id}
                                                                  placeholder="Select Client"
                                                                  className="client-select"
                                                                />
                                                              </div>
                                                              {item?.bank_reconciliation?.length >
                                                                0 &&
                                                                item?.bank_reconciliation
                                                                  ?.length !== 1 && (
                                                                  <div className="col-md-2 refund-column">
                                                                    <Form.Group>
                                                                      <Form.Control
                                                                        required
                                                                        type="number"
                                                                        id={bank.id}
                                                                        placeholder="Add Amount"
                                                                        value={
                                                                          bank.amount
                                                                            ? bank.amount
                                                                            : ''
                                                                        }
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                          handleNewAmount(
                                                                            e,
                                                                            index,
                                                                            bankIndex
                                                                          );
                                                                        }}
                                                                        onBlur={() => {
                                                                          handleCheck(
                                                                            item?.bank_reconciliation
                                                                          )?.length < 1 &&
                                                                            bank.id &&
                                                                            handleBlurNewAmount(
                                                                              bank.id,
                                                                              bank,
                                                                              item
                                                                            );
                                                                        }}
                                                                        rules={[
                                                                          {
                                                                            pattern: new RegExp(
                                                                              /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                                                            ),
                                                                            message:
                                                                              'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                                                                          }
                                                                        ]}
                                                                        style={{
                                                                          border:
                                                                            '1px solid #d9d9d9',
                                                                          padding: '5px 10px',
                                                                          fontSize: '14px',
                                                                          width: '100%'
                                                                        }}
                                                                      />
                                                                    </Form.Group>
                                                                  </div>
                                                                )}

                                                              <div className="col-md-4 refund-column">
                                                                <Form.Group>
                                                                  <TextArea
                                                                    name="description"
                                                                    placeholder="Add Description"
                                                                    rows={2}
                                                                    value={
                                                                      bank?.description
                                                                        ? bank?.description
                                                                        : ''
                                                                    }
                                                                    onChange={(e) => {
                                                                      handleNewDescription(
                                                                        e,
                                                                        index,
                                                                        bankIndex
                                                                      );
                                                                    }}
                                                                    onBlur={() => {
                                                                      handleCheck(
                                                                        item?.bank_reconciliation
                                                                      )?.length < 1 &&
                                                                        bank.id &&
                                                                        handleBlurDescription(
                                                                          bank.id,
                                                                          bank,
                                                                          item
                                                                        );
                                                                    }}
                                                                  />
                                                                </Form.Group>
                                                              </div>
                                                              {/* <div className="col-md-1 refund-column">
                                                                {bankIndex + 1 ===
                                                                  item?.bank_reconciliation
                                                                    ?.length && (
                                                                  <b>
                                                                    <span
                                                                      className="reconcile-item-list-split"
                                                                      size="sm"
                                                                      title="Split"
                                                                      onClick={() => {
                                                                        hanldeAddNewAccount(
                                                                          index,
                                                                          item
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img
                                                                        src={splitIcon}
                                                                        alt=""
                                                                        style={{ height: '16px' }}
                                                                      />
                                                                    </span>
                                                                  </b>
                                                                )}
                                                                {bank?.id === '' &&
                                                                  item?.bank_reconciliation
                                                                    ?.length && (
                                                                    <>
                                                                      {bankIndex + 1 ===
                                                                        item?.bank_reconciliation
                                                                          ?.length && (
                                                                        <>
                                                                          <span></span>
                                                                          &nbsp;&nbsp;/&nbsp;&nbsp;{' '}
                                                                        </>
                                                                      )}

                                                                      <FontAwesomeIcon
                                                                        title="Delete"
                                                                        icon={faTrash}
                                                                        onClick={() => {
                                                                          hanldeDeleteNewAccount(
                                                                            index,
                                                                            item,
                                                                            bankIndex
                                                                          );
                                                                        }}
                                                                      />
                                                                    </>
                                                                  )}
                                                              </div> */}

                                                              {addNewContact &&
                                                                transactionIndex === index &&
                                                                showNewIndexs === bankIndex && (
                                                                  <AddNewContactModal
                                                                    setAddNewContact={
                                                                      setAddNewContact
                                                                    }
                                                                    addNewContact={addNewContact}
                                                                    showNewIndexs={showNewIndexs}
                                                                    transactionIndex={
                                                                      transactionIndex
                                                                    }
                                                                    handleNewContactNameSelection={
                                                                      handleNewContactNameSelection
                                                                    }
                                                                    newContactItem={newContactItem}
                                                                    // handleContactList={
                                                                    //   handleContactList
                                                                    // }
                                                                    // handleSupplierList={
                                                                    //   handleSupplierList
                                                                    // }
                                                                  />
                                                                )}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                  <div className="refund-card-content-btn-small">
                                                    {item?.bank_reconciliation?.length > 0 &&
                                                      item?.bank_reconciliation?.find(
                                                        (ele) => ele?.id === ''
                                                      ) && (
                                                        <Button
                                                          className="btn-small"
                                                          disabled={handleCheckSave(
                                                            item?.bank_reconciliation,
                                                            item
                                                          )}
                                                          onClick={() => {
                                                            handleAddBankDetails(
                                                              item?.bank_reconciliation,
                                                              item?.id
                                                            );
                                                          }}
                                                        >
                                                          Save
                                                        </Button>
                                                      )}
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>

                                          {showSearchIndexs.indexOf(item.id) !== -1 ? (
                                            <ATOSearch
                                              searchParams={numberformat(item?.amount)}
                                              transaction_type={item?.transaction_type}
                                              ItemId={item.id}
                                              Item={item}
                                              close={handleSearchClose}
                                              getBankStatementReconcileData={
                                                getBankStatementReconcileData
                                              }
                                            />
                                          ) : null}
                                        </Container>
                                      </Container>
                                    </div>
                                  );
                                })}
                            </div>
                          </TabPane>
                          <TabPane tab="List View" className="list-icon" key="list">
                            <div className="bank-reconcile-list">
                              <Table
                                className="bankreconcilelist-table"
                                rowKey={(record) => record.id}
                                columns={getBankReconciliationTableColumns()}
                                dataSource={dataForList?.data || []}
                                expandable={{
                                  expandedRowRender: (record) => expandedRowRender(record)
                                }}
                                onExpandedRowsChange={(expandedRows) => {
                                  handleExpanded(expandedRows);
                                }}
                                pagination={{
                                  hideOnSinglePage: true,
                                  defaultCurrent: 1,
                                  showQuickJumper: true,
                                  onChange: (page) => {
                                    onPageChange(page);
                                  },
                                  itemRender: PaginationItemRenderer,
                                  pageSize: limit,
                                  ...pagingData
                                }}
                              />
                              {dataForList?.data?.length > 0 && (
                                <div className="mt-2">
                                  <div className="col-md-12">
                                    Showing&nbsp;
                                    <Select
                                      defaultValue={`${defaultPaginationLimit}`}
                                      value={limit}
                                      onChange={handleChange}
                                      options={[
                                        { value: '10', label: '10' },
                                        { value: '25', label: '25' },
                                        { value: '50', label: '50' },
                                        { value: '100', label: '100' }
                                      ]}
                                    />
                                    &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                                    {pagingData?.to} from {pagingData?.total} Records
                                  </div>
                                </div>
                              )}
                              {addNewContactForList && (
                                <AddNewContactModal
                                  setAddNewContact={setAddNewContactForList}
                                  addNewContact={addNewContactForList}
                                  showNewIndexs={showNewIndexsForList}
                                  transactionIndex={transactionIndexForList}
                                  handleNewContactNameSelection={
                                    handleNewContactNameSelectionForList
                                  }
                                  newContactItem={newContactItemForList}
                                  // handleContactList={handleContactList}
                                  // handleSupplierList={handleSupplierList}
                                />
                              )}
                            </div>
                          </TabPane>
                        </Tabs>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InfinityScrollComponent>
        {searchRecord && showSearchIndexsForList.indexOf(searchRecord.id) !== -1 ? (
          <ATOSearchForList
            searchParams={numberformat(searchRecord?.amount)}
            transaction_type={searchRecord?.transaction_type}
            ItemId={searchRecord.id}
            Item={searchRecord}
            close={handleSearchCloseForList}
          />
        ) : null}
      </Spin>
      {/* Modal of the Refresh */}
      {/* <Modal
        size="lg"
        show={showRefresh}
        onHide={toggleRefreshModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section fetch-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Select Period To Fetch Transactions
        </Modal.Header>
        <div className="modal-body">
          <div className="date-picker-refresh">
            <>
              <Form>
                <RangePicker
                  defaultValue={[
                    dateFilter.start_date ? moment(dateFilter.start_date, dateFormat) : '',
                    dateFilter.end_date ? moment(dateFilter.end_date, dateFormat) : ''
                  ]}
                  disabledDate={disabledDate}
                  format={dateFormat}
                  onChange={handleDateChange}
                />
              </Form>
            </>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            type="submit"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => {
              handleRefresh();
            }}
          >
            Fetch
          </Button>
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={() => {
              toggleRefreshModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal> */}
    </>
  );
};

export default BankReconciliation;
