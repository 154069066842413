import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import ABA from './ABA';
import ABATransactions from './ABATransactions';
import { API, defaultPaginationLimit, get } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import DialogModal from '../../components/Header/DialogModal';
import { helpCenterArray } from '../HelpCenter';
import { useEffect } from 'react';

const tabsKey = {
  'generate-aba': 1,
  'aba-transactions': 2
};

// const { TabPane } = Tabs;
const ABATabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  let selectedTab = query.get('tab');
  const [activeKey, setActiveKey] = useState(tabsKey[selectedTab]);
  const onTabClick = (activeKey) => {
    selectedTab = Object.keys(tabsKey)[Object.values(tabsKey)[Number(activeKey)] - 1];
    // selectedTab =  'aba-transactions';
    setActiveKey(String(activeKey));
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      navigate(`/aba?page=1&tab=${tabName}&limit=${defaultPaginationLimit}`, {
        replace: true
      });
    }
  };

  useEffect(async () => {
    const { data } = await get(`${API.GET_ABA_LIST}?page=1`);
    if (data.data.data?.length == 0) {
      const { data } = await get(`${API.ABA_FILE_LIST}?page=1`);
      if (data.data.data?.length > 0) {
        selectedTab = 'aba-transactions';
        setActiveKey(2);
        onTabClick(2);
      }
    }
  }, []);

  const abaTabsItems = [
    {
      key: "1",
      label: "Generate .ABA",
      children: (<ABA onTabClick={onTabClick} />),
    },
    {
      key: "2",
      label: "Download .ABA file / View History",
      children: (<ABATransactions />),
    },
  ]

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            ABA{' '}
            <DialogModal
              title="Dashboard"
              p1={helpCenterArray[13].name}
              p2={helpCenterArray[13].text}
            />
          </h1>
          <div className="content-details aba-tabs">
            <Tabs
              defaultActiveKey={activeKey.toString()}
              // defaultValue={2}
              activeKey={activeKey.toString()}
              size="large"
              style={{ marginBottom: 32 }}
              onTabClick={onTabClick}
              items={abaTabsItems}
            >
              {/* <TabPane tab="Generate .ABA" key="1">
                <ABA onTabClick={onTabClick} />
              </TabPane>
              <TabPane tab="Download .ABA file / View History" key="2">
                <ABATransactions />
              </TabPane> */}
            </Tabs>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ABATabs;
