import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { toast } from 'react-toastify';
import { API, deleteCall } from '../../../config.js';

const BeneficiaryDeleteModal = ({
  getBeneficiaryData,
  beneficiaryId,
  clientId,
  toggleDeleteModal
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const handleUserDelete = async () => {
    try {
      const { data } = await deleteCall(`${API.BENEFICIARY}/${beneficiaryId}`);
      toast.success(data.message);
      getBeneficiaryData(clientId ? clientId : '');
      toggleDeleteModal();
    } catch (e) {
      setErrorMessage('There is some issue with request, please try after some time.');
    }
  };
  return (
    <div>
      <Modal
        className="remove-popup"
        width={500}
        closable
        centered
        open
        footer={null}
        onCancel={toggleDeleteModal}
      >
        <div className="remove-content">
          <p>Are your sure you want to delete this beneficiary?</p>
          <div className="modal-action">
            <Button className="cancel" onClick={toggleDeleteModal}>
              Cancel
            </Button>
            <Button onClick={handleUserDelete}>OK</Button>
          </div>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </div>
      </Modal>
    </div>
  );
};

export default BeneficiaryDeleteModal;
