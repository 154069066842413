import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, defaultPaginationLimit, get } from '../../config.js';

const usepaymentList = (currentPage, search, limit) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [searchParam, setSearchParam] = useState(search || '');
  const [paymentList, setPaymentList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchpaymentList = useCallback(
    async (limit, page) => {
      try {
        setIsDataLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          `${API.GET_PAYMENT_LIST}?page=${page || pagingData.current}&limit=${tempLimit}&search=${searchParam ? searchParam : ''}`
        );
        const { total, current_page, per_page, from, to } = data.data;
        setPaymentList(data.data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
        setIsDataLoading(false);
      } catch (e) {
        setPaymentList([]);
        setIsDataLoading(false);
        setPagingData({ current: 1 });
        const errorMessage = e.response?.data?.errors?.myna_error[0];
        if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    fetchpaymentList(limit ?? query.get('limit'));
  }, [fetchpaymentList]);

  return {
    paymentList,
    isDataLoading,
    fetchpaymentList,
    pagingData,
    searchParam,
    onSearchChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/payments?search=${value}&page=1`, {
        replace: true
      });
    },
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/payments?page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default usepaymentList;
