import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Table } from 'antd';
import { defaultPaginationLimit, get } from '../../config';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';

const ABATableComponent = ({
  pagingData,
  dataSource,
  columns,
  nestedTableColumns,
  nestedTableColumns1,
  nestedRowEndpoint,
  isNested = false,
  startMonth,
  endMonth,
  isLedgerReport = false,
  limit,
  onPageChange = () => {},
  loading,
  handleSortChange
}) => {
  const [expandedData, setExpandedData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isNestedDataLoading, setIsNestedDataLoading] = useState(false);

  const [expandedColumn, setExpandedColumn] = useState(true);

  const expandedRowRender = (record) => {
    expandedData?.tableData && expandedData?.tableData?.find((item) => item?.direct_payment === 0)
      ? setExpandedColumn(false)
      : setExpandedColumn(true);

    return (
      <Table
        columns={expandedColumn === true ? nestedTableColumns1 : nestedTableColumns}
        loading={isNestedDataLoading}
        dataSource={expandedData?.tableData}
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: expandedData?.defaultPageSize,
          defaultCurrent: 1,
          showQuickJumper: true,
          itemRender: PaginationItemRenderer,
          onChange: (page) => setExpandedRowData(record, page),
          ...expandedData,
          pageSize: defaultPaginationLimit
        }}
      />
    );
  };

  const setExpandedRowData = async (record, page = 1) => {
    if (isLedgerReport) {
      const { data } = await get(
        `${nestedRowEndpoint}?end_month=${endMonth}&start_month=${startMonth}&page=${page}&limit=${defaultPaginationLimit}&export=0&chart_account_id=${record.id}`
      );
      const { total, per_page, data: tableData, from, to } = data.data.reports;
      setExpandedData({
        tableData,
        defaultPageSize: per_page,
        total,
        current: page,
        from: from,
        to: to
      });
    } else {
      const { data } = await get(`${nestedRowEndpoint}/${record.id}?page=${page}`);
      const { total, per_page, data: tableData, from, to } = data.data;
      setExpandedData({
        tableData,
        defaultPageSize: per_page,
        total,
        current: page,
        from: from,
        to: to
      });
    }
    return true;
  };

  const onExpand = async (expanded, record) => {
    try {
      const expandedRows = [];
      if (expanded) {
        setExpandedData({
          tableData: []
        });
        expandedRows.push(record.id);
        setExpandedRows(expandedRows);
        setIsNestedDataLoading(true);
        await setExpandedRowData(record);
        setIsNestedDataLoading(false);
      } else {
        setExpandedRows([]);
      }
    } catch (e) {
      setIsNestedDataLoading(false);
      setExpandedData({
        ...expandedData,
        tableData: []
      });
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <Table
      rowKey={(record) => record.id}
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      expandable={isNested ? { expandedRowRender, onExpand } : false}
      expandedRowKeys={isNested ? expandedRows : []}
      pagination={
        pagingData
          ? {
              hideOnSinglePage: true,
              defaultPageSize: pagingData.pageSize,
              defaultCurrent: 1,
              showQuickJumper: true,
              onChange: (page) => onPageChange(page),
              itemRender: PaginationItemRenderer,
              ...pagingData,
              pageSize: limit
            }
          : false
      }
      onChange={handleSortChange}
    />
  );
};

export default ABATableComponent;
