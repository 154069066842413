/* eslint-disable no-nested-ternary */
import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import proceedImage from '../../assets/images/processed.png';
import directPayment from '../../assets/images/direct-payment.png';
import { faDownload, faEye, faUndo } from '@fortawesome/free-solid-svg-icons';
import { API, get } from '../../config';
import { NegativeAmountConverter } from '../../helper/Conversions';
import { formatNumber } from '../../APIConfig/Interceptor';
import ABARefresh from './ABARefresh';
import { Tooltip } from 'antd';
import { calculateDayDifference } from '../../helper/utils';

const downloadLetter = async (path) => {
  try {
    if (path) {
      const response = await axios.get(`${path}`, {
        responseType: 'blob'
      });
      const fileData = response.data;
      const url = window.URL.createObjectURL(new Blob([fileData]));
      var a = document.createElement('a');
      a.href = url;
      var file = path.split('/');
      a.setAttribute('download', file[file.length - 1] || 'new_aba.aba');
      // a.target = '_blank';
      // a.download = '31012022_022649.pdf';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    } else {
      toast.error('File is not exist');
    }
  } catch (e) {
    const errors = e.response?.data?.errors;
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key][0]);
    });
  }
};

const generateCombinedABAFile = async (id) => {
  try {
    const { data } = await get(`${API.GENERATE_ABA_MERGED_FEES}/${id}`);
    // toast.success(data.message);
    data.file !== null && data.file !== undefined
      ? downloadLetter(data.file)
      : toast.error('File not found.!');
  } catch (e) {
    const errorMessage = e.response?.data?.errors?.myna_error[0];
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }
};

const toggleShowModal = (text, setShowPreview, setPreview) => {
  if (text?.transaction_id) {
    setShowPreview(true);
    setPreview(text);
  }
};

export const getTableColumns = (handleUndo) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      className: 'centeralign',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '10%',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (created_at) => created_at?.split(' ')[0]
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      width: '15%',
      render: (record) => <>{record ? record : '-'}</>
    },
    {
      title: 'BSB',
      dataIndex: 'bsb',
      width: '10%',
      render: (record) => (
        // <>{record ? 'xxx' + record?.toString().slice(-4) : '-'}</>
        <>{record ? record : '-'}</>
      )
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      width: '15%',
      render: (record) => <>{record ? record : '-'}</>
    },
    {
      title: 'Year',
      width: '8%',
      className: 'centeralign',
      render: (record) => (
        <>
          {record?.reconciliation?.year
            ? record?.reconciliation?.year
            : record?.reconciliation?.ato?.year
            ? record?.reconciliation?.ato?.year
            : ''}
        </>
      )
      // dataIndex: ['reconciliation', 'year']
    },
    {
      title: 'Gross Amount',
      width: '12%',
      className: 'centeralign',
      render: (record) => (
        <>{record?.gross_amount ? '$' + formatNumber(record?.gross_amount) : '$0.00'}</>
      )
    },
    {
      title: 'Fees',
      width: '12%',
      className: 'centeralign',
      render: (record) => <>{record?.fees ? '$' + formatNumber(record?.fees) : '$0.00'}</>
    },
    {
      title: 'Remittance Amount',
      width: '12%',
      className: 'centeralign',
      dataIndex: ['reconciliation', 'remittance'],
      render: (remittance) => <span>${formatNumber(remittance)}</span>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '5%',
      className: 'centeralign',
      render: (id) => (
        <FontAwesomeIcon title="Undo" icon={faUndo} size="1x" onClick={() => handleUndo(id)} />
      )
    }
  ];
};

export const abaTransactionTableColumns = (handleRefreshButton, handleUploadedStatusABA) => {
  return [
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      sorter: (a, b) => a.transaction_date.localeCompare(b.transaction_date),
      render: (transaction_date) => transaction_date.split('-').reverse().join('-'),
      width: '15%'
    },
    {
      title: 'Firm BSB',
      dataIndex: 'bsb',
      key: 'bsb',
      sorter: (a, b) => a.bsb.localeCompare(b.bsb),
      width: '10%'
    },
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
      sorter: (a, b) => a.bank_name.localeCompare(b.bank_name),
      width: '15%'
    },
    {
      title: 'Gross',
      dataIndex: 'total_amount',
      sorter: (a, b) => a.total_amount - b.total_amount,
      align: 'right',
      render: (total_amount) => <span>${formatNumber(Number.parseFloat(total_amount))}</span>,
      width: '15%'
    },
    {
      title: 'Fees',
      // sorter: (a, b) => a.amount - b.amount,
      align: 'right',
      render: (record) => (
        <span>
          {record.total_amount && record.amount
            ? NegativeAmountConverter(
                Number.parseFloat(record.total_amount) - Number.parseFloat(record.amount)
              )
            : '$0.00'}
        </span>
      ),
      width: '15%'
    },
    {
      title: 'Net Refund Total',
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      align: 'right',
      render: (amount) => <span>${formatNumber(Number.parseFloat(amount))}</span>,
      width: '15%'
    },
    {
      title: 'Source',
      sorter: (a, b) => a.is_direct_payment - b.is_direct_payment,
      render: (record) => (
        <>
          {record?.is_direct_payment ? (
            <img src={directPayment} alt="TrustEasy" style={{ width: '24px', height: '24px' }} />
          ) : (
            <img src={proceedImage} alt="TrustEasy" style={{ width: '24px', height: '24px' }} />
            // <FontAwesomeIcon icon={faCheck} />
            // <FontAwesomeIcon icon={faXmark} />
          )}
        </>
      ),
      className: 'centeralign',
      width: '5%'
    },
    {
      title: '.ABA File',
      dataIndex: 'id',
      className: 'centeralign',
      render: (id, item) => (
        <div className="action-column">
          <Tooltip placement="bottomLeft" title={'Download'}>
            <FontAwesomeIcon
              icon={faDownload}
              size="1x"
              onClick={() => {
                downloadLetter(item.file);
              }}
            />
          </Tooltip>
          {/* {!item?.is_direct_payment ? ( */}
          {calculateDayDifference(
            item?.transaction_date?.split('-')?.reverse()?.join('/'),
            new Date()?.toJSON()?.slice(0, 10)?.split('-')?.reverse()?.join('/')
          ) > 15 ? (
            <></>
          ) : (
            <>
              <span></span>&nbsp;/&nbsp;
              <ABARefresh id={id} handleRefreshButton={handleRefreshButton} />
            </>
          )}
          {/* ) : (
            <></>
          )} */}
        </div>
      ),
      width: '10%'
    },
    {
      title: 'Combined fees .ABA File',
      dataIndex: 'id',
      className: 'centeralign',
      render: (id, item) =>
        !item?.is_direct_payment ? (
          <div className="action-column">
            <Tooltip placement="bottomLeft" title={'Download'} className="action-column">
              <FontAwesomeIcon
                icon={faDownload}
                size="1x"
                onClick={() => {
                  if (item.merged_fees_aba_file != null && item.merged_fees_aba_file != undefined) {
                    downloadLetter(item.merged_fees_aba_file);
                  } else {
                    generateCombinedABAFile(item.id);
                  }
                }}
              />
            </Tooltip>
            {calculateDayDifference(
              item?.transaction_date?.split('-')?.reverse()?.join('/'),
              new Date()?.toJSON()?.slice(0, 10)?.split('-')?.reverse()?.join('/')
            ) > 15 ? (
              <></>
            ) : (
              <>
                <span></span>&nbsp;/&nbsp;
                <ABARefresh id={id} handleRefreshButton={handleRefreshButton} combine={true} />
              </>
            )}
          </div>
        ) : (
          ''
        ),
      width: '10%'
    },
    {
      title: 'Uploaded',
      dataIndex: 'uploaded',
      width: '10%',
      render: (id, item) => (
        <div className="action-column">
          <input
            type="checkbox"
            onChange={() => handleUploadedStatusABA(item?.id, item?.uploaded)}
            checked={item?.uploaded}
          />
        </div>
      )
    }
  ];
};

export const getAbaTransactionTableInnerColumns = (setShowPreview, setPreview) => {
  return [
    {
      title: 'Tr. ID',
      dataIndex: 'transaction_id',
      width: '5%'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      // sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (date) => moment(date?.split(' ')[0]).format('DD-MM-YYYY')
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      width: '15.5%'
    },
    {
      title: 'User',
      dataIndex: 'firm'
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name'
    },
    {
      title: 'BSB',
      dataIndex: 'bsb',
      key: 'bsb',
      // render: (bsb) => <span>{bsb ? 'xxx' + bsb?.toString().slice(-4) : ''}</span>
      render: (bsb) => <span>{bsb ? bsb?.toString() : ''}</span>
    },
    {
      title: 'AC No',
      dataIndex: 'ac_no',
      render: (accountNo) =>
        // accountNo ? <span> xxxx xxxx {accountNo?.toString().slice(-4)}</span> : ''
        accountNo ? <span>{accountNo?.toString()}</span> : ''
      // sorter: (a, b) => a.ac_no.localeCompare(b.ac_no),
    },
    {
      title: 'Statement Description',
      dataIndex: 'statement_description',
      width: '19.5%'
      // sorter: (a, b) =>
      //   a.statement_description.localeCompare(b.statement_description),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '9.1%',
      // sorter: (a, b) => a.amount.localeCompare(b.amount),
      render: (amount) => <span>${formatNumber(Number.parseFloat(amount))}</span>
    },
    {
      title: 'Receipt',
      dataIndex: 'action',
      width: '10%',
      className: 'centeralign',
      render: (id, text) => {
        if (text?.direct_payment === 1) {
          return;
        } else {
          if ('fee' in text) {
            if (text.fee === 1) {
              return;
            }
          } else {
            return (
              <>
                <FontAwesomeIcon
                  onClick={() => toggleShowModal(text, setShowPreview, setPreview)}
                  icon={faEye}
                  size="1x"
                />
                <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <FontAwesomeIcon
                  icon={faDownload}
                  size="1x"
                  onClick={() => {
                    downloadLetter(text.receipt_path);
                  }}
                />
              </>
            );
          }
        }
      }
    }
  ];
};

export const getAbaTransactionTableInnerColumns1 = () => {
  return [
    {
      title: 'Tr. ID',
      dataIndex: 'transaction_id',
      width: '5%'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      // sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (date) => moment(date?.split(' ')[0]).format('DD-MM-YYYY')
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      width: '15.5%'
    },
    {
      title: 'User',
      dataIndex: 'firm'
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name'
    },
    {
      title: 'BSB',
      dataIndex: 'bsb',
      key: 'bsb'
      // sorter: (a, b) => a.bsb.localeCompare(b.bsb),
    },
    {
      title: 'AC No',
      dataIndex: 'ac_no',
      render: (accountNo) =>
        // accountNo ? <span> xxxx xxxx {accountNo?.toString().slice(-4)}</span> : ''
        accountNo ? <span>{accountNo?.toString()}</span> : ''
      // sorter: (a, b) => a.ac_no.localeCompare(b.ac_no),
    },
    {
      title: 'Statement Description',
      dataIndex: 'statement_description',
      width: '19.5%'
      // sorter: (a, b) =>
      //   a.statement_description.localeCompare(b.statement_description),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '9.1%',
      // sorter: (a, b) => a.amount.localeCompare(b.amount),
      render: (amount) => <span>${formatNumber(Number.parseFloat(amount))}</span>
    }
  ];
};
