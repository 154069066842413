export const FullNameGetter = (params) => {
  if (
    params?.first_name !== null &&
    params?.first_name !== undefined &&
    params?.first_name !== '' &&
    params?.first_name
  ) {
    return params?.first_name?.charAt(0) + params?.first_name + ' ' + params?.last_name;
  } else {
    if (params?.last_name) {
      return FullNameGetterWithoutFirstName(params);
    }
    return '';
  }
};

export const clientNameGetter = (name, type) => {
  const charName = type === 1 ? 'Client' : 'Supplier';
  return charName?.charAt(0) + name;
};

export const FullNameGetterWithoutChartAt = (params) => {
  return (params.first_name ?? '') + ' ' + (params.last_name ?? '');
};

export const FullNameGetterWithoutFirstName = (params) => {
  if (
    params?.last_name !== null &&
    params?.last_name !== undefined &&
    params?.last_name !== '' &&
    params?.last_name
  ) {
    return params?.last_name?.charAt(0) + params?.last_name;
  } else {
    return '';
  }
};

export const FullNameGetterWithString = (name) => {
  return name?.charAt(0) + name;
};

export const capitalizeFirstLetter = (string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const NameGetter = (params) => {
  return params.first_name + ' ' + params.last_name;
};

export const trimString = (string, length) => {
  return string.length > length ? string.substring(0, length) + '...' : string;
};

export const phoneAndMobile = (string) => {
  if (string.phone && string.mobile) {
    return string.phone + ' / ' + string.mobile;
  } else {
    if (string.phone) {
      return string.phone;
    } else {
      if (string.mobile) {
        return string.mobile;
      } else {
        return '';
      }
    }
  }
};

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export const maskPostalCode = (num) => {
  return padWithLeadingZeros(num, 4)
}
