import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import ReceiptEditModal from './ReceiptEditModal';
import ReceiptViewDetailModal from './ReceiptViewDetailModal';
import { download } from '../../helper/download';
import { imageDefaultPrefixPath } from '../../config';

const ReceiptActionButtons = ({ record, fetchreceiptsList }) => {
  const [showEdit, setShowEdit] = useState(false);
  const toggleShowEditModal = () => {
    setShowEdit(!showEdit);
  };

  const [show, setShow] = useState(false);
  const toggleShowModal = () => {
    setShow(!show);
  };

  return (
    <span>
      <FontAwesomeIcon onClick={toggleShowModal} icon={faEye} size="1x" />
      {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
      <FontAwesomeIcon onClick={toggleShowEditModal} icon={faEdit} size="1x" /> */}
      {record.receipt &&
        <>
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <FontAwesomeIcon onClick={() => {download(`${imageDefaultPrefixPath}${record.receipt}`,  'receipt.pdf')}} icon={faDownload} size="1x" />
        </>
      }
      { show && (<ReceiptViewDetailModal record={record} toggleShowModal={toggleShowModal} show={show} />) }
      <Modal
        className="small-modal"
        show={showEdit}
        onHide={toggleShowEditModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Edit Receipt</Modal.Header>
        <Modal.Body>
          <ReceiptEditModal
            record={record}
            onClose={toggleShowEditModal}
            fetchreceiptsList={fetchreceiptsList}
          />
          <Button variant="secondary" className="reset" onClick={toggleShowEditModal}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </span>
  );
};

export default ReceiptActionButtons;
