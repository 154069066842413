import { API, get } from '../../config';

export const getNextPageData = async (page, dateFilter, sortField, sortOrder) => {
  try {
    const { data } = await get(
      `${API.BANK_RECONCILIATION_LIST}?limit=10&page=${page}&sort_column=${
        sortField ? sortField : ''
      }&sort_order=${sortOrder ? sortOrder : ''}&from=${dateFilter.start_date}&to=${
        dateFilter.end_date
      }`
    );
    return data?.data;
  } catch (e) {
    console.log('e :: ', e);
    throw e;
  }
};

export const getUnderReviewedNextPageData = async (page, dateFilter, sortField, sortOrder) => {
  try {
    const { data } = await get(
      `${API.BANK_RECONCILIATION_LIST}?limit=10&page=${page}&status=2&sort_column=${
        sortField ? sortField : ''
      }&sort_order=${sortOrder ? sortOrder : ''}&from=${dateFilter.start_date}&to=${
        dateFilter.end_date
      }`
    );
    return data?.data;
  } catch (e) {
    console.log('e :: ', e);
    throw e;
  }
};
