/* eslint-disable no-nested-ternary */
import moment from 'moment';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { faEdit, faEye, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import {
  FullNameGetter,
  FullNameGetterWithoutChartAt,
  FullNameGetterWithString,
  trimString
} from '../../helper/fullNameGetter';
import { NegativeAmountConverter } from '../../helper/Conversions';
import { API } from '../../config';
import { formatNumber } from '../../APIConfig/Interceptor';

export const statementReportTableColumns = [
  {
    title: 'Date',
    render: (date) => moment(date?.split(' ')[0]).format('DD-MM-YYYY'),
    dataIndex: 'date',
    key: 'date',
    width: '8%'
  },
  {
    title: 'Receipt No./EFT No.',
    render: (record) => (
      <>
        {record?.type == 1
          ? record?.receipt_number
            ? record?.receipt_number
            : '-'
          : record?.eft_number
          ? record?.eft_number
          : '-'}
      </>
    ),
    width: '11%'
  },
  {
    title: 'Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
    className: 'fullname',
    render: (name) => name ? trimString(FullNameGetterWithString(name), 32) : "",
    width: '13%'
  },
  // {
  //   title: 'Email',
  //   dataIndex: 'customer_email',
  //   key: 'customer_email',
  //   width: '15%'
  // },
  {
    title: 'BSB',
    dataIndex: 'bsb',
    key: 'bsb',
    render: (bsb) => <span>{bsb ? bsb?.toString() : ''}</span>,
    width: '8%'
  },
  {
    title: 'AC No',
    render: (record) =>
      record?.account_number ? <span>{record?.account_number?.toString()}</span> : '',
    width: '8%'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '18%'
  },
  {
    title: 'Debit',
    render: (record) => (
      <span className="rag-red">
        {record.type !== 1 ? `$${formatNumber(Number.parseFloat(record.amount))}` : ''}
      </span>
    ),
    width: '10%'
  },
  {
    title: 'Credit',
    render: (record) => (
      <span className="rag-green">
        {record.type === 1 ? `$${formatNumber(Number.parseFloat(record.amount))}` : ''}
      </span>
    ),
    width: '10%'
  },
  // {
  //   title: 'Balance',
  //   width: '10%',
  //   render: (record) => {
  //     return (
  //       <span>
  //         {record.running_balance
  //           ? `$${formatNumber(Number.parseFloat(record.running_balance))}`
  //           : 'N/A'}
  //       </span>
  //     );
  //   }
  // },
  {
    title: 'EFT Bank Rec.',
    render: (record) => (
      <span className="rag-green">
        {record.reconciled == 0 ? (
          <CloseCircleOutlined style={{ color: 'red' }} />
        ) : (
          <CheckCircleOutlined />
        )}
      </span>
    ),
    width: '8%'
  }
  // {
  //   title: 'Running Balance',
  //   render: (record) => (
  //     <span>
  //       {record.running_balance
  //         ? `$${Number.parseFloat(record.running_balance)?.toFixed(2)}`
  //         : `$${Number.parseFloat(0)}`}
  //     </span>
  //   )
  // }
];

export const deletedReportTableColumns = (
  toggleShowModal,
  toggleRestoreModal,
  toggleDeleteModal
) => {
  return [
    {
      title: 'Client ID',
      sorter: (a, b) => a.id - b.id,
      dataIndex: 'id',
      key: 'id',
      width: '7%'
    },
    {
      title: 'Name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      key: 'name',
      className: 'fullname',
      render: (record) => trimString(FullNameGetter(record), 32),
      width: '16%'
    },
    {
      title: 'Email Address',
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (record) => <span>{record.email}</span>,
      width: '18%'
    },
    {
      title: 'Mobile Number',
      sorter: (a, b) => a.mobile - b.mobile,
      render: (record) => <span>{record.mobile}</span>,
      width: '10%'
    },
    {
      title: 'BSB',
      key: 'bsb',
      render: (record) => <span>{record.customer_bank?.bsb}</span>,
      width: '6%'
    },
    {
      title: 'Account Number',
      sorter: (a, b) => a.customer_bank.account_number - b.customer_bank.account_number,
      render: (record) => <span>{record.customer_bank?.account_number}</span>,
      key: 'account_number',
      width: '10%'
    },
    {
      title: 'Deleted Date',
      sorter: (a, b) => a.deleted_at.localeCompare(b.deleted_at),
      render: (deleted_at) => deleted_at?.split(' ')[0],
      key: 'deleted_at',
      dataIndex: 'deleted_at',
      width: '10%'
    },
    {
      title: 'Action',
      width: '6%',
      key: 'action',
      className: 'centeralign',
      render: (text) => {
        return (
          <>
            <FontAwesomeIcon onClick={() => toggleShowModal(text)} icon={faEye} size="1x" />
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              title="Restore Client"
              icon={faUndo}
              size="1x"
              onClick={() => toggleRestoreModal(text)}
            />
            {text?.is_used === 0 && (
              <>
                <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <FontAwesomeIcon
                  onClick={() => toggleDeleteModal(text?.id)}
                  icon={faTrash}
                  size="1x"
                />
              </>
            )}
          </>
        );
      }
    }
  ];
};

export const clientWithoutBankColumns = (navigate, location) => [
  {
    title: 'Client ID',
    sorter: (a, b) => a.id - b.id,
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    key: 'name',
    className: 'fullname',
    render: (record) => trimString(FullNameGetter(record), 32)
  },
  {
    title: 'Email Address',
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (record) => <span>{record.email}</span>
  },
  {
    title: 'Mobile Number',
    sorter: (a, b) => a.mobile - b.mobile,
    render: (record) => <span>{record.mobile}</span>
  },
  {
    title: 'TFN',
    key: 'tfn',
    render: (record) => <span>{record.customer_bank?.tfn}</span>
  },
  {
    title: 'Action',
    render: (record) => (
      <span>
        <FontAwesomeIcon
          icon={faEdit}
          size="1x"
          onClick={() => {
            navigate(`${API.CUSTOMER_EDIT}?id=${record.id}`, { state: { from: location } });
          }}
        />
      </span>
    )
  }
];

export const clientRequiredUpdateColumns = (navigate, location) => [
  {
    title: 'Client ID',
    sorter: (a, b) => a.id - b.id,
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    sorter: (a, b) => a?.first_name.localeCompare(b?.first_name),
    key: 'name',
    className: 'fullname',
    render: (record) => trimString(FullNameGetter(record), 32)
  },
  {
    title: 'Email Address',
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (record) => <span>{record.email}</span>
  },
  {
    title: 'Mobile Number',
    sorter: (a, b) => a.phone - b.phone,
    render: (record) => <span>{record.phone}</span>
  },
  {
    title: 'TFN',
    key: 'tfn',
    render: (record) => <span>{record.customer_bank?.tfn}</span>
  },
  {
    title: 'Action',
    render: (record) => (
      <span>
        <FontAwesomeIcon
          icon={faEdit}
          size="1x"
          onClick={() => {
            navigate(`${API.CUSTOMER_EDIT}?id=${record.id}`, { state: { from: location } });
          }}
        />
      </span>
    )
  }
];

export const ClientReportsColumns = [
  // {
  //   title: 'Receipt No./EFT No.',
  //   render: (record) => (
  //     <>
  //       {record?.type == 1
  //         ? record?.receipt_number
  //           ? record?.receipt_number
  //           : '-'
  //         : record?.eft_number
  //         ? record?.eft_number
  //         : '-'}
  //     </>
  //   ),
  //   width: '12%'
  // },
  {
    title: 'Name',
    sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    key: 'name',
    className: 'fullname',
    render: (record) => trimString(FullNameGetter(record), 32)
  },
  {
    title: 'Email Address',
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (record) => <span>{record.email}</span>
  },
  // {
  //   title: 'BSB',
  //   dataIndex: 'bsb',
  //   key: 'bsb',
  //   render: (bsb) => <span>{bsb ? bsb?.toString() : ''}</span>,
  //   width: '8%'
  // },
  // {
  //   title: 'AC No',
  //   dataIndex: 'ac_no',
  //   render: (accountNo) =>
  //     accountNo ? <span>{accountNo?.toString()}</span> : '',
  //   width: '8%'
  // },
  {
    title: 'Mobile Number',
    sorter: (a, b) => a.mobile - b.mobile,
    render: (record) => <span>{record.mobile}</span>
  },
  {
    title: 'CRN',
    key: 'crn',
    dataIndex: 'crn'
  },
  {
    title: 'Closing Balance',
    key: 'closing_balance',
    dataIndex: 'closing_balance',
    render: (closingBalance) => (
      <>{closingBalance ? NegativeAmountConverter(closingBalance) : '$0.00'}</>
    )
  }
];

export const trialBalanceTableColumns = [
  {
    title: 'Code',
    dataIndex: 'code',
    sorter: (a, b) => a.code.localeCompare(b.code),
    key: 'code'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    key: 'name'
  },
  {
    title: 'Credit',
    render: (record) => (
      <span className="rag-green">
        {record.type === 1 ? `$${formatNumber(Number.parseFloat(record.amount))}` : ''}
      </span>
    )
  },
  {
    title: 'Debit',
    render: (record) => (
      <span className="rag-red">
        {record.type !== 1 ? `$${formatNumber(Number.parseFloat(record.amount))}` : ''}
      </span>
    )
  }
];

export const newTrialBalanceTableColumns = [
  {
    title: 'Account',
    // dataIndex: 'account',
    // sorter: (a, b) => a.name.localeCompare(b.name),
    key: 'account',
    render: (record) => (
      <div className={record?.bussiness_name === 'Total' ? '' : 'all-client-name'}>
        {/* <span> */}

        {(record?.bussiness_name === 'Total'
          ? ''
          : record?.type === 'firm'
          ? 'F'
          : Object.keys(record).includes('supplier_state')
          ? 'S'
          : 'C') +
          `${
            record?.withdrawal_account == 1
              ? record?.last_name + '(Withdrawal Account)'
              : (record?.first_name
                  ? FullNameGetterWithoutChartAt(record)
                  : record?.bussiness_name
                  ? record?.bussiness_name
                  : record?.last_name) +
                (record?.withdrawal_account == 1 ? '(Withdrawal Account)' : '')
          }`}
        {/* </span> */}
      </div>
    )
  },
  {
    title: 'Debit',
    dataIndex: '',
    render: (record) => (
      <span>
        {record?.debit_amount
          ? '$' + formatNumber(Number(record?.debit_amount))
          : !record?.debit_amount && !record?.credit_amount && record?.type === 'firm'
          ? '$0.00'
          : ''}
      </span>
    ),
    align: 'right'
    // dataIndex: 'debit'
  },
  {
    title: 'Credit',
    dataIndex: '',
    render: (record) => {
      return (
        <>
          {record?.debit_amount ? (
            <span>
              {record?.credit_amount
                ? '$' + formatNumber(Math.abs(Number(record?.credit_amount)))
                : ''}
            </span>
          ) : (
            // record?.type !== 'firm' && (
              <span>
                {record?.credit_amount
                  ? '$' + formatNumber(Math.abs(Number(record?.credit_amount)))
                  : record?.type !== 'firm' && '$0.00'}
              </span>
            // )
          )}
        </>
      );
      // if (Number(record?.debit_amount) < 0 && record?.type == 'firm') {
      //   return (
      //     <span>
      //       {record?.closing_balance
      //         ? '$' + formatNumber(Math.abs(Number(record?.closing_balance)))
      //         : '$0.00'}
      //     </span>
      //   );
      // } else {
      //   return (
      //     <span>
      //       {record?.closing_balance ? '$' + formatNumber(record?.closing_balance) : '$0.00'}
      //     </span>
      //   );
      // }
    }
    //
    //   record?.type === undefined ? (
    //     <span>
    //       {record?.closing_balance ? '$' + formatNumber(record?.closing_balance) : '$0.00'}
    //     </span>
    //   ) : (
    //     <span></span>
    //   ),
    // align: 'right'

    // dataIndex: 'credit'
    // render: (record) => (
  }
];

export const getTrialBalanceTableInnerColumns = () => {
  return [
    {
      title: 'Transaction ID',
      dataIndex: 'id'
    },
    {
      title: 'Account',
      render: (data) => <span>{data?.chart_account?.code}</span>
    },
    {
      title: 'Date',
      key: 'date',
      render: (data) => moment(data?.bank_statement?.date?.split(' ')[0]).format('DD-MM-YYYY')
    },
    {
      title: 'Description',
      render: (data) => <span>{data?.chart_account?.name}</span>
    },
    {
      title: 'Tax Code',
      render: (data) => {
        return (
          <span>
            {data.chart_account.tax === 1 ? `${data.chart_account.gst_percentage}% GST` : 'No GST'}
          </span>
        );
      }
    },
    {
      title: 'Credit',
      render: (record) => (
        <span className="rag-green">
          {record.chart_account.type === 1
            ? `$${formatNumber(Number.parseFloat(record.amount))}`
            : ''}
        </span>
      )
    },
    {
      title: 'Debit',
      render: (record) => (
        <span className="rag-red">
          {record.chart_account.type !== 1
            ? `$${formatNumber(Number.parseFloat(record.amount))}`
            : ''}
        </span>
      )
    }
  ];
};
