import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import '../../App.css';
import '../../assets/css/style.css';
import AuditStatementPage from './AuditStatementPage';
import AuditDeleteTransaction from './AuditDeleteTransaction';
import { defaultPaginationLimit } from '../../config';

// const { TabPane } = Tabs;

const tabsKey = {
  'import-history': 1,
  'deleted-transactions': 2
};

const AuditMainPage = ({ setBalanceInfo }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('childTab') || 'import-history';
  const defaultActiveKey = tabsKey[selectedTab];
  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      navigate(`/firm-bank-account?page=1&tab=audit-statement&childTab=${tabName}&limit=${defaultPaginationLimit}`, {
        replace: true
      });
    }
  };

  const auditTabItem = [
    {
      key: "1",
      label: "Import History",
      children: (<AuditStatementPage setBalanceInfo={setBalanceInfo} />)
    },
    {
      key: "2",
      label: "Archived Transactions",
      children: (<AuditDeleteTransaction setBalanceInfo={setBalanceInfo} />)
    }
  ]

  return (
    <>
      <Tabs
        defaultActiveKey={defaultActiveKey.toString()}
        size="large"
        style={{ marginBottom: 32, paddingTop: '30px' }}
        onTabClick={onTabClick}
        items={auditTabItem}
      >
        {/* <TabPane forceRender={true} tab="Import History" key="1">
          <AuditStatementPage setBalanceInfo={setBalanceInfo} />
        </TabPane>
        <TabPane tab="Deleted Transactions" key="2">
          <AuditDeleteTransaction setBalanceInfo={setBalanceInfo} />
        </TabPane> */}
      </Tabs>
    </>
  );
};

export default AuditMainPage;
