import React from 'react';
import Form from '@component/Form';
import { Button } from 'react-bootstrap';
import { DatePicker, Input, Select, Spin } from 'antd';
import { useEffect } from 'react';
import { API, fileUpload, get } from '../../config';
import { toast } from 'react-toastify';
import { useState } from 'react';
import UserSelect from '../../components/User/UserSelect';
import moment from 'moment';

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const ReceiptEditModal = ({ record, onClose, fetchreceiptsList }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;

  const [loading, setLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  const [customerId, setCustomerId] = useState(record?.customer_id ? record?.customer_id : '');

  const getAccountData = () => {
    setAccountLoading(true);
    get(`${API.GET_CHARTACCOUNT}?limit=-1`)
      .then((response) => {
        setAccountOptions(response?.data?.data?.data ? response?.data?.data?.data : []);
        response && setAccountLoading(false);
      })
      .catch((e) => {
        setAccountLoading(false);
        toast.error(e?.data?.message);
      });
  };

  const handleCLientSelect = (val) => {
    setCustomerId(val);
  };

  useEffect(() => {
    getAccountData();
  }, []);

  const handleFinish = async (value) => {
    value.transaction_date = value.transaction_date
      ? moment(value.transaction_date).format('DD-MM-YYYY')
      : '';
    value.customer_id = customerId ? customerId : '';

    const formData = new FormData();
    formData.append('transaction_date', value?.transaction_date ? value?.transaction_date : '');
    formData.append('note', value?.note ? value?.note : '');
    formData.append('chart_account_id', value?.chart_account_id ? value?.chart_account_id : '');
    formData.append('customer_id', value?.customer_id ? value?.customer_id : '');
    formData.append('amount', value?.amount ? value?.amount : '');
    formData.append('description', value?.description ? value?.description : '');
    formData.append('_method', 'PUT');

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.RECIEPT}/${record?.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (data?.status === 200) {
        setLoading(false);
        toast.success(data.message);
        onClose();
        fetchreceiptsList();
      }
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="content-details">
      {loading && <Spin />}
      <Form
        form={form}
        initialValues={{
          transaction_date: record?.transaction_date ? moment(record?.transaction_date) : '',
          note: record?.note ? record?.note : '',
          chart_account_id: record?.chart_account_id ? record?.chart_account_id : '',
          customer_id: record?.customer_id ? record?.customer_id : '',
          amount: record?.amount ? record?.amount : '',
          description: record?.description ? record?.description : ''
        }}
        name="supplierForm"
        layout="vertical"
        onFinish={handleFinish}
      >
        {/* <Spin spinning={loading}> */}
        <div className="input-boxes">
          <Form.Item
            className="full-width"
            label="Transaction Date"
            name="transaction_date"
            rules={[
              {
                required: true,
                message: 'Please select transaction date.'
              }
            ]}
          >
            <DatePicker
              className="receipt-modal"
              format={["DD-MM-YYYY", "DD/MM/YYYY"]}
              disabledDate={disabledDate}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            className="full-width"
            label="File Note"
            name="note"
            rules={[
              {
                required: true,
                message: 'Please enter file note.'
              },
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
              }
            ]}
          >
            <Input placeholder="File Note" />
          </Form.Item>
          <Form.Item
            className="full-width"
            label="Account"
            name="chart_account_id"
            rules={[
              {
                required: true,
                message: 'Please select account.'
              }
            ]}
          >
            <Select
              showSearch
              style={{
                width: '100%',
                margin: '0'
              }}
              loading={accountLoading}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {accountOptions &&
                accountOptions.map((option) => {
                  return (
                    <Option key={option.id} value={option.id}>
                      {option?.name +
                        (option?.account_type?.name
                          ? ' (' + option?.account_type?.name + ')'
                          : '') +
                        (option?.code ? ' - ' + option?.code.toString() : '') +
                        (option?.sub_code ? '.' + option?.sub_code.toString() : '')}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            className="full-width"
            label="Client Name"
            name="customer_id"
            rules={[
              {
                required: true,
                message: 'Please enter file note.'
              },
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
              }
            ]}
          >
            <UserSelect
              customerId={customerId}
              onCustomerSelectionChange={(val) => {
                handleCLientSelect(val);
              }}
              placeholder="Please select Client"
              className="customer-select"
            />
          </Form.Item>
          <Form.Item
            className="full-width"
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter Opening Balance'
              },
              {
                pattern: new RegExp(/^\d*\.?\d*$/),
                message: 'Amount should be number'
              }
            ]}
          >
            <Input placeholder="File Note" />
          </Form.Item>
          <Form.Item
            className="full-width"
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter file note.'
              },
              {
                pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                message:
                  'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
              }
            ]}
          >
            <TextArea name="description" placeholder="Add Description" />
          </Form.Item>
        </div>
        <div className="action-box">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
        {/* </Spin> */}
      </Form>
    </div>
  );
};

export default ReceiptEditModal;
