import React from 'react';
import { Button, Input, Modal } from 'antd';
import { toast } from 'react-toastify';
import { API, fileUpload } from '../../config.js';
import Form from '@component/Form';

const EditAmountModal = ({ data, fetchATORefundList, toggleShowModal }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('refund', values?.amount ? values?.amount : '0');
      formData.append('_method', 'PUT');

      const response = await fileUpload(`${API.UPDATE_ATO}/${data?.id}`, formData, {});
      if (response?.status === 200) {
        response?.data?.message && toast.success(response?.data?.message);
        toggleShowModal();
        fetchATORefundList();
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        Object.keys(data.errors).forEach((key) => {
          toast.error(data.errors[key][0]);
        });
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };
  return (
    <div>
      <Modal
        className="remove-popup"
        width={500}
        closable
        centered
        open
        footer={null}
        onCancel={toggleShowModal}
      >
        <div className="remove-content edit-amount-modal">
          <Form
            form={form}
            initialValues={{
              amount: data?.amount ? data?.amount : ''
            }}
            name="supplierForm"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <div className="input-boxes">
              <Form.Item
                className="opening-balance"
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: 'Please enter amount'
                  },
                  {
                    pattern: new RegExp(/^\d*\.?\d*$/),
                    message: 'Amount should be number'
                  }
                ]}
              >
                <Input type="number" placeholder="Opening Balance" name="amount" />
              </Form.Item>
            </div>
            <div className="modal-action edit-amount-action">
              <Button className="cancel" onClick={toggleShowModal}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                OK
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default EditAmountModal;
