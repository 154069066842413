import moment from 'moment';
import React from 'react';
import AmountEditActionButton from './AmountEditActionButton';
// import ATOActionButtons from './ATOActionButton';
import { formatNumber } from '../../APIConfig/Interceptor';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import addAction from '../../assets/images/add-action.png';
import updateAction from '../../assets/images/update-action.png';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye } from '@fortawesome/free-solid-svg-icons';

export const tableColumns = (
  toggleShowModal
  // fetchATORefundList
) => {
  return [
    {
      title: 'Record ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Description',
      dataIndex: 'res_desc',
      render: (desc, record) => {
        return (
          <span
            onClick={(e) => {
              toggleShowModal(e, record.staff_id);
              return false;
            }}
            dangerouslySetInnerHTML={{ __html: desc }}
          ></span>
        );
      },
      sorter: (a, b) => a.res_desc.localeCompare(b.res_desc),
      width: '50%'
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      className: 'date_field',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (created_at, record) => (
        <>
          {/* <ATOActionButtons data={record} fetchATORefundList={fetchATORefundList} />
          <span></span>&nbsp;&nbsp;&nbsp;&nbsp; */}
          {record?.import_date
            ? moment(record?.import_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
            : moment(created_at, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY')}
        </>
      )
    },
    {
      title: 'Number of records',
      className: 'centeralign',
      dataIndex: 'total_records',
      sorter: (a, b) => a.total_records - b.total_records
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      sorter: (a, b) => a.total_amount - b.total_amount,
      render: (total_amount) =>
        total_amount ? <span>${formatNumber(total_amount)}</span> : '$0.00',
      key: 'total_amount'
    }
  ];
};

export const ATOTableInnerColumns = (fetchATORefundList) => {
  return [
    {
      title: 'Transaction ID',
      dataIndex: 'ato_id',
      width: '12.75%',
      render: (ato_id, record) => (
        <>
          <span className={record.status == '2' ? 'reg-red' : ''}>{ato_id}</span>
        </>
      )
    },
    {
      title: 'Client ID',
      dataIndex: 'customer_id',
      render: (customer_id, record) => (
        <>
          <span className={record.status == '2' ? 'reg-red' : ''}>{customer_id}</span>
        </>
      )
    },
    {
      title: 'Name',
      dataIndex: 'client_name',
      render: (client_name, record) => (
        <>
          <span className={record.status == '2' ? 'reg-red' : ''}>{client_name}</span>
        </>
      )
    },
    {
      title: 'TFN',
      dataIndex: 'tfn',
      render: (tfn, record) => (
        <>
          <span className={record.status == '2' ? 'reg-red' : ''}>{tfn}</span>
        </>
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount, record) => (
        <>
          {amount ? (
            <span className={record.status == '2' ? 'reg-red' : ''}>${formatNumber(amount)}</span>
          ) : (
            '$0.00'
          )}
          <span></span>&nbsp;&nbsp;&nbsp;&nbsp;
          {record?.reconciliation_status === 0 && (
            <AmountEditActionButton
              color={record.status == '2' ? 'red' : ''}
              data={record}
              fetchATORefundList={fetchATORefundList}
            />
          )}
        </>
      ),
      key: 'amount',
      width: '9.25%'
    },
    {
      title: 'Lodgement Reference',
      dataIndex: 'lodgement_reference',
      width: '27.35%',
      render: (customer_id, record) => (
        <>
          <span className={record.status == '2' ? 'reg-red' : ''}>{customer_id}</span>
        </>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '5.35%',
      render: (data, record) => (
        <>
          {record.status == '2' ? (
            <Tooltip title="Add Client" color={'#005B98'} key={'#005B98'}>
              <Link
                to={{ pathname: '/new-customers' }}
                state={{
                  ato_id: record.ato_id,
                  client_name: record.client_name,
                  last_name: record.last_name,
                  tfn: record.tfn
                }}
                className="action"
              >
                <img src={addAction} alt="add_action" />
              </Link>
            </Tooltip>
          ) : (
            <img src={updateAction} alt="add_action" />
          )}
        </>
      )
    }
  ];
};
