import React from "react";
import AntForm from "antd/lib/form";

const { Provider, Item, useForm, List } = AntForm;

const Form = AntForm;

// eslint-disable-next-line react/display-name
Form.Item = ({
  label,
  className,
  rules = [],
  ...props
}) => {
  return <Item label={label} rules={rules} className={className} {...props} />;
};
Form.useForm = useForm;
Form.List = List;
Form.Provider = Provider;

export default Form;
