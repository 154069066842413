import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { API, deleteCall } from '../../config';
import { toast } from 'react-toastify';
import { Button, Modal } from 'antd';

const NotInmportedDelete = ({handleDelete, id}) => {
  const [showDelete, setShowDelete] = useState(false);

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const handleNotImportedDelete = async () => {
    try {
      const { data } = await deleteCall(`${API.IMPORT_LOGS}/${id}`);
      toast.success(data.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      const { data } = e;
      toggleDeleteModal();
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  };
  return (
    <>
      <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
      <FontAwesomeIcon onClick={toggleDeleteModal} icon={faTrash} size="1x" />
      {showDelete && (
        <>
          <Modal
            className="remove-popup"
            width={500}
            closable
            centered
            open
            footer={null}
            onCancel={toggleDeleteModal}
          >
            <div className="remove-content">
              <p>Are your sure you want to delete permanently this not imported log?</p>
              <div className="modal-action">
                <Button className="cancel" onClick={toggleDeleteModal}>
                  Cancel
                </Button>
                <Button onClick={handleNotImportedDelete}>Confirm</Button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default NotInmportedDelete;
