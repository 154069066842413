import axios from 'axios';
import { toast } from 'react-toastify';
import numbro from 'numbro';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const formatNumber = (value, delimiter = 2) => {
  try {
    if (isNaN(unformatNumber(value))) {
      return '';
    }
    let format = numbro(value).format({
      thousandSeparated: true,
      // negative: 'parenthesis',
      mantissa: delimiter
    });
    return format;
  } catch {
    return '';
  }
};

export const unformatNumber = (value) => {
  return numbro.unformat(value);
};

export const SetCookie = (name, value, expires = 84600) => {
  cookies.set(name, value, {
    path: '/',
    maxAge: expires
  });
};

export const GetCookies = (name) => {
  return cookies.get(name, {
    doNotParse: false
  });
};

export const RemoveCookie = (name) => {
  // document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  return cookies.remove(name);
};

export const URL = 'https://api.trusteasy.com.au';
// export const URL = 'https://a6b00797c84f-12018376576325490140.ngrok-free.app/client/trusteasy';
const baseURL = `${URL}/api/v1`;

//set config when creating the instance
const instance = axios.create({ baseURL: baseURL });

//add a request interceptor
instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('myna-auth-token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['timeout'] = 5000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.status === 'Token Expired. Please try again.') {
        localStorage.clear();
        window.location.href = '/';
      }
      if (response.data.status === 401) {
        localStorage.clear();
        // window.location.href = "/";
      }
    }
    return response;
  },

  function (error) {
    const originalRequest = error.config;

    const headers = {
      'Content-Type': 'application/json'
    };
    // const token = localStorage.getItem('myna-auth-token');
    const token = localStorage.getItem('myna-refresh-token');
    headers['Authorization'] = 'Bearer ' + token;

    //token expired
    if (error.response.status === 419 && originalRequest.url !== '/reset-password') {
      return axios
        .post(`${baseURL}/refresh`, '', { headers: headers })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem('myna-auth-token', res.data.access_token);
            localStorage.setItem('myna-refresh-token', res.data.refresh_token);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setTimeout(() => {
            localStorage.clear();
            window.location.href = '/';
          }, 2000);
        });
    }

    //if user unauthorised then redirect to login
    if (error.response.status === 401) {
      const data = error?.response?.data;
      if (data?.errors?.myna_error?.[0] == 'Email or password may be wrong!') {
        toast.error(data.errors.myna_error[0]);
        localStorage.clear();
      } else if (data?.message) {
        toast.error(data.message);
        localStorage.clear();
        console.log('errr data : ', data?.['xero-data']);
        let url = '/firm/signup';
        if (data?.['xero-data']) {
          url += `?email=${data?.['xero-data']?.email}&first_name=${data?.['xero-data']?.first_name}&last_name=${data?.['xero-data']?.last_name}`;
        }
        setTimeout(() => {
          window.location.href = url;
        }, 2000);
      } else {
        localStorage.clear();
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
    //if user has no permission then redirect to dashboard
    // if (error.response.status === 403) {
    //   window.location.href = "/";
    // }

    return Promise.reject(error);
  }
);

export default instance;
