/* eslint-disable react/no-is-mounted */
import React from 'react';
import axios from 'axios';
import '../App.css';
import '../assets/css/style.css';
import SidebarNav from '../components/Header/SidebarNav';
import Footer from '../components/Footer/Index';

class Clients extends React.Component {
  constructor() {
    super();
    this.state = {
      clients: []
    };
  }

  UNSAFE_componentWillMount() {
    this.getClients();
  }

  getClients() {
    const token = this.props.token;
    axios
      .get('/learn/jwt/public/api/clients', {
        headers: { Authorization: 'Bearer ' + token }
      })
      .then((response) => {
        const clients = response.data;
        this.setState({ clients });
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401 && this.props.isAuthenticated) {
          // logged in but invalid jwt
          this.props.refresh();
        }
      });
  }

  render() {
    return (
      <div className="cms-page">
        <div className="page-content-block">
          <SidebarNav />
          <div className="full-content-block">
            <h1 className="page-title">Clients</h1>
            {this.state.clients.map((client, index) => {
              return (
                <div className="client" key={index}>
                  {client.name}
                  <br />
                  {client.address}
                  <br />
                  {client.telephone}
                  <hr />
                </div>
              );
            })}
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
