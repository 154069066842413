import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import customericon from '../../assets/images/customers.png';
// import refundtax from '../../assets/images/refund.png';
import refundato from '../../assets/images/ato-logo-new.png';
import processrefund from '../../assets/images/process-refund.png';
import processIndividual from '../../assets/images/process-individual.png';
import abaicon from '../../assets/images/aba.png';
import firmicon from '../../assets/images/firm-trust-account.png';
//import userconsoleicon from "../../assets/images/user-console.png";

const SidebarNav = () => {
  const [addClass, setAddClass] = useState(false);
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggle = () => {
    setAddClass(!addClass);
  };
  let boxClass = ['sidebar-nav'];
  if (addClass) {
    boxClass.push('open');
  }
  const handleClick = () => {
    if (location.pathname.includes('/customer')) {
      localStorage.setItem('isFromSame', true);
      setTimeout(() => {
        navigate('/customer?page=1');
      }, 100);
    }
  };

  useEffect(() => {
    if (location.pathname.includes('/customer')) {
      setActive('Clients');
    }
    if (location.pathname.includes('/ato-refund')) {
      setActive('Refund from the ATO');
    }
    if (location.pathname.includes('/process-refund')) {
      setActive('Process Refund in Bulk');
    }
    if (location.pathname.includes('/process-refund-individual')) {
      setActive('Process Refund');
    }
    if (location.pathname.includes('/aba')) {
      setActive('.ABA Files');
    }
    if (location.pathname.includes('/firm-bank-account')) {
      setActive('Firm Bank Account');
    }
  }, [location]);
  
  return (
    <div className={boxClass.join(' ')}>
      <i className="sidebtn" onClick={toggle}></i>
      <ul>
        <li className={active == 'Clients' ? 'active' : ''}>
          <Link
            to={{
              pathname: '/customer',
              search: '?page=1'
            }}
            onClick={() => {
              handleClick();
            }}
            data-title="Clients"
          >
            <img src={customericon} alt="Clients" />
            Clients
          </Link>
        </li>
        {/* <li>
          <Link
            to={{ pathname: '/tax-refund', search: '?page=1' }}
            data-title="Refund expected as per Tax lodgement"
          >
            <img src={refundtax} alt="Refund expected as per Tax lodgement" />
            Refund expected{<br />}as per Tax lodgement
          </Link>
        </li> */}
        <li className={active == 'Refund from the ATO' ? 'active' : ''}>
          <Link
            to={{ pathname: '/ato-refund', search: '?page=1' }}
            data-title="Refund from the ATO"
          >
            <img src={refundato} alt="Refund from the ATO" />
            Refund from the ATO
          </Link>
        </li>
        <li className={active == 'Process Refund in Bulk' ? 'active' : ''}>
          <Link to={{ pathname: '/process-refund' }} data-title="Process Refund in Bulk">
            <img src={processrefund} alt="Process Refund in Bulk" />
            Process Refund in Bulk
            {/* Process Reconciliation */}
          </Link>
        </li>
        <li className={active == 'Process Refund' ? 'active' : ''}>
          <Link to={{ pathname: '/process-refund-individual' }} data-title="Process Refund">
            <img src={processIndividual} alt="Process Refund" />
            Process Refund
            {/* Process Reconciliation */}
          </Link>
        </li>
        <li className={active == '.ABA Files' ? 'active' : ''}>
          <Link
            to={{ pathname: '/aba', search: '?page=1&tab=generate-aba' }}
            data-title=".ABA Files"
          >
            <img src={abaicon} alt=".ABA Files" />
            .ABA Files
          </Link>
        </li>
        <li className={active == 'Firm Bank Account' ? 'active' : ''}>
          <Link
            to={{ pathname: '/firm-bank-account', search: '?page=1&tab=reconcile' }}
            data-title="Firm Bank Account"
          >
            <img src={firmicon} alt=".Firm Bank Account" />
            Firm Bank Account
          </Link>
        </li>
        {/* <li>
            <Link to={{ pathname: '#' }} data-title='Invoices'>
              <img src={invoiceicon} alt='Invoices' />
              Invoices
            </Link>
          </li>
          <li>
            <Link to={{ pathname: '#' }} data-title='Firm Trust Account'>
              <img src={trustaccount} alt='Firm Trust Account' />
              Firm Trust Account
            </Link>
          </li>
        <li>
          <Link
            to={{ pathname: "/user-console?page=1" }}
            data-title="User Console"
          >
            <img src={userconsoleicon} alt="User Console" />
            User Console
          </Link>
        </li>*/}
      </ul>
    </div>
  );
};

export default SidebarNav;
