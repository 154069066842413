import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, get } from '../../config.js';
import { FullNameGetterWithoutChartAt } from '../../helper/fullNameGetter.js';

const preocessABAList = (data) => {
  return data.map((ele) => {
    const totalGrossAmount = (remittance, invoice_amount, disbursement) => {
      let total = 0;
      total =
        (remittance ? Number(parseFloat(remittance)) : 0.0) +
        (invoice_amount ? Number(parseFloat(invoice_amount)) : 0.0) +
        (disbursement ? Number(parseFloat(disbursement)) : 0.0);
      return total;
    };

    const totalFees = (remittance, invoice_amount) => {
      let total = 0;
      total =
        (remittance ? Number(parseFloat(remittance)) : 0.0) +
        (invoice_amount ? Number(parseFloat(invoice_amount)) : 0.0);
      return total;
    };

    return {
      ...ele,
      name: FullNameGetterWithoutChartAt(ele?.reconciliation?.customer),
      account_name: ele?.reconciliation?.customer?.customer_bank?.account_name,
      account_number: ele?.reconciliation?.customer?.customer_bank?.account_number,
      bsb: ele?.reconciliation?.customer?.customer_bank?.bsb,
      gross_amount: totalGrossAmount(
        ele?.reconciliation?.remittance,
        ele?.reconciliation?.invoice_amount,
        ele?.reconciliation?.disbursement
      ),
      fees: totalFees(ele?.reconciliation?.invoice_amount, ele?.reconciliation?.disbursement)
    };
  });
};

const useABAList = (currentPage, search, limit) => {
  const navigate = useNavigate();
  const [ABAs, setABAs] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(search || '');
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchABA = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const { data } = await get(
        `${API.GET_ABA_LIST}?search=${searchParam}&page=${pagingData.current}&limit=${limit}`
      );
      const { total, current_page, per_page, from, to } = data.data;
      const ABAData = preocessABAList(data.data.data);
      setIsDataLoading(false);
      setPagingData({
        total,
        current: current_page,
        defaultPageSize: per_page,
        from: from,
        to: to
      });
      setABAs(ABAData);
    } catch (e) {
      setABAs([]);
      setIsDataLoading(false);
      setPagingData({ current: 0 });
    }
  }, [pagingData.current, searchParam, limit]);

  useEffect(() => {
    fetchABA();
  }, [fetchABA]);

  return {
    ABAs,
    isDataLoading,
    fetchABA,
    pagingData,
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(
        `/aba?/aba-list?search=${searchParam}&page=${page}&tab=generate-aba&limit=${limit}`,
        {
          replace: true
        }
      );
    },
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/aba?search=${value}&page=1&tab=generate-aba&limit=${limit}`, {
        replace: true
      });
    },
    searchParam
  };
};

export default useABAList;
