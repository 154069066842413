import React, { useState } from "react";
import { Select, Modal } from "antd";
import { toast } from "react-toastify";
import usePartnerStaffList from "./usePartnerStaffList";
import { assignStaff } from "./DashboardHelper";
import { capitalizeFirstLetter } from "../../helper/fullNameGetter";

const { Option, OptGroup } = Select;

const AssignModal = ({ onClose, cardId, onAssign }) => {
  const { staff, isDataLoading } = usePartnerStaffList();
  const [ selectedId, setSelectedId ] = useState(null);

  const handleChange = async (value) => {
    setSelectedId(value);
  };

  const onOk = async () => {
    try {
      const reqBody = {
        id: cardId,
      };
      const role = selectedId.split(" ")[ 0 ];
      if (role === "manager") {
        reqBody.manager_id = selectedId.split(" ")[ 1 ];
      }
      if (role === "staff") {
        reqBody.staff_id = selectedId.split(" ")[ 1 ];
      }
      const response = await assignStaff(reqBody);
      toast.success(response.message);
      onAssign();
      onClose();
    } catch (e) {
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[ key ][ 0 ]);
      });
    }
  };

  return (
    <div>
      <Modal
        className="remove-popup popup-without-header"
        width={500}
        closable
        centered
        open
        onOk={onOk}
        onCancel={onClose}
      >
        <h4>Assign</h4>
        <Select
          placeholder="Assign"
          style={{ width: 440 }}
          showArrow={false}
          filterOption={false}
          onChange={handleChange}
          loading={isDataLoading}
        >
          {Object.keys(staff).length > 0 && Object.keys(staff).map((key) => (
            <OptGroup label={capitalizeFirstLetter(key)} key={key}>
              {typeof staff[ key ] === "object" && Array.isArray(staff[ key ]) && staff[ key ].length > 0 ? staff[ key ]?.map((item) => (
                <Option key={item.id} value={`${key} ${item.id}`}>
                  {`${item.first_name} ${item.last_name}`}
                </Option>
              )) :
                <Option key={staff[ key ].id} value={`${key} ${staff[ key ].id}`}>
                  {`${staff[ key ].first_name} ${staff[ key ].last_name}`}
                </Option>
              }
            </OptGroup>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default AssignModal;
