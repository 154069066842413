import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AdminUserRemove from './AdminUserRemove';
import AdminUserDetailPopup from './AdminUserDetailPopup';

const AdminUserActionButtons = ({ adminUserDetails, data, handleDelete }) => {
  const [showView, setShowView] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const toggleShowModal = () => {
    setShowView(!showView);
  };
  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <FontAwesomeIcon
        title="View AdminUser Details"
        onClick={toggleShowModal}
        icon={faEye}
        size="1x"
      />
      {adminUserDetails?.role?.name !== 'Admin' && (
        <>
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <Link to={{ pathname: '/admin/users/edit', search: `?id=${data.id}` }}>
            <FontAwesomeIcon title="Edit AdminUser" icon={faEdit} size="1x" />
          </Link>
        </>
      )}

      {/* {adminUserDetails?.role?.name !== 'Admin' && (
        <>
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <FontAwesomeIcon
            title="Archive AdminUser"
            onClick={toggleDeleteModal}
            icon={faTrash}
            size="1x"
          />
        </>
      )} */}

      {showDelete && (
        <AdminUserRemove
          toggleDeleteModal={toggleDeleteModal}
          adminUserId={data.id}
          handleDelete={handleDelete}
        />
      )}

      {showView && <AdminUserDetailPopup adminUserId={data.id} toggleShowModal={toggleShowModal} />}
    </span>
  );
};

export default AdminUserActionButtons;
