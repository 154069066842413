import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DatePicker, Modal, Select, Spin, Table } from 'antd';
import Footer from '../../../components/Footer/Index';
import { API, defaultPaginationLimit, get } from '../../../config';
import '../../../App.css';
import '../../../assets/css/style.css';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import { RegisteredFirmColumns } from './registeredFirmHelper';
import { Button, Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { debounce } from 'lodash';
import ExpandedRegisteredTable from './ExpandedRegisteredTable';

const { RangePicker } = DatePicker;
// const dateFormat = 'DD-MM-YYYY';
const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const getDefaultDates = (from, to) => {
  if (from && to) {
    return { start_date: from, end_date: to };
  } else {
    const previousYearDate = '';
    const currentDate = '';
    return { start_date: previousYearDate, end_date: currentDate };
  }
};

const RegisteredFirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const from = query.get('from');
  const to = query.get('to');
  const tab = query.get('tab');
  const search = query.get('search');

  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [registeredFirmData, setRegisteredFirmData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const [dateFilter, setDateFilter] = useState(() => getDefaultDates(from, to));
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [searchParam, setSearchParam] = useState(search || '');
  const [expandedRows, setExpandedRows] = useState([]);

  const handleExpandRow = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record.id);
    }
    setExpandedRows(expandedRows);
  };

  const getRegisteredFirmData = useCallback(
    async (currentPageValue, limit) => {
      if (tab === 'registeredFirm') {
        try {
          setIsLoading(true);
          let tempLimit = defaultPaginationLimit;
          if (limit) {
            tempLimit = limit;
          } else {
            tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
          }
          const { data } = await get(
            `${API.REGISTERED_FIRM_LIST}?page=${
              currentPageValue ? currentPageValue : pagingData.current
            }&limit=${tempLimit}`
          );
          setIsLoading(false);
          const { total, current_page, per_page, from, to } = data.data;
          setRegisteredFirmData(data.data);
          setPagingData({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
        } catch (e) {
          setRegisteredFirmData({});
          setIsLoading(false);
          setPagingData({ current: currentPage || 1 });
        }
      }
    },
    [pagingData.current, tab]
  );

  const getClientsReportData = useCallback(
    async (prevDate, currDate, selectedCustomerList, currentPageValue) => {
      if (tab === 'registeredFirm') {
        try {
          setIsLoading(true);
          setSelectedCustomer([]);
          const { data } = await get(
            `${API.REGISTERED_FIRM_LIST}?page=${
              currentPageValue ? currentPageValue : pagingData.current
            }&search=${searchParam}&start_date=${prevDate !== null ? prevDate : ''}&end_date=${
              currDate !== null ? currDate : ''
            }`
          );
          setIsLoading(false);
          const { total, current_page, per_page, from, to } = data.data;
          setRegisteredFirmData(data.data);
          setPagingData({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
        } catch (e) {
          setRegisteredFirmData({});
          setIsLoading(false);
          setPagingData({ current: currentPage || 1 });
        }
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    const { start_date, end_date } = dateFilter;
    getClientsReportData(start_date, end_date);
  }, [getClientsReportData]);

  useEffect(() => {
    setDateFilter({
      start_date: from,
      end_date: to
    });
  }, [from, to]);

  useEffect(() => {
    getRegisteredFirmData();
  }, [getRegisteredFirmData]);

  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilter({
        start_date: startDate,
        end_date: endDate
      });
      navigate(
        `/admin/registered-firms?tab=registeredFirm?page=1&search=&from=${startDate}&to=${endDate}`,
        {
          replace: true
        }
      );
    } else {
      setDateFilter({
        start_date: '',
        end_date: ''
      });
    }
  };

  const onFilterOk = () => {
    const { start_date, end_date } = dateFilter;
    getClientsReportData(start_date, end_date, selectedCustomer);
    setDatePickerVisible(false);
    // }
  };

  const onPageChange = (page, limit) => {
    const { start_date, end_date } = dateFilter;
    setPagingData({ ...pagingData, current: page });
    navigate(
      `/admin/registered-firms?tab=registeredFirm&page=${page}&limit=${limit}&start_date=${
        start_date !== null ? start_date : ''
      }&end_date=${end_date !== null ? end_date : ''}`,
      {
        replace: true
      }
    );
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    const { start_date, end_date } = dateFilter;
    setSearchParam(value);
    setPagingData({ ...pagingData, current: 1 });
    navigate(
      `/admin/registered-firms?tab=registeredFirm&page=1&search=${value}&start_date=${
        start_date !== null ? start_date : ''
      }&end_date=${end_date !== null ? end_date : ''}`,
      {
        replace: true
      }
    );
  };

  const handleChange = async (value) => {
    setLimit(value);
    const { start_date, end_date } = dateFilter;
    navigate(
      `/admin/registered-firms?tab=registeredFirm?page=1&limit=${value}&start_date=${
        start_date !== null ? start_date : ''
      }&end_date=${end_date !== null ? end_date : ''}`,
      {
        replace: true
      }
    );
    getRegisteredFirmData(pagingData.current, value);
  };

  return (
    <div className="cms-page registed-firm-page">
      <div className="search-section">
        <Form>
          <FormControl
            onChange={(e) => debounce(onSearchChange(e), 300)}
            type="text"
            value={searchParam}
            placeholder="Search Accounting Firm"
          />
          <Button className="fa fa-search">Search</Button>
        </Form>
        <div className="summary-filter" onClick={() => setDatePickerVisible(true)}>
          {dateFilter.start_date &&
            'start:' + dateFilter.start_date + ' - End: ' + dateFilter.end_date}
          <FontAwesomeIcon icon={faCalendarAlt} size="1x" />
        </div>
      </div>

      <Spin spinning={isLoading}>
        <Table
          rowKey={(record) => record.id}
          columns={RegisteredFirmColumns(getRegisteredFirmData)}
          dataSource={registeredFirmData?.data || []}
          className="partial-registered"
          expandable={{
            expandedRowRender: () => <ExpandedRegisteredTable id={expandedRows[0]} />
          }}
          onExpand={(expanded, record) => handleExpandRow(expanded, record)}
          expandedRowKeys={expandedRows}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: pagingData.pageSize,
            defaultCurrent: 1,
            showQuickJumper: true,
            onChange: (page) => onPageChange(page, limit),
            itemRender: PaginationItemRenderer,
            ...pagingData,
            pageSize: limit
          }}
        />
        <div>
          {registeredFirmData?.data?.length > 0 && (
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          )}
        </div>
      </Spin>
      <Footer />
      {datePickerVisible && (
        <Modal
          width={500}
          className="popup-without-header"
          closable
          centered
          open
          onOk={onFilterOk}
          onCancel={() => setDatePickerVisible(false)}
        >
          <h4>Select Start and End Date</h4>
          <RangePicker
            defaultValue={[
              dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
              dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
            ]}
            disabledDate={disabledDate}
            format={dateFormat}
            onChange={handleDateChange}
          />
        </Modal>
      )}
    </div>
  );
};
export default RegisteredFirm;
