import React from 'react';
import { Button, Input } from 'antd';
import Form from '../../components/Form';
import { toast } from 'react-toastify';
import { API, IV, post, SECRETKEY } from '../../config.js';

const ChangePassword = () => {
  const [form] = Form.useForm();

  var CryptoJS = require('crypto-js');
  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);

  const handleFinish = async (values) => {
    const encOldPassword = CryptoJS.AES.encrypt(values?.old_password, fkey, {
      iv: fiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const finalEncryptedOldPassword = encOldPassword.ciphertext.toString(CryptoJS.enc.Base64);

    const encNewPassword = CryptoJS.AES.encrypt(values?.new_password, fkey, {
      iv: fiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const finalEncryptedNewPassword = encNewPassword.ciphertext.toString(CryptoJS.enc.Base64);

    const encConfirmPassword = CryptoJS.AES.encrypt(values?.confirm_password, fkey, {
      iv: fiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const finalEncryptedConfirmPassword = encConfirmPassword.ciphertext.toString(
      CryptoJS.enc.Base64
    );

    const finalValue = {
      ...values,
      old_password: finalEncryptedOldPassword,
      new_password: finalEncryptedNewPassword,
      confirm_password: finalEncryptedConfirmPassword
    };

    try {
      const { data } = await post(API.CHANGE_PASSWORD, finalValue);
      if (data.status === 200) {
        toast.success(data.message);
        form.resetFields();
      } else {
        toast.error(data.errors?.myna_error[0]);
      }
    } catch (e) {
      const errors = e.response?.data?.message;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key]);
      });
    }
  };

  return (
    <div className="content-details">
      <div className="entry-card full-card">
        <Form form={form} name="changePasswordForm" onFinish={handleFinish} layout="vertical">
          <div className="form-box profile-form">
            <div className="input-boxes">
              <Form.Item
                label="Current Password"
                name="old_password"
                validateTrigger={['submit', 'onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Password'
                  }
                ]}
              >
                <Input.Password placeholder="Current Password" />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="new_password"
                validateTrigger={['submit', 'onBlur']}
                dependencies={['old_password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please enter New Password'
                  },
                  {
                    min: 8,
                    message: 'Password must have a minimum length of 8'
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
                    ),
                    message:
                      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character(!@#$%^&*).'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('old_password') != value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Old and New passwords should not match!'));
                    }
                  })
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirm_password"
                validateTrigger={['submit', 'onBlur']}
                dependencies={['new_password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please enter Confirm Password'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('New and Confirm passwords should match!'));
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            </div>
          </div>
          <div className="action-box">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
