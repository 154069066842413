import React from 'react';
import { FullNameGetter } from '../../../helper/fullNameGetter';
import BeneficiActionButton from './BeneficiaryActionButton';

const useBeneficiaryList = (getBeneficiaryData, clientId) => {
  const BeneficiaryColumns = clientId
    ? [
        {
          title: 'ID',
          dataIndex: 'id',
          width: '10%',
          className: 'centeralign'
        },
        {
          title: 'Beneficiary Name',
          dataIndex: 'account_name',
          className: 'fullname',
          render: (record) => <>{record?.charAt(0) + record}</>
        },
        {
          title: 'BSB',
          dataIndex: 'bsb',
          render: (record, index) => (
            // <span>{index?.bsb ? 'xxx' + index?.bsb.toString().slice(-4) : ''}</span>
            <span>{index?.bsb ? index?.bsb.toString() : ''}</span>
          )
        },
        {
          title: 'Account Number',
          dataIndex: 'account_number',
          render: (record, index) => (
            <span>
              {/* {index?.account_number ? 'xxx' + index?.account_number.toString().slice(-4) : ''} */}
              {index?.account_number ? index?.account_number.toString() : ''}
            </span>
          )
        },
        {
          title: 'Bank Name',
          dataIndex: 'bank_name'
        },
        {
          title: 'Action',
          width: '10%',
          // dataIndex: 'id',
          className: 'centeralign',
          render: (record) => {
            return (
              <>
                <BeneficiActionButton
                  getBeneficiaryData={getBeneficiaryData}
                  clientId={clientId}
                  record={record}
                />
              </>
            );
          }
        }
      ]
    : [
        {
          title: 'ID',
          dataIndex: 'id',
          width: '10%',
          className: 'centeralign'
        },
        {
          title: 'Customer Name',
          className: 'fullname',
          render: (record) => <>{FullNameGetter(record?.customer)}</>
        },
        {
          title: 'Beneficiary Name',
          dataIndex: 'account_name',
          className: 'fullname',
          render: (record) => <>{record?.charAt(0) + record}</>
        },
        {
          title: 'BSB',
          dataIndex: 'bsb',
          render: (record, index) => (
            // <span>{index?.bsb ? 'xxx' + index?.bsb.toString().slice(-4) : ''}</span>
            <span>{index?.bsb ? index?.bsb.toString() : ''}</span>
          )
        },
        {
          title: 'Account Number',
          dataIndex: 'account_number',
          render: (record, index) => (
            <span>
              {/* {index?.account_number ? 'xxx' + index?.account_number.toString().slice(-4) : ''} */}
              {index?.account_number ? index?.account_number.toString() : ''}
            </span>
          )
        },
        {
          title: 'Bank Name',
          dataIndex: 'bank_name'
        },
        {
          title: 'Action',
          width: '10%',
          // dataIndex: 'id',
          className: 'centeralign',
          render: (record) => {
            return (
              <>
                <BeneficiActionButton
                  getBeneficiaryData={getBeneficiaryData}
                  clientId={clientId}
                  record={record}
                />
              </>
            );
          }
        }
      ];
  return {
    BeneficiaryColumns
  };
};

export default useBeneficiaryList;
