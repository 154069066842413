import React from 'react';
import { Select } from 'antd';
import MynaForm from '../Form/HigherOrderForm';
/* eslint-disable no-nested-ternary */

const MynaSelect = (props) => {
  const {
    formStyleWidth,
    defaultValue,
    value,
    formProps,
    onSearch,
    loading,
    placeholder,
    onPopupScroll,
    onSelect,
    onBlur,
    disabled,
    filterOption,
    selectValueList,
    onClick,
    onChange,
    className,
    dropdownMatchSelectWidth,
    dropdownRender,
    display,
    clearable = true,
    onClear,
  } = props;

  const getSelectTag = () => {
    return (
      <>
        {clearable ? (
          <Select
            dropdownMatchSelectWidth={dropdownMatchSelectWidth === false ? false : true}
            className={className ? className : ''}
            showSearch={onSearch ? true : false}
            onSearch={onSearch ? onSearch : null}
            loading={loading}
            defaultValue={defaultValue ? defaultValue : null}
            value={value ? value : null}
            style={formStyleWidth ? formStyleWidth : null}
            placeholder={placeholder ? placeholder : ''}
            onPopupScroll={onPopupScroll ? onPopupScroll : null}
            onSelect={onSelect ? onSelect : null}
            onBlur={onBlur ? onBlur : null}
            disabled={disabled}
            filterOption={filterOption ? filterOption : null}
            onClick={onClick ? onClick : null}
            onChange={onChange ? onChange : ''}
            dropdownRender={dropdownRender ? dropdownRender : ''}
            dropdownStyle={{ display: display }}
            onClear={onClear}
            allowClear
          >
            {selectValueList}
          </Select>
        ) : (
          <Select
            dropdownMatchSelectWidth={dropdownMatchSelectWidth === false ? false : true}
            className={className ? className : ''}
            showSearch={onSearch ? true : false}
            onSearch={onSearch ? onSearch : null}
            loading={loading}
            defaultValue={defaultValue ? defaultValue : null}
            value={value ? value : null}
            style={formStyleWidth ? formStyleWidth : null}
            placeholder={placeholder ? placeholder : ''}
            onPopupScroll={onPopupScroll ? onPopupScroll : null}
            onSelect={onSelect ? onSelect : null}
            onBlur={onBlur ? onBlur : null}
            disabled={disabled}
            filterOption={filterOption ? filterOption : null}
            onClick={onClick ? onClick : null}
            onChange={onChange ? onChange : ''}
            dropdownRender={dropdownRender ? dropdownRender : ''}
            dropdownStyle={{ display: display }}
          >
            {selectValueList}
          </Select>
        )}
      </>
    );
  };

  const getSelectedTag = () => {
    if (formProps) {
      return <MynaForm Component={getSelectTag()} formProps={formProps} />;
    } else {
      return getSelectTag();
    }
  };

  return getSelectedTag();
};

export default MynaSelect;
