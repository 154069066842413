import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table } from 'antd';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { API, deleteCall } from '../../../config';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import useChartAccountList from './useChartAccountList';
import AddEditChartAccount from './AddEditChartAccount';
import '../../../App.css';
import '../../../assets/css/style.css';
import '../Task/tasksetting.module.css';

const tabsKey = {
  expenses: 1,
  revenue: 2,
  'current-assets': 3,
  'non-current-assets': 4,
  'current-liabilities': 5,
  'non-current-liabilities': 6,
  equity: 7
};

const ChartAccountList = ({ isAddingNew, setIsAddingNew, search }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('chartAccounts');
  const accountId = tabsKey[selectedTab] || 1;
  const [show, setShow] = useState(false);
  const [chart, setChart] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const {
    fetchChartAccounts,
    chartAccounts,
    isDataLoading,
    pagingData,
    onSearchChange,
    onPageChange
  } = useChartAccountList(accountId);
  const handleClose = () => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === accountId);
    setShow(false);
    fetchChartAccounts(accountId);
    setIsAddingNew(false);
    setChart(null);
    navigate(`/settings?tab=chart-of-accounts&chartAccounts=${tabName}`, {
      replace: true
    });
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
    setChart(null);
  };

  const handleCancel = () => {
    setShow(false);
    setIsAddingNew(false);
    setChart(null);
  };

  useEffect(() => {
    onSearchChange(search);
  }, [search]);

  const chartAccountTableColumns = [
    /*{
      title: "Code",
      dataIndex: "code",
      width: "8%",
      className: "centeralign",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Sub Code",
      dataIndex: "sub_code",
      width: "10%",
      className: "centeralign",
      sorter: (a, b) => a.sub_code - b.sub_code,
    },*/
    {
      title: 'Name',
      dataIndex: 'name',
      width: '52%',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '10%',
      sorter: (a, b) => a.type - b.type,
      render: (type) => (type === 0 ? 'Debit' : 'Credit')
    },
    {
      title: 'Tax Rate',
      dataIndex: 'tax',
      width: '10%',
      sorter: (a, b) => a.tax - b.tax,
      render: (tax, record) =>
        tax === 0
          ? 'GST Excluded'
          : 'GST ' + (record.gst_percentage === null ? 0 : record.gst_percentage) + '%'
    },
    {
      title: 'Action',
      width: '8%',
      className: 'centeralign',
      render: (record) => {
        return (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              onClick={() => {
                setChart(record);
                setShow(true);
              }}
            />
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              icon={faTrash}
              size="1x"
              onClick={() => {
                setChart(record);
                setShowDelete(true);
              }}
            />
          </>
        );
      }
    }
  ];

  const deleteAccountData = () => {
    try {
      deleteCall(API.DELETE_CHART_ACCOUNT + `${chart.id}`).then((response) => {
        handleDeleteClose();
        fetchChartAccounts(accountId);
        toast.success(response.data.message);
      });
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  return (
    <div className="cms-page">
      <Table
        className="task-table"
        columns={chartAccountTableColumns}
        dataSource={chartAccounts}
        loading={isDataLoading}
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: pagingData.pageSize,
          defaultCurrent: 1,
          showQuickJumper: true,
          itemRender: PaginationItemRenderer,
          onChange: (page) => onPageChange(page),
          ...pagingData
        }}
      />

      <Modal
        className="small-modal"
        show={isAddingNew || show}
        onHide={handleClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>{chart ? 'Edit' : 'Add'} to Chart of Accounts</Modal.Header>
        <Modal.Body>
          <AddEditChartAccount
            chart={chart}
            activeTab={accountId.toString()}
            onClose={handleClose}
          />
          <Button variant="secondary" className="reset" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        className="small-modal remove-popup"
        show={showDelete}
        onHide={handleDeleteClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={handleDeleteClose}></Modal.Header>
        <Modal.Body>
          <div className="remove-content">
            <p>Are your sure you want to delete this Account?</p>
            <div className="modal-action">
              <Button className="cancel" onClick={handleDeleteClose}>
                Cancel
              </Button>
              <Button onClick={deleteAccountData}>OK</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChartAccountList;
