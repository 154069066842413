import { Select } from 'antd';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import SidebarNav from '../../components/Header/SidebarNav';
import { API, defaultPaginationLimit } from '../../config';
import TableComponent from '../ato/TableComponent';
import { PaymentTableInnerColumns, tableColumns } from './paymentHelper';
import usepaymentList from './usePaymentList';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const search = query.get('search');
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;

  const {
    paymentList,
    pagingData,
    searchParam,
    onSearchChange,
    onPageChange,
    isDataLoading,
    fetchpaymentList
  } = usepaymentList(currentPage, search);

  const mainTableColumns = useMemo(() => {
    return tableColumns();
  }, []);
  const innerTableColumns = useMemo(() => {
    return PaymentTableInnerColumns();
  }, []);

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/payments?page=1&limit=${value}`, {
      replace: true
    });
    fetchpaymentList(value, 1);
    // useOnlyCustomerList(currentPage, search, selectedId);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Trust Payments
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={(e) => debounce(onSearchChange(e), 300)}
                    type="text"
                    value={searchParam}
                    placeholder="Search"
                  />
                  <Button className="fa fa-search">Search</Button>
                </Form>
              </div>
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate('/record-payment');
                  }}
                >
                  New Payments
                </Button>
              </div>
            </div>
            <div className="ato-refund" style={{ width: '100%', height: '88%' }}>
              {/* {isDataLoading ? (
                <Spin />
              ) : (
                <> */}
              <TableComponent
                columns={mainTableColumns}
                dataSource={paymentList}
                loading={isDataLoading}
                nestedTableColumns={innerTableColumns}
                nestedRowEndpoint={API.GET_PAYMENT_LIST}
                isNested={true}
                onPageChange={(page) => onPageChange(page, limit)}
                pagingData={{
                  defaultPageSize: pagingData.pageSize,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  itemRender: PaginationItemRenderer,
                  onChange: (page) => onPageChange(page, limit),
                  ...pagingData,
                  pageSize: limit
                }}
              />
              <div>
                {paymentList?.length > 0 && (
                  <div className="col-md-12">
                    Showing&nbsp;
                    <Select
                      defaultValue={`${defaultPaginationLimit}`}
                      value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                      onChange={handleChange}
                      options={[
                        { value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }
                      ]}
                    />
                    &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                    {pagingData?.to} from {pagingData?.total} Records
                  </div>
                )}
              </div>
              {/* </>
              )} */}
            </div>
          </div>
          <Footer />
        </div>
        ;
      </div>
      ;
    </div>
  );
};

export default Payment;
