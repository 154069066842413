import React from "react";
import { Button, Modal } from 'react-bootstrap';
import AddCommentReview from '../../pages/process-refund/AddCommentReview';


const CommentModal = (props) => {
    const { showModal, handleModalClose, handleCommentFinish, commentValue } = props

    return (
        <Modal
            size="lg"
            show={showModal}
            onHide={handleModalClose}
            dialogClassName="modal-50w medium-popup review-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>Send For Review</Modal.Header>
            <Modal.Body>
                <AddCommentReview
                    commentValue={commentValue}
                    handleCommentFinish={handleCommentFinish}
                />
                <Button variant="secondary" className="reset" onClick={handleModalClose}>
                    Cancel
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default CommentModal;
