import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../APIConfig/Interceptor';
import addAction from '../../assets/images/add-action.png';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

function OrphanATOElement(props, ref) {
  const { item } = props;

  return (
    <>
      <li className="card-box" ref={ref}>
        <p className="username">
          <FontAwesomeIcon icon={faUser} size="1x" /> {item?.client_name ? item.client_name : ''}
        </p>
        <div className='orphanATO-tfn'>
          <p>
            Date: {item?.created_at ? item?.created_at?.split(" ")[0] : ''}
          </p>
          <p>
            TFN: {item?.tfn ? item?.tfn : ''}
          </p>
        </div>
        <p className="refund-p">
          Refund Amount <span>${formatNumber(item?.amount ? item?.amount : 0.00) || 0.00}</span>
        </p>
        <div className="card-footer orphanATO-card-footer">
          <p>
            Lodgement Ref.: {item?.lodgement_reference ? item?.lodgement_reference : '-'}
          </p>
          <p>
            <span>
              <Tooltip title="Add Client" color={'#005B98'} key={'#005B98'}>
                <Link
                  to={{ pathname: '/new-customers' }}
                  state={{
                    ato_id: item?.ato_id,
                    client_name: item?.client_name,
                    last_name: item?.last_name,
                    tfn: item?.tfn
                  }}
                  className="action"
                >
                  <img src={addAction} alt="add_action" />
                </Link>
              </Tooltip>
            </span>
          </p>
        </div>
      </li>
    </>
  );
}

export default forwardRef(OrphanATOElement);
