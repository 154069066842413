import React, { useEffect, useState } from 'react';
import { Select, Spin, Input, Table } from 'antd';
import '../../App.css';
import '../../assets/css/style.css';
import './customer.module.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { SearchOutlined } from '@ant-design/icons';
import SidebarNav from '../../components/Header/SidebarNav';
import BackButton from '../../components/Form/BackButton';
import Footer from '../../components/Footer/Index';
// import DialogModel from '../../components/Header/DialogModal';
import { useNavigate, useLocation } from 'react-router-dom';
import useDuplicateCustomerList from './useDuplicateCustomerList';
import { duplicateTableColumns } from './CustomersHelper';
import { API, defaultPaginationLimit, fileUpload } from '../../config';
import { toast } from 'react-toastify';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';

// import { helpCenterArray } from '../HelpCenter';

const DuplicateCustomerList = () => {
  const location = useLocation();
  const selectedId = localStorage.getItem('selectedId');
  const selectedMore = localStorage.getItem('selectedMore');
  const isFromSame = localStorage.getItem('isFromSame');
  const query = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const search = query.get('search');
  const navigate = useNavigate();
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [selectedRow, setSelectedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteDuplicate, setShowDeleteDuplicate] = useState(false);
  // const [disableRecords, setDisabledRecords] = useState([]);

  const {
    customersList,
    fetchCustomers,
    onSearchChange,
    searchParam,
    isCustomerLoading,
    pagingData,
    onPageChange,
    setSearchParam,
    disableRecords
  } = useDuplicateCustomerList(currentPage, search, selectedId, limit);

  const xeroCode = query.get('code');

  useEffect(() => {
    async function fetchData() {
      if (xeroCode) {
        try {
          const formData = new FormData();
          formData.append('code', xeroCode ? xeroCode : '');
          const { data } = await fileUpload(`${API.XERO_OAUTHREDIRECT}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          if (data?.status === 200) {
            navigate(`${API.GET_CUSTOMERLIST}?search=&page=1`, {
              replace: true
            });
            try {
              const response = await fileUpload(`${API.XERO_REFRESH}`, {});
              if (response?.data?.status === 200) {
                return null;
              }
            } catch (e) {
              try {
                const response = await fileUpload(`${API.XERO_REFRESH}`, {});
                if (response?.data?.status === 200) {
                  return null;
                }
              } catch (error) {
                toast.error('Something went wrong');
                // const errors = error.response?.data?.errors;
                // Object.keys(errors).forEach((key) => {
                //   toast.error(errors[key][0]);
                // });
              }
            }
          }
        } catch (e) {
          const { data } = e.response;
          if (data) {
            toast.error(data?.message);
            toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          }
        }
      }
    }
    fetchData();
  }, [xeroCode]);

  useEffect(() => {
    if (selectedMore) {
      localStorage.removeItem('selectedMore');
      setSearchParam(search);
      // onSearchChange(obj);
    }
    if (isFromSame) {
      localStorage.removeItem('isFromSame');
      fetchCustomers(pagingData.current, '', limit);
    }
    if (selectedId) {
      if (location.state.path?.includes('customer')) {
        fetchCustomers(pagingData.current, selectedId, limit);
      }
    }
  }, [search, selectedId, selectedMore]);

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/duplicate-customer?page=1&limit=${value}`, {
      replace: true
    });
    fetchCustomers(pagingData.current, '', value);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  // useEffect(() => {
  //   if (customersList?.length > 0) {
  //     let crn = [];
  //     for (let i = 0; i < customersList.length; i++) {
  //       if (crn.indexOf(customersList[i]?.crn) === -1) {
  //         crn.push(customersList[i]?.crn);
  //       }
  //     }
  //     let disabledKeys = [];
  //     for (let j = 0; j < crn.length; j++) {
  //       let data = customersList?.filter((item) => item.crn === crn[j] && item.is_used !== 1);
  //       if (data?.length > 1) {
  //         disabledKeys.push(data[0]?.id);
  //       }
  //     }
  //     setDisabledRecords(disabledKeys);
  //   }
  // }, []);

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      let crn = [];
      for (let i = 0; i < customersList.length; i++) {
        if (crn.indexOf(customersList[i]?.crn) === -1) {
          crn.push(customersList[i]?.crn);
        }
      }
      let disabledKeys = [];
      for (let j = 0; j < crn.length; j++) {
        let data = customersList?.filter((item) => item.crn === crn[j] && item.is_used !== 1);
        if (data?.length > 1) {
          disabledKeys.push(data[0]?.id);
        }
      }
      return {
        disabled: record.is_used === 1 || disabledKeys.includes(record.id) // Column configuration not to be checked
      };
    }
  };

  const handleDelete = () => {
    fetchCustomers();
  };

  const toggleDeleteDuplicateModal = () => {
    setShowDeleteDuplicate(!showDeleteDuplicate);
  };

  const handleDeleteDuplicate = async () => {
    const formData = new FormData();
    if (selectedRow?.length > 0) {
      for (let i = 0; i < selectedRow?.length; i++) {
        formData.append(`ids[${i}]`, selectedRow[i]);
      }
    }
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.DELETE_DUPLICATE_CUSTOMER}`, formData);
      setLoading(false);
      if (data) {
        setSelectedRow([]);
        toast.success(data?.message);
        toggleDeleteDuplicateModal();
        fetchCustomers();
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const isLoading = selectedId;
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Duplicate Clients{' '}
            {/* <DialogModel
              title="Duplicate Clients"
              p1={helpCenterArray[2].name}
              p2={helpCenterArray[2].text}
              p3={helpCenterArray[3].name}
              p4={helpCenterArray[3].text}
              p5={helpCenterArray[4].name}
              p6={helpCenterArray[4].text}
            /> */}
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="">
                <Form>
                  {/* <FormControl
                    onChange={(e) => onSearchChange(e)}
                    type="text"
                    value={searchParam}
                    placeholder="Search Client"
                  /> */}
                  {/* <Button type="primary" shape="circle"></Button> */}

                  <Input
                    style={{ width: '200%' }}
                    onChange={(e) => onSearchChange(e)}
                    suffix={<SearchOutlined />}
                    value={searchParam}
                    placeholder="Search Duplicate Client"
                    allowClear
                  />
                </Form>
              </div>
              <div className="table-btn">
                <Button
                  variant="primary"
                  onClick={() => toggleDeleteDuplicateModal()}
                  disabled={selectedRow?.length === 0}
                >
                  Delete Duplicate
                </Button>
              </div>
            </div>

            <div className="customer-table">
              {isLoading || loading ? (
                <Spin />
              ) : (
                <>
                  <Table
                    className="components-table-demo-nested"
                    loading={isCustomerLoading}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultPageSize: pagingData.pageSize,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      itemRender: PaginationItemRenderer,
                      onChange: (page) => onPageChange(page, limit),
                      ...pagingData
                    }}
                    columns={duplicateTableColumns(handleDelete, disableRecords)}
                    dataSource={customersList}
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection
                    }}
                  />
                  <div>
                    {customersList?.length > 0 && (
                      <div className="col-md-12">
                        Showing&nbsp;
                        <Select
                          defaultValue={`${defaultPaginationLimit}`}
                          value={
                            query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
                          }
                          onChange={handleChange}
                          options={[
                            { value: '10', label: '10' },
                            { value: '25', label: '25' },
                            { value: '50', label: '50' },
                            { value: '100', label: '100' }
                          ]}
                        />
                        &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                        {pagingData?.to} from {pagingData?.total} Records
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {showDeleteDuplicate && (
            <Modal
              className="small-modal remove-popup"
              show={showDeleteDuplicate}
              onHide={toggleDeleteDuplicateModal}
              dialogClassName="modal-50w"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton={toggleDeleteDuplicateModal}></Modal.Header>
              <Modal.Body>
                <div className="remove-content spin-body-relative">
                  <p>Are you sure you want to delete duplicate clients?</p>
                  <div className="modal-action">
                    <Button className="cancel" onClick={toggleDeleteDuplicateModal}>
                      Cancel
                    </Button>
                    <Button onClick={handleDeleteDuplicate}>OK</Button>
                  </div>
                  {loading && (
                    <div className="top-modal-spin-center">
                      <Spin />
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DuplicateCustomerList;
