import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
// import { Image } from 'antd';
import { API, get } from '../../config';
import { imageDefaultPrefixPath } from '../../config';
import { RemoveCookie, SetCookie } from '../../APIConfig/Interceptor';

const UserProfile = () => {
  const userName = localStorage.getItem('Name');
  const logout = () => {
    get(API.LOGOUT)
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          SetCookie('_subscription', '');
          RemoveCookie('_subscription');
          window.location.href = '/';
        }
      })
      .catch(() => {
        var error = {};
        error['myna_error'] = 'There is some issue with request, please try after some time.';
        this.setState({ errors: error });
      });
  };
  const profilePhoto = useMemo(() => localStorage.getItem('ProfilePhoto'), []);
  return (
    <div className="userprofile">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {!profilePhoto ? (
            <span>{(userName ? userName : '').charAt(0)}</span>
          ) : (
            <img alt='profile-image' src={`${imageDefaultPrefixPath}${localStorage.getItem('ProfilePhoto')}`} />
          )}
          {userName ? userName : ''}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="/user-settings?tab=myprofile">My Profile</Dropdown.Item>
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default UserProfile;
