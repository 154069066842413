import React from 'react';

export function PaginationItemRenderer(current, type, originalElement) {
  if (type === 'prev') {
    return <a>{'< Prev'}</a>;
  }
  if (type === 'next') {
    return <a>{'Next >'}</a>;
  }
  return originalElement;
}
