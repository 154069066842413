import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, Select, Spin } from 'antd';
import { toast } from 'react-toastify';
import Form from '@component/Form';
import { API, fileUpload } from '../../../config.js';
import './roles.module.css';
import { getRoleDetail, roleStatus, roleTypeOption } from './RoleListHelper';
import { Modal as BSModal } from 'react-bootstrap';

const RoleAdd = ({ showAddRole, toggleRoleModal, fetchRoles, edit = false, id = null }) => {
  const [form] = Form.useForm();
  const [roleDetail, setRoleDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const adminUserDetailData = await getRoleDetail(id, true);
      setRoleDetail(adminUserDetailData.data);
      setLoading(false);
    }
    if (edit) {
      fetchData();
    }
  }, [id]);

  const handleFinish = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append([key], values[key]);
      });
      if (edit) {
        formData.append('_method', 'put');
      }
      setLoading(true);
      const { data } = await fileUpload(`${API.CREATE_ROLE}${edit ? "/" + id : ""}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoading(false);
      toast.success(data.message);
      fetchRoles();
      toggleRoleModal();
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const getInitialValues = useMemo(() => {
    if (!roleDetail) return { status: 1 };
    return {
      ...roleDetail
    };
  }, [roleDetail]);

  return (
    <BSModal
      show={showAddRole}
      onHide={toggleRoleModal}
      className="small-modal"
      dialogClassName="modal-50w admin-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <BSModal.Header>{edit ? 'Edit Role' : 'Add Role'}</BSModal.Header>
      <BSModal.Body>
        <div className="content-details spin-body-relative">
          {edit ? (
            <>
              {roleDetail && <Form
                form={form}
                initialValues={getInitialValues}
                name="loginForm"
                onFinish={handleFinish}
                layout="vertical"
              >
                <div className="input-boxes">
                  <Form.Item
                    className="full-width"
                    label="Role Type"
                    name="role_type"
                    rules={[
                      {
                        required: true,
                        message: 'Select Role Type'
                      }
                    ]}
                  >
                    <Select showSearch optionFilterProp="children" placeholder="Select Role Type">
                      {roleTypeOption.map((role) => (
                        <Select.Option key={role.value} value={role.value}>
                          {role.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Role Name"
                    name="name"
                    validateTrigger={['onBlur']}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Role name'
                      },
                      {
                        max: 32,
                        message: 'Must be less than 32 character'
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]*$/),
                        message: 'Role name can contain letters & spaces.'
                      }
                    ]}
                  >
                    <Input placeholder="Role Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Status"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: 'Select Status'
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select Status"
                      defaultValue={1}
                    >
                      {roleStatus.map((status) => (
                        <Select.Option key={status.value} value={status.value}>
                          {status.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <div className="upload-btn-div">
                    <Button type="primary" htmlType="submit" className="upload">
                      Save
                    </Button>
                    <Button
                      variant="secondary"
                      className="cancel-btn"
                      onClick={toggleRoleModal}
                      style={{
                        borderRadius: '30px'
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>}
              {loading && !roleDetail &&
            <div className="modal-spin-center">
              <Spin />
            </div>
          }
            </>
          ) : (
            <>
              <Form
                form={form}
                initialValues={getInitialValues}
                name="loginForm"
                onFinish={handleFinish}
                layout="vertical"
              >
                <div className="input-boxes">
                  <Form.Item
                    className="full-width"
                    label="Role Type"
                    name="role_type"
                    rules={[
                      {
                        required: true,
                        message: 'Select Role Type'
                      }
                    ]}
                  >
                    <Select showSearch optionFilterProp="children" placeholder="Select Role Type">
                      {roleTypeOption.map((role) => (
                        <Select.Option key={role.value} value={role.value}>
                          {role.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Role Name"
                    name="name"
                    validateTrigger={['onBlur']}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Role name'
                      },
                      {
                        max: 32,
                        message: 'Must be less than 32 character'
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]*$/),
                        message: 'Role name can contain letters & spaces.'
                      }
                    ]}
                  >
                    <Input placeholder="Role Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Status"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: 'Select Status'
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select Status"
                      defaultValue={1}
                    >
                      {roleStatus.map((status) => (
                        <Select.Option key={status.value} value={status.value}>
                          {status.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <div className="upload-btn-div">
                    <Button type="primary" htmlType="submit" className="upload">
                      Save
                    </Button>
                    <Button
                      variant="secondary"
                      className="cancel-btn"
                      onClick={toggleRoleModal}
                      style={{
                        borderRadius: '30px'
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
              {loading && (
                <div className="modal-spin-center">
                  <Spin />
                </div>
              )}
            </>
          )}
        </div>
      </BSModal.Body>
    </BSModal>
  );
};

export default RoleAdd;
