import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import DeleteOpeningBalance from './DeleteOpeningBalance';
import { Modal } from 'react-bootstrap';
import AddEditOpeningBalance from './AddEditOpeningBalance';
// import { Link } from 'react-router-dom';
// import CustomerRemove from './CustomerRemove';
// import CustomerDetailPopup from './CustomerDetailPopup';
// import { API } from '../../config';

const OpeningActionButtons = ({ data, id, type, handleDelete }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const toggleEditModal = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    handleDelete();
  };

  return (
    <span>
      <FontAwesomeIcon onClick={toggleEditModal} icon={faEdit} size="1x" />
      <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
      <FontAwesomeIcon onClick={toggleDeleteModal} icon={faTrash} size="1x" />
      {showDelete && (
        <DeleteOpeningBalance
          toggleDeleteModal={toggleDeleteModal}
          id={id}
          type={type}
          handleDelete={handleDelete}
        />
      )}
      {show && (
        <Modal
          className="mid-modal supplier-modal"
          id="opening_balance_modal"
          show={show}
          onHide={handleClose}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>Edit Opening Balance</Modal.Header>
          <Modal.Body>
            <AddEditOpeningBalance data={data} type="customer" onClose={handleClose} />
          </Modal.Body>
        </Modal>
      )}
    </span>
  );
};

export default OpeningActionButtons;
