import React, { useState } from 'react';
import { getNextPageData, sortData } from './DashboardHelper';
import InfinityScrollComponent from './InfinityScrollComponent';
import ReturnAssessedElement from './ReturnAssessedElement';

const ReturnAssessed = ({
  returnLodged,
  ATOList,
  returnAssessed,
  underReview,
  processed,
  sortValue,
  updateState,
  onAssign,
  filter
}) => {
  const [lastElement, setLastElement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const assessedData = returnAssessed?.data;

  const loadMore = async () => {
    setIsLoading(true);
    const nextPageData = await getNextPageData(
      returnAssessed.current_page + 1,
      'tax_return_assessed',
      filter
    );
    const newObj = {
      ...nextPageData,
      data: [...assessedData, ...nextPageData.data]
    };
    const data = sortData(sortValue, returnLodged, ATOList, newObj, underReview, processed);
    updateState(data);
    setIsLoading(false);
    const isMoreDataExist =
      newObj.data.length < newObj.total &&
      nextPageData?.next_page_url !== '' &&
      nextPageData?.next_page_url !== null;
    setHasMore(isMoreDataExist);
  };

  const isLastItem = (idx) => {
    return idx === assessedData?.length - 1;
  };
  const createLists = () => {
    return assessedData?.map((item, idx) => {
      return isLastItem(idx) ? (
        <ReturnAssessedElement key={idx} item={item} ref={setLastElement} onAssign={onAssign} />
      ) : (
        <ReturnAssessedElement key={idx} item={item} onAssign={onAssign} />
      );
    });
  };

  return (
    <>
      <InfinityScrollComponent
        hasMore={hasMore}
        isLoading={isLoading}
        loadMore={loadMore}
        loadOnMount={true}
        lastItemRef={lastElement}
      >
        {createLists()}
      </InfinityScrollComponent>
      {isLoading && <li className="card-box">Loading...</li>}
    </>
  );
};

export default ReturnAssessed;
