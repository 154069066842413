import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { API, deleteCall } from '../../../config';
import { toast } from 'react-toastify';
import '../../../App.css';
import '../../../assets/css/style.css';
import '../../users/users.module.css';
import SalesForceLogo from '../../../assets/images/salesforce-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const SalesForceStatus = ({ id, salesForceData, getRegisteredFirmData }) => {
  const [show, setShow] = useState(false);
  const toggleSalesForceDisconnectModal = () => {
    setShow(!show);
  };

  const disconnectSalesForce = async (toggleSalesForceDisconnectModal) => {
    try {
      const { data } = await deleteCall(
        `${API.SALESFORCE_ADMIN_DISCONNECT}?accounting_firm_id=${id}`
      );
      toggleSalesForceDisconnectModal();
      return data;
    } catch (e) {
      return null;
    }
  };

  const handleSalesForceDisconnect = async () => {
    const salesForceDisconnect = await disconnectSalesForce(toggleSalesForceDisconnectModal);
    salesForceDisconnect?.message && toast.success(salesForceDisconnect?.message);
    getRegisteredFirmData();
  };

  return (
    // <span className="action-box" style={{ alignItems: 'center', justifyContent: 'center' }}>
    //   {(salesForceData?.connection_status !== 0) && salesForceData ? (
    //     <button
    //       className="xero-btn"
    //       onClick={toggleSalesForceDisconnectModal}
    //       style={{ minHeight: '40px', marginRight: '10px' }}
    //     >
    //       <img src={SalesForceLogo} alt="sales force logo" className="xero-logo" />
    //       Disconnect
    //     </button>
    //   ) : (
    //     <></>
    //   )}
    <span className="sales-force-status-body">
      {
        // (salesForceData?.connection_status !== 0)
        // &&
        salesForceData ? (
          <Tooltip
            placement="bottomLeft"
            title={salesForceData?.connection_status ? 'Connected' : 'Disconnected'}
          >
            <button
              className="xero-btn sales-force-status-xero-btn"
              onClick={
                salesForceData?.connection_status !== 0
                  ? () => toggleSalesForceDisconnectModal()
                  : () => {}
              }
              // disabled={salesForceData?.connection_status !== 0 ? false : true}
            >
              <img src={SalesForceLogo} alt="Xero logo" className="xero-logo" />
              {salesForceData?.connection_status !== 0 ? (
                <FontAwesomeIcon icon={faCheck} style={{ color: '#0da500' }} />
              ) : (
                <FontAwesomeIcon icon={faXmark} style={{ color: '#f00' }} />
              )}
            </button>
          </Tooltip>
        ) : (
          <></>
        )
      }

      {show && (
        <Modal
          show={show}
          className="remove-popup"
          width={500}
          closable
          centered
          open
          footer={null}
          onCancel={toggleSalesForceDisconnectModal}
        >
          <div className="remove-content">
            {/* {loading && <Spin />} */}
            <p>Do you want to disconnect SalesForce?</p>
            <div className="modal-action">
              <Button className="cancel" onClick={toggleSalesForceDisconnectModal}>
                Cancel
              </Button>
              <Button onClick={handleSalesForceDisconnect}>OK</Button>
            </div>
            {/* {errorMessage && <p className="text-danger">{errorMessage}</p>} */}
          </div>
        </Modal>
      )}
    </span>
  );
};

export default SalesForceStatus;
