import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import UploadFile from '../../components/UploadFile';
import { API, fileUpload } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import './ato.module.css';

const ATORefundUploadFile = ({ handleImportClose, fetchATORefundList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const handleFileChange = (info) => {
    setFiles(info.fileList);
  };

  const handleClose = () => {
    setIsLoading(false);
    setFiles([]);
    handleImportClose();
  };

  const handleUpload = async () => {
    if (files && files.length) {
      setIsLoading(true);
      const formData = new FormData();
      files.forEach((file) => {
        if (file !== undefined) {
          formData.append('ato_file', file.originFileObj);
        }
      });
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      fileUpload(API.IMPORT_ATOS, formData, config)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            toast.success(response.data.message);
            fetchATORefundList();
            handleClose();
          }
        })
        .catch((err) => {
          const { data } = err.response;
          setIsLoading(false);
          toast.error(data?.message);
          toast.error(data.errors && data.errors.ato_file && data.errors.ato_file[0]);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          var error = {};
          error['myna_error'] = 'There is some issue with request, please try after some time.';
        });
    }
  };

  return (
    <Modal
      show
      onHide={handleClose}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>Import</Modal.Header>
      <Modal.Body>
        <UploadFile
          isMultiple={true}
          isLoading={isLoading}
          handleFileChange={handleFileChange}
          handleUpload={handleUpload}
          handleCancel={handleClose}
          acceptFileFormat={
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          }
        />
      </Modal.Body>
    </Modal>
  );
};

export default ATORefundUploadFile;
