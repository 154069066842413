import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, get } from '../../config.js';

const preocessUserList = (data) => {
  return data.map((user) => {
    return {
      ...user,
      roleName: user?.role.name,
      officeName: user?.user_wise_staff?.staff_wise_office?.suburb?.name
    };
  });
};

const useUserList = (currentPage, search) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const query = new URLSearchParams(location.search);
  const [searchParam, setSearchParam] = useState(search || '');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchUsers = useCallback(
    async (page, limit) => {
      try {
        setIsDataLoading(true);
        const { data } = await get(
          `${API.GET_USER_LIST}?search=${searchParam}&page=${page || pagingData.current}&limit=${
            limit ?? query.get('limit')
          }`
        );
        setIsDataLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        const userData = preocessUserList(data.data.data);
        setUsers(userData);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setUsers([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return {
    fetchUsers,
    users,
    searchParam,
    isDataLoading,
    pagingData,
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/user-console?search=${value}&page=1`, {
        replace: true
      });
    },
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/user-console?search=${searchParam}&page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default useUserList;
