import React, { forwardRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronCircleRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { getAssignedTo, getFirstChar } from './DashboardHelper';
import AssignModal from './AssignModal';
import { useNavigate } from 'react-router-dom';
import CommentModal from '../../components/modal/CommentModal';
import { API, fileUpload } from '../../config';
import { toast } from 'react-toastify';
import { formatNumber } from '../../APIConfig/Interceptor';

function ReturnAssessedElement(props, ref) {
  const { item, onAssign } = props;
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const navigate = useNavigate();

  const handleSelect = (value, item) => {
    if (value === 'assign') {
      setSelectedCardId(item?.id);
      setIsAssignModalVisible(true);
    } else if (value === 'sendForReview') {
      setShowCommentModal(true);
    } else if (value === 'processRefund') {
      navigate('/process-refund-individual/' + item?.customer?.id);
    }
  };

  const handleCloseCommentModal = () => {
    setShowCommentModal(false);
  };

  const handleSubmitReview = (value) => {
    const itemId = item.id;
    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('send_for_review', '1');
    formData.append('comment', value.comment);
    fileUpload(API.MATCH_UNMATCH_REVIEW + itemId, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.status == '200') {
          // setSendReviewItems([ ...sendReviewItems, itemId ]);
          // window.location.reload(false)
          handleCloseCommentModal();
          onAssign();
          // getProcessData(id);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };

  var ato_time = item.ato?.created_at?.split(' ') || [''];
  var ato_date = ato_time[0];
  var process_time = item?.updated_at?.split(' ');
  var process_date = process_time[0];

  return (
    <>
      <li className="card-box" ref={ref}>
        <p className="crn">
          CRN:{' '}
          {item?.tax_software?.crn == null || item?.tax_software?.crn == ''
            ? 'ID' + item?.customer?.id
            : item?.tax_software?.crn}
        </p>
        <p className="username">
          <FontAwesomeIcon icon={faUser} size="1x" />
          {item?.tax_software?.first_name} {item?.tax_software?.last_name}
        </p>
        <p>
          {/* xxxx xxxx {item?.tax_software?.account_number?.toString().slice(-4)} */}
          {item?.tax_software?.account_number?.toString()}
          {item.year !== undefined && <span style={{ marginLeft: 'auto' }}>ITR {item.year}</span>}
        </p>
        <p className="refund-p">
          ATO Refund: {ato_date} <span>${formatNumber(item?.ato?.refund) || 0}</span>
        </p>
        <p className="refund-p">
          Refund as per{' '}
          {item?.tax_software?.tax_software ? item?.tax_software?.tax_software : 'Xero'}:{' '}
          {process_date}
          <span>${formatNumber(item?.tax_software?.refund) || 0}</span>
        </p>
        <div className="card-footer">
          <FontAwesomeIcon icon={faChevronCircleRight} /> Assigned to {getAssignedTo(item) || 'N/A'}{' '}
          {getFirstChar(item) ? <span className="firstchar">{getFirstChar(item)}</span> : null}
          <Dropdown onSelect={(value) => handleSelect(value, item)}>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faEllipsisV} size="1x" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="processRefund">Process Refund</Dropdown.Item>
              <Dropdown.Item eventKey="assign">Reassign</Dropdown.Item>
              <Dropdown.Item eventKey="sendForReview">Send for Review</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </li>
      {isAssignModalVisible && (
        <AssignModal
          cardId={selectedCardId}
          onClose={() => setIsAssignModalVisible(false)}
          onAssign={onAssign}
        />
      )}
      <CommentModal
        showModal={showCommentModal}
        handleModalClose={handleCloseCommentModal}
        handleCommentFinish={handleSubmitReview}
        // commentValue
      />
    </>
  );
}

export default forwardRef(ReturnAssessedElement);
