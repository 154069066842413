import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DatePicker, Divider, Form, Select, Space, Spin } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BackButton from '../../components/Form/BackButton';
import CancelButton from '../../components/Form/CancelButton';
import SidebarNav from '../../components/Header/SidebarNav';
import { API, get } from '../../config';
import AddNewContactModal from './AddNewContact';

const defaulEntryData = [
  {
    account: '',
    description: '',
    gst: '',
    debit: '',
    credit: '',
    tax: '',
    contact: '',
    notes: ''
  },
  {
    account: '',
    description: '',
    gst: '',
    debit: '',
    credit: '',
    tax: '',
    contact: '',
    notes: ''
  }
];
const newEntryData = {
  account: '',
  description: '',
  gst: '',
  debit: '',
  credit: '',
  tax: '',
  contact: '',
  notes: ''
};

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const AddJournalEntry = () => {
  const { Option, OptGroup } = Select;
  const [loading, setLoading] = useState(false);

  const [entryData, setEntryData] = useState(defaulEntryData);

  const [accountOptions, setAccountOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [contactOptionsReplica, setContactOptionsReplica] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [supplierOptionsReplica, setSupplierOptionsReplica] = useState([]);

  const [totalDeference, setTotalDeference] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const [addNewContact, setAddNewContact] = useState(false);
  const [newContactItem, setNewContactItem] = useState();
  const [showNewIndexs, setShowNewIndexs] = useState();
  const [entryDataForContact, setEntryDataForContact] = useState([]);

  useEffect(() => {
    let debitTotal = 0;
    let creditTotal = 0;
    entryData &&
      entryData?.length > 0 &&
      entryData?.map((item) => {
        debitTotal += item?.debit ? parseInt(item?.debit) : 0;
        creditTotal += item?.credit ? parseInt(item?.credit) : 0;
        return 0;
      });
    setTotalDebit(debitTotal);
    setTotalCredit(creditTotal);
    let deference = debitTotal - creditTotal;
    setTotalDeference(deference);
  }, [entryData]);

  useEffect(() => {
    handleAccountget();
    handleContactList();
  }, []);

  const handleNewContact = (item) => {
    setAddNewContact(true);
    setNewContactItem(item);
  };

  const handleAccountget = () => {
    get(`${API.GET_CHARTACCOUNT}?limit=-1`)
      .then((response) => {
        setAccountOptions(response?.data?.data?.data ? response?.data?.data?.data : []);
      })
      .catch((e) => {
        toast.error(e?.data?.message);
      });
  };
  const handleContactList = (search = '') => {
    get(`${API.GET_CUSTOMERLIST}?search=${search}&limit=-1`)
      .then((response) => {
        let options = response?.data?.data?.data.map((element) => {
          return { id: element.id, name: `${element.first_name} ${element.last_name}` };
        });
        setContactOptions(options ? options : []);
        setContactOptionsReplica(options ? options : []);
      })
      .catch((e) => {
        toast.error(e?.data?.message);
      });
  };
  const handleSupplierList = (search = '') => {
    get(`${API.SUPPLIERS}?search=${search}&limit=-1`)
      .then((response) => {
        let options = response?.data?.data?.data.map((element) => {
          return { id: element.id, name: element.name };
        });
        setSupplierOptions(options ? options : []);
        setSupplierOptionsReplica(options ? options : []);
      })
      .catch((e) => {
        toast.error(e?.data?.message);
      });
  };

  const handleSearch = (value) => {
    if (!value || value === '') {
      handleSupplierList();
      handleContactList();
    }
    const contacts = [...contactOptionsReplica];
    const suppliers = [...supplierOptionsReplica];
    var i;
    var updatedContactData = [];
    var updatedSupplierData = [];
    for (i = 0; i < contacts.length; i++) {
      if (contacts[i].name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        updatedContactData.push(contacts[i]);
      }
    }
    for (i = 0; i < suppliers.length; i++) {
      if (suppliers[i].name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        updatedSupplierData.push(suppliers[i]);
      }
    }
    setContactOptions(updatedContactData);
    setSupplierOptions(updatedSupplierData);
  };

  const handleNewContactNameSelection = (data, index, e) => {
    handleContactNameSelection(data, index, e);
  };

  const handleAddEntry = (data) => {
    setLoading(false);
    setEntryData([...data, newEntryData]);
  };
  const handleDeleteEntry = (data, index) => {
    data.splice(index, 1);
    setEntryData([...data]);
  };

  const handleAccountChange = (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, account: e };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleContactNameSelection = (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, contact: e };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleDescriptionChange = (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleDebitChange = (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, debit: e.target.value, credit: '' };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleCreditChange = (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, credit: e.target.value, debit: '' };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleTaxChange = (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, tax: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleNotesChange = (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, notes: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <SidebarNav />
        </div>
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            New Journal Entry{' '}
          </h1>
          <div className="content-details">
            <div className="entry-section">
              <div
                className={
                  totalDeference === 0 && totalDebit !== 0
                    ? 'jounal-entry-card-completed'
                    : 'jounal-entry-card'
                }
              >
                <span className="journal-entry-status">
                  <Form>
                    <DatePicker oneTap style={{ width: 130 }} disabledDate={disabledDate} />
                  </Form>
                  <div className="journal-entry">
                    <p>
                      Total Debit<i>{totalDebit}</i>
                    </p>
                    <p>
                      Total Credit<i>{totalCredit}</i>
                    </p>
                    <p>
                      Difference<i>{totalDeference}</i>
                    </p>
                  </div>
                </span>
                <div className="table table-bordered journal-table">
                  {loading && <Spin />}
                  <div className="react-bootstrap-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: '3%' }}></th>
                          <th scope="col" style={{ width: '15%' }}>
                            Account
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            Description
                          </th>
                          <th scope="col" style={{ width: '8%' }}>
                            GST
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            Debit
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            Credit
                          </th>
                          <th scope="col" style={{ width: '8%' }}>
                            Tax
                          </th>
                          <th scope="col" style={{ width: '10%' }}>
                            Contact
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            Notes
                          </th>
                          <th scope="col" style={{ width: '6%' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {entryData &&
                          entryData?.length > 0 &&
                          entryData?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {index + 1 === entryData?.length ? (
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        handleAddEntry(entryData, index);
                                      }}
                                      icon={faAdd}
                                      size="1x"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td>
                                  <Select
                                    showSearch
                                    style={{ width: '250px', margin: '0' }}
                                    loading={accountOptions?.length === 0 ? true : false}
                                    onChange={(e) => handleAccountChange(entryData, index, e)}
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                      0
                                    }
                                    value={item?.account}
                                  >
                                    {accountOptions &&
                                      accountOptions.map((option) => {
                                        return (
                                          <Option key={option.id} value={option.id}>
                                            {option?.name +
                                              (option?.account_type?.name
                                                ? ' (' + option?.account_type?.name + ')'
                                                : '') +
                                              (option?.code
                                                ? ' - ' + option?.code.toString()
                                                : '') +
                                              (option?.sub_code
                                                ? '.' + option?.sub_code.toString()
                                                : '')}
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name={`description`}
                                    value={item?.description ? item?.description : ''}
                                    onChange={(e) => {
                                      handleDescriptionChange(entryData, index, e);
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td>
                                <td>
                                  <Select
                                    showSearch
                                    style={{ width: '110px', margin: '0' }}
                                    // loading={accountOptions?.length === 0 ? true : false}
                                    // onChange={(e) => handleAccountChange(entryData, index, e)}
                                    // filterOption={(input, option) =>
                                    // option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    // 0
                                    // }
                                    // value={item?.account}
                                  >
                                    <Option key="1" value={1}>
                                      GST Excluded
                                    </Option>
                                    <Option key="2" value={2}>
                                      GST Included
                                    </Option>
                                    {/* {accountOptions &&
                                      accountOptions.map((option) => {
                                        return (
                                          <Option key={option.id} value={option.id}>
                                            {option?.name +
                                              (option?.account_type?.name
                                                ? ' (' + option?.account_type?.name + ')'
                                                : '') +
                                              (option?.code
                                                ? ' - ' + option?.code.toString()
                                                : '') +
                                              (option?.sub_code
                                                ? '.' + option?.sub_code.toString()
                                                : '')}
                                          </Option>
                                        );
                                      })} */}
                                  </Select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name={`debit`}
                                    value={item?.debit ? item?.debit : ''}
                                    onChange={(e) => {
                                      handleDebitChange(entryData, index, e);
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name={`credit`}
                                    value={item?.credit ? item?.credit : ''}
                                    onChange={(e) => {
                                      handleCreditChange(entryData, index, e);
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name={`tax`}
                                    value={item?.tax ? item?.tax : ''}
                                    onChange={(e) => {
                                      handleTaxChange(entryData, index, e);
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td>
                                <td>
                                  <Select
                                    showSearch
                                    filterOption={false}
                                    onSearch={debounce(handleSearch, 500)}
                                    style={{ width: '155px' }}
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: '8px 0'
                                          }}
                                        />
                                        <Space
                                          style={{
                                            padding: '0 8px 4px'
                                          }}
                                        >
                                          <span
                                            type="text"
                                            onClick={() => {
                                              handleNewContact(item);
                                              setShowNewIndexs(index);
                                              setEntryDataForContact(entryData);
                                            }}
                                          >
                                            <strong> + Add New Contact</strong>
                                          </span>
                                        </Space>
                                      </>
                                    )}
                                    loading={
                                      contactOptions.length === 0 && supplierOptions.length === 0
                                        ? true
                                        : false
                                    }
                                    value={item?.contact}
                                    onChange={(e) =>
                                      handleContactNameSelection(entryData, index, e)
                                    }
                                    // disabled={!record?.chart_account?.account_type}
                                  >
                                    <OptGroup label="Clients">
                                      {contactOptions &&
                                        contactOptions.map((option) => {
                                          return (
                                            <Option
                                              key={option.id}
                                              group="client"
                                              value={option.id}
                                            >
                                              {option.name}
                                            </Option>
                                          );
                                        })}
                                    </OptGroup>
                                    <OptGroup label="Suppliers">
                                      {supplierOptions &&
                                        supplierOptions.map((option) => {
                                          return (
                                            <Option
                                              key={option.id}
                                              group="supplier"
                                              value={option.id}
                                            >
                                              {option.name}
                                            </Option>
                                          );
                                        })}
                                    </OptGroup>
                                  </Select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name={`notes`}
                                    value={item?.notes ? item?.notes : ''}
                                    onChange={(e) => {
                                      handleNotesChange(entryData, index, e);
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {index + 1 === entryData?.length ? (
                                    <>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleAddEntry(entryData, index);
                                        }}
                                        icon={faAdd}
                                        size="1x"
                                      />
                                      {index > 1 && (
                                        <>
                                          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              handleDeleteEntry(entryData, index);
                                            }}
                                            icon={faTrash}
                                            size="1x"
                                          />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    index > 1 && (
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleDeleteEntry(entryData, index);
                                        }}
                                        icon={faTrash}
                                        size="1x"
                                      />
                                    )
                                  )}
                                </td>
                                {addNewContact && showNewIndexs == index && (
                                  <AddNewContactModal
                                    setAddNewContact={setAddNewContact}
                                    showNewIndexs={showNewIndexs}
                                    entryDataForContact={entryDataForContact}
                                    addNewContact={addNewContact}
                                    handleNewContactNameSelection={handleNewContactNameSelection}
                                    newContactItem={newContactItem}
                                    handleContactList={handleContactList}
                                    handleSupplierList={handleSupplierList}
                                  />
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="action-box">
                  <Button type="submit" className="btn btn-primary" style={{ color: '#FFF' }}>
                    Post
                  </Button>
                  <CancelButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddJournalEntry;
