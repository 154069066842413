/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faUserCheck, faUserGear, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { getSupportDetail } from './SupportTicketHelper';
import SupportTicketView from './SupportTicketView';
// import { checkRole } from '../../../helper/utils';
import { Button, Modal } from 'react-bootstrap';
import { get } from '../../../config';
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const SupportTicketActionButton = ({ id, fetchSupportTickets, supportDetails }) => {
  const [supportDetail, setSupportDetail] = useState();
  const [reply, setReplay] = useState(false);
  const [accessModal, setAccessModal] = useState(false);
  const [accessStatus, setAccessStatus] = useState();
  const [isLoading, setIsLoading] = useState();

  const [showView, setShowView] = useState(false);
  // const roleType = localStorage.getItem('role_type');

  const fetchData = async () => {
    setLoading(true);
    const userDetailData = await getSupportDetail(id);
    if (userDetailData?.support_ticket_comments?.length === 1) {
      setReplay(true);
    } else {
      setReplay(false);
    }
    setSupportDetail(userDetailData);
    setLoading(false);
  };
  const toggleShowModal = () => {
    if (!showView) {
      fetchData();
    } else {
      fetchSupportTickets();
    }
    setShowView(!showView);
  };

  const toggleAccessModal = (status) => {
    if (accessModal) {
      setAccessStatus('');
    } else {
      setAccessStatus(status);
    }
    setAccessModal(!accessModal);
  };

  const handleAccessStatus = (status) => {
    setIsLoading(true);
    const formData = new FormData();
    if (status === 'isResolved') {
      formData.append('is_resolved', 1);
    } else {
      formData.append('status', status);
    }
    formData.append('_method', 'put');
    get(status === 'get-access' ? `${status}/${supportDetails?.accounting_firm_id}` : `${status}`)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data.message);
          toggleAccessModal();
        }
      })
      .catch((err) => {
        const { data } = err.response;
        setIsLoading(false);
        toast.error(data?.message);
        toast.error(data.errors && data.errors.customer_file && data.errors.customer_file[0]);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        var error = {};
        error['myna_error'] = 'There is some issue with request, please try after some time.';
      });
  };
  const [loading, setLoading] = useState(true);

  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <FontAwesomeIcon
        title="View Support Ticket"
        onClick={toggleShowModal}
        icon={faEye}
        size="1x"
      />
      {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
      {checkRole(roleType) ? (
        <>
          <FontAwesomeIcon
            title="Get Access"
            icon={faUserGear}
            size="1x"
            onClick={() => {
              toggleAccessModal('get-access');
            }}
          />
        </>
      ) : (
        <>
          <FontAwesomeIcon
            title="Grant Access"
            icon={faUserCheck}
            size="1x"
            onClick={() => {
              toggleAccessModal('grant-access');
            }}
          />
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <FontAwesomeIcon
            title="Revoke Access"
            icon={faUserXmark}
            size="1x"
            onClick={() => {
              toggleAccessModal('revoke-access');
            }}
          />
        </>
      )} */}
      {/* <>
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon title="Edit Support Ticket" icon={faEdit} size="1x" />
      </> */}
      {showView && (
        <>
          <SupportTicketView
            showView={showView}
            toggleShowModal={toggleShowModal}
            loading={loading}
            supportDetail={supportDetail}
            handleDelete={fetchSupportTickets}
            id={id}
            fetchData={fetchData}
            fetchSupportTickets={fetchSupportTickets}
            setReplay={setReplay}
            reply={reply}
          />
        </>
      )}
      {accessModal && (
        <Modal
          className="small-modal remove-popup"
          show={accessModal}
          onHide={toggleAccessModal}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton={toggleAccessModal}></Modal.Header>
          <Modal.Body>
            <div className="remove-content spin-body-relative">
              <p>
                {accessStatus === 'get-access'
                  ? 'Do you want to get the user account access?'
                  : accessStatus === 'grant-access'
                  ? 'Do you want to grant the support user to access?'
                  : accessStatus === 'revoke-access'
                  ? 'Do you want to revoke the support user to access?'
                  : ''}
              </p>
              <div className="modal-action">
                <Button
                  className="cancel"
                  onClick={() => {
                    toggleAccessModal();
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={() => handleAccessStatus(accessStatus)}>OK</Button>
              </div>
              {isLoading && (
                <div className="top-modal-spin-center">
                  <Spin />
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </span>
  );
};

export default SupportTicketActionButton;
