import { useEffect, useState, useCallback } from "react";
import { API, get } from "../../config.js";

const usePartnerStaffList = () => {
  const [staff, setStaff] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);

  const fetchPartnerStaffData = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const { data } = await get(API.PARTNER_STAFF_LIST);
      setStaff(data?.data);
      setIsDataLoading(false);
    } catch (e) {
      setStaff({});
      setIsDataLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPartnerStaffData();
  }, [fetchPartnerStaffData]);

  return {
    staff,
    isDataLoading,
  };
};

export default usePartnerStaffList;
