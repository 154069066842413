import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select, Spin, Table } from 'antd';
import Footer from '../../components/Footer/Index';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import useAuditDeleteTransactionList from './useAuditDeleteTransactionList';
import { auditDeleteTransactionTableColumns } from './FirmTrustHelper';
import '../../App.css';
import '../../assets/css/style.css';
import { API, defaultPaginationLimit, fileUpload } from '../../config';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';

const AuditDeleteTransaction = ({ setBalanceInfo }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPage = query.get('page');
  const selectedTab = query.get('childTab');
  const [deletedItem, setDeletedItem] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
  );
  const {
    auditDeleteTransaction,
    isDataLoading,
    pagingData,
    onPageChange,
    fetchAuditDeleteTransactionList
  } = useAuditDeleteTransactionList(currentPage, '', selectedTab, setBalanceInfo, limit);

  useEffect(() => {}, [auditDeleteTransaction]);

  const handleUserDelete = async () => {
    if (deletedItem) {
      const formData = new FormData();
      formData.append(`ids[0]`, deletedItem);
      await fileUpload(`${API.PERMANETALY_DELETE_BANK_STATEMENT}`, formData)
        .then((response) => {
          toast.success(response?.data?.message);
          if (response?.data?.errors?.myna_error) {
            toast.error(response?.data?.errors?.myna_error[0]);
          }
          if (response?.status === 200) {
            fetchAuditDeleteTransactionList();
            setTimeout(() => {
              setDeletedItem('');
              setShowDelete(!showDelete);
            }, 700);
          }
        })
        .catch((e) => {
          const { data } = e.response;
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          toast.error(e?.data?.message);
        });
    }
  };

  const toggleDeleteModal = (id) => {
    setShowDelete(!showDelete);
    setDeletedItem(id);
  };

  const toggleDeleteAllModal = () => {
    setShowDeleteAll(!showDeleteAll);
  };

  const handleDeleteAll = async () => {
    const formData = new FormData();
    if (selectedRow?.length > 0) {
      for (let i = 0; i < selectedRow?.length; i++) {
        formData.append(`ids[${i}]`, selectedRow[i]);
      }
    }
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.PERMANETALY_DELETE_BANK_STATEMENT}`, formData);
      setLoading(false);
      if (data) {
        setSelectedRow([]);
        toast.success(data?.message);
        toggleDeleteAllModal();
        fetchAuditDeleteTransactionList();
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: onSelectChange
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(
      `/firm-bank-account?tab=audit-statement&childTab=deleted-transactions&page=1&limit=${value}`,
      {
        replace: true
      }
    );
    fetchAuditDeleteTransactionList(value, 1);
  };

  return (
    <>
      <div className="cms-page audit-statement">
        <div className="table-btn">
          <Button
            variant="primary"
            onClick={() => {
              toggleDeleteAllModal();
            }}
            className="audit-delete-count"
            disabled={selectedRow?.length === 0 ? true : false}
          >
            Delete Selected
          </Button>
        </div>

        <div className="audit-statement-react-table">
          {loading ? (
            <Spin />
          ) : (
            <>
              <Table
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                columns={auditDeleteTransactionTableColumns(toggleDeleteModal)}
                dataSource={auditDeleteTransaction}
                loading={isDataLoading}
                pagination={
                  pagingData
                    ? {
                        hideOnSinglePage: true,
                        defaultCurrent: 1,
                        defaultPageSize: pagingData?.pageSize || defaultPaginationLimit,
                        showQuickJumper: true,
                        onChange: (page) => {
                          onPageChange(page);
                        },
                        itemRender: PaginationItemRenderer,
                        pageSize: limit,
                        ...pagingData
                      }
                    : false
                }
              />
              {auditDeleteTransaction?.length > 0 && (
                <div className="col-md-12" style={{ paddingLeft: '10px' }}>
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              )}
            </>
          )}
        </div>
        <Footer />
      </div>
      {showDeleteAll && (
        <Modal
          className="small-modal remove-popup"
          show={showDeleteAll}
          onHide={toggleDeleteAllModal}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton={toggleDeleteAllModal}></Modal.Header>
          <Modal.Body>
            <div className="remove-content">
              <p>
                Are you sure you want to delete {selectedRow?.length === 0 ? 'all' : 'selected'}{' '}
                bank transactions permanently?
              </p>
              <div className="modal-action">
                <Button className="cancel" onClick={toggleDeleteAllModal}>
                  Cancel
                </Button>
                <Button onClick={handleDeleteAll}>OK</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {
        <Modal
          show={showDelete && deletedItem}
          onHide={() => toggleDeleteModal('')}
          dialogClassName="modal-50w small-popup bank-transaction-delete-modal"
          arial-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>Bank Transaction</Modal.Header>
          <div className="remove-content bank-transaction-delete-body">
            <p>Are your sure you want to delete this transaction permanently?</p>
          </div>
          <div className="modal-action bank-transaction-delete-action">
            <Button onClick={() => toggleDeleteModal('')}>Close</Button>
            <Button onClick={handleUserDelete}>Delete</Button>
          </div>
        </Modal>
      }
    </>
  );
};

export default AuditDeleteTransaction;
