import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import './firm.module.css';
import { toast } from 'react-toastify';
import StripeModal from '../../components/modal/StripeModal';
import StripePaymentModal from '../../components/modal/StripePaymentModal';
import { API, get } from '../../config.js';
import { decrypt } from '../../helper/utils';
import numbro from 'numbro';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const stripeTestPromise = loadStripe(API.STRIPE_KEY);

const Subscription = () => {
  // eslint-disable-next-line no-undef
  const { id } = useParams();
  const [login, setlogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [setsubscription, setsubscriptionlist] = useState([]);
  const [selectsubscription, selectsubscriptionplan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [promoCode, setPromoCode] = useState(false);
  const [selectedPeriodPlan, setSelectedPeriodPlan] = useState('year');
  const [percentOff, setPercentOff] = useState(0);
  const [supportPlan, setSupportPlan] = useState([]);
  const [serviceChecked, setServiceChecked] = useState(false);

  // const [defaultPromoCode, setDefaultPromoCode] = useState('');
  const setsubscriptiondata = async () => {
    setIsLoading(true);
    const { data } = await get(API.Subscription);
    let arr = [
      { type: 'month', plan: [] },
      { type: 'year', plan: [] }
    ];
    data.data.map((item) => {
      if (item.metadata.type == 'month') {
        arr[0].plan.push(item);
      } else {
        arr[1].plan.push(item);
      }
    });

    // Sorting Based on Price (Month)
    arr[0].plan.sort(function (a, b) {
      return a.prices.data[0].price - b.prices.data[0].price;
    });

    // Sorting Based on Price (Year)
    arr[1].plan.sort(function (a, b) {
      return a.prices.data[0].price - b.prices.data[0].price;
    });
    setSelectedPeriodPlan(arr[1]);
    setsubscriptionlist(arr);
    setPromoCode(data.coupons);
    setSupportPlan(data?.supportPlans);
    // data.coupons &&
    //   data.coupons?.length > 0 &&
    //   data.coupons?.map((el) => {
    //     el?.id === 'FIRST50' && setDefaultPromoCode(el?.id);
    //   });
    setIsLoading(false);
  };

  const ProceedPayment = async (selectedPlan) => {
    if (selectedPlan) {
      selectsubscriptionplan(selectedPlan);
      setShowPaymentModal(true);
    } else {
      toast.error('Please select subscription plan');
    }
  };

  useEffect(() => {
    setsubscriptiondata();
  }, []);

  const handleStripePaymentmodalclose = () => {
    setShowPaymentModal(false);
  };

  const handleStripemodalclose = () => {
    setShowModal(false);
  };

  const loginbutton = (data) => {
    if (data.status == 200) {
      setlogin(!login);
      toast.success(data.message);
    }
  };

  const formatNumber = (value) => {
    try {
      let format = numbro(value).format({
        thousandSeparated: true
        // negative: 'parenthesis',
        // mantissa: 0
      });
      return format;
    } catch {
      return '';
    }
  };

  useEffect(() => {
    let result = promoCode && promoCode.filter((item) => item.id === 'FIRST50');
    setPercentOff(result?.[0]?.['percent_off'] ? result?.[0]?.['percent_off'] : 0);
  }, [promoCode]);

  return (
    <div className="container-fluid subscription-plan">
      {isLoading ? (
        <div className="spinloader">
          <Spin />
        </div>
      ) : (
        <div className="container p-5">
          <div className="row custom-tabs">
            <h2
              style={{ textAlign: 'center', margin: '0 0 15px', fontWeight: '700', color: '#FFF' }}
            >
              The Best Trust and KYC Solution at the Best Price!
            </h2>
            <p
              style={{ textAlign: 'center', margin: '0 0 30px', fontWeight: '700', color: '#FFF' }}
            >
              {/* Sign up as beta client before the 31st of May 2022 and get 50% off your first year
              subscription!!! */}
            </p>
            {setsubscription &&
              setsubscription.length > 0 &&
              setsubscription.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <h6
                      className={`${el.type == 'month' ? 'monthly-tab' : 'yearly-tab'} ${
                        selectedPeriodPlan.type == el.type ? 'active' : ''
                      }`}
                      onClick={() => setSelectedPeriodPlan(el)}
                    >
                      {el.type == 'month' ? 'Monthly' : 'Yearly'}
                    </h6>
                  </React.Fragment>
                );
              })}
            <div
              className={selectedPeriodPlan.type == 'month' ? 'monthly active' : 'yearly active'}
            >
              {selectedPeriodPlan &&
                selectedPeriodPlan?.plan.length > 0 &&
                selectedPeriodPlan?.plan.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="col-lg-6 col-md-12 mb-4 price-tabs">
                        {moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
                        selectedPeriodPlan.type != 'month' ? (
                          <>
                            <p className="saleoffer">
                              EOFY 50% OFF *&nbsp;<small>limited time only</small>
                            </p>
                            <h2 className="h2 stricked-price">
                              <s> ${item.prices.data[0].price} </s>
                            </h2>
                            <h2 className="h2">
                              <sup>$</sup>
                              {(
                                (Number(item.prices.data[0].price) * Number(percentOff)) /
                                100
                              ).toFixed(2)}
                            </h2>
                            <p
                              style={{
                                fontSize: '14px',
                                fontWeight: '700',
                                textTransform: 'uppercase',
                                opacity: '0.7',
                                margin: 0
                              }}
                            >
                              Per {item.metadata.type == 'month' ? 'Month' : 'Annum'}
                            </p>
                          </>
                        ) : null}

                        <div className="card-body">
                          <div className="text-center">
                            {moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
                            selectedPeriodPlan.type == 'year' ? (
                              ''
                            ) : (
                              <>
                                <h5
                                  className="card-title"
                                  style={{
                                    margin: '5px',
                                    textTransform: 'uppercase',
                                    fontSize: '16px',
                                    fontWeight: '700'
                                  }}
                                >
                                  {item.metadata.plan_type}
                                </h5>
                                <h2 className="h2" style={{ margin: '0' }}>
                                  <sup>$</sup>
                                  <NumberFormat
                                    thousandsGroupStyle="thousand"
                                    value={item.prices.data[0].price}
                                    decimalSeparator="."
                                    displayType="text"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={true}
                                  />
                                  <span className="plan-gst">{`(${
                                    item.prices.data[0].tax_behavior === 'inclusive' ? 'Inc' : 'Ex'
                                  }. GST)`}</span>
                                </h2>
                                <p
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: '700',
                                    textTransform: 'uppercase',
                                    opacity: '0.7',
                                    margin: 0
                                  }}
                                >
                                  Per {item.metadata.type == 'month' ? 'Month' : 'Annum'}
                                </p>
                              </>
                            )}
                            {/* {!moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
                            selectedPeriodPlan.type != 'month' &&
                            item.metadata.default_coupon_title != null
                              ? promoCode &&
                                promoCode?.length > 0 &&
                                promoCode?.map((el, index) => {
                                  return (
                                    el?.id === 'FIRST50' && (
                                      <p className="coupon-title" key={index}>
                                        {el?.percent_off + '% OFF '}
                                      </p>
                                    )
                                  );
                                })
                              : ''} */}
                            {/* <p className='coupon-title'>
                                {item.metadata.default_coupon_title}
                              </p> */}
                            <p>
                              {item.metadata.plan_type == 'basic'
                                ? item?.description
                                : item?.description}
                              {/* Firms */}
                            </p>
                            <button
                              onClick={() => ProceedPayment(item)}
                              className={`btn ${
                                item.prices.data[0]['id'] == selectsubscription ? 'btn-disable' : ''
                              }`}
                            >
                              Get Started
                            </button>
                          </div>
                          <div className="text-center">
                            <ul className="list-group list-group-flush h-100">
                              <li className="list-group-item">
                                {formatNumber(item.metadata.customers)} Fee From Refunds p.a.
                              </li>
                              <li className="list-group-item">
                                KYC for up to {formatNumber(item.metadata.kyc_storage)} Clients
                              </li>
                              <li className="list-group-item">{item.metadata.users} Users</li>
                              {item.metadata['kanban_dashboard '] == '1' && (
                                <li className="list-group-item">Kanban Dashboard</li>
                              )}
                              {item.metadata.aba_bulk_payment == '1' && (
                                <li className="list-group-item">.ABA files for bulk payment</li>
                              )}
                              {item.metadata.bulk_refund_reconciliation == '1' && (
                                <li className="list-group-item">Bulk refund reconciliation</li>
                              )}
                              {item.metadata.api_integration == '1' && (
                                <li className="list-group-item">API Integration </li>
                              )}
                              {item.metadata.produce_authority_letter == '1' && (
                                <li className="list-group-item">Produce Authority letter </li>
                              )}
                              {item.metadata.produce_receipts == '1' && (
                                <li className="list-group-item">Produce Receipts</li>
                              )}
                              {item?.prices?.data &&
                                item?.prices?.data?.length > 0 &&
                                item?.prices?.data?.map((items, index) => {
                                  return (
                                    items?.active === true &&
                                    item.metadata.type !== 'month' &&
                                    items?.recurring?.trial_period_days &&
                                    items?.recurring?.trial_period_days !== null &&
                                    items?.recurring?.trial_period_days !== undefined &&
                                    items?.recurring?.trial_period_days !== '' && (
                                      <li className="list-group-item" key={index}>
                                        {items?.recurring?.trial_period_days + ' days free trial'}
                                      </li>
                                    )
                                  );
                                })}
                              {item.metadata['2fa'] == '1' && (
                                <li className="list-group-item">Two Factor Authentication</li>
                              )}
                              {item.metadata['bank_reconciliation'] == '1' && (
                                <li className="list-group-item">
                                  {item.metadata.plan_type == 'basic'
                                    ? '.CSV Import of Bank statement '
                                    : 'Automatic Bank Feeds '}
                                </li>
                              )}
                              {supportPlan && (
                                <li className="list-group-item">
                                  <div className="service-plan-card">
                                    <div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '5px'
                                        }}
                                      >
                                        White Glove Onboarding Service{' '}
                                        <span className="integration-card-info service-card-info">
                                          <DropdownButton className="info-icon">
                                            <Dropdown.Item>
                                              <div>
                                                <p
                                                  style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '15px',
                                                    color: 'white'
                                                  }}
                                                >
                                                  A. Setup of :
                                                </p>
                                                <ol>
                                                  <li>
                                                    Firm accounts incl Trust and Withdrawal accounts
                                                  </li>
                                                  <li>Receipt and Authority Letter</li>
                                                  <li>
                                                    Open Banking connection for direct bankfeeds
                                                  </li>
                                                  <li>Opening Balance match up</li>
                                                  <li>
                                                    Connection with Xero Practice Manager or Access
                                                    Practice Manager
                                                  </li>{' '}
                                                </ol>
                                                <br />
                                                <p
                                                  style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '15px',
                                                    color: 'white'
                                                  }}
                                                >
                                                  B. Training on :
                                                </p>
                                                <ol>
                                                  <li>Import client lists</li>
                                                  <li>Import EFT reconciliation report</li>
                                                  <li>Bulk and individual Refund Processing</li>
                                                  <li>.aba file management</li>
                                                  <li>Bank Reconciliation</li>
                                                </ol>
                                              </div>
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        </span>
                                      </div>
                                      <div>
                                        {moment().isBefore(moment('16-05-2024', 'DD-MM-YYYY')) ? (
                                          <>
                                            <span className="service-price strick-price">
                                              ${supportPlan?.prices?.data[0]?.price}
                                            </span>
                                            <span className="service-price">$0.00</span>
                                            <small> (till 15th May 2024)</small>
                                          </>
                                        ) : (
                                          <>
                                            <span className="service-price">
                                              ${supportPlan?.prices?.data[0]?.price}
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <input
                                      type="checkbox"
                                      name="service"
                                      onChange={(e) => setServiceChecked(e.target.checked)}
                                    />
                                  </div>
                                </li>
                              )}
                              {item.metadata.type == 'year' &&
                              moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
                              selectedPeriodPlan.type == 'year' ? (
                                <li className="list-group-item">
                                  <small className="condition">* First year only</small>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
            {/* <div className="notice">
              All the plans include “On Demand Trust compliance” that includes Bank Reconciliation,
              Refund Management, .Aba file ready for you to upload, all you have to do is upload
              reports in the given format!”
            </div> */}
            {showPaymentModal && (
              <Elements stripe={stripeTestPromise}>
                <StripePaymentModal
                  showModal={showPaymentModal}
                  handleModalClose={handleStripePaymentmodalclose}
                  id={Number(encodeURIComponent(decrypt(id)))}
                  planDetail={selectsubscription}
                  promoCode={promoCode}
                  defaultPromocode={''}
                  islogin={0}
                  supportPlan={serviceChecked && supportPlan}
                />
              </Elements>
            )}
            <StripeModal
              showModal={showModal}
              onLogin={loginbutton}
              handleModalClose={handleStripemodalclose}
              id={Number(encodeURIComponent(decrypt(id)))}
              planId={selectsubscription}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Subscription;
