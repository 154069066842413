import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, defaultPaginationLimit, get } from '../../config';

const useDuplicateCustomerList = (currentPage, search, id, recursion = true) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [customersList, setCustomersList] = useState([]);
  const [searchParam, setSearchParam] = useState(search || '');
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const query = new URLSearchParams(location.search);
  const [disableRecords, setDisabledRecords] = useState([]);

  // useEffect(() => {
  //   fetchCustomers(0, searchParam)
  // }, [ searchParam ])

  const fetchCustomers = useCallback(
    async (pageNo, newId, limit) => {
      try {
        setIsCustomerLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          `${API.GET_DUPLICATE_CUSTOMERLIST}?limit=${tempLimit}&page=${
            pageNo || pagingData.current
          }`
        );
        setIsCustomerLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        const customersList = data.data.data;
        let crn = [];
        for (let i = 0; i < customersList.length; i++) {
          if (crn.indexOf(customersList[i]?.crn) === -1) {
            crn.push(customersList[i]?.crn);
          }
        }
        let disabledKeys = [];
        for (let j = 0; j < crn.length; j++) {
          let data = customersList?.filter((item) => item.crn === crn[j] && item.is_used !== 1);
          if (data?.length > 1) {
            disabledKeys.push(data[0]?.id);
          }
        }
        setDisabledRecords(disabledKeys);
        setCustomersList(customersList);
        setPagingData({ total, current: current_page, pageSize: per_page, from: from, to: to });
        return data.data;
      } catch (e) {
        const { data } = e;
        setIsCustomerLoading(false);
        setPagingData({ current: 0 });
        setCustomersList([]);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    if (recursion) {
      fetchCustomers();
    }
  }, [fetchCustomers]);

  return {
    fetchCustomers,
    customersList,
    searchParam,
    isCustomerLoading,
    pagingData,
    setDisabledRecords,
    disableRecords,
    setIsCustomerLoading,
    setPagingData,
    onSearchChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/duplicate-customer?search=${value}&page=1`, {
        replace: true
      });
    },
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/duplicate-customer?search=${searchParam}&page=${page}&limit=${limit}`, {
        replace: true
      });
    },
    setSearchParam
  };
};

export default useDuplicateCustomerList;
