import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, defaultPaginationLimit, get } from '../../config';

const useClientList = (currentPage, search, id, recursion = true) => {
  const navigate = useNavigate();
  const [customersList, setCustomersList] = useState([]);
  const [searchParam, setSearchParam] = useState(search || '');
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });

  const fetchCustomers = useCallback(
    async (pageNo, newId) => {
      try {
        setIsCustomerLoading(true);
        const { data } = await get(
          `${API.GET_ALL_CLIENT}?search=${newId || id || ''}&page=${
            pageNo || pagingData.current
          }&limit=${defaultPaginationLimit}`
        );
        setIsCustomerLoading(false);
        const { total, current_page, per_page } = data.data;
        const customersList = data.data.data;
        setCustomersList(customersList);
        setPagingData({ total, current: current_page, pageSize: per_page });
        return data.data;
      } catch (e) {
        const { data } = e;
        setIsCustomerLoading(false);
        setPagingData({ current: 0 });
        setCustomersList([]);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    if (recursion) {
      fetchCustomers();
    }
  }, [fetchCustomers]);

  return {
    fetchCustomers,
    customersList,
    searchParam,
    isCustomerLoading,
    pagingData,
    setIsCustomerLoading,
    setPagingData,
    onSearchChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`${API.GET_ALL_CLIENT}?search=${value}&page=1`, {
        replace: true
      });
    },
    onPageChange: (page) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`${API.GET_ALL_CLIENT}?search=${searchParam}&page=${page}`, {
        replace: true
      });
    },
    setSearchParam
  };
};

export default useClientList;
