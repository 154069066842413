import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { toast } from 'react-toastify';
import { API, get } from '../../config.js';

const ClientRestore = ({ clientId, toggleRestoreModal, handleRestore }) => {
  const [restoreLoader, setRestoreLoader] = useState(false);
  const handleClientRestore = async () => {
    try {
      setRestoreLoader(true);
      const { data } = await get(`${API.UNDO_CLIENT}/${clientId}`);
      setRestoreLoader(false);
      toast.success(data.message);
      handleRestore();
      toggleRestoreModal();
    } catch (e) {
      setRestoreLoader(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  return (
    <div>
      <Modal
        className="remove-popup"
        width={500}
        closable
        centered
        open
        footer={null}
        onCancel={toggleRestoreModal}
      >
        <div className="remove-content spin-body-relative">
          <p>Are your sure you want to restore this client?</p>
          <div className="modal-action">
            <Button className="cancel" onClick={toggleRestoreModal}>
              Cancel
            </Button>
            <Button onClick={handleClientRestore}>OK</Button>
          </div>
          {restoreLoader && (
            <div className="top-modal-spin-center">
              <Spin />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ClientRestore;
