import React, { useEffect, useCallback, useState } from 'react';
import { FullNameGetter, maskPostalCode, trimString } from '../../helper/fullNameGetter';
import CustomerActionButtons from './ActionButtons';
import { API, get, post } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { NegativeAmountConverter } from '../../helper/Conversions';
import { formatNumber } from '../../APIConfig/Interceptor';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
// import moment from 'moment';

export const verificationMethods = [
  { title: 'Visual + Visual', value: 1 },
  { title: 'Visual + Source ATO', value: 2 },
  { title: 'Visual + Source DVS', value: 3 },
  { title: 'Source ATO + Source DVS', value: 4 }
];

export const mainTableColumns = (handleDelete) => {
  return [
    // {
    //   title: 'Client ID',
    //   sorter: (a, b) => a.id - b.id,
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: '8%'
    // },
    {
      title: 'Name',
      sorter: (a, b) =>
        (a.first_name ? a.first_name + a.last_name : a.last_name).localeCompare(
          b.first_name ? b.first_name + b.last_name : b.last_name
        ),
      key: 'name',
      render: (record) => {
        return record?.bussiness_name
          ? record?.bussiness_name?.charAt(0) + record?.bussiness_name
          : trimString(FullNameGetter(record), 32);
      },
      width: '22%',
      className: 'fullname'
    },
    {
      title: 'Email Address',
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (record) => <span>{record.email}</span>,
      width: '22%'
    },
    {
      title: 'CRN',
      key: 'crn',
      sorter: (a, b) => (a?.crn || '').localeCompare(b?.crn || ''),
      render: (record) => <span>{record.crn ? record.crn : ''}</span>,
      width: '11%'
    },
    {
      title: 'Mobile Number',
      sorter: (a, b) => a.mobile - b.mobile,
      render: (record) => <span>{record.mobile}</span>,
      width: '12%'
    },
    {
      title: 'BSB',
      key: 'bsb',
      render: (record) => (
        <span>
          {/* {record.customer_bank?.bsb ? 'xxx' + record.customer_bank?.bsb?.toString().slice(-4) : ''} */}
          {record.customer_bank?.bsb ? record.customer_bank?.bsb?.toString() : ''}
        </span>
      ),
      width: '8%'
    },
    {
      title: 'Account Number',
      sorter: (a, b) => a.customer_bank.account_number - b.customer_bank.account_number,
      render: (record) => (
        <span>
          {record.customer_bank?.account_number
            ? // ? 'xxxx xxxx ' + record.customer_bank?.account_number?.toString().slice(-4) : ''}
              record.customer_bank?.account_number?.toString()
            : ''}
        </span>
      ),
      key: 'account_number',
      width: '13%'
    },
    {
      title: 'Closing Balance',
      sorter: (a, b) => a.closing_balance - b.closing_balance,
      render: (record) => (
        <>{record?.closing_balance ? NegativeAmountConverter(record?.closing_balance) : '$0.00'}</>
      ),
      key: 'closing_balance',
      className: 'centeralign',
      width: '13%'
    },
    {
      title: 'Action',
      key: 'id',
      width: '9%',
      render: (id) => (
        <CustomerActionButtons
          data={{ id: id }}
          handleDelete={handleDelete}
          is_delete={id?.is_used === 1}
        />
      )
    }
  ];
};

export const duplicateTableColumns = (handleDelete, disableRecords) => {
  return [
    // {
    //   title: 'Client ID',
    //   sorter: (a, b) => a.id - b.id,
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: '8%'
    // },
    {
      title: 'Name',
      // sorter: (a, b) =>
      //   (a.first_name ? a.first_name + a.last_name : a.last_name).localeCompare(
      //     b.first_name ? b.first_name + b.last_name : b.last_name
      //   ),
      key: 'name',
      render: (record) => {
        return trimString(FullNameGetter(record), 32);
      },
      width: '22%',
      className: 'fullname'
    },
    {
      title: 'Email Address',
      // sorter: (a, b) => a.email.localeCompare(b.email),
      render: (record) => <span>{record.email}</span>,
      width: '22%'
    },
    {
      title: 'CRN',
      key: 'crn',
      // sorter: (a, b) => (a?.crn || '').localeCompare(b?.crn || ''),
      render: (record) => <span>{record.crn ? record.crn : '-'}</span>,
      width: '11%'
    },
    {
      title: 'Mobile Number',
      // sorter: (a, b) => a.mobile - b.mobile,
      render: (record) => <span>{record.mobile ? record.mobile : '-'}</span>,
      width: '12%'
    },
    {
      title: 'BSB',
      key: 'bsb',
      render: (record) => (
        <span>
          {/* {record.customer_bank?.bsb ? 'xxx' + record.customer_bank?.bsb?.toString().slice(-4) : '-'} */}
          {record.customer_bank?.bsb ? record.customer_bank?.bsb?.toString() : '-'}
        </span>
      ),
      width: '8%'
    },
    {
      title: 'Account Number',
      // sorter: (a, b) => a.customer_bank.account_number - b.customer_bank.account_number,
      render: (record) => (
        <span>
          {/* {record.customer_bank?.account_number ? 'xxxx xxxx ' + record.customer_bank?.account_number?.toString().slice(-4) : '-'} */}
          {record.customer_bank?.account_number
            ? record.customer_bank?.account_number?.toString()
            : '-'}
        </span>
      ),
      key: 'account_number',
      width: '13%'
    },
    {
      title: 'Action',
      key: 'id',
      width: '9%',
      render: (id) => (
        <CustomerActionButtons
          data={{ id: id }}
          handleDelete={handleDelete}
          is_delete={disableRecords?.includes(id?.id) || id?.is_used === 1}
          duplicateDelete={true}
        />
      )
    }
  ];
};

// Due to conflict, Please check with Neel & Brijesh.
/*export const expandedRowData = [
    {
      title: 'Client ID',
      sorter: (a, b) => a.id - b.id,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      key: 'name',
      render: (record) => FullNameGetter(record),
    },
    {
      title: 'BSB',
      key: 'bsb',
      render: (record) => <span>{record.customer_bank?.bsb}</span>,
    },
    {
      title: 'Account Number',
      sorter: (a, b) =>
        a.customer_bank.account_number - b.customer_bank.account_number,
      render: (record) => <span>{record.customer_bank?.account_number}</span>,
      key: 'account_number',
    },
    {
      title: 'Action',
      key: 'id',
      render: (id) => (
        <CustomerActionButtons data={{ id: id }} handleDelete={handleDelete} />
      ),
    },
  ];
};*/

export const expandedRowData = [
  {
    key: '1',
    year: '2022',
    bankaccount: 12345678,
    office: 'New York No. 1 Lake Park',
    refundamount: '100',
    fees: '70',
    remittanceamount: '30',
    updated_at: '2021-12-29'
  }
];

export const getCustomerDetail = async (customerId) => {
  try {
    const { data } = await get(`${API.CUSTOMER_DETAILS}/` + customerId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const getAuthorityDetail = async (id, screenView) => {
  try {
    let body = {};
    if (screenView === 'customer') {
      body = { customer_id: id };
    } else if (screenView === 'processRefund') {
      body = { reconciliation_id: id };
    }
    const { data } = await post(`${API.GET_AUTHORITY_LETTER}`, body);
    return data.data;
  } catch (e) {
    console.log('e :: ', e);
    throw e;
  }
};

export const expandedRowColumns = [
  { title: 'Year', dataIndex: 'year', key: 'year', width: '5%' },
  {
    title: 'Bank Account Number',
    dataIndex: 'bankaccount',
    key: 'bankaccount',
    // render: (record) => <span>{record ? 'xxxx xxxx ' + record?.toString().slice(-4) : ''}</span>
    render: (record) => <span>{record ? record?.toString() : ''}</span>
  },
  {
    title: 'Office',
    key: 'office',
    dataIndex: 'office',
    width: '14.75%',
    render: (office) => <span>{office}</span>
  },
  {
    title: 'Software Refund',
    dataIndex: 'refundamount',
    key: 'refundamount',
    render: (refundamount) => (refundamount ? <span>${formatNumber(refundamount)}</span> : 'N/A')
  },
  {
    title: 'ATO Refund',
    dataIndex: 'ato_refund',
    key: 'ato_refund',
    width: '11.15%',
    render: (ato_refund) => (ato_refund ? <span>${formatNumber(ato_refund)}</span> : 'N/A')
  },
  {
    title: 'Fees',
    dataIndex: 'invoice_amount',
    key: 'invoice_amount',
    width: '8%',
    render: (invoice_amount) =>
      invoice_amount ? <span>${formatNumber(invoice_amount)}</span> : 'N/A'
  },
  {
    title: 'Disbursement',
    dataIndex: 'disbursement',
    width: '10%',
    key: 'disbursement',
    render: (disbursement) => (disbursement ? <span>${formatNumber(disbursement)}</span> : 'N/A')
  },
  {
    title: 'Remittance',
    dataIndex: 'remittanceamount',
    key: 'remittanceamount',
    width: '12.8%',
    render: (remittanceamount) =>
      remittanceamount ? <span>${formatNumber(remittanceamount)}</span> : 'N/A'
  },
  {
    title: 'Comment',
    key: 'comment',
    className: 'centeralign',
    render: (record) => (
      <>
        {record?.comment && (
          <Tooltip placement="bottomLeft" title={record?.comment} className="action-column">
            <FontAwesomeIcon icon={faComment} size="1x" />
          </Tooltip>
        )}
        {record?.comment && record?.note && (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          </>
        )}
        {record?.note && (
          <Tooltip placement="bottomLeft" title={record?.note} className="action-column">
            <FontAwesomeIcon icon={faStickyNote} size="1x" />{' '}
          </Tooltip>
        )}
      </>
    )
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    //sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
    render: (updated_at) => updated_at?.split(' ')[0],
    width: '9.45%'
  },
  {
    title: 'Status',
    dataIndex: 'aba_status',
    key: 'aba_status',
    //sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
    render: (aba_status) => aba_status
  }
];

export const useRelatedTo = () => {
  const [relatedList, setRelatedList] = useState([]);
  const [relatedLoading, setRelatedLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRelatedLoading(true);
      const { data } = await get(API.GET_CUSTOMERLIST);
      setRelatedLoading(false);
      setRelatedList(data.data.data);
    } catch (e) {
      setRelatedList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    relatedList,
    relatedLoading
  };
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading
  };
};

export const useSuburbList = () => {
  const [suburbList, setSuburbList] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [suburbLoading, setSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState) {
        setSuburbLoading(true);
        const { data } = await get(`${API.GET_SUBURB_LIST}/${selectedState}`);
        setSuburbLoading(false);
        setSuburbList(data.data);
      }
    } catch (e) {
      setSuburbList([]);
    }
  }, [selectedState]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    handleStateSelect: (value) => {
      setSuburbList([]);
      setSelectedState(value);
    },
    suburbLoading,
    setSuburbList,
    selectedState
  };
};

export const useSuburbList1 = () => {
  const [suburbList1, setSuburbList1] = useState([]);
  const [selectedState1, setSelectedState1] = useState('');
  const [suburbLoading1, setSuburbLoading1] = useState(false);
  const init = useCallback(async () => {
    try {
      if (selectedState1) {
        setSuburbLoading1(true);
        const { data } = await get(`${API.GET_SUBURB_LIST}/${selectedState1}`);
        setSuburbLoading1(false);
        setSuburbList1(data.data);
      }
    } catch (e) {
      setSuburbList1([]);
    }
  }, [selectedState1]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList1,
    handleStateSelect1: (value) => {
      setSuburbList1([]);
      setSelectedState1(value);
    },
    suburbLoading1,
    setSuburbList1,
    selectedState1
  };
};

export const useStaffList = () => {
  const [staffList, setStaffList] = useState([]);
  const [staffListLoading, setStaffListLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStaffListLoading(true);
      const { data } = await get(`${API.USER_LIST_BY_ROLE}/staff`);
      setStaffListLoading(false);
      setStaffList(data.data);
    } catch (e) {
      setStaffList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    staffList,
    staffListLoading
  };
};
export const useManageList = () => {
  const [managerList, setManagerList] = useState([]);
  const [managerListLoading, setManagerListLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setManagerListLoading(true);
      const { data } = await get(`${API.USER_LIST_BY_ROLE}/manager`);
      setManagerListLoading(false);
      setManagerList(data.data);
    } catch (e) {
      setManagerList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    managerList,
    managerListLoading
  };
};
export const useOfficeList = () => {
  const [officeList, setOfficeList] = useState([]);
  const [officeListLoading, setOfficeListLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setOfficeListLoading(true);
      const { data } = await get(`${API.GET_OFFICE_LIST}?limit=-1`);
      setOfficeListLoading(false);
      setOfficeList(data.data.data);
    } catch (e) {
      setOfficeList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    officeList,
    officeListLoading
  };
};

export const processNestedTableData = (data) => {
  const refundData = data.map((item) => ({
    key: item?.id,
    year: item?.year,
    bankaccount: item?.tax_software?.account_number,
    office: item?.customer?.firm_customer?.staff
      ? item?.customer?.firm_customer?.staff?.user_wise_staff?.staff_wise_office?.office_suburb +
        ', ' +
        item?.customer?.firm_customer?.staff?.user_wise_staff?.staff_wise_office?.office_state
      : 'N/A',
    refundamount: item?.tax_software?.refund,
    ato_refund: item?.ato?.refund,
    disbursement: item?.disbursement,
    invoice_amount: item?.invoice_amount,
    remittanceamount: item?.remittance,
    customer_id: item?.customer_id,
    updated_at: item?.updated_at,
    comment: item?.comment ? item?.comment : '',
    note: item?.ato?.note ? item?.ato?.note : '',
    aba_status:
      item?.aba_transaction !== null && item?.aba_transaction?.aba_file_id !== null
        ? 'Processed'
        : 'Not Yet Processed'
  }));
  return refundData;
};

export const getDisplayPostalAddress = (customer) => {
  const { street, customer_suburb, customer_state, postal_code } = customer;
  if (!street && !customer_suburb && !customer_state && !postal_code) return 'N/A';
  let address = '';
  if (street) address = `${street}${postal_code || customer_state || customer_suburb ? ', ' : ''} `;
  if (customer_suburb) address += `${customer_suburb}${postal_code || customer_state ? ', ' : ''}`;
  if (customer_state) address += `${customer_state}${postal_code ? ', ' : ''}`;
  if (postal_code) address += `${maskPostalCode(postal_code)}`;
  return address;
};

export const getDisplayBillingAddressForLogs = (customer) => {
  const { billing_street, billing_suburb, billing_state, billing_postal_code } = customer;
  if (!billing_street && !billing_suburb?.name && !billing_state?.name && !billing_postal_code)
    return 'N/A';
  let address = '';
  if (billing_street)
    address = `${billing_street}${
      billing_postal_code || billing_state?.name || billing_suburb?.name ? ', ' : ''
    } `;
  if (billing_suburb?.name)
    address += `${billing_suburb?.name}${billing_postal_code || billing_state?.name ? ', ' : ''}`;
  if (billing_state?.name) address += `${billing_state?.name}${billing_postal_code ? ', ' : ''}`;
  if (billing_postal_code) address += `${maskPostalCode(billing_postal_code)}`;
  return address;
};

export const getDisplayShippingAddressForLogs = (customer) => {
  const { shipping_street, shipping_suburb, shipping_state, shipping_postal_code } = customer;
  if (!shipping_street && !shipping_suburb?.name && !shipping_state?.name && !shipping_postal_code)
    return 'N/A';
  let address = '';
  if (shipping_street)
    address = `${shipping_street}${
      shipping_postal_code || shipping_state?.name || shipping_suburb?.name ? ', ' : ''
    } `;
  if (shipping_suburb?.name)
    address += `${shipping_suburb?.name}${
      shipping_postal_code || shipping_state?.name ? ', ' : ''
    }`;
  if (shipping_state?.name) address += `${shipping_state?.name}${shipping_postal_code ? ', ' : ''}`;
  if (shipping_postal_code) address += `${maskPostalCode(shipping_postal_code)}`;
  return address;
};

export const getDisplayShippingAddress = (customer) => {
  const { shipping_street, shipping_suburb, shipping_state, shipping_postal_code } = customer;
  if (!shipping_street && !shipping_suburb && !shipping_state && !shipping_postal_code)
    return 'N/A';
  let address = '';
  if (shipping_street)
    address = `${shipping_street}${
      shipping_postal_code || shipping_state || shipping_suburb ? ', ' : ''
    } `;
  if (shipping_suburb)
    address += `${shipping_suburb}${shipping_postal_code || shipping_state ? ', ' : ''}`;
  if (shipping_state) address += `${shipping_state}${shipping_postal_code ? ', ' : ''}`;
  if (shipping_postal_code) address += `${maskPostalCode(shipping_postal_code)}`;
  return address;
};

export const statementTableColumns = [
  {
    title: 'Date',
    sorter: (a, b) => a.date.localeCompare(b.date),
    // sorter: (a, b) => moment(a.date, 'DD-MM-YYYY') - moment(b.date, 'DD-MM-YYYY'),
    dataIndex: 'date',
    render:(date) => date ? date?.split(' ')?.[0]?.split("-")?.reverse()?.join("-") : '-',
    key: 'date',
    width: '10%'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    sorter: (a, b) => a.description.localeCompare(b.description),
    key: 'description'
  },
  {
    title: 'Credit',
    render: (record) => (
      <span className="rag-green">
        {record.type === 1 ? `$${formatNumber(Number.parseFloat(record.amount))}` : ''}
      </span>
    ),
    width: '10%'
  },
  {
    title: 'Debit',
    render: (record) => (
      <span className="rag-red">
        {record.type !== 1 ? `$${formatNumber(Number.parseFloat(record.amount))}` : ''}
      </span>
    ),
    width: '10%'
  },
  {
    title: 'Action',
    render: (record) => (
      <span role="img">
        {record.reconciled === 1 ? <CheckCircleOutlined style={{ color: '#00b300' }} /> : ''}
      </span>
    ),
    width: '10%'
  }
];

export const getStatementData = async (id) => {
  try {
    const { data } = await get(`${API.VIEW_STATEMENT}/${id}`);
    return data?.data?.customerDetails;
  } catch (e) {
    return null;
  }
};

export const getSummaryTableHtml = (data) => {
  let tableHtml = `
  <table style="width: 100%; border: #333 1px solid; text-align: center; border-bottom: none;" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th style="font-family: Poppins; border-bottom: #333 1px solid; border-right: #333 1px solid; padding: 10px; text-align: left;">Date</th>
                    <th style="font-family: Poppins; border-bottom: #333 1px solid; border-right: #333 1px solid; padding: 10px; text-align: left;">Description</th>
                    <th style="font-family: Poppins; border-bottom: #333 1px solid; border-right: #333 1px solid; padding: 10px; text-align: right;">Credit</th>
                    <th style="font-family: Poppins; border-bottom: #333 1px solid; padding: 10px; text-align: right;">Debit</th>
                  </tr>
                </thead>
                <tbody>`;
  for (const ele of data) {
    tableHtml = `${tableHtml} <tr>
        <td style="margin: 0; border-bottom: #333 1px solid; border-right: #333 1px solid; padding: 5px 10px; font-family: Poppins; text-align: left;">${
          ele.date
        }</td>
        <td style="margin: 0; border-bottom: #333 1px solid; border-right: #333 1px solid; padding: 5px 10px; font-family: Poppins; text-align: left;">${
          ele.description
        }</td>
        <td style="margin: 0; border-bottom: #333 1px solid; border-right: #333 1px solid; padding: 5px 10px; font-family: Poppins; color: #0DA500; text-align: right;">${
          ele.type === 1 ? formatNumber(ele.amount) : ''
        }</td>
        <td style="margin: 0; border-bottom: #333 1px solid; padding: 5px 10px; font-family: Poppins; color: #F00; text-align: right;">${
          ele.type !== 1 ? formatNumber(ele.amount) : ''
        }</td>
        `;
  }
  tableHtml = `${tableHtml}</tbody></table>`;
  return tableHtml;
};
