import React from 'react';
import ReactDOM from 'react-dom';

// Import CSS
import './index.css';

// Import config file
import App from './App';
import reportWebVitals from './reportWebVitals';
import UnsecuredPage from './UnsecuredPage';

document.body.classList.add('hold-transition', 'login-page');

ReactDOM.render(
  <React.StrictMode>{window.self === window.top ? <App /> : <UnsecuredPage />}</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
