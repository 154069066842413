/* eslint-disable no-nested-ternary */
// import { Tooltip } from 'antd';
import React, { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { API, get } from '../../../config.js';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminUserActionButtons from './AdminUserActionButtons.js';
// import { faBan } from '@fortawesome/free-solid-svg-icons';

const roleType = localStorage.getItem('role_type');

export const getTableColumns = (handleDelete) => {
  return [
    {
      title: 'Name',
      render: (obj) =>
        obj?.first_name === obj?.last_name
          ? obj?.first_name?.charAt(0) + obj?.first_name
          : obj?.first_name
          ? obj?.first_name?.charAt(0) + obj?.first_name + ' ' + obj?.last_name
          : obj?.last_name
          ? obj?.last_name?.charAt(0) + obj?.last_name
          : '-',
      width: '16%',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '18%',
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: 'Contact Number',
      dataIndex: 'phone',
      width: '12%',
      sorter: (a, b) => a.phone - b.phone
    },
    {
      title: 'Role',
      dataIndex: 'roleName',
      width: '10%',
      sorter: (a, b) => a.roleName.localeCompare(b.roleName)
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '10%',
      render: (id, data) => (
        <AdminUserActionButtons
          adminUserDetails={data}
          data={{ id: id }}
          handleDelete={handleDelete}
        />
      )
    }
    // {
    //   title: '',
    //   width: '5%',
    //   className: 'centeralign',
    //   render: (record) => {
    //     return (
    //       <span role="img">
    //         {record.deleted_at !== null && record.deleted_at !== '' ? (
    //           <Tooltip title="Archived" color={'red'} key={'red'}>
    //             <FontAwesomeIcon icon={faBan} size="1x" />
    //           </Tooltip>
    //         ) : (
    //           ''
    //         )}
    //       </span>
    //     );
    //   }
    // }
  ];
};

export const getAdminUserDetail = async (adminUsersrId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.GET_ADMIN_USER_DETAIL}/${adminUsersrId}`);
      return data;
    } else {
      const { data } = await get(`${API.GET_ADMIN_USER_DETAIL}/${adminUsersrId}`);
      return data.data;
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
    return null;
  }
};

export const useOfficeList = () => {
  const [officeList, setOfficeList] = useState([]);
  const [officeDataLoading, setOfficeDataLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setOfficeDataLoading(true);
      const { data } = await get(`${API.GET_OFFICE_LIST}?limit=-1`);
      setOfficeDataLoading(false);
      setOfficeList(data.data.data);
    } catch (e) {
      setOfficeList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    officeList,
    officeDataLoading
  };
};

export const useRoleList = () => {
  const [roleList, setRoleList] = useState([]);
  const [roleDataLoading, setRoleDataLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRoleDataLoading(true);
      const { data } = await get(`${API.GET_ROLE_LIST}?role_type=${roleType}`);
      setRoleDataLoading(false);
      setRoleList(data.data.data);
    } catch (e) {
      setRoleList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    roleList,
    roleDataLoading
  };
};

export const phoneValidationRules = [
  {
    required: true,
    message: 'Please enter Phone number'
  },
  {
    pattern: new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/),
    message: 'Please enter only number'
  },
  {
    min: 9,
    message: 'Minimum 9 digits are required in Phone number'
  },
  {
    max: 16,
    message: 'Maximum 16 digits are allowed in Phone number'
  }
];
