import React from "react";
import { Button, Modal } from "antd";
import { toast } from "react-toastify";
import { API, deleteCall } from "../../config.js";

const UserRemove = ({ userId, toggleDeleteModal, handleDelete }) => {
  const handleUserDelete = async () => {
    try {
      const { data } = await deleteCall(`${API.DELETE_USER}/${userId}`);
      toast.success(data.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  return (
    <div>
      <Modal
        className="remove-popup"
        width={500}
        closable
        centered
        open
        footer={null}
        onCancel={toggleDeleteModal}
      >
        <div className="remove-content">
          <p>Are your sure you want to delete this user?</p>
          <div className="modal-action">
            <Button className="cancel" onClick={toggleDeleteModal}>
              Cancel
            </Button>
            <Button onClick={handleUserDelete}>OK</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserRemove;
