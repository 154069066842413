import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../config.js';

const preprocessDeleteTransactionList = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
      name: `${ele?.reconciliation?.customer?.first_name} ${ele?.reconciliation?.customer?.last_name}`
    };
  });
};

const useAuditDeleteTransactionList = (currentPage, search, selectedTab, setBalanceInfo, limit) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [searchParam, setSearchParam] = useState(search || '');
  const [auditDeleteTransaction, setAuditDeleteTransaction] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  // const [fromDate, setFromDate] = useState(fromDate || "");
  // const [toDate, setToDate] = useState(toDate || "");
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchAuditDeleteTransactionList = useCallback(
    async (limit, page) => {
      try {
        if (selectedTab === 'deleted-transactions') {
          setIsDataLoading(true);
          let tempLimit = defaultPaginationLimit;
          if (limit) {
            tempLimit = limit;
          } else {
            tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
          }
          const { data } = await get(
            `${API.DELETED_BANK_STATEMENT}?page=${page ? page : pagingData.current}&limit=${tempLimit}`
          );
          const { total, current_page, per_page, from, to } = data.data;
          const balanceDetails = {};
          balanceDetails['firm_bank'] = data.firm_bank;
          balanceDetails['firm_balance'] = data.firm_balance;
          balanceDetails['statement_balance'] = data.statement_balance;
          balanceDetails['date'] = data?.latest_statement?.date;
          setBalanceInfo(balanceDetails);
          const AuditDeletedTransactionsData = preprocessDeleteTransactionList(data.data.data);
          setIsDataLoading(false);
          setPagingData({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
          setAuditDeleteTransaction(AuditDeletedTransactionsData);
        }
      } catch (e) {
        setAuditDeleteTransaction([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current, searchParam, selectedTab]
  );

  useEffect(() => {
    fetchAuditDeleteTransactionList();
  }, [fetchAuditDeleteTransactionList]);

  return {
    auditDeleteTransaction,
    isDataLoading,
    fetchAuditDeleteTransactionList,
    setAuditDeleteTransaction,
    pagingData,
    onPageChange: (page) => {
      setPagingData({ ...pagingData, current: page });
      navigate(
        `/firm-bank-account?page=${page}&tab=audit-statement&childTab=deleted-transactions&limit=${limit}`,
        {
          replace: true
        }
      );
    },
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(
        `/firm-bank-account?page=1&tab=audit-statement&childTab=deleted-transactions&limit=${limit}`,
        {
          replace: true
        }
      );
    },
    searchParam
  };
};

export default useAuditDeleteTransactionList;
