import React, { useState } from 'react';
import { API, fileUpload } from '../../config.js';
import { Modal } from 'react-bootstrap';
import { Button, Input, InputNumber, Form } from 'antd';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

const StripeModal = (props) => {
  const { showModal, handleModalClose, id, planId, onLogin } = props;
  const [form] = Form.useForm();
  let date = new Date();
  const [loading, setLoading] = useState(false);

  const handleFinish = async (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append(['id'], id);
    formData.append(['price_id'], planId);
    Object.keys(values).forEach((key) => {
      if (key == 'expdate') {
        let dateData = values[key].split('/');
        formData.append(['exp_month'], 12);
        formData.append(['exp_year'], Number(dateData[1]));
      } else if (key != 'expdate') {
        formData.append([key], values[key]);
      }
    });

    await fileUpload(API.FIRM_SUBSCRIPTION, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        onLogin(response);
        handleModalClose();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error('Something went wrong');
        handleModalClose();
      });
  };
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleModalClose}
      dialogClassName="modal-50w medium-popup review-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>Payment</Modal.Header>
      <Modal.Body>
        <Form form={form} name="stripeform" layout="vertical" onFinish={handleFinish}>
          <div className="entry-card full-card">
            <div className="form-box">
              <Form.Item
                label="Card Number"
                name="number"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Card Number'
                  },
                  {
                    min: 15,
                    message: 'Please enter minimum 15 digit'
                  },
                  {
                    max: 16,
                    message: 'Please enter maximum  16 digit'
                  },
                  {
                    pattern: new RegExp('^[0-9]+$'),
                    message: 'Only number is allowed.'
                  }
                ]}
              >
                <Input placeholder="Card Number" />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item
                label="Card Holder name"
                name="name"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter card holder name'
                  }
                ]}
              >
                <Input placeholder="Please enter card holder name" />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item className="half-width" label="Expiry Date" name="expdate">
                <InputMask
                  mask="99/9999"
                  placeholder={`01/${date.getFullYear()}`}
                  className="ant-input"
                />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item
                label="CVV"
                name="cvv"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter CVV'
                  }
                ]}
              >
                <InputNumber placeholder="Please enter CVV" />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item
                label="city"
                name="city"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter City'
                  }
                ]}
              >
                <Input placeholder="Please enter City" />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item
                label="country"
                name="country"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Country'
                  }
                ]}
              >
                <Input placeholder="Please enter Country" />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item
                label="line1"
                name="line1"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Line1'
                  }
                ]}
              >
                <Input placeholder="Please enter Line1" />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item
                label="line2"
                name="line2"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Line2'
                  }
                ]}
              >
                <Input placeholder="Please enter Line2" />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item
                label="Postle Code"
                name="postal_code"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Postle Code'
                  }
                ]}
              >
                <Input placeholder="Please enter Postle Code" />
              </Form.Item>
            </div>
            <div className="form-box">
              <Form.Item
                label="State"
                name="state"
                className="half-width"
                rules={[
                  {
                    required: true,
                    message: 'Please enter State'
                  }
                ]}
              >
                <Input placeholder="Please enter State" />
              </Form.Item>
            </div>
          </div>
          <div className="action-box"></div>
          <Button htmlType="submit" loading={loading}>
            Confirm
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default StripeModal;
