/* eslint-disable no-nested-ternary */
import { Button, DatePicker, Form, Select, Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';

const RefreshTransactionModal = ({
  dateValue,
  setDateValue,
  accountFetchOptions,
  loading,
  showRefresh,
  toggleRefreshModal,
  accountId,
  handleAccountChange,
  handleRefresh
}) => {
  const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const { RangePicker } = DatePicker;
  const handleDateModalRange = (val) => {
    if (val) {
      if (val && val.length) {
        const startDate = val[0].format('DD-MM-YYYY');
        const endDate = val[1].format('DD-MM-YYYY');
        setDateValue({
          start_date: startDate,
          end_date: endDate
        });
      } else {
        setDateValue({
          start_date: '',
          end_date: ''
        });
      }
      //   navigate(`/firm-bank-account?page=1&tab=statement`, {
      //     replace: true
      //   });
    }
  };
  return (
    <>
      <Button variant="primary" onClick={toggleRefreshModal}>
        Fetch
      </Button>
      {/* Modal of the Refresh */}
      <Modal
        size="lg"
        show={showRefresh}
        onHide={toggleRefreshModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="business-section fetch-modal"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Fetch Bank Feeds
        </Modal.Header>
        <Modal.Body>
          {/* {loading && <Spin />} */}
          <div className='spin-body-relative'>
            <div className="modal-body">
              <div className="date-picker-refresh">
                <>
                  <div
                    style={{
                      minWidth: '318px'
                    }}
                  >
                    <p>Account</p>
                    <Select
                      style={{
                        minWidth: '100%'
                      }}
                      placeholder="Select Account"
                      value={
                        accountId
                          ? accountFetchOptions?.find((i) => i.value === accountId)
                          : accountFetchOptions
                          ? accountFetchOptions[0]
                          : ''
                      }
                      onChange={(item) => {
                        handleAccountChange(item);
                      }}
                      loading={loading}
                      className={'sorting-data-select'}
                      options={accountFetchOptions}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: 'grey'
                        }
                      })}
                    />
                  </div>
                  <Form>
                    <p>Select Date Range</p>
                    <RangePicker
                      defaultValue={[
                        dateValue.start_date ? moment(dateValue.start_date, dateFormat[0]) : '',
                        dateValue.end_date ? moment(dateValue.end_date, dateFormat[0]) : ''
                      ]}
                      style={{
                        borderRadius: '30px'
                      }}
                      onChange={handleDateModalRange}
                      disabledDate={disabledDate}
                      format={dateFormat}
                    />
                  </Form>
                </>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleRefresh();
                }}
              >
                Fetch
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleRefreshModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
          {loading && (
            <div className="modal-spin-center">
              <Spin />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RefreshTransactionModal;
