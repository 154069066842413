import React, { useEffect, useState } from 'react';
import BackButton from '../../components/Form/BackButton';
import SidebarNav from '../../components/Header/SidebarNav';
import moment from 'moment';
import { DatePicker, Select, Modal } from 'antd';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

const getDefaultDates = (from, to) => {
  if (from && to) {
    return { start_date: from, end_date: to };
  } else {
    const previousYear =
      new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
    const previousYearDate = `01-04-${previousYear}`;
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    const year = currentTime.getFullYear();
    const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
      Number(month),
      2
    )}-${year}`;
    return { start_date: previousYearDate, end_date: currentDate };
  }
};

const { RangePicker } = DatePicker;

// const dateFormat = 'DD-MM-YYYY';
const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const GeneralLedgerReport = () => {
  const defaultData = [
    // {
    //   subcontractor: "Bank Account(Project Trust Account)",
    //   balance: "$0.00",
    //   details: [
    //     {
    //       date: "25/03/2022",
    //       audit_no: "56",
    //       transaction_details:
    //         "Payment recieved into the trust acount from ABC Developer for  progressClaim #258",
    //       corresponding_account: "Trustee Pty Ltd",
    //       dr_cr: "$152,369.00",
    //       balance: "$152,369.00",
    //     },
    //     {
    //       date: "27/03/2022",
    //       audit_no: "56",
    //       transaction_details:
    //         "To take up the payments net of retentions to contractors from the trust account ",
    //       corresponding_account: "AD Electrician Pty Ltd, CA Carpenter Pty",
    //       dr_cr: "$79,505.10",
    //       balance: "$72,863.90",
    //     },
    //   ],
    // },

    {
      subcontractor: 'AD Electrician',
      balance: '$0.00',
      details: [
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        }
      ]
    },
    {
      subcontractor: 'AD Concreter Pty Ltd',
      balance: '$0.00',
      details: [
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        },

        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        },
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        }
      ]
    },
    {
      subcontractor: 'CA Carpenter Pty Ltd',
      balance: '$0.00',
      details: [
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        },
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        }
      ]
    },
    {
      subcontractor: 'NJ Plumbing Pty Ltd',
      balance: '$0.00',
      details: [
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        },
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        },
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        }
      ]
    },
    {
      subcontractor: 'Trustee Pty Ltd',
      balance: '$0.00',
      details: [
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        },
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        },
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        },
        {
          date: '27/03/2022',
          audit_no: '56',
          transaction_details:
            'To take up the payments net of retentions to contractors from the trust account ',
          corresponding_account: 'AD Electrician Pty Ltd, CA Carpenter Pty',
          dr_cr: '$79,505.10',
          balance: '$72,863.90'
        }
      ]
    }
  ];

  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const from = query.get('from');
  const to = query.get('to');
  const [dateFilter, setDateFilter] = useState(() => getDefaultDates(from, to));
  const [exportLoading, setExportLoading] = useState(false);
  //   const [isLoading, setIsLoading] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  //   const handleDateChange = (dates) => {
  //     if (dates && dates.length) {
  //       const startDate = dates[0].format('MM-YYYY');
  //       const endDate = dates[1].format('MM-YYYY');
  //       setDateFilter({
  //         start_date: startDate,
  //         end_date: endDate
  //       });
  //     } else {
  //       setDateFilter({
  //         start_date: '',
  //         end_date: ''
  //       });
  //     }
  //   };
  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilter({
        start_date: startDate,
        end_date: endDate
      });
    } else {
      setDateFilter({
        start_date: '',
        end_date: ''
      });
    }
  };
  const onFilterOk = () => {
    const { start_date, end_date } = dateFilter;
    navigate(`/general-ledger-report?page=1&from=${start_date}&to=${end_date}`, {
      replace: true
    });
    setDatePickerVisible(false);
  };
  const handleExportReport = async () => {
    // const { start_date, end_date } = dateFilter;
    setExportLoading(true);
    // try {
    //   const formData = new FormData();
    //   selectedCustomer?.forEach((customer) => {
    //     if (customer) {
    //       formData.append('customer_ids[]', customer);
    //     }
    //   });
    //   const { data } = await fileUpload(
    //     `${API.EXPORT_CUSTOMER_TRANSACTION_REPORT}?from=${start_date}&to=${end_date}`,
    //     formData,
    //     {}
    //   );
    //   const { export_statement_path } = data.data;
    //   if (export_statement_path) {
    //     const response = await axios.get(`${imageDefaultPrefixPath}${export_statement_path}`, {
    //       responseType: 'blob'
    //     });
    //     const fileData = response.data;
    //     const url = window.URL.createObjectURL(new Blob([fileData]));
    //     var a = document.createElement('a');
    //     a.href = url;
    //     var file = export_statement_path.split('/');
    //     a.setAttribute('download', file[file.length - 1] || 'client_transaction_report.pdf');
    //     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //     a.click();
    //     a.remove();
    //     setExportLoading(false);
    //   }
    // } catch (error) {
    //   console.log('error :>> ', error);
    //   const { data } = error.response;
    //   if (data) {
    //     toast.error(data?.message);
    //     toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    //   } else {
    //     toast.error('Something went wrong');
    //   }
    //   setExportLoading(false);
    // }
  };
  //   const [finalData, setFinalData] = useState(defaultData);
  // const navigate = useNavigate();
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const [total, setTotal] = useState();
  // const [from, setFrom] = useState();
  // const [to, setTo] = useState();

  // const [loading, setLoading] = useState(false);
  // const [projectData, setProjectData] = useState({});
  // const [reportData, setReportData] = useState([]);
  //   const [valueRange, setValueRange] = useState();
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const [total, setTotal] = useState();
  // const [from, setFrom] = useState();
  // const [to, setTo] = useState();
  // const fetchGeneralLedgerReportData = useCallback(async () => {
  //   if (projectId) {
  //     try {
  //       // setLoading(true);
  //       const { data } = await get(
  //         `${API.TRANSACTION_REPORT}?builder_project_id=${
  //           projectId ? projectId : ""
  //         }&contact_id=${
  //           contractorDetails?.id ? contractorDetails?.id : ""
  //         }&trust_type=${trust_type}&start_month=${
  //           moment(valueRange[0]).format("MM-YYYY")
  //             ? moment(valueRange[0]).format("MM-YYYY")
  //             : ""
  //         }&end_month=${
  //           moment(valueRange[1]).format("MM-YYYY")
  //             ? moment(valueRange[1]).format("MM-YYYY")
  //             : ""
  //         }&export=0&limit=${limit ? limit : 10}&page=${page ? page : 1}`
  //       );
  //       // setLoading(false);
  //       // setLimit(data?.data?.reports?.per_page);
  //       // setPage(data?.data?.reports?.current_page);
  //       // setTotal(data?.data?.reports?.total);
  //       // setFrom(data?.data?.reports?.from);
  //       // setTo(data?.data?.reports?.to);
  //       // const projectData = data?.data?.project ? data?.data?.project : {};
  //       // const reportData = data?.data?.reports?.data
  //       //   ? Object.values(data?.data?.reports?.data)
  //       //   : [];

  //       // // setAllReportData(data?.data ? data?.data : "");
  //       // setProjectData(projectData);
  //       // setReportData(reportData);
  //     } catch (error) {
  //       // setLoading(false);
  //       // // setAllReportData({});
  //       // setProjectData();
  //       // setReportData([]);
  //       // if (error?.response?.data?.errors) {
  //       //   Object.keys(error?.response?.data?.errors).forEach((key) => {
  //       //     toast.error(error?.response?.data?.errors[key][0]);
  //       //   });
  //       // } else {
  //       //   toast.error(error?.message);
  //       // }
  //     }
  //   }
  // }, [projectId, trust_type, valueRange, contractorDetails, limit, page]);

  // useEffect(() => {
  //   fetchGeneralLedgerReportData();
  // }, [fetchGeneralLedgerReportData]);
  useEffect(() => {
    if (from && to) {
      setDateFilter({
        start_date: from,
        end_date: to
      });
    }
  }, [from, to]);
  console.log(dateFilter);
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            General Ledger Report
          </h1>

          <div className="content-details ">
            {/* <Spin spinning={isLoading} tip="Loading..."> */}
            <div className="table-top-btn">
              <div className="search-section">
                <Select
                  // showSearch
                  labelInValue={false}
                  mode="multiple"
                  style={{ minWidth: '200px' }}
                  filterOption={false}
                  //   value={selectedCustomer}
                  //   notFoundContent={fetching ? <Spin size="small" /> : null}
                  //   onChange={handleCustomerSelect}
                  //   onSearch={(e) => debounce(handleCustomerSearch(e), 300)}
                  placeholder="Please select client(s)"
                >
                  {/* {customerList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.first_name + ' ' + item.last_name}
                    </Option>
                  ))} */}
                </Select>
              </div>
              <div className="table-btn">
                <Button onClick={() => handleExportReport()} type="print">
                  {exportLoading ? 'Loading…' : 'Export'}
                </Button>
              </div>
            </div>
            <div className="summary-filter" onClick={() => setDatePickerVisible(true)}>
              {dateFilter.start_date &&
                'start:' + dateFilter.start_date + ' - End: ' + dateFilter.end_date}
              <FontAwesomeIcon icon={faCalendarAlt} size="1x" />
            </div>
            <div className="ant-table-wrapper">
              <div
                className="table-details "
                style={{
                  textAlign: 'center',
                  fontSize: '17px'
                }}
              >
                <div className="general-ledger-table-top">
                  <div className="row">
                    <div
                      className="col-md-12 center-align dark-row border"
                      style={{
                        background: '#06488b',
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '30px'
                      }}
                    >
                      Trust Account Ledger for Firm Trust Account
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      marginBlock: '4px',
                      display: 'flex',
                      color: 'white'
                    }}
                  >
                    <div
                      className="col-md-1 center-align "
                      style={{
                        background: '#06488b'
                        //   marginRight: '2px'
                      }}
                    >
                      Date{' '}
                    </div>
                    <div
                      className="col-md-1 center-align dark-row border"
                      style={{
                        background: '#06488b'
                        //   marginRight: '2px'
                      }}
                    >
                      Audit no.
                    </div>
                    <div
                      className="col-md-5 center-align dark-row border"
                      style={{
                        background: '#06488b'
                        //   marginRight: '2px'
                      }}
                    >
                      Transaction Details
                    </div>
                    <div
                      className="col-md-3 center-align dark-row border"
                      style={{
                        background: '#06488b'
                        //   marginRight: '2px'
                      }}
                    >
                      Corresponding Account{' '}
                    </div>
                    <div
                      className="col-md-1 center-align dark-row border"
                      style={{
                        background: '#06488b'
                        //   marginRight: '2px'
                      }}
                    >
                      Dr(Cr)
                    </div>
                    <div
                      className="col-md-1 center-align dark-row border"
                      style={{
                        background: '#06488b'
                        // marginRight: "2px ",
                      }}
                    >
                      Balance
                    </div>
                  </div>
                </div>

                {defaultData.map((data, i) => {
                  return (
                    <>
                      <div
                        className={i === 0 ? `general-ledger-report-top` : `general-ledger-report`}
                      >
                        <div
                          className="row"
                          style={{
                            marginBlock: '4px',
                            display: 'flex',
                            color: 'black',
                            fontSize: '19px'
                          }}
                        >
                          <div
                            className="col-md-1 center-align "
                            style={{
                              // marginRight: '2px',
                              border: `1px solid #dee2e6!important`
                            }}
                          ></div>
                          <div
                            className="col-md-1 center-align "
                            style={
                              {
                                // marginRight: '2px'
                              }
                            }
                          ></div>
                          <div
                            className="col-md-5 center-align "
                            style={{
                              // marginRight: '2px',
                              textAlign: 'left'
                            }}
                          >
                            {data?.subcontractor}
                          </div>
                          <div
                            className="col-md-3 center-align "
                            style={{
                              // marginRight: '2px',
                              textAlign: 'left'
                            }}
                          ></div>
                          <div
                            className="col-md-1 center-align "
                            style={{
                              // marginRight: '2px',
                              textAlign: 'left'
                            }}
                          ></div>
                          <div
                            className="col-md-1 center-align "
                            style={{
                              // marginRight: '2px',
                              textAlign: 'left'
                            }}
                          >
                            {data?.balance}
                          </div>
                        </div>
                        {data?.details?.map((item) => {
                          return (
                            <>
                              <div
                                className="row"
                                style={{
                                  marginBlock: '4px',
                                  display: 'flex',
                                  color: 'black'
                                }}
                              >
                                <div
                                  className="col-md-1 center-align "
                                  style={{
                                    //   marginRight: '2px',
                                    border: `1px solid #dee2e6!important`
                                  }}
                                >
                                  {item?.date}
                                </div>
                                <div
                                  className="col-md-1 center-align "
                                  style={
                                    {
                                      //   marginRight: '2px'
                                    }
                                  }
                                >
                                  {item?.audit_no}
                                </div>
                                <div
                                  className="col-md-5 center-align "
                                  style={{
                                    //   marginRight: '2px',
                                    textAlign: 'left'
                                  }}
                                >
                                  {item?.transaction_details}
                                </div>
                                <div
                                  className="col-md-3 center-align "
                                  style={{
                                    //   marginRight: '2px',
                                    textAlign: 'left'
                                  }}
                                >
                                  {item?.corresponding_account}
                                </div>
                                <div
                                  className="col-md-1 center-align "
                                  style={{
                                    //   marginRight: '2px',
                                    textAlign: 'left'
                                  }}
                                >
                                  {item?.dr_cr}
                                </div>
                                <div
                                  className="col-md-1 center-align "
                                  style={{
                                    //   marginRight: '2px',
                                    textAlign: 'left'
                                  }}
                                >
                                  {item?.balance}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* </Spin> */}
          </div>
        </div>
      </div>
      {datePickerVisible && (
        <Modal
          width={500}
          className="popup-without-header"
          closable
          centered
          open
          onOk={onFilterOk}
          onCancel={() => setDatePickerVisible(false)}
        >
          <h4>Select Start and End Month</h4>
          <RangePicker
            defaultValue={[
              dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
              dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
            ]}
            disabledDate={disabledDate}
            // picker="month"
            format={dateFormat}
            onChange={handleDateChange}
          />
        </Modal>
      )}
    </div>
  );
};

export default GeneralLedgerReport;
