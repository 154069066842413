import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faUndo, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import UserRemove from './UserRemove';
import UserDetailPopup from './UserDetailPopup';
import UserUndo from './UserUndo';
import UserResendLink from './UserResendLink';

const UserActionButtons = ({ userDetails, data, handleDelete }) => {
  const [showView, setShowView] = useState(false);
  const existingUserEmail = localStorage.getItem('myna-auth-email');
  const [showDelete, setShowDelete] = useState(false);
  const [showUndo, setShowUndo] = useState(false);
  const [showResendLink, setShowResendLink] = useState(false);
  const toggleShowModal = () => {
    setShowView(!showView);
  };
  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  const toggleUndoModal = () => {
    setShowUndo(!showUndo);
  };
  const toggleResendLinkModal = () => {
    setShowResendLink(!showResendLink);
  };
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <FontAwesomeIcon title="View User Details" onClick={toggleShowModal} icon={faEye} size="1x" />
      {parseInt(userDetails?.role_id) !== 2 && userDetails?.deleted_at === null && (
        <>
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <Link to={{ pathname: '/user/edit', search: `?id=${data.id}` }}>
            <FontAwesomeIcon title="Edit User" icon={faEdit} size="1x" />
          </Link>
        </>
      )}

      {parseInt(userDetails?.role_id) !== 2 &&
        userDetails.email !== existingUserEmail &&
        (userDetails?.deleted_at !== null ? (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              title="Restore User"
              icon={faUndo}
              size="1x"
              onClick={toggleUndoModal}
            />
          </>
        ) : (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              title="Archive User"
              onClick={toggleDeleteModal}
              icon={faTrash}
              size="1x"
            />
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              onClick={toggleResendLinkModal}
              title="Resend Password Link"
              icon={faShareSquare}
              size="1x"
            />
          </>
        ))}

      {showDelete && (
        <UserRemove
          toggleDeleteModal={toggleDeleteModal}
          userId={data.id}
          handleDelete={handleDelete}
        />
      )}
      {showUndo && (
        <UserUndo toggleUndoModal={toggleUndoModal} userId={data.id} handleDelete={handleDelete} />
      )}
      {showResendLink && (
        <UserResendLink
          toggleResendLinkModal={toggleResendLinkModal}
          userId={data.id}
          handleDelete={handleDelete}
        />
      )}
      {showView && <UserDetailPopup userId={data.id} toggleShowModal={toggleShowModal} />}
    </span>
  );
};

export default UserActionButtons;
