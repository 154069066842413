import React, { useEffect } from 'react';

const XeroSignup = () => {
  useEffect(() => {
    window.open(
      'https://login.xero.com/identity/connect/authorize?client_id=5E52BCEEA33849AB92BBB3230F989DBA&redirect_uri=https://app.trusteasy.com.au/firm/signup&scope=openid profile email accounting.settings.read&response_type=code&state=0CrVlKp0l8LVIkw32vgip4SqcOKBbBjY',
      '_self'
    );
  });

  return <div />;
};

export default XeroSignup;
