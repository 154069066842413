import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { RemoveCookie, SetCookie } from '../APIConfig/Interceptor';
// import { RemoveCookie, SetCookie } from '../APIConfig/Interceptor';
// import { API, get } from '../config';

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
//   const logout = () => {
//     get(API.LOGOUT)
//       .then((response) => {
//         if (response.status === 200) {
//           localStorage.clear();
//           SetCookie('_subscription', '');
//           RemoveCookie('_subscription');
//           window.location.href = '/';
//         }
//       })
//       .catch(() => {
//         var error = {};
//         error['myna_error'] = 'There is some issue with request, please try after some time.';
//         this.setState({ errors: error });
//       });
//   };

  const idleTimeout = 1000 * 60 * idleTime;
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    setIdle(true);
    localStorage.clear();
    SetCookie('_subscription', '');
    RemoveCookie('_subscription');
    // logout();
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    crossTab: true,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 5000,
    syncTimers: 200
  });
  return {
    isIdle,
    setIdle,
    idleTimer
  };
};

export default useIdleTimeout;
