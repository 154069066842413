import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, defaultPaginationLimit, get } from '../../config.js';

const useATORefundList = (currentPage) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [atoRefundList, setATORefundList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [pagingData, setPagingData] = useState({ current: currentPage });
  const pageLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
  const fetchATORefundList = useCallback(
    async (pageNo, limit) => {
      try {
        setIsDataLoading(true);
        const { data } = await get(
          `${API.ATO_HISTORY}?page=${pageNo || pagingData.current}&limit=${limit ? limit : pageLimit}`
        );
        const { total, current_page, per_page, from, to } = data.data;
        setATORefundList(data.data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
        navigate(`/ato-refund?page=${current_page}&limit=${limit ? limit : pageLimit}`, {
          replace: true
        });
        setIsDataLoading(false);
      } catch (e) {
        setATORefundList([]);
        setIsDataLoading(false);
        setPagingData({ current: 1 });
        const errorMessage = e.response?.data?.errors?.myna_error[0];
        if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    },
    [pagingData.current]
  );

  useEffect(() => {
    fetchATORefundList();
  }, [fetchATORefundList]);

  return {
    atoRefundList,
    isDataLoading,
    fetchATORefundList,
    pagingData,
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/ato-refund?page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default useATORefundList;
