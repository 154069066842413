import { toast } from 'react-toastify';
import { API, deleteCall, get, post } from '../../../config';

export const fetchSalesForceData = async () => {
  try {
    const { data } = await get(
      `${API.GENERAL_SETTING_INTEGRATION}?domain=${window.location.origin}`
    );
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const fetchBasiqData = async () => {
  try {
    const { data } = await get(
      `${API.GENERAL_SETTING_INTEGRATION}?domain=${window.location.origin}`
    );
    return data?.data?.basiq_integration_data;
  } catch (e) {
    return null;
  }
};
export const fetchYodleeData = async () => {
  try {
    const { data } = await get(
      `${API.GENERAL_SETTING_INTEGRATION}?domain=${window.location.origin}`
    );
    return data?.data?.yodlee_integration_data;
  } catch (e) {
    return null;
  }
};

export const fetchAccountConnectionData = async () => {
  try {
    const { data } = await get(
      `${API.GENERAL_SETTING_INTEGRATION}?domain=${window.location.origin}`
    );
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const updateSalesforceAuthCode = async (code) => {
  try {
    const { data } = await get(
      `${API.SALESFORCE_AUTH_CALLBACK}?code=${code}&domain=${window.location.origin}`
    );
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const disconnectSalesforce = async () => {
  try {
    const { data } = await get(API.SALESFORCE_DISCONNECT);
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const fetchFirmInfoData = async () => {
  try {
    const { data } = await get(API.GET_FIRM_INFO);
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const getUserBasiq = async () => {
  try {
    const { data } = await get(`${API.BASIQ_GET_USER}`);
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const disconnectBasiq = async () => {
  try {
    const { data } = await get(`${API.BASIQ_DISCONNECT}`);
    return data;
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
    return data;
  }
};

export const createUserBasiq = async () => {
  try {
    const { data } = await post(`${API.BASIQ_CREATE_USER}`);
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const disconnectXero = async (toggleXeroDisconnectModal) => {
  try {
    const { data } = await deleteCall(API.XERO_DISCONNECT);
    toggleXeroDisconnectModal();
    return data;
  } catch (e) {
    return null;
  }
};

export const getYodleeAccessToken = async () => {
  try {
    const { data } = await post(`${API.YODLEE_GET_TOKEN}`);
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const disconnectProviderBank = async () => {
  try {
    const { data } = await get(`${API.PROVIDER_BANK_DISCONNECT}`);
    return data;
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
  }
};
