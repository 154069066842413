import React from 'react';
import { formatNumber } from '../APIConfig/Interceptor';

export const NegativeAmountConverter = (params) => {
  const number = Number(params);
  return (
    <>
      {number && Math.sign(number?.toFixed(2)) == -1
        ? '-' + '$' + formatNumber(number?.toFixed(2).toString().slice(1))
        : '$' + formatNumber(number)}
    </>
  );
};

export const formatNumberWithoutComma = (number) => {
  const formatedNumber = parseFloat(number) ? parseFloat(number).toFixed(2) : 0.0;

  return formatedNumber;
};

// export const formatNumber = (number) => {
//   const formatedNumber = number
//     ? number.toLocaleString('en-US', {
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2
//       })
//     : 0.0;

//   return formatedNumber;
// };
