import { Form, Input, Select, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, defaultPaginationLimit, fileUpload, get } from '../../../config';
import useBeneficiaryList from './useBeneficiaryList';

import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import AddEditBeneficiaries from './AddEditBeneficiaries';
import UserSelectWithNewUser from '../../../components/User/UserSelectWithNewUser';

const Beneficiaries = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [show, setShow] = useState(false);

  const query = new URLSearchParams(location.search);
  // const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const client_id = query.get('client_id') ? parseInt(query.get('client_id')) : null;
  const [clientId, setClientId] = useState(client_id ? client_id : '');

  const [newClientModal, setNewClientModal] = useState(false);

  const [pagingData, setPagingData] = useState({ current: 1 });
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );

  const onPageChange = (page) => {
    setPagingData({ ...pagingData, current: page });
    getBeneficiaryData(clientId, { ...pagingData, current: page });
  };

  const getBeneficiaryData = useCallback(
    (id, pagingData, limit) => {
      setIsDataLoading(true);
      let tempLimit = defaultPaginationLimit;
      if (limit) {
        tempLimit = limit;
      } else {
        tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
      }
      const url = id
        ? `${API.GET_BENEFICIARY_DATA}/${id}?page=${
            pagingData?.current ? pagingData?.current : 1
          }&limit=${tempLimit}`
        : `${API.GET_BENEFICIARY_DATA}?page=${
            pagingData?.current ? pagingData?.current : 1
          }&limit=${tempLimit}`;
      get(url)
        .then((response) => {
          setBeneficiaries(response.data.data.data);
          const { total, current_page, per_page, from, to } = response.data.data;
          setPagingData({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
          setIsDataLoading(false);
        })
        .catch((e) => {
          const errorMessage = e.response?.data?.errors?.myna_error[0];
          if (errorMessage) {
            toast.error(errorMessage);
          }
        });
    },
    [pagingData]
  );

  const handleAddNewClient = () => {
    setNewClientModal(!newClientModal);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const url = API.CREATE_CUSTOMER;
      const reqObj = values;
      Object.keys(reqObj).forEach((key) => {
        if (reqObj[key]) {
          formData.append([key], reqObj[key]);
        }
      });
      const { data } = await fileUpload(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      toast.success(data.message);
      if (data?.status === 200) {
        handleAddNewClient();
        onSelect(data?.data?.customer?.id);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const { BeneficiaryColumns } = useBeneficiaryList(getBeneficiaryData, clientId);

  useEffect(() => {
    if (!clientId) {
      getBeneficiaryData();
    } else {
      getBeneficiaryData(clientId);
    }
  }, []);

  const onSelect = (value) => {
    setClientId(value);
    getBeneficiaryData(value);
    navigate(`/settings?tab=beneficiaries&client_id=${value}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleNewBeneficiary = () => {
    clientId ? setShow(true) : toast.error('Please Select a Client.');
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/settings?tab=beneficiaries&page=1&limit=${value}`, {
      replace: true
    });
    getBeneficiaryData(null, pagingData, value);
  };

  return (
    <div className="cms-page beneficiaries-page">
      <div className="content-details">
        <div className="table-top-btn beneficiaries-table-top">
          <div className="search-section process-search">
            <div className="search-section process-search">
              {!loading && (
                <UserSelectWithNewUser
                  customerId={Number(clientId)}
                  formStyle={{ width: 252 }}
                  onCustomerSelectionChange={onSelect}
                  handleAddNewClient={handleAddNewClient}
                  onClear={() => {
                    setClientId();
                    navigate(`/settings?tab=beneficiaries`);
                    getBeneficiaryData();
                  }}
                />
              )}

              <span className="beneficiaries-or-text">or</span>
              <Link
                to={{ pathname: '/new-customers' }}
                className="action solid-btn beneficiaries-add-client"
              >
                Add Client
              </Link>
            </div>
          </div>
          <div className="table-btn">
            <Button
              variant="primary"
              onClick={() => {
                handleNewBeneficiary();
              }}
            >
              Add New
            </Button>
          </div>
        </div>
        <Table
          columns={BeneficiaryColumns}
          dataSource={beneficiaries}
          loading={isDataLoading}
          pagination={{
            hideOnSinglePage: true,
            defaultCurrent: 1,
            showQuickJumper: true,
            defaultPageSize: pagingData.pageSize,
            onChange: (page) => onPageChange(page),
            itemRender: PaginationItemRenderer,
            ...pagingData,
            pageSize: limit
          }}
          className="task-table"
        />
        {beneficiaries?.length > 0 && (
          <div className="mt-2">
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={limit}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          </div>
        )}
      </div>
      <Modal
        className="mid-modal supplier-modal"
        id="supplier_modal"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Add beneficiaries</Modal.Header>
        <Modal.Body>
          <AddEditBeneficiaries
            getBeneficiaryData={getBeneficiaryData}
            beneficiaries={beneficiaries}
            onClose={handleClose}
            clientId={clientId}
            setShow={setShow}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="small-modal"
        show={newClientModal}
        onHide={handleAddNewClient}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Add New Contact</Modal.Header>
        <Modal.Body>
          <div className="content-details">
            {loading && <Spin />}
            <Form
              form={form}
              name="officeForm"
              initialValues={{ first_name: '', last_name: '', tfn: '', email: '' }}
              layout="vertical"
              onFinish={handleSubmit}
            >
              <div className="input-boxes">
                <>
                  <Form.Item
                    className="full-width"
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        max: 32,
                        message: 'Must be less than 32 character'
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]*$/),
                        message: 'First name can contain letters & spaces.'
                      }
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Last Name Or Trading Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Last name'
                      },
                      {
                        max: 150,
                        message: 'Must be less than 150 character'
                      },
                      {
                        pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&()]*$/),
                        message:
                          `Last name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                      }
                    ]}
                  >
                    <Input /*onChange={handleLastName}*/ placeholder="Last Name" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="TFN"
                    name="tfn"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter TFN'
                      },
                      {
                        pattern: new RegExp(/^([0-9]+$)([ .-]?)/),
                        message: 'Please enter valid TFN'
                      },
                      {
                        min: 8,
                        message: 'Minimum 8 digits are required in TFN'
                      },
                      {
                        max: 9,
                        message: 'Maximum 9 digits are allowed in TFN'
                      },
                      {
                        validator: (_, value) => {
                          if (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) {
                            return Promise.reject(
                              'Please enter valid TFN Number. All digits of same number are not allowed.'
                            );
                          } else {
                            if (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) {
                              return Promise.reject(
                                'Please enter valid TFN Number. All digits of same number are not allowed.'
                              );
                            }
                            return Promise.resolve();
                          }
                        }
                      }
                    ]}
                  >
                    <Input placeholder="123412345" value="" />
                  </Form.Item>
                  <Form.Item
                    className="full-width"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Email'
                      },
                      {
                        type: 'email',
                        message: 'Please enter valid Email'
                      }
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </>
              </div>
              <div className="action-box">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
          </div>
          <Button
            variant="secondary"
            className="reset beneficiaries-cancel-btn"
            onClick={handleAddNewClient}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Beneficiaries;
