import React, { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { API, get } from '../../../config.js';
import RoleActionButtons from './RoleActionButtons';

const roleType = localStorage.getItem("role_type");

export const getTableColumns = (handleDelete) => {
  return [
    {
      title: 'Role Name',
      width: '16%',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Role Type',
      dataIndex: 'role_type',
      width: '18%',
      sorter: (a, b) => a.role_type.localeCompare(b.role_type),
    },
    {
      title: 'Status',
      dataIndex: 'id',
      width: '5%',
      className: 'centeralign',
      sorter: (a, b) => a.role_type.localeCompare(b.role_type),
      render: (id, data) => (
        <RoleActionButtons roleDetails={data} data={{ id: id }} handleDelete={handleDelete} statusButton={true} />
      )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '5%',
      render: (id, data) => (
        <RoleActionButtons roleDetails={data} data={{ id: id }} handleDelete={handleDelete} />
      )
    }
  ];
};

export const getRoleDetail = async (roleId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.GET_ROLE_DETAIL}/${roleId}`);
      return data;
    } else {
      const { data } = await get(`${API.GET_ROLE_DETAIL}/${roleId}`);
      return data.data;
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      toast.error(data?.message);
      toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
    return null;
  }
};

export const useRoleList = () => {
  const [roleList, setRoleList] = useState([]);
  const [roleDataLoading, setRoleDataLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRoleDataLoading(true);
      const { data } = await get(`${API.GET_ROLE_LIST}?role_type=${roleType}`);
      setRoleDataLoading(false);
      setRoleList(data.data.data);
    } catch (e) {
      setRoleList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    roleList,
    roleDataLoading
  };
};

export const roleTypeOption = [
  {
    label: "Admin",
    value: "Admin"
  },
  {
    label: "Accounting Firm",
    value: "Accounting Firm"
  }
]

export const roleStatus = [
  {
    label: "Active",
    value: 1
  },
  {
    label: "Deactive",
    value: 0
  }
]