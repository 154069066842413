/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState } from 'react';
import { Button, Input, InputNumber, Form, Select, DatePicker } from 'antd';
// import Form from "@component/Form";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, post } from '../../config.js';
// import useCustomerList from "../customer/useCustomerList.js";
import { getCustomerDetail } from '../customer/CustomersHelper.js';
import UserSelect from '../../components/User/UserSelect';
import moment from 'moment';

const minOffset = 0;
const maxOffset = 20;

const AddATORefund = (props) => {
  const { fetchATORefundList, onClose, customerId } = props;
  const [form] = Form.useForm();
  // const {
  //   customersList,
  //   // isCustomerLoading,
  //   // setIsCustomerLoading,
  //   // setPagingData,
  //   // pagingData,
  //   // setSearchParam,
  //   // searchParam,
  //   // fetchCustomers
  // } = useCustomerList(0, "", undefined, false);
  // const [ customers, setCustomers ] = useState(customersList);
  // const [ selectInput, setSelectInput ] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  // const [ isCustomerLoading, setIsCustomerLoading ] = useState(false);
  const [isLoadingCustomerDetail, setIsLoadingCustomerDetail] = useState(false);
  const [hasBankDetail, setHasBankDetail] = useState(false);
  const selectedCustomer = form.getFieldValue('customer_id');

  const thisYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const yearOptions = useMemo(() => {
    const options = [];
    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i;
      if (i < 5) {
        let yearB = year + 'B';
        options.push(
          <Select.Option key={yearB} value={yearB}>
            {yearB}
          </Select.Option>
        );
        let yearA = year + 'A';
        options.push(
          <Select.Option key={yearA} value={yearA}>
            {yearA}
          </Select.Option>
        ); 
      }
      options.push(
        <Select.Option key={year} value={year}>
          {year}
        </Select.Option>
      );
    }
    return options;
  }, [thisYear]);

  const handleFinish = async (values) => {
    try {
      if (!hasBankDetail) {
        toast.error('Selected customer does not have bank details');
        return;
      }
      setIsSaving(true);
      values.refund_date = moment(values.refund_date).format('DD-MM-YYYY')
      const { data } = await post(API.MANUAL_ATOS, values);
      setIsSaving(false);
      if (fetchATORefundList) fetchATORefundList();
      onClose();
      toast.success(data.message);
    } catch (e) {
      setIsSaving(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    if (customerId) onCustomerSelectionChange(customerId);
  }, []);

  // useEffect(() => {
  //   // setCustomers([ ...customersList ]);
  //   // const data = await fetchCustomers(pagingData.current + 1, searchParam);
  //   //
  //   // setCustomers([ ...customers, ...data.data ]);
  //   // const firstListRecord = customersList[ 0 ];
  //   // if (firstListRecord) {
  //   //   const id = firstListRecord?.id;
  //   //   const isRecordExist = customers.find((c) => c.id === id);
  //   //   if (!isRecordExist) {
  //   //     setCustomers([ ...customers, ...customersList ]);
  //   //     // return [ ...customers, ...customersList ]
  //   //   }
  //   // }
  //   // if (searchParam) {
  //   //   setCustomers([ ...customersList ]);
  //   // }

  //   const test = async () => {

  //     if (!isCustomerLoading) {
  //       const data = await fetchCustomers(pagingData.current, searchParam);
  //       // setPagingData({ current: data.current_page, total: data.total, pageSize: data.per_page });

  //       if (data)
  //         setCustomers([ ...customers, ...data.data ]);
  //     }
  //   }
  //   test()
  // }, []);

  // const fetchCustomerData = async (page, searchKey) => {
  //   // const data = await fetchCustomers(pagingData.current, searchParam);
  //   // // setPagingData({ current: data.current_page, total: data.total, pageSize: data.per_page });

  //   // if (data)
  //   //   setCustomers([ ...customers, ...data.data ]);

  //   const data = await fetchCustomers(page, searchKey);

  //   if (data && data?.data.length > 0) {
  //     // let pagingData = {}
  //     // if (flag == "INITIAL") {
  //     //   pagingData = { current: data.current_page, total: data.total, pageSize: data.per_page }
  //     // } else {
  //     //   pagingData = { ...pagingData, current: data.current_page }
  //     // }
  //     // setPagingData(pagingData);
  //     if (page == 1) {
  //       setCustomers(data.data);
  //     } else {
  //       setCustomers([ ...customers, ...data.data ]);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (customerId) {
  //     form.setFieldsValue({
  //       customer_id: customerId
  //     });
  //   }
  // }, [])

  // useEffect(() => {

  //   const response = customers.map((item) => (
  //     <Select.Option key={Number(item.id)} value={Number(item.id)} email={item.email}>
  //       {item.first_name} {item.last_name}
  //     </Select.Option>
  //   ));
  //   if (isCustomerLoading) {
  //     response.push(<Select.Option key="loading">Loading ...</Select.Option>);
  //   }

  //   setSelectInput([ ...response ])
  // }, [ customers, isCustomerLoading ])

  // const onScroll = async (event) => {
  //   var target = event.target;
  //   if (
  //     !isCustomerLoading &&
  //     target.scrollTop + target.offsetHeight === target.scrollHeight &&
  //     (!pagingData?.pageSize || (pagingData.pageSize * pagingData.current == customers.length))
  //   ) {
  //     if (pagingData.total > customers.length) {
  //       target.scrollTo(0, target.scrollHeight);
  //       await fetchCustomerData(pagingData.current + 1, searchParam)
  //     }
  //   }
  // };

  const onCustomerSelectionChange = async (value) => {
    form.setFieldsValue({ customer_id: value });
    setHasBankDetail(true);
    setIsLoadingCustomerDetail(true);
    const data = await getCustomerDetail(value);
    setIsLoadingCustomerDetail(false);
    const {
      customer_bank: { account_name, account_number, bsb }
    } = data;
    const hasDetail = !!account_name && !!account_number && !!bsb;
    setHasBankDetail(hasDetail);
  };

  // const getOptions = () => {

  //   const response = customers.map((item) => (
  //     <Select.Option key={Number(item.id)} value={Number(item.id)}>
  //       {item.first_name} {item.last_name}
  //     </Select.Option>
  //   ));
  //   if (isCustomerLoading) {
  //     response.push(<Select.Option key="loading">Loading...</Select.Option>);
  //   }

  //   return response;
  // };

  // const onSearch = async (val) => {
  //   // if (!isCustomerLoading) {
  //   // setIsCustomerLoading(true);
  //   // if (searchParam !== val)
  //   // setCustomers([])
  //   await fetchCustomerData(1, val)
  //   setSearchParam(val);
  //   // const data = await fetchCustomers(1, val);
  //   // setCustomers(data.data);
  //   // setIsCustomerLoading(false);
  //   // setPagingData({ ...pagingData, current: 1 });
  //   // }
  // };

  return (
    <div className="content-details">
      <Form
        form={form}
        name="addATORefundnForm"
        initialValues={{
          customer_id: customerId ? Number(customerId) : null,
          year: thisYear ? (currentMonth > 6 ? thisYear : thisYear - 1) : '',
          refund_date: moment()
        }}
        layout="vertical"
        onFinish={handleFinish}
      >
        <div className="entry-card full-card">
          <div className="form-box">
            <div className="half-width">
              <UserSelect
                onCustomerSelectionChange={onCustomerSelectionChange}
                isLoadingCustomerDetail={customerId ? customerId : isLoadingCustomerDetail}
                customerId={
                  customerId ? Number(customerId) : selectedCustomer ? selectedCustomer : ''
                }
                formProps={{
                  label: 'Name',
                  name: 'customer_id',
                  rules: [
                    {
                      required: true,
                      message: 'Please select client'
                    }
                  ],
                  style: {
                    width: '100%'
                  }
                }}
              />
              {/* <Form.Item
                label="Name"
                name="customer_id"
                rules={[
                  {
                    required: true,
                    message: "Please select client",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Select
                  showSearch
                  // value={customerId}
                  // optionFilterProp="children"
                  onSearch={onSearch}
                  loading={isCustomerLoading}
                  placeholder="Select Client Name"
                  onPopupScroll={onScroll}
                  onSelect={onCustomerSelectionChange}
                  disabled={isLoadingCustomerDetail}
                  filterOption={(input, option) => {

                    return option.props.children[ 0 ].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.props.children[ 2 ].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.props.email.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                >
                  {selectInput}
                </Select>
              </Form.Item> */}
              <Link to={{ pathname: '/new-customers' }} className="action">
                Add Client
              </Link>
              {!hasBankDetail && selectedCustomer && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="text-danger">Selected client does not have bank details</p>
                  <Link to={{ pathname: `/customer/edit`, search: `?id=${selectedCustomer}` }}>
                    Update bank details
                  </Link>
                </div>
              )}
            </div>
            <Form.Item
              label="Amount"
              name="refund"
              className="half-width"
              rules={[
                {
                  required: true,
                  message: 'Please enter Amount'
                }
              ]}
            >
              <InputNumber
                // min={1}
                placeholder="Amount"
                disabled={isLoadingCustomerDetail}
              />
            </Form.Item>
          </div>

          <div className="form-box">
            <Form.Item
              label="Lodgement Reference Number"
              name="lodgement_reference"
              className="half-width"
              rules={[
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\d]*$/),
                  message: 'Lodgement Reference Number can contain letters & numbers.'
                }
              ]}
            >
              <Input placeholder="Lodgement Reference Number" disabled={isLoadingCustomerDetail} />
            </Form.Item>
            <Form.Item
              label="Year"
              name="year"
              className="half-width"
              rules={[
                {
                  required: true,
                  message: 'Please select year'
                }
              ]}
            >
              <Select showSearch optionFilterProp="children" placeholder="Select Year">
                {yearOptions}
              </Select>
            </Form.Item>
          </div>
          <div className="form-box">
            <Form.Item
              label="Note"
              name="note"
              className="half-width"
              rules={[
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                  message:
                    'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                }
              ]}
            >
              <Input placeholder="Note" />
            </Form.Item>
            <Form.Item
              className="half-width"
              label="Refund Date"
              name="refund_date"
              // validateTrigger={['onBlur']}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please select Refund Date'
              //   }
              // ]}
            >
              <DatePicker
                style={{ border: '1px solid #d9d9d9', borderRadius: '30px', width: '100%' }}
                format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                placeholder="DD-MM-YYYY"
                disabledDate={disabledDate}
                allowClear={false}
                defaultValue={form.getFieldValue('refund_date') && moment()}
              />
            </Form.Item>
          </div>
        </div>
        <div className="action-box">
          <Button disabled={isSaving} type="primary" htmlType="submit">
            {isSaving ? 'Loading...' : 'Save'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddATORefund;
