import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { API, get } from "../../config.js";

const useFirmInfo = () => {
  const [firmInfo, setFirmInfo] = useState(null);
  const fetchFirmInfo = useCallback(async () => {
    try {
      const { data } = await get(API.GET_FIRM_INFO);
      const firmObj = data.data;
      setFirmInfo(firmObj);
    } catch (e) {
      setFirmInfo({});
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }, []);

  useEffect(() => {
    fetchFirmInfo();
  }, [fetchFirmInfo]);

  return {
    firmInfo,
  };
};

export default useFirmInfo;
