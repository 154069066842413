import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const UnsecuredPage = () => {
  return (
    <div className="insecure-content">
      <div className="icon">
        <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>
      </div>
      <h2>The information you&rsquo;re about to submit is not secure.</h2>
      <p>
        Because the site is using a connection that&rsquo;s not completely secure, your information
        will be seen to others.
      </p>
      <div className="btn-block">
        <a href="/dashboard" target="_blank" className="btn">
          Go to dashboard
        </a>
      </div>
    </div>
  );
};

export default UnsecuredPage;
