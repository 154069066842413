import React, { useEffect, useState } from 'react';
import '../../users/users.module.css';
import Form from '@component/Form';
import { API, fileUpload, get } from '../../../config';
import { toast } from 'react-toastify';
import { Button, Input, Select } from 'antd';
import InputMask from 'react-input-mask';

const WithdrawalAccount = () => {
  const [form] = Form.useForm();
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [bankSearch, setBankSearch] = useState('');
  const [bankLoading, setBankLoading] = useState(false);

  const getBankList = async (searchValue) => {
    try {
      setBankLoading(true);
      const { data } = await get(`${API.BANK_ABBREVIATION}?search=${searchValue}`);
      setBankLoading(false);
      setBankOptions(data.data);
    } catch (error) {
      setBankLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleBankSearch = (value) => {
    setBankSearch(value);
    if (value) {
      getBankList(value);
    }
  };

  useEffect(() => {
    fetchData();
    getBankList(bankSearch);
  }, []);

  async function fetchData() {
    try {
      const { data } = await get(`${API.WITHDRAWAL_ACCOUNT}`);
      if (data) {
        const initialObj = {
          ...data.data,
          // first_name: data?.data?.first_name,
          // last_name: data?.data?.last_name,
          tfn: data?.data?.customer_bank?.tfn,
          account_name: data?.data?.customer_bank?.account_name,
          account_number: data?.data?.customer_bank?.account_number,
          bank_name: data?.data?.customer_bank?.bank_name,
          bsb: data?.data?.customer_bank?.bsb
        };
        setCustomerData(initialObj);
      }
    } catch (e) {
      console.log('error', e);
      setCustomerData(null);
    }
  }

  const handleFinish = async (values) => {
    values.last_name = values?.account_name ? values?.account_name : '';

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      Object.keys(values).forEach((key) => {
        formData.append([key], values[key]);
      });
      const { data } = await fileUpload(API.WITHDRAWAL_ACCOUNT, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoading(false);
      toast.success(data?.message);
    } catch (error) {
      setLoading(false);
      const { data } = error.response;
      if (data) {
        if (data.errors && Object.keys(data.errors).length > 0) {
          Object.keys(data.errors).forEach((key) => {
            if (key == 'myna_error') {
              if (typeof data.errors[key] === 'object') {
                toast.error(data.errors[key][0]);
              } else {
                toast.error(data.errors[key]);
              }
            } else {
              toast.error(data.errors[key][0]);
            }
          });
        }
      }
    }
  };

  if (!customerData) return null;

  return (
    <div className="content-details">
      <Form
        form={form}
        initialValues={customerData}
        name="personalForm"
        onFinish={handleFinish}
        layout="vertical"
      >
        <div className="entry-card">
          <div className="form-box">
            {/* <Form.Item
              className="half-width"
              label="First Name"
              name="first_name"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: 'Please enter First Name'
                },
                {
                  max: 32,
                  message: 'Must be less than 32 character'
                },
                {
                  pattern: new RegExp(/^[a-zA-Z\s]*$/),
                  message: 'First name can contain letters & spaces.'
                }
              ]}
            >
              <Input placeholder="First Name" name="first_name" />
            </Form.Item>
            <Form.Item
              className="half-width"
              label="Last Name"
              name="last_name"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: 'Please enter Last Name'
                },
                {
                  max: 32,
                  message: 'Must be less than 32 character'
                },
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&]*$/),
                  message:
                    'Last name can contain letters, numbers, ampersand(&), dot(.), comma(,) & spaces.'
                }
              ]}
            >
              <Input placeholder="Last Name" name="last_name" />
            </Form.Item> */}
            <Form.Item
              className="half-width"
              label="TFN"
              name="tfn"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: 'Please enter TFN'
                },
                {
                  min: 8,
                  message: 'Minimum 8 digits are required in TFN'
                },
                {
                  max: 9,
                  message: 'Maximum 9 digits are allowed in TFN'
                },
                {
                  validator: (_, value) => {
                    if (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) {
                      return Promise.reject(
                        'Please enter valid TFN Number. All digits of same number are not allowed.'
                      );
                    } else {
                      if (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) {
                        return Promise.reject(
                          'Please enter valid TFN Number. All digits of same number are not allowed.'
                        );
                      }
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <Input placeholder="Can be a dummy TFN." name="tfn" />
            </Form.Item>
            <Form.Item
              className="half-width"
              label="Email"
              name="email"
              validateTrigger={['onBlur']}
              rules={[
                {
                  type: 'email',
                  message: 'Please enter valid Email'
                },
                {
                  required: true,
                  message: 'Please enter Email'
                }
              ]}
            >
              <Input placeholder="Can be a dummy e-mail address." name="email" />
            </Form.Item>
            <Form.Item
              className="half-width"
              label="Account Name"
              name="account_name"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: 'Please enter Account Name'
                },
                {
                  max: 32,
                  message: 'Must be less than 32 character'
                },
                {
                  pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-()]*$/),
                  message: `Account name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                }
              ]}
            >
              <Input placeholder="Account Name" name="account_name" />
            </Form.Item>
            <Form.Item
              label="Bank Name"
              name="bank_name"
              className="half-width"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: 'Please select Bank Name'
                }
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                onSearch={handleBankSearch}
                loading={bankLoading}
                placeholder="Please select Bank"
              >
                {bankOptions.map((item, i) => (
                  <Select.Option key={i} value={item.abbreviation}>
                    {item.abbreviation} - {item.bank_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="half-width"
              label="BSB"
              name="bsb"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: 'Please enter BSB'
                }
              ]}
            >
              <InputMask mask="999-999" name="bsb" placeholder="123-456" className="ant-input" />
            </Form.Item>
            <Form.Item
              className="half-width"
              label="Account Number"
              name="account_number"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: 'Please enter Account Number'
                },
                {
                  pattern: new RegExp(/^\d{0,9}$/),
                  message: 'Must be less than 10 digit number'
                },
                {
                  validator: (_, value) => {
                    if (
                      (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                      (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                      (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                      (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                      (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                      (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                      (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                      (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                    ) {
                      return Promise.reject(
                        'Please enter valid Account Number. All digits of same number are not allowed.'
                      );
                    } else {
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <Input placeholder="xxxxxxxx1234" name="account_number" />
            </Form.Item>
          </div>
        </div>
        <div className="action-box">
          <Button
            type="primary"
            loading={loading}
            disabled={loading}
            className="saveBtn"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default WithdrawalAccount;
