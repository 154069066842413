import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../config.js';

const activityList = (currentPage, search) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [searchParam, setSearchParam] = useState(search || '');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });

  const fetchUsers = useCallback(
    async (id = null, page = null, limit) => {
      try {
        setIsDataLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        let url = `?${
          id || query.get('user') ? `user_id=${id || query.get('user')}&` : ''
        }search=${searchParam}&page=${page || query.get('page')}&limit=${tempLimit}`;

        const { data } = await get(`${API.GET_ACTIVITY_LIST + url}`);

        setIsDataLoading(false);
        const { total, current_page, per_page, from, to } = data.data.activities;

        const userData = data.data.activities.data;
        setUsers(userData);
        setData(data?.data?.users);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setUsers([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return {
    fetchUsers,
    users,
    data,
    searchParam,
    isDataLoading,
    pagingData,
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/activity-logs?search=${value}&page=1`, {
        replace: true
      });
    },
    onPageChange: (page, user_id, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/activity-logs?${user_id ? `user=${user_id}&` : ''}page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default activityList;
