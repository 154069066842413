import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Spin } from 'antd';
import TableComponent from '../ato/TableComponent';
import Footer from '../../components/Footer/Index';
// import { toast } from "react-toastify";
import useAuditStatementList from './useAuditStatementList';
import { AuditStatementInnerColumns, auditStatementTableColumns } from './FirmTrustHelper';
import '../../App.css';
import '../../assets/css/style.css';
import { API, defaultPaginationLimit } from '../../config';
import UserDetailPopup from '../users/UserDetailPopup';
import { Select } from 'antd';

const AuditStatementPage = ({ setBalanceInfo }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [customerID, setCustomerID] = useState('');
  const [showView, setShowView] = useState(false);
  const query = new URLSearchParams(location.search);
  const currentPage = query.get('page');
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
  );
  const selectedTab = query.get('childTab') || 'import-history';
  const {
    auditStatement,
    isDataLoading,
    // fetchStatement,
    pagingData,
    onPageChange,
    fetchAuditStatementList
  } = useAuditStatementList(currentPage, '', selectedTab, setBalanceInfo, limit);

  useEffect(() => {}, [auditStatement]);

  const toggleShowModal = (e, staffId) => {
    e.preventDefault();
    setCustomerID(staffId);
    if (showView) {
      setCustomerID('');
    }
    setShowView(!showView);
  };

  const innerTableColumns = useMemo(() => {
    return AuditStatementInnerColumns();
  }, []);

  const handleChange = async (value) => {
    setLimit(value);
    navigate(
      `/firm-bank-account?tab=audit-statement&childTab=import-history&page=1&limit=${value}`,
      {
        replace: true
      }
    );
    fetchAuditStatementList(value);
  };

  return (
    <>
      <div className="cms-page audit-statement">
        <div className='audit-statment-react-table'>
          {/* {isDataLoading ? (
            <Spin />
          ) : ( */}
          <TableComponent
            columns={auditStatementTableColumns(toggleShowModal)}
            dataSource={auditStatement}
            nestedTableColumns={innerTableColumns}
            nestedRowEndpoint={API.BANK_AUDIT_STATEMENT_SHOW}
            isNested={true}
            loading={isDataLoading}
            onPageChange={onPageChange}
            pagingData={pagingData}
            limit={limit}
          />
          {/* )} */}
          {auditStatement?.length > 0 && (
            <div className="col-md-12" style={{ paddingLeft: '10px' }}>
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          )}
        </div>
        <Footer />
        {showView && <UserDetailPopup userId={customerID} toggleShowModal={toggleShowModal} />}
      </div>
    </>
  );
};

export default AuditStatementPage;
