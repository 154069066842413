import { useEffect, useState, useCallback } from 'react';
import { API, defaultPaginationLimit, get } from '../../../config';
import { useLocation, useNavigate } from 'react-router-dom';

const useSupplierList = (currentPage, search, limit) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [suppliers, setSuppliers] = useState([]);
  const [searchParam, setSearchParam] = useState(search || '');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchSuppliers = useCallback(
    async (limit, id) => {
      try {
        setIsDataLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }

        let tempId = ""
        if (id) {
          tempId = id;
        } else {
          tempId = query.get('client_id') ? parseInt(query.get('client_id')) : '';
        }
        const { data } = await get(
          tempId
            ? `${API.SUPPLIERS}?search=${searchParam}&customer_id=${tempId}&page=${pagingData.current}&limit=${tempLimit}`
            : `${API.SUPPLIERS}?search=${searchParam}&page=${pagingData.current}&limit=${tempLimit}`
        );
        setIsDataLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        setSuppliers(data.data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setSuppliers([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);
  return {
    fetchSuppliers,
    suppliers,
    searchParam,
    isDataLoading,
    pagingData,
    setPagingData,
    onSearchChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
    },
    onPageChange: (page) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/settings?tab=supplier&page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default useSupplierList;
