/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Spin } from 'antd';
import { toast } from 'react-toastify';
import StripeModal from '../../../components/modal/StripeModal';
import StripePaymentModal from '../../../components/modal/StripePaymentModal';
import numbro from 'numbro';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { API, get } from '../../../config';
// import { Dropdown, DropdownButton } from 'react-bootstrap';
// import { Dropdown, DropdownButton } from 'react-bootstrap';
// import { decrypt } from '../../../helper/utils';

const stripeTestPromise = loadStripe(API.STRIPE_KEY);

export default function SubscriptionDetails(props) {
  const { subscriptionDetail, detail, coupon, isLoading } = props;
  const [id, setId] = useState('');
  const [login, setlogin] = useState(true);
  const [setsubscription, setsubscriptionlist] = useState([]);
  const [selectsubscription, selectsubscriptionplan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [promoCode, setPromoCode] = useState(false);
  const [selectedPeriodPlan, setSelectedPeriodPlan] = useState('year');
  const [percentOff, setPercentOff] = useState(0);
  // const [serviceChecked, setServiceChecked] = useState(false);
  // const [defaultPromoCode, setDefaultPromoCode] = useState('');
  // const setsubscriptiondata = async () => {
  //   const { data } = await get(API.Subscription);
  //   let arr = [
  //     { type: 'month', plan: [] },
  //     { type: 'year', plan: [] }
  //   ];
  //   data.data.data.map((item) => {
  //     if (item.metadata.type == 'month') {
  //       arr[0].plan.push(item);
  //     } else {
  //       arr[1].plan.push(item);
  //     }
  //   });
  //   setSelectedPeriodPlan(arr[1]);
  //   setsubscriptionlist(arr);
  //   setPromoCode(data.coupons);
  //   // data.coupons &&
  //   //   data.coupons?.length > 0 &&
  //   //   data.coupons?.map((el) => {
  //   //     el?.id === 'FIRST50' && setDefaultPromoCode(el?.id);
  //   //   });
  //   setIsLoading(false);
  // };

  useEffect(() => {
    setSelectedPeriodPlan(detail[1]);
    setsubscriptionlist(detail);
    setPromoCode(coupon);
  }, [coupon, detail]);

  const ProceedPayment = async (selectedPlan) => {
    if (selectedPlan) {
      selectsubscriptionplan(selectedPlan);
      setShowPaymentModal(true);
    } else {
      toast.error('Please select subscription plan');
    }
  };

  // useEffect(() => {
  //   setsubscriptiondata();
  // }, []);

  const handleStripePaymentmodalclose = () => {
    setShowPaymentModal(false);
  };

  const handleStripemodalclose = () => {
    setShowModal(false);
  };

  const loginbutton = (data) => {
    if (data.status == 200) {
      setlogin(!login);
      toast.success(data.message);
    }
  };

  const formatNumber = (value) => {
    try {
      let format = numbro(value).format({
        thousandSeparated: true
        // negative: 'parenthesis',
        // mantissa: 0
      });
      return format;
    } catch {
      return '';
    }
  };

  useEffect(async () => {
    const { data } = await get(API.GET_USER_PROFILE);
    setId(data.id);
  }, []);

  useEffect(() => {
    let result = promoCode && promoCode.filter((item) => item.id === 'FIRST50');
    setPercentOff(result?.[0]?.['percent_off'] ? result?.[0]?.['percent_off'] : 0);
  }, [promoCode]);

  return (
    <>
      <div className="content subscription-detail-content">
        {isLoading ? (
          <div className="spinloader">
            <Spin />
          </div>
        ) : (
          <div className="container-fluid subscription-plan subscription-detail-content-body firm-setting">
            <div className="container p-5">
              <div className="row custom-tabs">
                {setsubscription &&
                  setsubscription?.length > 0 &&
                  setsubscription.map((el, index) => {
                    return (
                      <React.Fragment key={index}>
                        <h6
                          className={`${
                            el.type == 'month'
                              ? 'monthly-tab'
                              : el.type == 'year'
                              ? 'yearly-tab'
                              : 'support-tab'
                          } ${selectedPeriodPlan?.type == el?.type ? 'active' : ''}`}
                          onClick={() => setSelectedPeriodPlan(el)}
                        >
                          {el.type == 'month'
                            ? 'Monthly'
                            : el.type == 'year'
                            ? 'Yearly'
                            : 'OnBoarding'}
                        </h6>
                      </React.Fragment>
                    );
                  })}
                <div
                  className={
                    selectedPeriodPlan?.type == 'month'
                      ? 'monthly active'
                      : selectedPeriodPlan?.type == 'year'
                      ? 'yearly active'
                      : 'support active'
                  }
                >
                  {selectedPeriodPlan &&
                    selectedPeriodPlan?.plan?.length > 0 &&
                    selectedPeriodPlan?.plan.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="col-lg-6 col-md-12 mb-4 price-tabs">
                            {moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
                            selectedPeriodPlan.type != 'month' ? (
                              <>
                                <p className="saleoffer">
                                  EOFY 50% OFF *&nbsp;<small>limited time only</small>
                                </p>
                                <h2 className="h2 stricked-price">
                                  <s> ${item.prices.data[0].price} </s>
                                </h2>
                                <h2 className="h2">
                                  <sup>$</sup>
                                  {(
                                    (Number(item.prices.data[0].price) * Number(percentOff)) /
                                    100
                                  ).toFixed(2)}
                                </h2>
                                {item.metadata.type !== 'support_plan' && (
                                  <p className="subscription-detail-content-per-text">
                                    Per {item.metadata.type == 'month' ? 'Month' : 'Annum'}
                                  </p>
                                )}
                              </>
                            ) : null}

                            <div className="card-body">
                              <div className="text-center">
                                {moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
                                selectedPeriodPlan.type == 'year' ? (
                                  ''
                                ) : (
                                  <>
                                    {/* {item.metadata.type === 'support_plan' && (
                                      <span
                                        className="integration-card-info service-card-info"
                                        style={{
                                          position: 'absolute',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'end',
                                          top: '10px',
                                          right: '10px'
                                        }}
                                      >
                                        <DropdownButton className="info-icon">
                                          <Dropdown.Item>
                                            <div>
                                              <p
                                                style={{
                                                  fontWeight: 'bold',
                                                  marginBottom: '15px',
                                                  color: 'white'
                                                }}
                                              >
                                                A. Setup of :
                                              </p>
                                              <ol>
                                                <li>
                                                  Firm accounts incl Trust and Withdrawal accounts
                                                </li>
                                                <li>Receipt and Authority Letter</li>
                                                <li>
                                                  Open Banking connection for direct bankfeeds
                                                </li>
                                                <li>Opening Balance match up</li>
                                                <li>
                                                  Connection with Xero Practice Manager or Access
                                                  Practice Manager
                                                </li>{' '}
                                              </ol>
                                              <br />
                                              <p
                                                style={{
                                                  fontWeight: 'bold',
                                                  marginBottom: '15px',
                                                  color: 'white'
                                                }}
                                              >
                                                B. Training on :
                                              </p>
                                              <ol>
                                                <li>Import client lists</li>
                                                <li>Import EFT reconciliation report</li>
                                                <li>Bulk and individual Refund Processing</li>
                                                <li>.aba file management</li>
                                                <li>Bank Reconciliation</li>
                                              </ol>
                                            </div>
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      </span>
                                    )} */}
                                    <h5 className="card-title support-plan-title">
                                      {selectedPeriodPlan.type === 'supportPlan'
                                        ? item.name
                                        : item.metadata.plan_type}
                                    </h5>
                                    <h2 className="h2" style={{ margin: '0' }}>
                                      <sup>$</sup>
                                      {moment().isBefore(moment('16-05-2024', 'DD-MM-YYYY')) &&
                                      selectedPeriodPlan.type === 'supportPlan' ? (
                                        <>
                                          <span>0.00</span>
                                          <NumberFormat
                                            thousandsGroupStyle="thousand"
                                            value={item.prices.data[0].price}
                                            decimalSeparator="."
                                            displayType="text"
                                            type="text"
                                            thousandSeparator={true}
                                            allowNegative={true}
                                            className="service-price strick-price"
                                          />
                                        </>
                                      ) : (
                                        <NumberFormat
                                          thousandsGroupStyle="thousand"
                                          value={item.prices.data[0].price}
                                          decimalSeparator="."
                                          displayType="text"
                                          type="text"
                                          thousandSeparator={true}
                                          allowNegative={true}
                                        />
                                      )}
                                      <span className="plan-gst">{`(${
                                        item.prices.data[0].tax_behavior === 'inclusive'
                                          ? 'Inc'
                                          : 'Ex'
                                      }. GST)`}</span>
                                      {moment().isBefore(moment('16-05-2024', 'DD-MM-YYYY')) &&
                                        selectedPeriodPlan.type === 'supportPlan' && (
                                          <p
                                            style={{
                                              fontSize: '14px',
                                              fontWeight: '700',
                                              textTransform: 'uppercase',
                                              opacity: '0.7',
                                              margin: 0
                                            }}
                                          >
                                            {' '}
                                            (till 15th May 2024)
                                          </p>
                                        )}
                                    </h2>
                                    {item.metadata.type !== 'support_plan' && (
                                      <p className="subscription-detail-content-per-text">
                                        Per {item.metadata.type == 'month' ? 'Month' : 'Annum'}
                                      </p>
                                    )}
                                  </>
                                )}
                                {/* {!moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
                            selectedPeriodPlan.type != 'month' &&
                            item.metadata.default_coupon_title != null
                              ? promoCode &&
                                promoCode?.length > 0 &&
                                promoCode?.map((el, index) => {
                                  return (
                                    el?.id === 'FIRST50' && (
                                      <p className="coupon-title" key={index}>
                                        {el?.percent_off + '% OFF '}
                                      </p>
                                    )
                                  );
                                })
                              : ''} */}
                                {/* <p className='coupon-title'>
                                {item.metadata.default_coupon_title}
                              </p> */}
                                <p>
                                  {item.metadata.plan_type == 'basic'
                                    ? item?.description
                                    : item?.description}
                                  {/* Firms */}
                                </p>
                                {selectedPeriodPlan.type === 'supportPlan' ? (
                                  <button
                                    onClick={() => ProceedPayment(item)}
                                    className={`btn ${
                                      item.prices.data[0]['id'] == selectsubscription
                                        ? 'btn-disable'
                                        : ''
                                    }`}
                                    // disabled={
                                    //   subscriptionDetail?.plan_time &&
                                    //   subscriptionDetail?.plan_time === item.metadata.type
                                    //     ? true
                                    //     : false
                                    // }
                                    disabled={subscriptionDetail?.support_plan ? true : false}
                                  >
                                    {/* {subscriptionDetail?.plan_time &&
                                  subscriptionDetail?.plan_time === item.metadata.type
                                    ? 'Current Plan'
                                    : 'Get Started'} */}
                                    {subscriptionDetail?.support_plan ? 'Active' : 'Get Started'}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => ProceedPayment(item)}
                                    className={`btn ${
                                      item.prices.data[0]['id'] == selectsubscription
                                        ? 'btn-disable'
                                        : ''
                                    }`}
                                    // disabled={
                                    //   subscriptionDetail?.plan_time &&
                                    //   subscriptionDetail?.plan_time === item.metadata.type
                                    //     ? true
                                    //     : false
                                    // }
                                    disabled={
                                      subscriptionDetail?.plan_time &&
                                      subscriptionDetail?.plan_time === item.metadata.type &&
                                      subscriptionDetail?.plan_id &&
                                      subscriptionDetail?.plan_id === item.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {/* {subscriptionDetail?.plan_time &&
                                  subscriptionDetail?.plan_time === item.metadata.type
                                    ? 'Current Plan'
                                    : 'Get Started'} */}
                                    {subscriptionDetail?.plan_time &&
                                    subscriptionDetail?.plan_time === item.metadata.type &&
                                    subscriptionDetail?.plan_id &&
                                    subscriptionDetail?.plan_id === item.id
                                      ? 'Current Plan'
                                      : 'Get Started'}
                                  </button>
                                )}
                              </div>
                              <div className="text-center">
                                <ul className="list-group list-group-flush h-100">
                                  {selectedPeriodPlan.type === 'supportPlan' ? (
                                    <>
                                      <div
                                        style={{
                                          listStyle: 'none',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                            // color: 'white'
                                          }}
                                        >
                                          A. Setup of :
                                        </p>
                                        {/* <ol> */}
                                        <li className="list-group-item">
                                          Firm accounts incl Trust and Withdrawal accounts
                                        </li>
                                        <li className="list-group-item">
                                          Receipt and Authority Letter
                                        </li>
                                        <li className="list-group-item">
                                          Open Banking connection for direct bankfeeds
                                        </li>
                                        <li className="list-group-item">
                                          Opening Balance match up
                                        </li>
                                        <li className="list-group-item">
                                          Connection with Xero Practice Manager or Access Practice
                                          Manager
                                        </li>{' '}
                                        {/* </ol> */}
                                        {/* <br /> */}
                                        <p
                                          style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px'
                                            // color: 'white'
                                          }}
                                        >
                                          B. Training on :
                                        </p>
                                        {/* <ol> */}
                                        <li className="list-group-item">Import client lists</li>
                                        <li className="list-group-item">
                                          Import EFT reconciliation report
                                        </li>
                                        <li className="list-group-item">
                                          Bulk and individual Refund Processing
                                        </li>
                                        <li className="list-group-item">.aba file management</li>
                                        <li className="list-group-item">Bank Reconciliation</li>
                                        {/* </ol> */}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <li className="list-group-item">
                                        {formatNumber(item.metadata.customers)} Fee From Refunds
                                        p.a.
                                      </li>
                                      <li className="list-group-item">
                                        KYC for up to {formatNumber(item.metadata.kyc_storage)}{' '}
                                        Clients
                                      </li>{' '}
                                      <li className="list-group-item">
                                        {item.metadata.users} Users
                                      </li>
                                    </>
                                  )}
                                  {item.metadata['kanban_dashboard '] == '1' && (
                                    <li className="list-group-item">Kanban Dashboard</li>
                                  )}
                                  {item.metadata.aba_bulk_payment == '1' && (
                                    <li className="list-group-item">.ABA files for bulk payment</li>
                                  )}
                                  {item.metadata.bulk_refund_reconciliation == '1' && (
                                    <li className="list-group-item">Bulk refund reconciliation</li>
                                  )}
                                  {item.metadata.api_integration == '1' && (
                                    <li className="list-group-item">API Integration </li>
                                  )}
                                  {item.metadata.produce_authority_letter == '1' && (
                                    <li className="list-group-item">Produce Authority letter </li>
                                  )}
                                  {item.metadata.produce_receipts == '1' && (
                                    <li className="list-group-item">Produce Receipts</li>
                                  )}
                                  {item?.prices?.data &&
                                    item?.prices?.data?.length > 0 &&
                                    item?.prices?.data?.map((items, index) => {
                                      return (
                                        items?.active === true &&
                                        item.metadata.type !== 'month' &&
                                        items?.recurring?.trial_period_days &&
                                        items?.recurring?.trial_period_days !== null &&
                                        items?.recurring?.trial_period_days !== undefined &&
                                        items?.recurring?.trial_period_days !== '' && (
                                          <li className="list-group-item" key={index}>
                                            {items?.recurring?.trial_period_days +
                                              ' days free trial'}
                                          </li>
                                        )
                                      );
                                    })}
                                  {item.metadata['2fa'] == '1' && (
                                    <li className="list-group-item">Two Factor Authentication</li>
                                  )}
                                  {item.metadata['bank_reconciliation'] == '1' && (
                                    <li className="list-group-item">
                                      {item.metadata.plan_type == 'basic'
                                        ? '.CSV Import of Bank statement '
                                        : 'Automatic Bank Feeds '}
                                    </li>
                                  )}
                                  {/* {supportPlan && (
                                    <li className="list-group-item">
                                      <div className="service-plan-card">
                                        <div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: '5px'
                                            }}
                                          >
                                            White Glove Onboarding Service{' '}
                                            <span className="integration-card-info service-card-info">
                                              <DropdownButton className="info-icon">
                                                <Dropdown.Item>
                                                  <div>
                                                    <p
                                                      style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '15px',
                                                        color: 'white'
                                                      }}
                                                    >
                                                      A. Setup of :
                                                    </p>
                                                    <ol>
                                                      <li>
                                                        Firm accounts incl Trust and Withdrawal
                                                        accounts
                                                      </li>
                                                      <li>Receipt and Authority Letter</li>
                                                      <li>
                                                        Open Banking connection for direct bankfeeds
                                                      </li>
                                                      <li>Opening Balance match up</li>
                                                      <li>
                                                        Connection with Xero Practice Manager or
                                                        Access Practice Manager
                                                      </li>{' '}
                                                    </ol>
                                                    <br />
                                                    <p
                                                      style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '15px',
                                                        color: 'white'
                                                      }}
                                                    >
                                                      B. Training on :
                                                    </p>
                                                    <ol>
                                                      <li>Import client lists</li>
                                                      <li>Import EFT reconciliation report</li>
                                                      <li>Bulk and individual Refund Processing</li>
                                                      <li>.aba file management</li>
                                                      <li>Bank Reconciliation</li>
                                                    </ol>
                                                  </div>
                                                </Dropdown.Item>
                                              </DropdownButton>
                                            </span>
                                          </div>
                                          <div>
                                            {moment().isBefore(
                                              moment('16-05-2024', 'DD-MM-YYYY')
                                            ) ? (
                                              <>
                                                <span className="service-price strick-price">
                                                  ${supportPlan?.prices?.data[0]?.price}
                                                </span>
                                                <span className="service-price">$0.00</span>
                                                <small> (till 15th Jun 2024)</small>
                                              </>
                                            ) : (
                                              <>
                                                <span className="service-price">
                                                  ${supportPlan?.prices?.data[0]?.price}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                        <input type="checkbox" name="service" onChange={(e) => setServiceChecked(e.target.checked)} />
                                      </div>
                                    </li>
                                  )} */}
                                  {item.metadata.type == 'year' &&
                                  moment().isBefore(moment('16-06-2023', 'DD-MM-YYYY')) &&
                                  selectedPeriodPlan.type == 'year' ? (
                                    <li className="list-group-item">
                                      <small className="condition">* First year only</small>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
                {/* <div className="notice">
              All the plans include “On Demand Trust compliance” that includes Bank Reconciliation,
              Refund Management, .Aba file ready for you to upload, all you have to do is upload
              reports in the given format!”
            </div> */}
                {showPaymentModal && (
                  <Elements stripe={stripeTestPromise}>
                    <StripePaymentModal
                      showModal={showPaymentModal}
                      handleModalClose={handleStripePaymentmodalclose}
                      id={Number(id)}
                      planDetail={selectsubscription}
                      promoCode={promoCode}
                      defaultPromocode={''}
                      islogin={1}
                      subscriptionDetail={subscriptionDetail}
                      supportProduct={selectedPeriodPlan.type === 'supportPlan' ? true : false}
                      // supportPlan={serviceChecked && supportPlan}
                    />
                  </Elements>
                )}
                <StripeModal
                  showModal={showModal}
                  onLogin={loginbutton}
                  handleModalClose={handleStripemodalclose}
                  id={Number(id)}
                  planId={selectsubscription}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
