import { useEffect, useState, useCallback } from 'react';
import { API, defaultPaginationLimit, get } from '../../../config';
import { useLocation, useNavigate } from 'react-router-dom';

const useOfficeList = (limit) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [offices, setOffices] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: 1 });
  const fetchOffices = useCallback(
    async (limit) => {
      try {
        setIsDataLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          `${API.GET_OFFICE_LIST}?page=${pagingData.current}&limit=${tempLimit}`
        );
        setIsDataLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        setOffices(data.data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setOffices([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current]
  );

  useEffect(() => {
    fetchOffices();
  }, [fetchOffices]);

  return {
    fetchOffices,
    offices,
    isDataLoading,
    pagingData,
    onPageChange: (page) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/settings?tab=office&page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default useOfficeList;
