import React from 'react';
import { Link } from 'react-router-dom';
const roleName = localStorage.getItem('role_name');
class HeaderNav extends React.Component {
  render() {
    return (
      <ul className="header-nav">
        <li>
          <Link to="/admin/dashboard">Home</Link>
        </li>
        <li>
          <Link to={{ pathname: '/admin/registered-firms', search: '?tab=registeredFirm&page=1' }}>
            Registered Firms
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: '/admin/registered-firms',
              search: '?tab=partialRegisteredFirm&page=1'
            }}
          >
            Partial Registered Firms
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: '/admin/support-tickets'
            }}
          >
            Support Tickets
          </Link>
        </li>
        {roleName == 'Admin' && (
          <li>
            <Link
              to={{
                pathname: '/admin/roles'
              }}
            >
              Roles
            </Link>
          </li>
        )}
        {(roleName == 'Admin' || roleName == 'Manager') && (
          <li>
            <Link
              to={{
                pathname: '/admin/users'
              }}
            >
              Users
            </Link>
          </li>
        )}
      </ul>
    );
  }
}

export default HeaderNav;
