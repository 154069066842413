import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import SidebarNav from "../../components/Header/SidebarNav";
import Header from "../../components/Header/Index";
import Footer from "../../components/Footer/Index";
import BackButton from "../../components/Form/BackButton";
import { API, fileUpload, get } from "../../config";
import "../../App.css";
import "../../assets/css/style.css";

const permissionIndex = {
  "accounting-firm": 2,
  manager: 3,
  accountant: 4,
  staff: 5,
};

const UserRole = () => {
  const [data, setData] = useState([]);
  const [updatedPermissions, setUpdatedPermissions] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    try {
      get(API.GET_USER_ROLE_PERMISSION).then((response) => {
        setData(response.data.data);
      });
    } catch (e) {
      setData([]);
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        // toast.error(errorMessage);
      }
    }
  };

  const roleTableColumns = [
    {
      title: "Permission",
      dataIndex: "name",
      width: "60%",
    },
    {
      title: "Administrator",
      dataIndex: "accounting-firm",
      width: "10%",
      className: "centeralign",
      render: (value, record, index) => {
        return (
          <input
            type="checkbox"
            id="administrator"
            defaultValue={false}
            checked={value === 1}
            onClick={() => {
              handleOnChange(value, "accounting-firm", index, record);
            }}
          />
        );
      },
    },
    {
      title: "Manager",
      dataIndex: "manager",
      width: "10%",
      className: "centeralign",
      render: (value, record, index) => {
        return (
          <input
            type="checkbox"
            id="manager"
            defaultValue={false}
            checked={value === 1}
            onClick={() => {
              handleOnChange(value, "manager", index, record);
            }}
          />
        );
      },
    },
    {
      title: "Accountant",
      dataIndex: "accountant",
      width: "10%",
      className: "centeralign",
      render: (value, record, index) => {
        return (
          <input
            type="checkbox"
            id="accountant"
            defaultValue={false}
            checked={value === 1}
            onClick={() => {
              handleOnChange(value, "accountant", index, record);
            }}
          />
        );
      },
    },
    {
      title: "Staff",
      dataIndex: "staff",
      width: "10%",
      className: "centeralign",
      render: (value, record, index) => {
        return (
          <input
            type="checkbox"
            id="staff"
            defaultValue={false}
            checked={value === 1}
            onClick={() => {
              handleOnChange(value, "staff", index, record);
            }}
          />
        );
      },
    },
  ];
  const handleOnChange = (value, name, index, record) => {
    const newData = [...data];
    newData[index][name] = value === 0 ? 1 : 0;
    setData(newData);
    const newObj = { ...updatedPermissions };
    const foundIndex = permissionIndex[name];
    newObj[record.slug] = {
      ...newObj[record.slug],
      [foundIndex]: value === 0 ? 1 : 0,
    };
    setUpdatedPermissions(newObj);
  };

  const onCancle = () => {
    window.location.reload(false);
  };

  const onSave = async () => {
    try {
      const formData = new FormData();
      Object.keys(updatedPermissions).forEach((key) => {
        const per = updatedPermissions[key];
        Object.keys(per).forEach((ele) => {
          formData.append(`${key}[${ele}]`, per[ele]);
        });
      });
      const { data } = await fileUpload(
        API.UPDATE_USER_ROLE_PERMISSION,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      toast.success(data.message);
      setUpdatedPermissions({});
      getData();
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <Header />
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton /> User Role Management
          </h1>
          <h2></h2>
          <div className="content-details">
            <div className="search-section role-table">
              <div style={{ width: "100%", height: "100%" }}>
                <Table
                  columns={roleTableColumns}
                  dataSource={data}
                  pagination={false}
                />
              </div>
            </div>
            <p />
            <div className="action-box">
              <Button
                variant="primary"
                className="action"
                onClick={(e) => {
                  onSave(e);
                }}
              >
                Save
              </Button>
              <Button
                variant="primary"
                className="reset action"
                onClick={onCancle}
              >
                Cancel
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default UserRole;
