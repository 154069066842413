import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../config.js';

const useABATransactionList = (currentPage, search, limit) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [searchParam, setSearchParam] = useState(search || '');
  const [ABATransactions, setABATransactions] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const [sortField, setSortField] = useState('transaction_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const fetchABATransactions = useCallback(
    async (limit = defaultPaginationLimit, page) => {
      try {
        setIsDataLoading(true);
        const { data } = await get(
          `${API.ABA_FILE_LIST}?page=${page || pagingData.current}&limit=${
            limit ?? query.get('limit')
          }&search=${searchParam}&sort_column=${sortField ? sortField : ''}&sort_order=${
            sortOrder ? sortOrder : ''
          }`
        );
        const { total, current_page, per_page, from, to } = data.data;
        setIsDataLoading(false);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
        setABATransactions(data.data.data);
      } catch (e) {
        setABATransactions([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current, searchParam, sortOrder, sortField]
  );

  useEffect(() => {
    fetchABATransactions(limit ?? query.get('limit'));
  }, [fetchABATransactions]);

  return {
    ABATransactions,
    isDataLoading,
    fetchABATransactions,
    setABATransactions,
    pagingData,
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/aba?search=${searchParam}&page=${page}&tab=aba-transactions&limit=${limit}`, {
        replace: true
      });
    },
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/aba?search=${value}&page=1&tab=aba-transactions&limit=${limit}`, {
        replace: true
      });
    },
    handleSortChange: (pagination, filters, sorter) => {
      // console.log(sorter);
      setSortField(sorter?.field && sorter?.order != undefined ? sorter?.field : 'transaction_date');
      setSortOrder(sorter?.order === 'ascend' ? 'asc' : 'desc');
    },
    searchParam,
    setIsDataLoading
  };
};

export default useABATransactionList;
