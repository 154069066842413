import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import { API, fileUpload, get } from '../../config';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { NameGetter, trimString } from '../../helper/fullNameGetter';

const TaxSearch = ({ close, ItemId }) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState();
  const [selectedItem, setSelectedItem] = useState('');
  const [data, setData] = useState([]);

  const userTableColumns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (created_at) => created_at?.split(' ')[0],
      width: '10%'
    },
    {
      title: 'TFN',
      dataIndex: 'tfn',
      width: '10%'
    },
    {
      title: 'Name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      key: 'name',
      render: (record) => trimString(NameGetter(record), 32),
      width: '50%'
    },
    {
      title: 'Refund as per the Tax Software',
      dataIndex: 'refund',
      render: (refund) => <span>${refund}</span>,
      width: '25%',
      className: 'centeralign'
    }
  ];

  const processUserList = (response) => {
    return response.map((user) => {
      return {
        ...user,
        key: user.id
      };
    });
  };

  const getTaxSearchData = (searchKeyWord) => {
    try {
      setIsDataLoading(true);
      setSearchKeyWord(searchKeyWord);
      if (searchKeyWord != '') {
        get(API.TAX_SOFTWARES + `?search=${searchKeyWord}`).then((response) => {
          setIsDataLoading(false);
          const userData = processUserList(response.data.data.data);
          setData(userData);
        });
      } else {
        setIsDataLoading(false);
        setData([]);
      }
    } catch (e) {
      setData([]);
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItem(selectedRows[0]);
    }
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  const handleSaveSearch = async () => {
    try {
      const formData = new FormData();
      formData.append('recon_id', ItemId);
      formData.append('ts_id', selectedItem.id);
      const response = await fileUpload(API.SAVE_SEARCH_PROCESS_REFUND, formData, {});
      if (response.status === 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          close(true);
        }, 3000);
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const isButtonDisabled = useMemo(() => {
    if (!data.length) return true;
    if (!selectedItem) return true;
    return false;
  }, [data.length, selectedItem]);

  return (
    <>
      <div className="table-top-btn">
        <div className="searchbar">
          <input
            type="text"
            value={searchKeyWord}
            placeholder="Search"
            onChange={(e) => {
              getTaxSearchData(e.target.value);
            }}
          />
        </div>
        <div className="table-btn">
          <Button variant="secondary" size="sm" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleSaveSearch()}
            size="sm"
            disabled={isButtonDisabled}
          >
            OK
          </Button>
        </div>
      </div>
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection
        }}
        columns={userTableColumns}
        dataSource={data}
        loading={isDataLoading}
      />
    </>
  );
};

export default TaxSearch;
