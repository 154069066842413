/* eslint-disable no-nested-ternary */
import { Select, Spin, DatePicker, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import SidebarNav from '../../components/Header/SidebarNav';
// import UserSelect from '../../components/User/UserSelect';
import { API, fileUpload, get } from '../../config';
// import splitIcon from '../../assets/images/split.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FullNameGetterWithoutChartAt } from '../../helper/fullNameGetter';
import InputMask from 'react-input-mask';
import moment from 'moment';
import AddEditBeneficiary from './AddBeneficiary';
import { NegativeAmountConverter } from '../../helper/Conversions';
import PostConfirmationModal from './PostConfirmationModal';
import { useNavigate } from 'react-router-dom';
import numbro from 'numbro';
import AllClientsSelect from '../../components/User/AllClientsSelect';
import { formatNumber } from '../../APIConfig/Interceptor';
import SupplierBasedOnClient from './SupplierBasedOnClient';
import NumberFormat from 'react-number-format';

// const { TextArea } = Input;

const numberformat = (value) => {
  try {
    let format = numbro(value).format({
      // thousandSeparated: true,
      // negative: 'parenthesis',
      mantissa: 2
    });
    return format;
  } catch {
    return '';
  }
};

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

// const newEntryData = {
//   beneficiary_id: '',
//   chart_account_id: '',
//   id: '',
//   amount: '',
//   bsb: '',
//   account_number: ''
// };
function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

const RecordPayment = ({
  bankReconcilation = false,
  reconcileFunction,
  toggleAddModal,
  itemJson
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [clientId, setClientId] = useState();
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  const [withdrawalAccountData, setWithdrawalAccountData] = useState({});
  const [clientData, setClientData] = useState({});
  const [itemClientData, setItemClientData] = useState({});
  const [itemPaymentIndex, setItemPaymentIndex] = useState({});
  const [itemBlockIndex, setItemBlockIndex] = useState({});
  const [itemBlockItem, setItemBlockItem] = useState({});
  const [addSupplierModal, setAddSupplierModal] = useState(false);

  const [beneficiarylength, setBeneficiarylength] = useState(0);
  const [fileNote, setFileNote] = useState('');
  const [transactionDate, setTransactionDate] = useState(
    itemJson?.transactionDate
      ? itemJson?.transactionDate
      : state?.transactionDate
      ? state?.transactionDate
      : new Date()
  );

  const [clientErrors, setClientErrors] = useState({});
  const [amountErrors, setAmountErrors] = useState({});
  const [descriptionErrors, setDescriptionErrors] = useState({});
  const [accountErrors, setAccountErrors] = useState({});
  const [finalTotal, setFinalTotal] = useState(0);

  const [beneficiariesArray, setBeneficiariesArray] = useState([]);

  const defaulBlockData = [
    {
      id: '',
      clientData: '',
      withdrawalAccountData: '',
      payments: [],
      customer_balance: '',
      customer_paid: itemJson?.amount
        ? Number(itemJson?.amount)
        : Number(state?.amount)
        ? state?.amount
        : 0
    }
  ];
  const newBlockEntryData = {
    id: '',
    clientData: '',
    withdrawalAccountData: '',
    payments: [],
    customer_balance: '',
    customer_paid: itemJson?.amount
      ? Number(itemJson?.amount)
      : Number(state?.amount)
      ? state?.amount
      : 0
  };

  const [blockData, setBlockData] = useState(defaulBlockData);

  const handleAddBlockEntry = (data) => {
    setLoading(false);
    setBlockData([...data, newBlockEntryData]);
  };
  const handleDeleteBlockEntry = (data, index) => {
    data.splice(index, 1);
    setBlockData([...data]);
  };

  const [finalData, setFinalData] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    let beneficiaryArray = [];
    let defaultArray = [];
    finalData?.length > 0 &&
      finalData?.forEach((item) => {
        if (item?.type === 'default') {
          defaultArray.push(item);
        } else {
          beneficiaryArray.push(item);
        }
      });

    setBeneficiariesArray(beneficiaryArray);
  }, [finalData]);

  const getAccountData = () => {
    setAccountLoading(true);
    get(`${API.GET_CHARTACCOUNT}?limit=-1`)
      .then((response) => {
        setAccountOptions(response?.data?.data?.data ? response?.data?.data?.data : []);
        response && setAccountLoading(false);
      })
      .catch((e) => {
        setAccountLoading(false);
        toast.error(e?.data?.message);
      });
  };

  const getCustomerDetail = async (clientId) => {
    try {
      setLoading(true);
      const { data } = await get(`${API.CUSTOMER_DETAILS}/` + clientId);
      setClientData(data?.data ? data?.data : {});
      setBeneficiarylength(data?.data?.beneficiaries ? data?.data?.beneficiaries?.length : 0);
      setLoading(false);
      return data.data;
    } catch (e) {
      setClientData({});
      setBeneficiarylength(0);
      setLoading(false);
      return null;
    }
  };

  const getCustomerBlockDetail = async (clientId, index, blockData, type) => {
    try {
      setLoading(true);
      const { data } = await get(
        type === 2 ? `${API.SUPPLIER_DETAILS}/` + clientId : `${API.CUSTOMER_DETAILS}/` + clientId
      );
      const newArr = blockData?.map((obj, i) => {
        if (i === index) {
          return { ...obj, clientData: data?.data };
        }
        return obj;
      });
      setLoading(false);
      return newArr;
    } catch (e) {
      setLoading(false);
      return null;
    }
  };

  const getWithdrawalAccountData = async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.WITHDRAWAL_ACCOUNT}`);
      if (data) {
        const initialObj = {
          ...data.data,
          first_name: data?.data?.first_name,
          last_name: data?.data?.last_name,
          account_name: data?.data?.customer_bank?.account_name,
          account_number: data?.data?.customer_bank?.account_number,
          bank_name: data?.data?.firm_bank?.bank_name,
          bsb: data?.data?.customer_bank?.bsb
        };
        setWithdrawalAccountData(initialObj);
        setLoading(false);
      }
    } catch (e) {
      setWithdrawalAccountData(null);
      setLoading(false);
    }
  };

  const getWithdrawalAccountBlockData = async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.WITHDRAWAL_ACCOUNT}`);
      if (data) {
        const initialObj = {
          ...data.data,
          first_name: data?.data?.first_name,
          last_name: data?.data?.last_name,
          account_name: data?.data?.customer_bank?.account_name,
          account_number: data?.data?.customer_bank?.account_number,
          bank_name: data?.data?.firm_bank?.bank_name,
          bsb: data?.data?.customer_bank?.bsb
        };
        setLoading(false);
        setWithdrawalAccountData(initialObj);
        return initialObj;
      }
    } catch (e) {
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    getAccountData();
    clientId && getWithdrawalAccountData();
    clientId && getCustomerDetail(clientId);
  }, [clientId]);

  const defaultAccount = accountOptions
    ? accountOptions?.find((item) => item?.name === 'Funds Held In Trust')
    : {};

  const onSelect = async (e, index, blockData) => {
    const { data } = await get(`${API.WITHDRAWAL_ACCOUNT}`);
    if (!data?.data) {
      toast.error('Please add withdrawal Account Details in General Setting Page');
    }
    // setClientId(e);
    const newArr = blockData?.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          id: e,
          // type: options?.find((item) => item?.id == e)?.type
          type: 1
        };
      }
      return obj;
    });

    // let type = options?.find((item) => item?.id == e)?.type;
    let type = 1;
    let withdrawalAccountData = await getWithdrawalAccountBlockData();
    let customerDataArray = await getCustomerBlockDetail(e, index, newArr, type);
    let block = await customerDataArray;
    // for (let i = 0; i < block?.length; i++) {
    let i = index;
    block[i].supplier = 0;
    let lastElement = block[i]?.payments[block[i]?.payments.length - 1];
    let withdrawalData = withdrawalAccountData
      ? [
          {
            ...withdrawalAccountData,

            chart_account_id:
              block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                ? block[i]?.payments[0]?.chart_account_id
                : defaultAccount?.id,

            amount:
              block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                ? block[i]?.payments[0]?.amount
                : lastElement?.amount,

            bsb:
              block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                ? block[i]?.payments[0]?.bsb
                : withdrawalAccountData?.bsb,

            account_number:
              block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                ? block[i]?.payments[0]?.account_number
                : withdrawalAccountData?.account_number,

            description:
              block[i]?.payments[0]?.withdrawal_account_id === withdrawalAccountData?.id
                ? block[i]?.payments[0]?.description ?? 'Professional Fees'
                : 'Professional Fees',

            type: 'default',

            withdrawal_account_id: withdrawalAccountData?.id
          }
        ]
      : [];

    let clientObject = {};

    clientObject = block[i]?.payments?.find(
      (item) =>
        item?.type === 'default' && !item?.withdrawal_account_id && item?.client_id === block[i]?.id
    );

    let clientAccountData = [];

    if (block[i]?.clientData && block[i]?.clientData?.withdrawal_account !== 1) {
      clientAccountData.push({
        chart_account_id: clientObject?.chart_account_id
          ? clientObject?.chart_account_id
          : defaultAccount?.id,

        first_name: block[i]?.clientData?.first_name ? block[i]?.clientData?.first_name : '',

        last_name: block[i]?.clientData?.last_name ? block[i]?.clientData?.last_name : '',

        account_name:
          block[i]?.type === 2
            ? block[i]?.clientData?.account_name
            : block[i]?.clientData?.customer_bank?.account_name,

        client_type: block[i]?.type,

        account_number: clientObject?.account_number
          ? clientObject?.account_number
          : block[i]?.type === 2
          ? block[i]?.clientData?.account_number
          : block[i]?.clientData?.customer_bank?.account_number,

        bsb: clientObject?.bsb
          ? clientObject?.bsb
          : block[i]?.type === 2
          ? block[i]?.clientData?.bsb
          : block[i]?.clientData?.customer_bank?.bsb,

        client_id: block[i]?.clientData?.id,

        beneficiary_id: '',

        description: itemJson?.description
          ? itemJson?.description
          : state?.description
          ? state.description
          : clientObject?.description,

        amount: itemJson?.amount
          ? numberformat(itemJson?.amount)
          : state?.amount
          ? numberformat(state.amount)
          : clientObject?.amount,

        clientData: block[i]?.clientData,

        type: 'default'
      });
    }

    const beneficiaryAccountData = [];
    if (block[i]?.clientData?.beneficiaries && block[i]?.clientData?.beneficiaries?.length > 0) {
      block[i]?.clientData?.beneficiaries.map((obj, index) => {
        let beneficiaryObject = block[i]?.payments
          ? block[i]?.payments?.find(
              (item) => item?.beneficiary_id && item?.beneficiary_id === obj?.id
            )
          : {};

        if (index != -1) {
          beneficiaryAccountData.push({
            ...obj,

            beneficiary_id: beneficiaryObject?.beneficiary_id
              ? beneficiaryObject?.beneficiary_id
              : obj?.id,

            chart_account_id: beneficiaryObject?.chart_account_id
              ? beneficiaryObject?.chart_account_id
              : defaultAccount?.id,

            amount: beneficiaryObject?.amount ? beneficiaryObject?.amount : '',

            description: beneficiaryObject?.description ? beneficiaryObject?.description : '',

            bsb: beneficiaryObject?.bsb ? beneficiaryObject?.bsb : obj?.bsb,

            account_number: beneficiaryObject?.account_number
              ? beneficiaryObject?.account_number
              : obj?.account_number
          });
        } else {
          beneficiaryAccountData.push(obj);
        }
      });
    }

    if (block[i]?.id) {
      if (block[i]?.type == 2) {
        block[i].payments = [...clientAccountData];
      } else {
        block[i].payments = [...clientAccountData, ...beneficiaryAccountData, ...withdrawalData];
      }
    } else {
      block[i].payments = [];
    }
    // }

    setBlockData(block);
  };

  const handleNewAccountSelection = (val, index, finalData, mainIndex) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, chart_account_id: val };
      }
      return obj;
    });
    blockData[mainIndex].payments = newArr;
    setBlockData(blockData);
    setFinalData(newArr);
  };

  const handleClientChange = (val, index, finalData, mainIndex) => {
    let errors = clientErrors;
    const check = finalData?.find((item) => item?.beneficiary_id === val);
    if (!check) {
      const newArr = finalData.map((obj, i) => {
        if (i === index) {
          return { ...obj, beneficiary_id: val };
        }
        return obj;
      });
      errors[index] = null;
      blockData[mainIndex].payments = newArr;
      setBlockData(blockData);
      setFinalData(newArr);
    } else {
      errors[index] = 'Select other client name.';
      toast.error('Please select another client name.');
      const newArr = finalData.map((obj, i) => {
        if (i === index) {
          return { ...obj, beneficiary_id: '' };
        }
        return obj;
      });
      blockData[mainIndex].payments = newArr;
      setBlockData(blockData);
      setFinalData(newArr);
    }
    setClientErrors({ ...errors });
  };

  const handleAmount = (e, index, finalData, mainIndex) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, amount: e.target.value };
      }
      return obj;
    });
    let customer_paid = 0;
    newArr?.map((item) => {
      if (item?.amount !== undefined) {
        customer_paid = customer_paid + Number(item?.amount);
      }
    });
    blockData[mainIndex].payments = newArr;
    blockData[mainIndex].customer_paid = customer_paid;
    setBlockData(blockData);
    setFinalData(newArr);
  };

  // eslint-disable-next-line no-unused-vars
  const handleBlurAmount = (e, index) => {
    let errors = amountErrors;
    let regex = new RegExp(/^\d*\.?\d*$/);
    if (e) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index] = 'Enter valid Amount.';
      } if (e.target.value && (Number(e.target.value) === 0)) {
        errors[index] = 'Amount must be greater than zero.';
      } else {
        errors[index] = null;
      }
    } else {
      toast.error('Something went wrong.');
    }
    setAmountErrors({ ...errors });
  };

  const handleBlurDescription = (e, index) => {
    let errors = descriptionErrors;
    let regex = new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/);
    if (e) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index] = 'Enter valid Description.';
      } else {
        errors[index] = null;
      }
    } else {
      toast.error('Something went wrong.');
    }
    setDescriptionErrors({ ...errors });
  };

  const handleBlurAccount = (e, index) => {
    let errors = accountErrors;
    let regex = new RegExp(/^\d{0,9}$/);

    if (e.target.value || e.target.value === 0) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index] = 'Must be 9 digit number.';
      } else {
        let myFunc = (num) => Number(num);

        var intArr = Array.from(String(e.target.value), myFunc);
        const removeDuplicatesArr = removeDuplicates(intArr);

        if (intArr?.length > 1 && removeDuplicatesArr?.length === 1) {
          errors[index] =
            'Please enter valid Account Number. All digits of same number are not allowed.';
        } else {
          errors[index] = null;
        }
      }
    } else {
      toast.error('Something went wrong.');
    }
    setAccountErrors({ ...errors });
  };

  const handleDescription = (e, index, finalData, mainIndex) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    blockData[mainIndex].payments = newArr;
    setBlockData(blockData);
    setFinalData(newArr);
  };

  const handleBSB = (e, index, finalData, mainIndex) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, bsb: e.target.value };
      }
      return obj;
    });
    blockData[mainIndex].payments = newArr;
    setBlockData(blockData);
    setFinalData(newArr);
  };

  const handleAccount = (e, index, finalData, mainIndex) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, account_number: e.target.value };
      }
      return obj;
    });
    blockData[mainIndex].payments = newArr;
    setBlockData(blockData);
    setFinalData(newArr);
  };

  function removeObjectWithId(arr, id) {
    // Making a copy with the Array from() method
    const arrCopy = Array.from(arr);
    const objWithIdIndex = arrCopy.findIndex((obj) => obj.id === id);
    arrCopy.splice(objWithIdIndex, 1);
    return arrCopy;
  }

  const hanldeDelete = async (index, finalData, mainIndex) => {
    let deletedData = blockData[mainIndex].payments[index];
    if (deletedData?.beneficiary_id) {
      blockData[mainIndex].clientData.beneficiaries = removeObjectWithId(
        blockData[mainIndex]?.clientData?.beneficiaries,
        deletedData?.beneficiary_id
      );
    }
    finalData.splice(index, 1);
    blockData[mainIndex].payments = finalData;
    let customer_paid = 0;
    finalData?.map((item) => {
      customer_paid = customer_paid + Number(item?.amount);
    });
    blockData[mainIndex].customer_paid = customer_paid;
    setBlockData(blockData);
    setFinalData([...finalData]);
  };

  // const hanldeAddNew = (finalData, mainIndex) => {
  //   blockData[mainIndex].payments = [...finalData, newEntryData];
  //   setBlockData(blockData);
  //   setFinalData([...finalData, newEntryData]);
  // };

  const handlePostDisabled = (finalData) => {
    const clientError = Object.values(clientErrors);
    const amountError = Object.values(amountErrors);
    const descriptionError = Object.values(descriptionErrors);
    const accountError = Object.values(accountErrors);

    const checkClientError = Object.values(
      clientError.filter((item, index) => clientError.indexOf(item) === index)
    );
    const checkAmountError = Object.values(
      amountError.filter((item, index) => amountError.indexOf(item) === index)
    );
    const checkDescriptionError = Object.values(
      descriptionError.filter((item, index) => descriptionError.indexOf(item) === index)
    );
    const checkAccountError = Object.values(
      accountError.filter((item, index) => accountError.indexOf(item) === index)
    );

    const checkData = finalData?.map((item) =>
      item?.payments?.map((element, index) => {
        if (element?.description == undefined) {
          element.description = '';
        }
        if (element?.amount == undefined) {
          element.amount = '';
        }
        if (
          (checkAccountError[index] === null || checkAccountError[index] === undefined) &&
          (checkClientError[index] === null || checkClientError[index] === undefined) &&
          (checkAmountError[index] === null || checkAmountError[index] === undefined) &&
          (checkDescriptionError[index] === null || checkDescriptionError[index] === undefined) &&
          element?.chart_account_id &&
          (element?.beneficiary_id || element?.type === 'default') &&
          element?.amount &&
          element?.description &&
          String(element?.bsb) &&
          `${element?.bsb}`.replace('_', '').length === 7 &&
          element?.account_number
        ) {
          return false;
        } else {
          return true;
        }
      })
    );
    let finalCheckData = checkData?.map((item) =>
      item?.find((item) => item === true) || !transactionDate || !fileNote ? true : false
    );

    return finalCheckData?.find((item) => item === true) || !transactionDate || !fileNote
      ? true
      : false;
  };

  const handlePost = async (finalData) => {
    if (
      bankReconcilation &&
      Number(itemJson?.amount).toFixed(2) !== Number(finalTotal).toFixed(2)
    ) {
      toast.error('The total payment amount must match bank transaction amount');
      handleClosePostModal();
    } else {
      const formData = new FormData();
      // formData.append('customer_id', clientId);
      formData.append('transaction_date', moment(transactionDate).format('DD-MM-YYYY'));
      formData.append('note', fileNote ? fileNote : '');
      // formData.append('total_amount', finalTotal ? finalTotal : 0);
      finalData?.forEach((mainItem, mainIndex) => {
        if (mainItem) {
          formData.append(`paymentData[${mainIndex}][id]`, mainItem?.id ? mainItem?.id : '');
          // formData.append(`paymentData[${mainIndex}][type]`, mainItem?.type ? mainItem?.type : '');
          formData.append(
            `paymentData[${mainIndex}][is_supplier]`,
            mainItem?.supplier ? mainItem?.supplier : 0
          );
          mainItem?.payments?.forEach((item, index) => {
            if (item) {
              formData.append(
                `paymentData[${mainIndex}][payments][${index}][chart_account_id]`,
                item?.chart_account_id ? item?.chart_account_id : ''
              );
              formData.append(
                `paymentData[${mainIndex}][payments][${index}][withdrawal_account_id]`,
                item?.withdrawal_account_id ? item?.withdrawal_account_id : ''
              );
              formData.append(
                `paymentData[${mainIndex}][payments][${index}][beneficiary_id]`,
                item?.beneficiary_id ? item?.beneficiary_id : ''
              );
              formData.append(
                `paymentData[${mainIndex}][payments][${index}][supplier_id]`,
                item?.supplier_id ? item?.supplier_id : ''
              );
              formData.append(
                `paymentData[${mainIndex}][payments][${index}][amount]`,
                item?.amount ? item?.amount : ''
              );
              formData.append(
                `paymentData[${mainIndex}][payments][${index}][bsb]`,
                item?.bsb ? item?.bsb : ''
              );
              formData.append(
                `paymentData[${mainIndex}][payments][${index}][account_number]`,
                item?.account_number ? item?.account_number : ''
              );
              formData.append(
                `paymentData[${mainIndex}][payments][${index}][description]`,
                item?.description ? item?.description : ''
              );
            }
          });
        }
      });
      try {
        setPostLoading(true);
        const response = await fileUpload(`${API.PAYMENTS}`, formData, {});
        if (response?.data?.message) {
          handleClosePostModal();
          toast.success(response?.data?.message);
          if (bankReconcilation === true) {
            reconcileFunction();
            toggleAddModal();
          } else {
            navigate('/payments');
          }
          setClientId();
          setFinalData([]);
          setClientData({});
          setTransactionDate(moment());
          setFileNote('');
          // onSelect();
        }
        setPostLoading(false);
      } catch (error) {
        setPostLoading(false);
        const errors = error.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  };

  const [showPostModal, setShowPostModal] = useState(false);
  const [finalPostData, setFinalPostData] = useState();

  const handleClickPost = (finalData) => {
    if (!withdrawalAccountData.account_name || !withdrawalAccountData.account_number) {
      toast.error('Please add withdrawal Account Details in General Setting Page');
    } else {
      setShowPostModal(!showPostModal);
      setFinalPostData(finalData);
    }
  };

  const handleClosePostModal = () => {
    setShowPostModal(!showPostModal);
  };

  const handleClose = () => {
    setItemClientData();
    setItemPaymentIndex();
    setShow(!show);
  };

  const handleAddNewBeneficiary = (clientId, index) => {
    setItemClientData(clientId);
    setItemPaymentIndex(index);
    clientId?.id ? setShow(true) : toast.error('Please Select a Client.');
  };

  useEffect(() => {
    var finalAmount = 0;
    blockData.forEach((data) => {
      data?.customer_paid && (finalAmount = finalAmount + Number(data?.customer_paid));
    });
    setFinalTotal(finalAmount);
  });

  const handleAddSupplierModal = (mainItem, mainIndex) => {
    if (mainItem?.id) {
      setItemBlockIndex(mainIndex);
      setItemBlockItem(mainItem);
      setAddSupplierModal(!addSupplierModal);
    } else {
      toast.error('Please Select a Client.');
    }
  };

  const CustomerPaid = (array) => {
    let paid = 0;
    for (let i = 0; i < array?.length; i++) {
      if (array[i]?.payments?.length > 0) {
        paid = paid + array[i]?.customer_paid;
      }
    }
    return Number(paid);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        {bankReconcilation ? <></> : <SidebarNav />}
        <div
          className="full-content-block"
          style={bankReconcilation ? { height: 'auto', margin: '0px' } : {}}
        >
          {bankReconcilation ? (
            <></>
          ) : (
            <h1 className="page-title">
              <BackButton />
              Enter Trust Payment
            </h1>
          )}
          <div
            className="content-details refund-process"
            style={bankReconcilation ? { padding: '30px', marginTop: '0px' } : {}}
          >
            <div
              style={{ justifyContent: 'space-between', gap: '20px', marginBottom: '30px' }}
              className="table-top-btn refund-top-details record-payment"
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <div>
                  <strong>Transaction Date</strong>
                  <DatePicker
                    format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                    disabledDate={disabledDate}
                    defaultValue={moment(transactionDate)}
                    onChange={(e) => {
                      e && setTransactionDate(e);
                    }}
                    allowClear={false}
                  />
                </div>
                <div>
                  <span
                    style={{
                      color: 'red',
                      marginRight: '4px',
                      fontSize: ' 18px',
                      verticalAlign: 'middle'
                    }}
                  >
                    *
                  </span>
                  <strong>File Note</strong>{' '}
                  <Form.Control
                    type="text"
                    className="left-text-align file-note-input"
                    required
                    style={{ textAlign: 'left', width: '300px' }}
                    maxLength={100}
                    value={fileNote ? fileNote : ''}
                    rules={[
                      {
                        pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                        message:
                          'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                      }
                    ]}
                    onChange={(e) => {
                      setFileNote(e.target.value);
                    }}
                  ></Form.Control>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                {bankReconcilation && (
                  <div>
                    <strong>Transaction Amount: </strong>
                    <strong>
                      ${itemJson?.amount ? formatNumber(Number(itemJson?.amount)) : '0.00'}
                    </strong>
                  </div>
                )}
                <div>
                  <strong>Payment Total: </strong>
                  <strong>${finalTotal ? formatNumber(finalTotal) : '0.00'}</strong>
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div id="grid-wrapper" style={{ width: '100%', height: '100%' }}>
                <div id="myGrid" className="ag-theme-alpine ato-grid">
                  <Spin spinning={loading || postLoading}>
                    <Form>
                      <div className="form-box">
                        {blockData &&
                          blockData?.map((mainItem, mainIndex) => (
                            <div key={mainIndex} className="refund-process-box">
                              <Container>
                                <Container>
                                  <Row
                                    style={{ marginBottom: '0px', alignItems: 'flex-start' }}
                                    className="refund-top-details record-payment"
                                  >
                                    <Col xs={12} md={8}>
                                      <div style={{ display: 'flex', gap: '30px' }}>
                                        <div className="search-section process-search">
                                          {!postLoading && (
                                            <>
                                              {' '}
                                              <AllClientsSelect
                                                // customerId={
                                                //   clientId && !postLoading ? Number(clientId) : ''
                                                // }
                                                customerId={
                                                  mainItem?.id && !postLoading
                                                    ? Number(mainItem?.id)
                                                    : ''
                                                }
                                                clearable={false}
                                                formStyle={{ width: 330 }}
                                                onCustomerSelectionChange={(e, options) =>
                                                  onSelect(e, mainIndex, blockData, options)
                                                }
                                              />
                                              <span
                                                style={{
                                                  justifyContent: 'center',
                                                  marginLeft: '4px'
                                                }}
                                              >
                                                or
                                              </span>
                                              <Link
                                                to={{ pathname: '/new-customers' }}
                                                className="action solid-btn"
                                                style={{
                                                  padding: '0px 12px',
                                                  fontSize: '13px',
                                                  height: '28px',
                                                  lineHeight: '28px'
                                                }}
                                              >
                                                Add Client
                                              </Link>{' '}
                                            </>
                                          )}
                                        </div>
                                        {mainItem?.clientData.closing_balance ? (
                                          <div
                                            className="client-account-balance"
                                            style={{
                                              display: 'flex',
                                              gap: '10px',
                                              marginBottom: '15px'
                                            }}
                                          >
                                            <p style={{ fontWeight: '700' }}>
                                              Account balance for{' '}
                                              {FullNameGetterWithoutChartAt(mainItem?.clientData)} :{' '}
                                              {mainItem?.clientData?.closing_balance
                                                ? NegativeAmountConverter(
                                                    mainItem?.clientData?.closing_balance
                                                  )
                                                : '$0.00'}
                                            </p>
                                            <p style={{ fontWeight: '700' }}>
                                              Balance after making this payment :{' '}
                                              {CustomerPaid(
                                                blockData?.filter(
                                                  (customerItem) =>
                                                    customerItem?.id === mainItem?.id
                                                )
                                              )
                                                ? mainItem?.clientData?.closing_balance &&
                                                  NegativeAmountConverter(
                                                    mainItem?.clientData?.closing_balance -
                                                      CustomerPaid(
                                                        blockData?.filter(
                                                          (customerItem) =>
                                                            customerItem?.id === mainItem?.id
                                                        )
                                                      )
                                                  )
                                                : '$0.00'}
                                            </p>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <div style={{ display: 'flex', float: 'right' }}>
                                        {mainItem?.supplier != 1 &&
                                          mainItem?.clientData?.withdrawal_account !== 1 && (
                                            <Button
                                              onClick={() => {
                                                handleAddSupplierModal(mainItem, mainIndex);
                                              }}
                                              style={{
                                                float: 'none',
                                                marginRight: '15px',
                                                padding: '5px 14px'
                                              }}
                                            >
                                              Pay To Supplier
                                            </Button>
                                          )}
                                        {mainItem?.supplier != 1 &&
                                          mainItem?.clientData?.withdrawal_account !== 1 && (
                                            <Button
                                              onClick={() => {
                                                handleAddNewBeneficiary(mainItem, mainIndex);
                                              }}
                                              style={{ float: 'none', padding: '5px 14px' }}
                                            >
                                              Add Beneficiary
                                            </Button>
                                          )}
                                        <div
                                          style={{
                                            paddingLeft: '15px',
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        >
                                          {mainIndex + 1 === blockData?.length ? (
                                            <>
                                              <Tooltip
                                                placement="bottomLeft"
                                                title={
                                                  'Add additional payments to the same ABA file'
                                                }
                                              >
                                                <Button
                                                  style={{ padding: '3px 10px', float: 'none' }}
                                                  onClick={() => {
                                                    handleAddBlockEntry(blockData, mainIndex);
                                                  }}
                                                >
                                                  <FontAwesomeIcon icon={faAdd} size="1x" />
                                                </Button>
                                              </Tooltip>
                                              {mainIndex > 0 && (
                                                <>
                                                  <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                                  <Button
                                                    style={{ padding: '3px 10px', float: 'none' }}
                                                    onClick={() => {
                                                      handleDeleteBlockEntry(blockData, mainIndex);
                                                    }}
                                                  >
                                                    <FontAwesomeIcon icon={faTrash} size="1x" />
                                                  </Button>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <Button
                                              style={{ padding: '5px 14px', float: 'none' }}
                                              onClick={() => {
                                                handleDeleteBlockEntry(blockData, mainIndex);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faTrash} size="1x" />
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row
                                    className="refund-card-box record-payment"
                                    style={{ marginTop: '5px' }}
                                  >
                                    <Col xs={6} md={12}>
                                      <div className="refund-card">
                                        <label>Record Payment Details</label>
                                        <div className="refund-card-content">
                                          <div className="refund-card-content-label refund-card-title row col-md-12">
                                            <h6 className="col-md-2 center-align">Account</h6>
                                            <h6 className="col-md-2 center-align">Client Name</h6>
                                            <h6 className="col-md-2 center-align">Amount</h6>
                                            <h6 className="col-md-2 center-align">Description</h6>
                                            <h6 className="col-md-1 center-align">BSB</h6>
                                            <h6 className="col-md-2 center-align">
                                              Account Number
                                            </h6>
                                            <h6 className="col-md-1 center-align"></h6>
                                          </div>
                                          <div className="refund-card-content-row">
                                            {mainItem?.payments &&
                                            mainItem?.payments?.length > 0 ? (
                                              mainItem?.payments?.map((item, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="refund-card-content-label row col-md-12"
                                                  >
                                                    <div className="col-md-2 refund-column">
                                                      <Select
                                                        showSearch
                                                        style={{
                                                          width: '100%',
                                                          margin: '0'
                                                        }}
                                                        loading={accountLoading}
                                                        onChange={(id) => {
                                                          handleNewAccountSelection(
                                                            id,
                                                            index,
                                                            mainItem?.payments,
                                                            mainIndex
                                                          );
                                                        }}
                                                        filterOption={(input, option) =>
                                                          option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        value={
                                                          item?.chart_account_id &&
                                                          accountOptions?.length > 0 &&
                                                          accountOptions?.find(
                                                            (val) =>
                                                              val?.id === item?.chart_account_id
                                                          ) &&
                                                          item?.chart_account_id
                                                        }
                                                      >
                                                        {accountOptions &&
                                                          accountOptions.map((option) => {
                                                            return (
                                                              <Option
                                                                key={option.id}
                                                                value={option.id}
                                                              >
                                                                {option?.name +
                                                                  (option?.account_type?.name
                                                                    ? ' (' +
                                                                      option?.account_type?.name +
                                                                      ')'
                                                                    : '') +
                                                                  (option?.code
                                                                    ? ' - ' +
                                                                      option?.code.toString()
                                                                    : '') +
                                                                  (option?.sub_code
                                                                    ? '.' +
                                                                      option?.sub_code.toString()
                                                                    : '')}
                                                              </Option>
                                                            );
                                                          })}
                                                      </Select>
                                                    </div>
                                                    <div className="col-md-2 refund-column">
                                                      <Select
                                                        showSearch
                                                        style={{
                                                          width: '100%',
                                                          margin: '0'
                                                        }}
                                                        loading={loading}
                                                        disabled={
                                                          item?.type === 'default' ? true : false
                                                        }
                                                        value={
                                                          item?.type === 'default'
                                                            ? mainItem?.type == 2
                                                              ? item?.clientData?.name
                                                              : FullNameGetterWithoutChartAt(
                                                                  item
                                                                ) || item?.account_name
                                                            : item?.account_name
                                                            ? item?.account_name
                                                            : item?.name
                                                        }
                                                        onChange={(val) => {
                                                          handleClientChange(
                                                            val,
                                                            index,
                                                            mainItem?.payments,
                                                            mainIndex
                                                          );
                                                        }}
                                                      >
                                                        {clientData?.beneficiaries &&
                                                          clientData?.beneficiaries?.length > 0 &&
                                                          clientData?.beneficiaries.map(
                                                            (option) => {
                                                              return (
                                                                <Option
                                                                  key={option.id}
                                                                  value={option.id}
                                                                >
                                                                  {option?.account_name}
                                                                </Option>
                                                              );
                                                            }
                                                          )}
                                                      </Select>
                                                      <span className="text-danger">
                                                        {clientErrors[index]
                                                          ? clientErrors[index]
                                                          : ''}
                                                      </span>
                                                    </div>
                                                    <div className="col-md-2 refund-column">
                                                      <NumberFormat
                                                        value={
                                                          item?.amount == undefined
                                                            ? ''
                                                            : item?.amount
                                                            ? item?.amount
                                                            : ''
                                                        }
                                                        decimalSeparator="."
                                                        type="text"
                                                        className={item?.amount == undefined
                                                          ? 'error-payment-amount'
                                                          : Number(item?.amount) !== 0
                                                          ? ''
                                                          : 'error-payment-amount'}
                                                        placeholder={
                                                          item?.withdrawal_account_id
                                                            ? 'Enter Fees'
                                                            : 'Enter Amount'
                                                        }
                                                        thousandSeparator={false}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        onChange={(e) => {
                                                          handleAmount(
                                                            e,
                                                            index,
                                                            mainItem?.payments,
                                                            mainIndex
                                                          );
                                                        }}
                                                        // onBlur={(e) => handleBlurAmount(e, index)}
                                                        style={{
                                                          border: '1px solid #d9d9d9',
                                                          padding: '5px 10px',
                                                          fontSize: '14px',
                                                          width: '100%',
                                                          height: '30px',
                                                          background: '#FFF',
                                                          borderRadius: '30px'
                                                        }}
                                                      />
                                                      <span className="error">
                                                        {/* {amountErrors[index]
                                                          ? amountErrors[index]
                                                          : ''} */}
                                                          {item?.amount !== '' && Number(item?.amount) === 0
                                                          ? 'Amount must be greater than zero.'
                                                          : ''}
                                                      </span>

                                                      {/* <Form.Group>
                                                        <Form.Control
                                                          required
                                                          type="text"
                                                          placeholder={
                                                            item?.withdrawal_account_id
                                                              ? 'Enter Fees'
                                                              : 'Enter Amount'
                                                          }
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message:
                                                                'Please enter Amount'
                                                            },
                                                            {
                                                              pattern: new RegExp(/^\d*\.?\d*$/),
                                                              message: 'Value should be number'
                                                            }
                                                          ]}
                                                          value={
                                                            item?.amount == undefined
                                                              ? ''
                                                              : item?.amount
                                                              ? item?.amount
                                                              : ''
                                                          }
                                                          onChange={(e) => {
                                                            handleAmount(
                                                              e,
                                                              index,
                                                              mainItem?.payments,
                                                              mainIndex
                                                            );
                                                            handleBlurAmount(e, index);
                                                          }}
                                                          style={{
                                                            border: '1px solid #d9d9d9',
                                                            padding: '5px 10px',
                                                            fontSize: '14px',
                                                            width: '100%'
                                                          }}
                                                        />
                                                        <span className="text-danger">
                                                          {amountErrors[index]
                                                            ? amountErrors[index]
                                                            : ''}
                                                        </span>
                                                      </Form.Group> */}
                                                    </div>
                                                    <div className="col-md-2 refund-column">
                                                      <Form.Group>
                                                        <Form.Control
                                                          required
                                                          type="text"
                                                          placeholder="Enter Description"
                                                          maxLength={100}
                                                          onChange={(e) => {
                                                            handleDescription(
                                                              e,
                                                              index,
                                                              mainItem?.payments,
                                                              mainIndex
                                                            );
                                                            handleBlurDescription(e, index);
                                                          }}
                                                          value={
                                                            item?.description == undefined
                                                              ? ''
                                                              : item?.description
                                                          }
                                                          style={{
                                                            border: '1px solid #d9d9d9',
                                                            padding: '5px 10px',
                                                            fontSize: '14px',
                                                            width: '100%'
                                                          }}
                                                        />
                                                        <span className="error">
                                                          {descriptionErrors[index]
                                                            ? descriptionErrors[index]
                                                            : ''}
                                                        </span>
                                                      </Form.Group>
                                                    </div>
                                                    <div className="col-md-1 refund-column">
                                                      <Form.Group>
                                                        <InputMask
                                                          mask="999-999"
                                                          name="bsb"
                                                          value={item?.bsb ? item?.bsb : ''}
                                                          onChange={(e) => {
                                                            handleBSB(
                                                              e,
                                                              index,
                                                              mainItem?.payments,
                                                              mainIndex
                                                            );
                                                          }}
                                                          placeholder="123-456"
                                                          className="ant-input record-payment"
                                                        />
                                                      </Form.Group>
                                                    </div>
                                                    <div className="col-md-2 refund-column">
                                                      <Form.Group>
                                                        <Form.Control
                                                          required
                                                          type="text"
                                                          style={{
                                                            border: '1px solid #d9d9d9',
                                                            padding: '5px 10px',
                                                            fontSize: '14px',
                                                            width: '100%'
                                                          }}
                                                          onChange={(e) => {
                                                            handleAccount(
                                                              e,
                                                              index,
                                                              mainItem?.payments,
                                                              mainIndex
                                                            );
                                                            handleBlurAccount(e, index);
                                                          }}
                                                          value={
                                                            item?.account_number
                                                              ? item?.account_number
                                                              : ''
                                                          }
                                                        />
                                                        <span className="error">
                                                          {accountErrors[index]
                                                            ? accountErrors[index]
                                                            : ''}
                                                        </span>
                                                      </Form.Group>
                                                    </div>
                                                    <div className="col-md-1 receipt-column">
                                                      {index + 1 === mainItem?.payments?.length && (
                                                        <>
                                                          {beneficiariesArray?.length ===
                                                          beneficiarylength ? (
                                                            <></>
                                                          ) : (
                                                            <>
                                                              {/* <b>
                                                                <span
                                                                  className="reconcile-item-list-split"
                                                                  size="sm"
                                                                  title="Split"
                                                                  onClick={() => {
                                                                    hanldeAddNew(
                                                                      mainItem?.payments,
                                                                      mainIndex
                                                                    );
                                                                  }}
                                                                  disabled
                                                                >
                                                                  <img
                                                                    src={splitIcon}
                                                                    alt=""
                                                                    style={{ height: '16px' }}
                                                                  />
                                                                </span>
                                                              </b>
                                                              {mainItem?.payments?.length !== 1 && (
                                                                <>
                                                                  <span></span>
                                                                  &nbsp;&nbsp;/&nbsp;&nbsp;
                                                                </>
                                                              )} */}
                                                            </>
                                                          )}
                                                        </>
                                                      )}

                                                      {mainItem?.payments?.length !== 1 && (
                                                        <FontAwesomeIcon
                                                          icon={faTrash}
                                                          onClick={() => {
                                                            hanldeDelete(
                                                              index,
                                                              mainItem?.payments,
                                                              mainIndex
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              })
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                              </Container>
                            </div>
                          ))}
                      </div>
                    </Form>
                  </Spin>
                </div>
              </div>
              <div className="refund-card-content-btn-small" style={{ textAlign: 'right' }}>
                <Button
                  className="btn-small"
                  disabled={handlePostDisabled(blockData)}
                  onClick={() => {
                    handleClickPost(blockData);
                    // handlePost(finalData);
                  }}
                >
                  Post
                </Button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        className="mid-modal supplier-modal"
        id="supplier_modal"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Add beneficiaries</Modal.Header>
        <Modal.Body>
          <AddEditBeneficiary
            clientData={itemClientData?.clientData}
            onClose={handleClose}
            clientId={itemClientData?.id}
            blockData={blockData}
            itemPaymentIndex={itemPaymentIndex}
            setBlockData={setBlockData}
            getWithdrawalAccountBlockData={getWithdrawalAccountBlockData}
            getCustomerBlockDetail={getCustomerBlockDetail}
            defaultAccount={defaultAccount}
            itemJson={itemJson}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="mid-modal supplier-modal"
        id="supplier_modal"
        show={addSupplierModal}
        onHide={() => handleAddSupplierModal(itemBlockItem)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ marginBottom: '0px' }}>
          Select Supplier
        </Modal.Header>
        <Modal.Body>
          <SupplierBasedOnClient
            clientId={itemBlockItem?.id}
            onClose={() => handleAddSupplierModal(itemBlockItem)}
            clientData={itemBlockItem?.clientData}
            itemBlockItem={itemBlockItem}
            itemBlockIndex={itemBlockIndex}
            setBlockData={setBlockData}
            blockData={blockData}
            defaultAccount={defaultAccount}
            itemJson={itemJson}
          />
        </Modal.Body>
      </Modal>
      {showPostModal && (
        <PostConfirmationModal
          handlePost={handlePost}
          finalPostData={finalPostData}
          handleClosePostModal={handleClosePostModal}
          postLoading={postLoading}
        />
      )}
    </div>
  );
};

export default RecordPayment;
