import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import { getImportLogDetail } from './ImportLogsHelper';
import { FullNameGetterWithoutChartAt } from '../../helper/fullNameGetter';
import moment from 'moment';
import { maskTFN } from '../../config';
import {
  getDisplayBillingAddressForLogs,
  getDisplayShippingAddressForLogs
} from '../customer/CustomersHelper';

const ImportLogDetail = ({
  //data,
  importLogId,
  toggleShowModal
}) => {
  const [importLogDetail, setImportLogDetail] = useState(null);
  //   const [verifiedByUser, setVerifiedByUser] = useState(null);
  //   const [statementData, setStatementData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const importLogRes = await getImportLogDetail(importLogId);
      setImportLogDetail(importLogRes);

      setLoading(false);
    }
    fetchData();
  }, [importLogId]);

  const clientStatus = (id) => {
    if (id === 1) {
      return 'Prospect';
    } else {
      if (id === 2) {
        return 'Current';
      } else {
        return 'Non Current';
      }
    }
  };

  const clientType = (id) => {
    if (id === 1) {
      return 'Individual';
    } else {
      if (id === 2) {
        return 'Non Individual';
      } else {
        return 'N/A';
      }
    }
  };

  const Status = (id, description) => {
    if (id === 0 && description) {
      return `Client data not imported because ${description}`;
    } else {
      if (id === 1) {
        return 'Client Created';
      } else {
        return 'Client Updated';
      }
    }
  };

  return (
    <div>
      <Modal
        title=""
        centered
        open
        footer={null}
        width={1000}
        closable
        className="modal-body"
        onCancel={toggleShowModal}
      >
        {loading ? (
          <div className="centeralign spin-padding">
            <Spin />
          </div>
        ) : (
          <>
            <div className="modal-content-details">
              <div className="profile">
                <span>
                  {importLogDetail?.first_name
                    ? importLogDetail?.first_name?.charAt(0)
                    : importLogDetail?.last_name?.charAt(0)}
                </span>

                <h5>{FullNameGetterWithoutChartAt(importLogDetail)}</h5>
              </div>
              <div className="customer-account">
                <div className="action-block">
                  <span className="import-status">
                    <strong>Import Date:</strong>
                    {importLogDetail?.created_at
                      ? moment(importLogDetail?.created_at, 'DD-MM-YYYY HH:mm:ss').format(
                          'DD-MM-YYYY'
                        )
                      : 'N/A'}
                    <br />
                    <strong>Note:</strong>
                    {importLogDetail?.status == null || importLogDetail?.status == ''
                      ? 'N/A'
                      : Status(parseInt(importLogDetail?.status), importLogDetail?.description)}
                  </span>
                </div>
              </div>
            </div>

            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <div className="modal-content-left">
                  <p>
                    <strong>First Name:</strong>{' '}
                    {importLogDetail?.first_name ? importLogDetail?.first_name : 'N/A'}
                  </p>
                  <p>
                    <strong>Last Name:</strong>{' '}
                    {importLogDetail?.last_name ? importLogDetail?.last_name : 'N/A'}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{' '}
                    {importLogDetail?.phone == null || importLogDetail?.phone == ''
                      ? 'N/A'
                      : importLogDetail?.phone}
                  </p>
                  <p>
                    <strong>Mobile Number:</strong>
                    {importLogDetail?.mobile == null || importLogDetail?.mobile == ''
                      ? 'N/A'
                      : importLogDetail?.mobile}
                  </p>

                  <p>
                    <strong>Postal Address:</strong>{' '}
                    {getDisplayBillingAddressForLogs(importLogDetail)}
                  </p>

                  <p>
                    <strong>Street Address:</strong>{' '}
                    {getDisplayShippingAddressForLogs(importLogDetail)}
                  </p>
                </div>
                <div className="modal-content-right">
                  <p>
                    <strong>Business:</strong>{' '}
                    {importLogDetail?.bussiness_name == null ||
                    importLogDetail?.bussiness_name == ''
                      ? 'N/A'
                      : importLogDetail?.bussiness_name}
                    <i>
                      <strong>ABN:</strong>{' '}
                      {importLogDetail?.customer_bank?.abn == null ||
                      importLogDetail?.customer_bank?.abn == ''
                        ? 'N/A'
                        : importLogDetail?.customer_bank?.abn}
                    </i>
                  </p>
                  <p>
                    <strong>Client Type:</strong>{' '}
                    {importLogDetail?.customer_type == null || importLogDetail?.customer_type == ''
                      ? 'N/A'
                      : clientType(parseInt(importLogDetail?.customer_type))}
                    <i>
                      <strong>CRN:</strong>{' '}
                      {importLogDetail?.crn == null || importLogDetail?.crn == ''
                        ? 'N/A'
                        : importLogDetail?.crn}
                    </i>
                  </p>
                  <p>
                    <strong>Email Address:</strong>{' '}
                    {importLogDetail?.email ? importLogDetail?.email : ''}
                  </p>
                  <p>
                    <strong>Import Date:</strong>
                    {importLogDetail?.created_at
                      ? moment(importLogDetail?.created_at, 'DD-MM-YYYY HH:mm:ss').format(
                          'DD-MM-YYYY'
                        )
                      : 'N/A'}
                  </p>

                  <p>
                    <strong>Type:</strong>{' '}
                    {importLogDetail?.client_status == null || importLogDetail?.client_status == ''
                      ? 'N/A'
                      : clientStatus(parseInt(importLogDetail?.client_status))}
                  </p>

                  <p>
                    <strong>TFN:</strong>{' '}
                    {importLogDetail?.tfn == null || importLogDetail?.tfn == ''
                      ? 'N/A'
                      : maskTFN(importLogDetail?.tfn)}
                  </p>
                </div>
              </div>
            </div>

            <div className="modal-content">
              <h3>Bank Details</h3>
              <div className="modal-content-details">
                <div className="modal-content-left">
                  <p>
                    <strong>Account Name:</strong>{' '}
                    {importLogDetail?.account_name == null || importLogDetail?.account_name == ''
                      ? 'N/A'
                      : importLogDetail?.account_name}
                  </p>
                  <p>
                    <strong>Account Number:</strong>{' '}
                    {importLogDetail?.account_number == null ||
                    importLogDetail?.account_number == ''
                      ? 'N/A'
                      // : 'xxxx xxxx ' + importLogDetail?.account_number?.toString().slice(-4)}
                      : importLogDetail?.account_number?.toString()}
                  </p>
                </div>
                <div className="modal-content-right">
                  <p>&nbsp;</p>
                  <p>
                    <strong>BSB:</strong>{' '}
                    {importLogDetail?.bsb == null || importLogDetail?.bsb == ''
                      ? 'N/A'
                      : importLogDetail?.bsb}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default ImportLogDetail;
