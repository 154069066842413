import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function CancelButton() {
  const navigate = useNavigate();
  return (
    <Button className="reset" onClick={() => navigate(-1)}>
      Cancel
    </Button>
  );
}

export default CancelButton;
