import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Input } from 'antd';
import Form from '@component/Form';
//import Loginimg from '@src/assets/images/Loginimg.js';
import { API, post, get } from '@src/config.js';
import Sitelogo from '@component/Header/Logo.js';
import Authentication from '@component/Authentication';
import { useSearchParams } from 'react-router-dom';
import './auth.module.css';
import { IV, SECRETKEY } from '../../config';
import { FullNameGetterWithoutChartAt } from '../../helper/fullNameGetter';
import { SetCookie } from '../../APIConfig/Interceptor';
import { checkRole } from '../../helper/utils';

const Login = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const tfa = localStorage.getItem('myna-auth-tfa');
  const hasToken = localStorage.getItem('myna-auth-token');
  const role = localStorage.getItem('role');
  // const roleName = localStorage.getItem('role_name');
  const roleType = localStorage.getItem('role_type');
  const [message, setMessage] = useState({ error: '' });
  const [loading, setLoading] = useState(false);
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState('password');
  const [authentication] = useState(false);
  const [searchParams] = useSearchParams();

  var CryptoJS = require('crypto-js');
  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);
  var currentdate = new Date();

  const handleFinish = async (values) => {
    try {
      setMessage({});
      if (values.email.trim() == '' || values.password.trim() == '') {
        setMessage({
          error: 'Enter valid email and password'
        });
      } else {
        setLoading(true);
        const encPassword = CryptoJS.AES.encrypt(values?.password, fkey, {
          iv: fiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
        const finalEncryptedPassword = encPassword.ciphertext.toString(CryptoJS.enc.Base64);
        const finalValue = { ...values, password: finalEncryptedPassword };

        const { data } = await post(API.LOGIN, finalValue);

        if (data.status === 200 && data.access_token !== undefined) {
          localStorage.setItem('role_name', data['role_name']);
          localStorage.setItem('role_type', data['role_type']);
          const token = data.access_token;
          const refreshToken = data.refresh_token;
          props.authenticate(token, values.email, data['2fa'], refreshToken);
          setLoading(false);
          if (data['2fa'] === -1) {
            const userDetails = await get(API.GET_USER_PROFILE);
            const { first_name, profile_pic } = userDetails.data;
            localStorage.setItem('Name', first_name);
            localStorage.setItem('FullName', FullNameGetterWithoutChartAt(userDetails.data));
            localStorage.setItem('ProfilePhoto', profile_pic);
            localStorage.setItem('role', data.role);
            localStorage.setItem('myna-auth-login-time', currentdate.getTime());
            SetCookie('_subscription', 1);
            // if (data['role_name'] != 'Admin') {
            if (!checkRole(data['role_type'])) {
              try {
                let subdata = await get(API.GET_SUBSCRIPTION);
                if (subdata) {
                  await SetCookie('_subscription', 1);
                }
              } catch (error) {
                SetCookie('_subscription', 0);
                window.location.href = '/settings?tab=subscription&childTab=more-packages';
                return;
              }
            }
            // if (data['role'] === 1) {
            // if (checkRole(data['role_name'])) {
            if (checkRole(data['role_type'])) {
              window.location.href = '/admin/dashboard';
            } else {
              window.location.href = '/dashboard?field=name&type=asc&filter=weekly';
              // navigate('/dashboard?field=name&type=asc&filter=weekly', { replace: true });
            }
          } else {
            if (data['2fa'] !== null && data['2fa'] !== 0) {
              localStorage.setItem('role_name', data['role_name']);
              localStorage.setItem('role_type', data['role_type']);
              navigate('/2fa', { replace: true });
            } else {
              navigate('/2fa/register', { replace: true });
            }
          }
        } else if (!data.access_token) {
          setMessage({
            error: 'Username or password not recognised, please try again.'
          });
          setLoading(false);
        } else {
          setMessage({ error: data.errors?.myna_error[0] });
          setLoading(false);
        }
        setLoading(false);
      }
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      setMessage({
        error: errorMessage || 'There is some issue with request, please try after some time.'
      });
      setLoading(false);
    }
  };

  const Eye = () => {
    if (password == 'password') {
      setpassword('text');
      seteye(false);
    } else {
      setpassword('password');
      seteye(true);
    }
  };
  const loadDynamicScript = (callback) => {
    const existingScript = document.getElementById('xero');

    // if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://edge.xero.com/platform/sso/xero-sso.js'; // URL for the third-party library being loaded.
    script.id = 'xero'; // e.g., googleMaps or stripe
    script.defer = 'defer';
    script.async = 'async';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
    // }
    if (existingScript && callback) callback();
    return () => {
      if (existingScript && callback) {
        existingScript.remove();
      }
    };
  };

  useEffect(() => {
    loadDynamicScript();
  }, []);

  useEffect(async () => {
    if (hasToken && role) {
      // if (role != 'Admin') {
      if (!checkRole(roleType)) {
        try {
          const subdata = await get(API.GET_SUBSCRIPTION);
          if (subdata?.data) {
            await SetCookie('_subscription', 1);
          }
        } catch (error) {
          SetCookie('_subscription', 0);
          window.location.href = '/settings?tab=subscription&childTab=more-packages';
          return;
        }
      }
      // if (role === 1) {
      if (checkRole(roleType)) {
        window.location.href = '/admin/dashboard';
      } else {
        window.location.href = '/dashboard?field=name&type=asc&filter=weekly';
      }
    }
    if (searchParams.get('code')) {
      let code = { code: searchParams.get('code') };
      const { data } = await post(API.XEROLOGIN, code);
      if (data.status === 200 && data.access_token !== undefined) {
        const token = data.access_token;
        props.authenticate(token, '', data['2fa']);
        const userDetails = await get(API.GET_USER_PROFILE);

        setLoading(false);
        if (data['2fa'] === -1) {
          // const userDetails = await get(API.GET_USER_PROFILE);
          const { first_name, profile_pic, email } = userDetails.data;
          localStorage.setItem('myna-auth-email', email);
          localStorage.setItem('Name', first_name);
          localStorage.setItem('FullName', FullNameGetterWithoutChartAt(userDetails.data));
          localStorage.setItem('ProfilePhoto', profile_pic);
          localStorage.setItem('role', data.role);
          localStorage.setItem('role_name', data['role_name']);
          localStorage.setItem('role_type', data['role_type']);

          // if (data['role'] === 1) {
          // if (checkRole(data['role_name'])) {
          if (checkRole(data['role_type'])) {
            window.location.href = '/admin/dashboard';
          } else {
            window.location.href = '/dashboard?field=name&type=asc&filter=weekly';
          }
        } else {
          if (data['2fa'] !== null && data['2fa'] !== 0) {
            localStorage.setItem('role_name', data['role_name']);
            localStorage.setItem('role_type', data['role_type']);
            navigate('/2fa', { replace: true });
          } else {
            navigate('/2fa/register', { replace: true });
          }
        }
      } else if (!data.access_token) {
        setMessage({
          error: 'Username or password not recognised, please try again.'
        });
        setLoading(false);
      } else {
        setMessage({ error: data.errors?.myna_error[0] });
        setLoading(false);
      }
      setLoading(false);
    }
  }, []);

  return (
    <>
      <div className="login-pages">
        {/* {hasToken && tfa === "2" ? <Authentication /> : <></>} */}
        {hasToken && tfa === '2' ? (
          <Navigate to={'/dashboard?field=name&type=asc&filter=weekly'} />
        ) : (
          <></>
        )}
        {!authentication ? (
          <Row>
            <Col>
              <div className="login-img white-site">
                {/* <Loginimg /> */}
                <Sitelogo />
              </div>
              <div
                className="footer-link"
                style={{ maxWidth: '100%', color: '#CCC', padding: '0 30px' }}
              >
                <p style={{ fontSize: '12px' }}>
                  TrustEasy acknowledges the Traditional Custodians of country throughout Australia
                  and their connections to land, sea and community. We pay our respect to their
                  Elders past and present and extend that respect to all Aboriginal and Torres
                  Strait Islander peoples today.
                  <br />
                </p>
              </div>
            </Col>
            <Col className="login-form-block trans-login" style={{ flex: '1 0' }}>
              <div className="login-form">
                {/* <Sitelogo /> */}
                {message.error && <p className="text-danger">{message.error}</p>}
                <Form form={form} name="loginForm" onFinish={handleFinish}>
                  <Form.Item
                    name="email"
                    // validateTrigger={['submit', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Email'
                      },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      }
                    ]}
                  >
                    <Input placeholder="Email Address" bordered={false} />
                  </Form.Item>
                  <div className="password">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Password'
                        }
                      ]}
                    >
                      <Input
                        placeholder="Password"
                        bordered={false}
                        type={password}
                        name="password"
                      />
                    </Form.Item>
                    <i
                      onClick={Eye}
                      className={`icon ${eye ? 'icon-eye-close' : 'icon-eye-open'}`}
                    ></i>
                  </div>

                  <div className="form-group ant-form-item login-btn">
                    <Button
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      size="large"
                      loading={loading}
                    >
                      Login
                    </Button>
                    <div className="col-12 topgap btnarea justify-content-center or-txt">OR</div>
                    <div className="col-12 topgap btnarea justify-content-center">
                      <span
                        data-xero-sso
                        data-label="Sign In with Xero"
                        data-theme="light"
                        data-size="large"
                        data-border-radius="20"
                      ></span>
                    </div>
                  </div>
                </Form>
                <p>
                  <Button
                    type="button"
                    onClick={() => navigate('/forgot-password', { replace: true })}
                    className="linkButton"
                  >
                    Forgot Password?
                  </Button>
                </p>
                <p style={{ fontSize: '14px', marginBottom: '2px' }}>
                  Not registered yet?{' '}
                  <a
                    href="/firm/signup"
                    rel="noreferrer"
                    className="ant-btn ant-btn-button linkButton"
                    style={{ fontSize: '12px', padding: '0px' }}
                    // target="_blank"
                  >
                    Signup Now
                  </a>
                </p>
              </div>
              <div className="footer-link">
                <p style={{ fontSize: '12px', marginBottom: '2px' }}>
                  Trouble Logging In?{' '}
                  <a
                    href="https://trusteasy.com.au/#contact"
                    rel="noreferrer"
                    style={{ fontSize: '12px', textDecoration: 'underline' }}
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </p>
                <p style={{ fontSize: '12px' }}>
                  By selecting Login, you agree to our{' '}
                  <a
                    href="https://trusteasy.com.au/terms-conditions/"
                    rel="noreferrer"
                    style={{ fontSize: '12px', textDecoration: 'underline' }}
                    target="_blank"
                  >
                    Terms of Use{' '}
                  </a>
                  and have read and acknowledge our{' '}
                  <a
                    href="https://trusteasy.com.au/privacy-policy/"
                    rel="noreferrer"
                    style={{ fontSize: '12px', textDecoration: 'underline' }}
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        ) : (
          <Authentication />
        )}
      </div>
    </>
  );
};
export default Login;
