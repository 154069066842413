import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { toast } from 'react-toastify';
import { API, deleteCall, fileUpload } from '../../config.js';

const CustomerRemove = ({ customerId, toggleDeleteModal, handleDelete, duplicateDelete }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleUserDelete = async () => {
    try {
      setIsLoading(true);
      if (duplicateDelete) {
        const formData = new FormData();
        formData.append(`ids[0]`, customerId);
        const { data } = await fileUpload(`${API.DELETE_DUPLICATE_CUSTOMER}`, formData);
        setIsLoading(false);
        toast.success(data.message);
        handleDelete();
        toggleDeleteModal();
      } else {
        const { data } = await deleteCall(`${API.GET_CUSTOMERLIST}/${customerId}`);
        setIsLoading(false);

        toast.success(data.message);
        handleDelete();
        toggleDeleteModal();
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage('There is some issue with request, please try after some time.');
    }
  };
  return (
    <div>
      <Modal
        className="remove-popup"
        width={500}
        closable
        centered
        open
        footer={null}
        onCancel={toggleDeleteModal}
      >
        <div className="remove-content spin-body-relative">
          <p>Are your sure you want to delete this client?</p>
          <div className="modal-action">
            <Button className="cancel" onClick={toggleDeleteModal}>
              Cancel
            </Button>
            <Button onClick={handleUserDelete}>OK</Button>
          </div>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
          {isLoading && (
            <div className="top-modal-spin-center">
              <Spin />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CustomerRemove;
