import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import EditAmountModal from './EditAmountModal';
// import CustomerRemove from '../customer/CustomerRemove';

const AmountEditActionButton = ({ data, fetchATORefundList }) => {
  const [showView, setShowView] = useState(false);
  const toggleShowModal = () => {
    setShowView(!showView);
  };

  return (
    <span>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => {
          toggleShowModal(true);
        }}
      ></FontAwesomeIcon>

      {showView && (
        <EditAmountModal
          data={data}
          fetchATORefundList={fetchATORefundList}
          toggleShowModal={toggleShowModal}
        />
      )}
    </span>
  );
};

export default AmountEditActionButton;
