/* eslint-disable no-nested-ternary */
import moment from 'moment';
import React from 'react';
import { API, get } from '../../config';
import { NegativeAmountConverter } from '../../helper/Conversions';
// import { FullNameGetterWithoutChartAt } from '../../helper/fullNameGetter';
import ReceiptActionButtons from './ReceiptActionButton';

export const getReceiptDetail = async (receiptId) => {
  try {
    const { data } = await get(`${API.RECIEPT}/` + receiptId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const getReceiptsColumn = (fetchreceiptsList) => {
  return [
    {
      title: 'Transaction Date',
      // className: 'centeralign',
      width: '10%',
      render: (record) => (
        <span>
          {record?.transaction_date
            ? moment(record?.transaction_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
            : ''}
        </span>
      )
    },
    
    {
      title: 'Client Name',
      width: '22%',
      render: (record) => (
        <span>
          {record?.customer
            ? record?.customer?.first_name
              ? record?.customer?.first_name + ' ' + record?.customer?.last_name
              : record?.customer?.last_name
            : record?.supplier
            ? record?.supplier?.name
            : '-'}
        </span>
      )
    },
    // {
    //   title: 'Account',
    //   width: '25%',
    //   render: (record) => (
    //     <span>
    //       {record?.chart_account?.name +
    //         (record?.chart_account?.account_type?.name
    //           ? ' (' + record?.chart_account?.account_type?.name + ')'
    //           : '') +
    //         (record?.chart_account?.code ? ' - ' + record?.chart_account?.code.toString() : '') +
    //         (record?.chart_account?.sub_code
    //           ? '.' + record?.chart_account?.sub_code.toString()
    //           : '')}
    //     </span>
    //   )
    // },
    {
      title: 'Amount',
      width: '10%',
      render: (record) => (
        <span>{record?.amount ? NegativeAmountConverter(record?.amount) : '$0.00'}</span>
      )
    },
    {
      title: 'Note',
      width: '23%',
      render: (record) => <span>{record?.note ? record?.note : ''}</span>
    },
    {
      title: 'Action',
      width: '10%',
      render: (record) => (
        <ReceiptActionButtons record={record} fetchreceiptsList={fetchreceiptsList} />
      )
    }
  ];
};
