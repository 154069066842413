import React, { useState } from 'react';
import { Select, Table } from 'antd';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import useOfficeList from './useOfficeList';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import AddEditOffice from './AddEditOffice.js';
import { API, defaultPaginationLimit, deleteCall } from '../../../config';
import '../../../App.css';
import '../../../assets/css/style.css';
import '../Task/tasksetting.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { maskPostalCode } from '../../../helper/fullNameGetter.js';

const OfficeList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [show, setShow] = useState(false);
  const [office, setOffice] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const { fetchOffices, offices, isDataLoading, pagingData, onPageChange } = useOfficeList(limit);
  const handleClose = () => {
    setShow(false);
    setOffice(null);
    fetchOffices();
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
    setOffice(null);
  };

  const officeTableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
      className: 'centeralign',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Suburb',
      dataIndex: 'office_suburb',
      sorter: (a, b) => a.office_suburb.localeCompare(b.office_suburb) //a.office_suburb - b.office_suburb,
    },
    {
      title: 'State',
      dataIndex: 'office_state',
      sorter: (a, b) => a.office_state.localeCompare(b.office_state)
    },
    {
      title: 'Street',
      dataIndex: 'street',
      sorter: (a, b) => a.street.localeCompare(b.street)
    },
    {
      title: 'Post Code',
      dataIndex: 'postal_code',
      key: 'postal_code',
      sorter: (a, b) => a.postal_code - b.postal_code,
      render: (record) => <>{record ? maskPostalCode(Number(record)) : '-'}</>
    },
    {
      title: 'Action',
      width: '10%',
      className: 'centeralign',
      render: (record) => {
        return (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              onClick={() => {
                setOffice(record);
                setShow(true);
              }}
            />
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <FontAwesomeIcon
              icon={faTrash}
              size="1x"
              onClick={() => {
                setOffice(record);
                setShowDelete(true);
              }}
            />
          </>
        );
      }
    }
  ];

  const deleteOffice = () => {
    try {
      deleteCall(API.DELETE_OFFICE + `${office.id}`).then((response) => {
        handleDeleteClose();
        fetchOffices();
        toast.success(response.data.message);
      });
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/settings?tab=office&page=1&limit=${value}`, {
      replace: true
    });
    fetchOffices(value);
  };
  return (
    <div className="cms-page">
      <div className="content-details">
        <div className="table-top-btn">
          <div className="table-btn">
            <Button variant="primary" onClick={() => setShow(true)}>
              Add New
            </Button>
          </div>
        </div>
        <Table
          className="task-table"
          columns={officeTableColumns}
          dataSource={offices}
          loading={isDataLoading}
          pagination={{
            hideOnSinglePage: true,
            defaultCurrent: 1,
            showQuickJumper: true,
            defaultPageSize: pagingData.pageSize,
            onChange: (page) => onPageChange(page, limit),
            itemRender: PaginationItemRenderer,
            ...pagingData,
            pageSize: limit
          }}
        />
        {offices?.length > 0 && (
          <div className="mt-2">
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={limit}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          </div>
        )}
        <Modal
          className="small-modal"
          show={show}
          onHide={handleClose}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>{office ? 'Edit' : 'Add'} Office</Modal.Header>
          <Modal.Body>
            <AddEditOffice office={office} onClose={handleClose} />
            <Button
              variant="secondary"
              className="reset"
              onClick={() => {
                setShow(false);
                setOffice(null);
              }}
            >
              Cancel
            </Button>
          </Modal.Body>
        </Modal>

        <Modal
          className="small-modal remove-popup"
          show={showDelete}
          onHide={handleDeleteClose}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton={handleDeleteClose}></Modal.Header>
          <Modal.Body>
            <div className="remove-content">
              <p>Are your sure you want to delete this Office?</p>
              <div className="modal-action">
                <Button
                  className="cancel"
                  onClick={() => {
                    setShowDelete(false);
                    setOffice(null);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={deleteOffice}>OK</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default OfficeList;
