import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Table, Modal, Spin, DatePicker, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, defaultPaginationLimit, fileUpload, get } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import { ClientReportsColumns } from './reportHelper';
import { toast } from 'react-toastify';
import axios from 'axios';
import DialogModal from '../../components/Header/DialogModal';
import { helpCenterArray } from '../HelpCenter';
// import Workinprogrees from '../../components/Workinprogrees';

const { RangePicker } = DatePicker;
// const dateFormat = 'DD-MM-YYYY';
const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

const getDefaultDates = (from, to) => {
  if (from && to) {
    return { start_date: from, end_date: to };
  } else {
    const previousYear =
      new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
    const previousYearDate = `01-04-${previousYear}`;
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    const year = currentTime.getFullYear();
    const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
      Number(month),
      2
    )}-${year}`;
    return { start_date: previousYearDate, end_date: currentDate };
  }
};

const ClientReports = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const from = query.get('from');
  const to = query.get('to');
  const search = query.get('search');
  const [searchParam, setSearchParam] = useState(search || '');
  const [clientsReportData, setClientsReportData] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const [exportLoading, setExportLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(() => getDefaultDates(from, to));
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const currentPageIds =
    clientsReportData && clientsReportData?.data?.length > 0
      ? clientsReportData?.data?.map((item) => {
          return item?.id;
        })
      : '';

  const getClientsReportData = useCallback(
    async (prevDate, currDate, selectedCustomerList, currentPageValue, limit) => {
      try {
        setIsLoading(true);
        setSelectedCustomer([]);
        const { data } = await get(
          `${API.CUSTOMER_REPORT}?page=${
            currentPageValue ? currentPageValue : pagingData.current
          }&start_date=${prevDate}&end_date=${currDate}&search=${searchParam}&limit=${
            limit ? limit : query.get('limit') || defaultPaginationLimit
          }`
        );
        setIsLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        setClientsReportData(data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setClientsReportData({});
        setIsLoading(false);
        setPagingData({ current: currentPage || 1 });
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    const { start_date, end_date } = dateFilter;
    getClientsReportData(start_date, end_date);
  }, [getClientsReportData]);

  useEffect(() => {
    if (from && to) {
      setDateFilter({
        start_date: from,
        end_date: to
      });
    }
  }, [from, to]);

  const onPageChange = (page, limit) => {
    setPagingData({ ...pagingData, current: page });
    const { start_date, end_date } = dateFilter;
    navigate(`/clients-report?page=${page}&from=${start_date}&to=${end_date}&limit=${limit}`, {
      replace: true
    });
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilter({
        start_date: startDate,
        end_date: endDate
      });
      navigate(`/clients-report?page=1&from=${startDate}&to=${endDate}`, {
        replace: true
      });
    } else {
      setDateFilter({
        start_date: '',
        end_date: ''
      });
    }
  };

  const onFilterOk = () => {
    const { start_date, end_date } = dateFilter;
    getClientsReportData(start_date, end_date, selectedCustomer);
    setDatePickerVisible(false);
    // }
  };

  const handleExportReport = async () => {
    setExportLoading(true);
    try {
      const { start_date, end_date } = dateFilter;
      const formData = new FormData();
      selectedRowIds?.forEach((id) => {
        if (id) {
          formData.append('customer_ids[]', id);
        }
      });
      formData.append('client_report', 1);

      const { data } = await fileUpload(
        `${API.EXPORT_CUSTOMER_TRANSACTION_REPORT}?from=${start_date}&to=${end_date}`,
        formData,
        {}
      );
      const { export_statement_path } = data.data;
      if (export_statement_path) {
        const response = await axios.get(`${export_statement_path}`, {
          responseType: 'blob'
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var file = export_statement_path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'client_transaction_report.pdf');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
      setExportLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowIds,
    onSelect: (record, selected) => {
      if (selected) {
        setSelectedRowIds((keys) => [...keys, record.key]);
      } else {
        setSelectedRowIds((keys) => {
          const index = keys.indexOf(record.key);
          return [...keys.slice(0, index), ...keys.slice(index + 1)];
        });
      }
    },
    // onChange: (selectedRowKeys) => {
    //   setSelectedRowIds([...selectedRowKeys]);
    // },
    onSelectAll: (record) => {
      if (record === true) {
        const allSelectedIds = [...selectedRowIds, ...currentPageIds];
        const finalIds = allSelectedIds.filter(
          (item, index) => allSelectedIds.indexOf(item) === index
        );

        setSelectedRowIds(finalIds);
      } else {
        const removeableIds = new Set(currentPageIds);
        setSelectedRowIds(selectedRowIds.filter((x) => !removeableIds.has(x)));
      }
    }
  };

  const onSearchChange = (e, start_date, end_date) => {
    const value = e;
    // const { start_date, end_date } = dateFilter;
    setSearchParam(value);
    setPagingData({ ...pagingData, current: 1 });
    navigate(`/clients-report?page=1&from=${start_date}&to=${end_date}&search=${value}`, {
      replace: true
    });
  };

  const handleChange = async (value) => {
    setLimit(value);
    const { start_date, end_date } = dateFilter;
    navigate(`/clients-report?page=1&from=${start_date}&to=${end_date}&limit=${value}`, {
      replace: true
    });
    getClientsReportData(start_date, end_date, selectedCustomer, 1, value);
  };

  const debouncedSave = useCallback(
    debounce((nextValue, start_date, end_date) => {
      onSearchChange(nextValue, start_date, end_date);
    }, 300),
    []
  );

  const handleSearchChange = (e, start_date, end_date) => {
    debouncedSave(e.target.value, start_date, end_date);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Client&apos;s Statement/GL{' '}
            <DialogModal
              title="Dashboard"
              p1={helpCenterArray[26].name}
              p2={helpCenterArray[26].text}
            />
          </h1>
          <div className="content-details">
            {/* <Workinprogrees
              text={"We are enhancing this feature, kindly don't produce any report this week."}
            /> */}
            <Spin spinning={isLoading}>
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={(e) =>
                        handleSearchChange(e, dateFilter?.start_date, dateFilter?.end_date)
                      }
                      type="text"
                      value={searchParam}
                      placeholder="Search Clients"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                <div className="table-btn">
                  <Button
                    onClick={() => handleExportReport()}
                    className={exportLoading ? 'disabled' : ''}
                    type="print"
                  >
                    {exportLoading ? 'Loading…' : 'Export'}
                  </Button>
                </div>
              </div>
              <div className="summary-filter" onClick={() => setDatePickerVisible(true)}>
                {dateFilter.start_date &&
                  'start:' + dateFilter.start_date + ' - End: ' + dateFilter.end_date}
                <FontAwesomeIcon icon={faCalendarAlt} size="1x" />
              </div>
              <Table
                rowKey={(record) => record.id}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection
                }}
                columns={ClientReportsColumns}
                dataSource={clientsReportData?.data || []}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: pagingData.pageSize,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page, limit),
                  itemRender: PaginationItemRenderer,
                  pageSize: limit,
                  ...pagingData
                }}
              />
              {clientsReportData?.data?.length > 0 && (
                <div className="col-md-12">
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              )}
            </Spin>
          </div>
          <Footer />
        </div>
        {datePickerVisible && (
          <Modal
            width={500}
            className="popup-without-header"
            closable
            centered
            open
            onOk={onFilterOk}
            onCancel={() => setDatePickerVisible(false)}
          >
            <h4>Select Start and End Date</h4>
            <RangePicker
              defaultValue={[
                dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
                dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
              ]}
              disabledDate={disabledDate}
              format={dateFormat}
              onChange={handleDateChange}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};
export default ClientReports;
