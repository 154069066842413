/* eslint-disable no-nested-ternary */
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../../config.js';

const preocessAdminUserList = (data) => {
  return data.map((adminUser) => {
    return {
      ...adminUser,
      roleName: adminUser?.role.name
    };
  });
};

const useAdminUserList = (currentPage, search) => {
  const navigate = useNavigate();
  const [adminUsers, setAdminUsers] = useState([]);
  const query = new URLSearchParams(location.search);
  const [searchParam, setSearchParam] = useState(search || '');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchAdminUsers = useCallback(
    async (page, limit) => {
      try {
        setIsDataLoading(true);
        const { data } = await get(
          `${API.GET_ADMIN_USER_LIST}?search=${searchParam}&page=${
            page || pagingData.current
          }&limit=${
            limit ? limit : query.get('limit') ? query.get('limit') : defaultPaginationLimit
          }`
        );
        setIsDataLoading(false);
        const { total, current_page, per_page, from, to } = data.data;

        const adminUserData = preocessAdminUserList(data.data.data);

        setAdminUsers(adminUserData);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setAdminUsers([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    fetchAdminUsers();
  }, [fetchAdminUsers]);

  return {
    fetchAdminUsers,
    adminUsers,
    searchParam,
    isDataLoading,
    pagingData,
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/admin/users?search=${value}&page=1`, {
        replace: true
      });
    },
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/admin/users?search=${searchParam}&page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default useAdminUserList;
