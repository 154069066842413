var CryptoJS = require('crypto-js');
var moment = require('moment');

const key = '60fu0TX0heuBb0oLE5HnN7Mo72SFKrb70cLcdpmDYFU1WdPU';

const generateUUID = (length = 16, options = { numericOnly: false }) => {
  let text = '';
  const possible =
    options && options.numericOnly
      ? '0123456789'
      : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, key).toString();
};

const compare = (encryptedText, text) => {
  var decrypted = CryptoJS.AES.decrypt(encryptedText, key).toString(CryptoJS.enc.Utf8);
  return text == decrypted;
};

const decrypt = (encryptedText) => {
  return CryptoJS.AES.decrypt(encryptedText, key).toString(CryptoJS.enc.Utf8);
};

const calculateDayDifference = (date1, date2) => {
  var dateParts = date1.split('/');
  date1 = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

  var date2Parts = date2.split('/');
  date2 = new Date(+date2Parts[2], date2Parts[1] - 1, +date2Parts[0]);

  //calculate time difference
  var time_difference = date2.getTime() - date1.getTime();

  //calculate days difference by dividing total milliseconds in a day
  var days_difference = time_difference / (1000 * 60 * 60 * 24);

  return days_difference;
};

const RoleTypeArray = ['Admin'];

const checkRole = (roleType) => {
  if (RoleTypeArray.find((item) => item === roleType)) {
    return true;
  } else {
    return false;
  }
};

const convertedDate = (inputDate) => {
  return moment(inputDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY hh:mm A');
};

module.exports = {
  generateUUID,
  encrypt,
  decrypt,
  compare,
  calculateDayDifference,
  checkRole,
  convertedDate
};
