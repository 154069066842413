import React, { useState } from "react";
import { Button, Modal, Spin, Tooltip } from "antd";
import { toast } from "react-toastify";
import { API, get } from "../../config.js";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ABARefresh = ({id, handleRefreshButton, combine = false}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleShowModal = () => {
    setShowModal(!showModal)
  }

  const handleRefresh = async () => {
    try {
      setLoading(true);
      const { data } = await get(`${combine ? API.GENERATE_ABA_MERGED_FEES : API.REGENERATE_ABA}/${id}`);
      toast.success(data.message);
      toggleShowModal();
      setLoading(false);
      handleRefreshButton();
    } catch (e) {
      toast.error(
        "There is some issue with request, please try after some time."
      );
      setLoading(false);
    }
  };
  return (
    <div>
      <Tooltip placement="bottomLeft" title={combine ? "Regenerate Combined Fees ABA" : "Regenerate ABA"}>
        <FontAwesomeIcon
          icon={faRefresh}
          size="1x"
          onClick={() => {
            toggleShowModal()
          }}
        />
      </Tooltip>
      {showModal &&
        <Modal
          className="remove-popup"
          width={500}
          closable
          centered
          open={showModal}
          footer={null}
          onCancel={toggleShowModal}
        >
          <div className="remove-content spin-body-relative">
            <p>Are your sure you want to regenerate the {combine ? "combined fees .ABA File" : ".ABA File"}? It will change the payment date to today&apos;s date; are you ok with that?</p>
            <div className="modal-action">
              <Button disabled={loading} className="cancel" onClick={() => toggleShowModal()}>
                Cancel
              </Button>
              <Button disabled={loading} onClick={handleRefresh}>OK</Button>
            </div>
          </div>
          {loading &&
            <div className="modal-spin-center">
              <Spin />
            </div>
          }
        </Modal>
      }
    </div>
  );
};

export default ABARefresh;
