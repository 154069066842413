import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, get } from '../../config.js';

const useReceiptsList = (currentPage, search, limit) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParam, setSearchParam] = useState(search || '');
  const query = new URLSearchParams(location.search);
  const [receiptsList, setReceiptsList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchreceiptsList = useCallback(
    async (limit, page) => {
      try {
        setIsDataLoading(true);
        const { data } = await get(
          `${API.GET_RECEIPTS}?page=${page || pagingData.current}&limit=${
            limit ?? query.get('limit')
          }&search=${searchParam ? searchParam : ''}`
        );
        const { total, current_page, per_page, from, to } = data.data;
        setReceiptsList(data.data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
        setIsDataLoading(false);
      } catch (e) {
        setReceiptsList([]);
        setIsDataLoading(false);
        setPagingData({ current: 1 });
        const errorMessage = e.response?.data?.errors?.myna_error[0];
        if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    fetchreceiptsList(limit ?? query.get('limit'));
  }, [fetchreceiptsList]);

  return {
    receiptsList,
    isDataLoading,
    fetchreceiptsList,
    pagingData,
    searchParam,
    onSearchChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/receipts?search=${value}&page=1`, {
        replace: true
      });
    },
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/receipts?page=${page}&limit=${limit}`, {
        replace: true
      });
    }
  };
};

export default useReceiptsList;
