import {
  faCheckCircle,
  faClock,
  faExclamationCircle,
  faFolderPlus,
  faTicket,
  faUsers,
  faXmarkCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
//import { Card, Button } from 'antd';
import '../../../App.css';
import '../../../assets/css/style.css';
import { Link } from 'react-router-dom';
import { API, get } from '../../../config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Spin } from 'antd';

const AdminDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    try {
      setLoading(true);
      const { data } = await get(API.ADMIN_DASHBOARD);
      setLoading(false);
      setDashboardData(data.data);
    } catch (error) {
      setLoading(false);
      const errors = error.response?.data?.errors;
      toast.error(error.response.data.message);
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }, []);

  return (
    <div className="admin-dashboard">
      {loading ? (
        <Spin />
      ) : (
        <>
          <div className="admin-box halfwidth">
            <Link
              to={{ pathname: '/admin/registered-firms', search: '?tab=registeredFirm&page=1' }}
            >
              <div className="icon">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              <h4>Registered Users</h4>
              <h2>{dashboardData?.registered_firm ? dashboardData?.registered_firm : 0}</h2>
              <p>
                <strong>Last Updated:</strong>{' '}
                {moment(dashboardData?.registered_firm?.date).format('DD-MM-YYYY')}
              </p>
            </Link>
          </div>
          <div className="admin-box halfwidth">
            <Link
              to={{
                pathname: '/admin/registered-firms',
                search: '?tab=partialRegisteredFirm&page=1'
              }}
            >
              <div className="icon">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              <h4>Partial Registered Users</h4>
              <h2>
                {dashboardData?.partial_registered_firm
                  ? dashboardData?.partial_registered_firm
                  : 0}
              </h2>
              <p>
                <strong>Last Updated:</strong>{' '}
                {moment(dashboardData?.partial_registered_firm?.date).format('DD-MM-YYYY')}
              </p>
            </Link>
          </div>
          <div className="admin-box halfwidth">
            <Link to={{ pathname: '/admin/support-tickets' }}>
              <div className="icon">
                <FontAwesomeIcon icon={faTicket} />
              </div>
              <h4>Support Ticket Status</h4>
              <div className="dashboard-status-body">
                <div className="dashboard-status-item">
                  <div className="support-status-new support-status-body">
                    {' '}
                    <FontAwesomeIcon icon={faFolderPlus} title="New" />
                    <span className="support-status-text">New:</span>
                  </div>
                  <div>{dashboardData?.support?.new}</div>
                </div>
                <div className="dashboard-status-item">
                  <div className="support-status-open support-status-body">
                    {' '}
                    <FontAwesomeIcon icon={faExclamationCircle} title="Open" />
                    <span className="support-status-text">Open:</span>
                  </div>
                  <div>{dashboardData?.support?.open}</div>
                </div>
                <div className="dashboard-status-item">
                  <div className="support-status-in-progress support-status-body">
                    {' '}
                    <FontAwesomeIcon icon={faClock} title="Pending" />
                    <span className="support-status-text">Pending:</span>
                  </div>
                  <div>{dashboardData?.support?.pending}</div>
                </div>
                <div className="dashboard-status-item">
                  <div className={`support-status-resolved support-status-body`}>
                    {' '}
                    <FontAwesomeIcon icon={faCheckCircle} title="Resolved" />
                    <span className="support-status-text">Resolved:</span>
                  </div>
                  <div>{dashboardData?.support?.resolved}</div>
                </div>
                <div className="dashboard-status-item">
                  <div className="support-status-closed support-status-body">
                    {' '}
                    <FontAwesomeIcon icon={faXmarkCircle} title="Closed" />
                    <span className="support-status-text">Closed:</span>
                  </div>
                  <div>{dashboardData?.support?.closed}</div>
                </div>
              </div>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
