import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Header from './components/Header/Index';
import CustomerDetailPopup from './pages/customer/CustomerDetailPopup';
import { API, get, idleTime } from './config';
import { RemoveCookie, SetCookie } from './APIConfig/Interceptor';
import useIdleTimeout from './shared/useIdleTimeout';
import { Button, Modal } from 'react-bootstrap';
import { checkRole } from './helper/utils';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const tfaCheck = localStorage.getItem('myna-auth-tfa');
  const token = localStorage.getItem('myna-auth-token');
  // const role = localStorage.getItem('role');
  // const roleName = localStorage.getItem('role_name');
  const roleType = localStorage.getItem('role_type');
  // Idle Logic start
  const logout = () => {
    get(API.LOGOUT)
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          SetCookie('_subscription', '');
          RemoveCookie('_subscription');
          window.location.href = '/';
        }
      })
      .catch(() => {
        var error = {};
        error['myna_error'] = 'There is some issue with request, please try after some time.';
        this.setState({ errors: error });
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const handleIdle = () => {
    setOpenModal(true);
    localStorage.clear();
    RemoveCookie("_subscription");
  };

  // Here idleTime is in minutes
  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: idleTime });

  // const stay = () => {
  //   setOpenModal(false);
  //   idleTimer.reset();
  // };
  // const handleLogout = () => {
  //   logout();
  //   setOpenModal(false);
  // };

  const handleLogout = () => {
    idleTimer.reset();
    logout();
    setOpenModal(false);
  };

  // Idle Logic end
  if (token) {
    // if (role === '1') {
    if (checkRole(roleType)) {
      return <Navigate to="/admin/dashboard" />;
    } else {
      if (tfaCheck === '2' || tfaCheck === '-1') {
        return (
          <>
            <Header setIsVisible={setIsVisible} setSelectedCustomer={setSelectedCustomer} />
            <Component {...rest} />
            {isVisible && (
              <CustomerDetailPopup
                customerId={selectedCustomer}
                toggleShowModal={() => setIsVisible(false)}
              />
            )}
            {/* Idle Logic start */}
            {openModal && (
              <Modal
                size="lg"
                show={openModal}
                // onHide={stay}
                onHide={handleLogout}
                dialogClassName="modal-50w medium-popup review-popup idle-expire-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton className="idle-expire-header">
                  Your session is expired
                </Modal.Header>
                <Modal.Body>
                  <div className="idle-expire-body">
                    <p>Your session is expired. You are automatically signed out.</p>
                    <p>Expired due to the reason that user is idle for {idleTime} mins.</p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {/* <Button variant="secondary" className="reset" onClick={stay}>
                    Stay signed in
                  </Button> */}
                  <Button variant="secondary" className="reset" onClick={handleLogout}>
                    Login Again
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            {/* Idle Logic end */}
          </>
        );
      } else {
        return <Navigate to="/2fa" />;
      }
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
