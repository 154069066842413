import React, { useRef, useEffect } from "react";
export default function InfinityScrollComponent({
  loadMore,
  isLoading,
  hasMore,
  children,
  lastItemRef,
}) {
  const contentRef = useRef(null);

  useEffect(() => {
    const handleObserver = (entries) => {
      // 2 .intersection Observer object callback handler
      const { isIntersecting } = entries[0];
      // fetch new data when the element is intersecting with the last element
      if (!isLoading && hasMore && isIntersecting) {
        loadMore();
      }
    };

    // 1. Instantiate an intersection Observer object
    const observer = new IntersectionObserver(handleObserver);

    // 3. Targeting an element to be observed
    if (lastItemRef) {
      observer.observe(lastItemRef);
    }

    return () => {
      if (lastItemRef) {
        observer.unobserve(lastItemRef);
      }
    };
  }, [lastItemRef, loadMore, isLoading, hasMore]);

  return (
    <div ref={contentRef} className="infinity-scroll-component-container">
      {children}
    </div>
  );
}
