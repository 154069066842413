import React from 'react';
import { Button, Modal } from 'antd';
import { toast } from 'react-toastify';
import { API, get } from '../../config.js';

const UserUndo = ({ userId, toggleUndoModal, handleDelete }) => {
  const handleUserUndo = async () => {
    try {
      const { data } = await get(`${API.UNDO_USER}/${userId}`);
      toast.success(data.message);
      handleDelete();
      toggleUndoModal();
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  return (
    <div>
      <Modal
        className="remove-popup"
        width={500}
        closable
        centered
        open
        footer={null}
        onCancel={toggleUndoModal}
      >
        <div className="remove-content">
          <p>Are your sure you want to restore this user?</p>
          <div className="modal-action">
            <Button className="cancel" onClick={toggleUndoModal}>
              Cancel
            </Button>
            <Button onClick={handleUserUndo}>OK</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserUndo;
