import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const tfaCheck = localStorage.getItem("myna-auth-tfa");
  const token = localStorage.getItem("myna-auth-token");
  const pathname = window.location.pathname;
  if (token) {
    if (tfaCheck === "2" && (pathname !== "/2fa/change" && pathname !== "/2fa/disable" && pathname !== "/2fa/register")) {
      return (
        <Navigate to='/dashboard?field=name&type=asc&filter=weekly' />
      );
    } else if (tfaCheck === "1" && pathname === "/2fa/register") {
      return <Navigate to="/2fa" />;
    } else if (tfaCheck === "0" && pathname === "/2fa") {
      return <Navigate to="/2fa/register" />;
    } else {
      return (<>
        <Component {...rest} />
      </>)
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
