import { useEffect, useState, useCallback } from 'react';
import { API, get } from '../../../config';

const useChartAccountList = (accountId, search) => {
  const [chartAccounts, setChartAccounts] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(search || '');
  const [pagingData, setPagingData] = useState({ current: 1 });
  const fetchChartAccounts = useCallback(
    async (passedId) => {
      try {
        setIsDataLoading(true);
        const { data } = await get(
          `${API.GET_CHARTACCOUNT_LIST}/${passedId || accountId}?page=${
            pagingData.current
          }&search=${searchParam}&limit=30`
        );
        setIsDataLoading(false);
        const { total, current_page, per_page } = data.data;
        setChartAccounts(data.data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page
        });
      } catch (e) {
        setChartAccounts([]);
        setIsDataLoading(false);
        setPagingData({ current: 1 });
      }
    },
    [pagingData.current, searchParam, accountId]
  );

  useEffect(() => {
    fetchChartAccounts();
  }, [fetchChartAccounts]);

  return {
    fetchChartAccounts,
    chartAccounts,
    isDataLoading,
    pagingData,
    onPageChange: (page) => {
      setPagingData({ ...pagingData, current: page });
    },
    onSearchChange: (value) => {
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
    }
  };
};

export default useChartAccountList;
