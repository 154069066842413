import moment from 'moment';
import React from 'react';
import { FullNameGetter, trimString } from '../../../helper/fullNameGetter';
import RegisterFirmAction from './RegisterFirmAction';
import ExpandRegisteredActionButton from './ExpandRegisteredActionButton';
import { formatNumber } from '../../../APIConfig/Interceptor';
import XeroStatus from './XeroStatus';
import SalesForceStatus from './SalesForceStatus';

export const RegisteredFirmColumns = (getRegisteredFirmData) => {
  return [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Business Name',
      key: 'business_name',
      render: (record) => (
        <span>
          {record?.account_firm?.business_name
            ? record?.account_firm?.business_name?.charAt(0) + record?.account_firm?.business_name
            : ''}
        </span>
      ),
      className: 'breakcolumn fullname'
    },
    {
      title: 'Firm Email',
      key: 'firm_email',
      render: (record) => (
        <span>{record?.account_firm?.firm_email ? record?.account_firm?.firm_email : ''}</span>
      ),
      className: 'breakcolumn'
    },
    {
      title: 'Firm Phone',
      key: 'firm_phone',
      render: (record) => (
        <span>{record?.account_firm?.phone ? record?.account_firm?.phone : ''}</span>
      )
    },
    {
      title: 'Name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      key: 'name',
      className: 'fullname',
      render: (record) => trimString(FullNameGetter(record), 32)
    },
    // {
    //   title: 'Mobile Number',
    //   sorter: (a, b) => a.phone - b.phone,
    //   render: (record) => <span>{record.phone}</span>,
    //   className: 'breakcolumn'
    // },
    {
      title: 'Registered Date',
      dataIndex: 'created_at',
      render: (created_at) => created_at?.split(' ')[0],
      className: 'wrapword'
    },
    {
      title: 'Last Login Date',
      key: 'last_login_date',
      render: (record) => <span>{record?.last_login_date ? record?.last_login_date : '-'}</span>
    },
    {
      title: '',
      key: 'disconnect',
      render: (record) => (
        <div
         className='disconnect-service-body'
        >
          <div style={record?.sales_force_connection && record?.account_firm?.xero_connection ? { marginRight: '10px' } : {}}>
            <XeroStatus
              id={record?.account_firm?.id}
              xeroData={record?.account_firm?.xero_connection}
              getRegisteredFirmData={getRegisteredFirmData}
            ></XeroStatus>
          </div>
          <div>
            <SalesForceStatus
              id={record?.account_firm?.id}
              salesForceData={record?.sales_force_connection}
              getRegisteredFirmData={getRegisteredFirmData}
            ></SalesForceStatus>
          </div>
        </div>
      )
    },
    {
      title: '',
      key: 'id',
      render: (record) => (
        <RegisterFirmAction
          id={record?.id}
          xeroData={record?.account_firm?.xero_connection}
        ></RegisterFirmAction>
      )
    }
  ];
};

export const ExpandedRegisterFirmColumns = () => {
  return [
    {
      title: 'Package',
      key: 'package',
      dataIndex: 'package',
      render: (product_name, record) => (
        <span>{record?.product_name ? record?.product_name : '-'}</span>
      )
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (price, record) => (
        <span>
          {record?.price ? formatNumber(Number(record?.price)) : ' '}
          {record?.amount ? formatNumber(Number(record?.amount)) : ' '}
        </span>
      )
    },
    {
      title: 'Start Date',
      key: 'start_date',
      dataIndex: 'start_date',
      render: (start_date, record) => (
        <span>
          {record?.subscription_start_date
            ? moment(record?.subscription_start_date).format('DD-MM-YYYY')
            : '-'}
        </span>
      )
    },
    {
      title: 'End Date',
      key: 'end_date',
      dataIndex: 'end_date',
      render: (end_date, record) => (
        <span>
          {record?.subscription_end_date
            ? moment(record?.subscription_end_date).format('DD-MM-YYYY')
            : '-'}
        </span>
      )
    },
    {
      title: 'Action',
      width: '5%',
      key: 'id',
      dataIndex: 'id',
      render: (id) => <ExpandRegisteredActionButton id={id}></ExpandRegisteredActionButton>
    }
  ];
};

export const PartialRegisteredFirmColumns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: 'Business Name',
    key: 'business_name',
    dataIndex: 'business_name',
    className: 'fullname',
    render: (record) => record?.charAt(0) + record
  },
  {
    title: 'Firm Email',
    key: 'firm_email',
    dataIndex: 'firm_email'
  },
  {
    title: 'Name',
    sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    key: 'name',
    className: 'fullname',
    render: (record) => trimString(FullNameGetter(record), 32)
  },
  {
    title: 'Email Address',
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (record) => <span>{record.email}</span>
  },
  {
    title: 'Mobile Number',
    sorter: (a, b) => a.phone - b.phone,
    render: (record) => <span>{record.phone}</span>
  },
  {
    title: 'Registered Date',
    dataIndex: 'created_at',
    render: (created_at) => created_at?.split(' ')[0]
  }
];
