import { orderBy } from 'lodash';
import moment from 'moment';
import { API, post } from '../../config.js';

export const assignStaff = async (payload) => {
  try {
    const { data } = await post(API.ASSIGN_STAFF, payload);
    return data;
  } catch (e) {
    console.log('e :: ', e);
    throw e;
  }
};

export const scrollOption = {
  root: null,
  rootMargin: '20px',
  threshold: 0
};

export const getAssignedTo = (record) => {
  if (!record?.customer) {
    return null;
  }
  const { accounting_firm_customer } = record.customer;
  if (accounting_firm_customer.staff) {
    return `${accounting_firm_customer?.staff?.first_name} ${accounting_firm_customer?.staff?.last_name}`;
  }
  if (accounting_firm_customer.manager) {
    return `${accounting_firm_customer?.manager?.first_name} ${accounting_firm_customer?.manager?.last_name}`;
  }
  return null;
};

export const getFirstChar = (record) => {
  if (!record?.customer) {
    return null;
  }
  const { accounting_firm_customer } = record.customer;
  if (accounting_firm_customer.staff) {
    return accounting_firm_customer?.staff?.first_name.charAt(0);
  }
  if (accounting_firm_customer.manager) {
    return accounting_firm_customer?.manager?.first_name.charAt(0);
  }
  return null;
};

export const getNextPageData = async (page, type, filter) => {
  try {
    const { data } = await post(`${API.DASHBOARD}?page=${page}&type=${type}`, filter);
    return data?.data[type];
  } catch (e) {
    console.log('e :: ', e);
    throw e;
  }
};

export const sortData = (value, returnLodged, ATOList, returnAssessed, underReview, completed) => {
  const splitValue = value.split(',');
  const field = splitValue[0];
  const order = splitValue[1];
  let returnLodgedData = [];
  let atoData = [];
  let returnAssessedData = [];
  let underReviewData = [];
  let completedData = [];
  if (field === 'name') {
    returnLodgedData = orderBy(returnLodged?.data, ['first_name'], [order]);
    atoData = orderBy(ATOList?.data, ['client_name'], [order]);
    returnAssessedData = orderBy(returnAssessed?.data, ['tax_software.first_name'], [order]);
    underReviewData = orderBy(underReview?.data, ['tax_software.first_name'], [order]);
    completedData = orderBy(completed?.data, ['tax_software.first_name'], [order]);
  } else {
    const descReturnLodgedData = returnLodged?.data.sort(
      (a, b) =>
        new Date(moment(b.created_at, 'DD-MM-YYYY HH:mm:ss')) -
        new Date(moment(a.created_at, 'DD-MM-YYYY HH:mm:ss'))
    );
    const descATOData = ATOList?.data.sort(
      (a, b) =>
        new Date(moment(b.created_at, 'DD-MM-YYYY HH:mm:ss')) -
        new Date(moment(a.created_at, 'DD-MM-YYYY HH:mm:ss'))
    );
    const descReturnAssessedData = returnAssessed?.data.sort(
      (a, b) =>
        new Date(moment(b.created_at, 'DD-MM-YYYY HH:mm:ss')) -
        new Date(moment(a.created_at, 'DD-MM-YYYY HH:mm:ss'))
    );
    const descUnderReviewData = underReview?.data.sort(
      (a, b) =>
        new Date(moment(b.updated_at, 'DD-MM-YYYY HH:mm:ss')) -
        new Date(moment(a.updated_at, 'DD-MM-YYYY HH:mm:ss'))
    );
    const descCompletedData = completed.data?.sort(
      (a, b) =>
        new Date(moment(b.updated_at, 'DD-MM-YYYY HH:mm:ss')) -
        new Date(moment(a.updated_at, 'DD-MM-YYYY HH:mm:ss'))
    );

    if (order === 'desc') {
      returnLodgedData = descReturnLodgedData;
      atoData = descATOData;
      returnAssessedData = descReturnAssessedData;
      underReviewData = descUnderReviewData;
      completedData = descCompletedData;
    } else {
      returnLodgedData = descReturnLodgedData.reverse();
      atoData = descATOData.reverse();
      returnAssessedData = descReturnAssessedData.reverse();
      underReviewData = descUnderReviewData.reverse();
      completedData = descCompletedData.reverse();
    }

    // returnLodgedData = orderBy(returnLodged.data, [(obj) => Date.parse(obj.created_at)], [order]);
    // returnAssessedData = orderBy(
    //   returnAssessed.data,
    //   [(obj) => Date.parse(obj?.ato?.created_at)],
    //   [order]
    // );
    // underReviewData = orderBy(underReview.data, [(obj) => Date.parse(obj.updated_at)], [order]);
    // completedData = orderBy(completed.data, [(obj) => Date.parse(obj.updated_at)], [order]);

    // returnLodgedData = orderBy(returnLodged.data, (obj) => {new Date(moment(obj.reconciliation.created_at).format('DD-MM-YYYY HH:mm:ss'))}, [order]);
    // returnAssessedData = orderBy(returnAssessed.data, (obj) => {new Date(moment(obj?.ato?.created_at).format('DD-MM-YYYY HH:mm:ss'))}, [order]);
    // underReviewData = orderBy(underReview.data, (obj) => {new Date(moment(obj.updated_at).format('DD-MM-YYYY HH:mm:ss'))}, [order]);
    // completedData = orderBy(completed.data, (obj) => {new Date(moment(obj.updated_at).format('DD-MM-YYYY HH:mm:ss'))}, [order]);
  }
  return { returnLodgedData, atoData, returnAssessedData, underReviewData, completedData };
};
