import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../config.js';

const preprocessStatementList = (data) => {
  return data.map((ele) => {
    return {
      ...ele,
      name: `${ele?.reconciliation?.customer?.first_name} ${ele?.reconciliation?.customer?.last_name}`
    };
  });
};

const useAuditStatementList = (currentPage, search, selectedTab, setBalanceInfo, limit) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [searchParam, setSearchParam] = useState(search || '');
  const [auditStatement, setAuditStatement] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  // const [fromDate, setFromDate] = useState(fromDate || "");
  // const [toDate, setToDate] = useState(toDate || "");
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchAuditStatementList = useCallback(
    async (limit) => {
      try {
        if (selectedTab === 'import-history') {
          setIsDataLoading(true);
          let tempLimit = defaultPaginationLimit;
          if (limit) {
            tempLimit = limit;
          } else {
            tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
          }
          const { data } = await get(
            `${API.BANK_AUDIT_STATEMENT_LIST}?page=${pagingData.current}&limit=${tempLimit}`
          );
          const { total, current_page, per_page, from, to } = data.data;
          const balanceDetails = {};
          balanceDetails['firm_bank'] = data.firm_bank;
          balanceDetails['firm_balance'] = data.firm_balance;
          balanceDetails['statement_balance'] = data.statement_balance;
          balanceDetails['date'] = data?.latest_statement?.date;
          setBalanceInfo(balanceDetails);
          const AuditStatementsData = preprocessStatementList(data.data.data);
          setIsDataLoading(false);
          setPagingData({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
          setAuditStatement(AuditStatementsData);
        }
      } catch (e) {
        setAuditStatement([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current, searchParam, selectedTab]
  );

  useEffect(() => {
    fetchAuditStatementList();
  }, [fetchAuditStatementList]);

  return {
    auditStatement,
    isDataLoading,
    fetchAuditStatementList,
    setAuditStatement,
    pagingData,
    onPageChange: (page) => {
      setPagingData({ ...pagingData, current: page });
      navigate(`/firm-bank-account?page=${page}&tab=audit-statement&limit=${limit}`, {
        replace: true
      });
    },
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      navigate(`/firm-bank-account?page=1&tab=audit-statement&limit=${limit}`, {
        replace: true
      });
    },
    searchParam
  };
};

export default useAuditStatementList;
