import React from 'react';
import Sitelogo from '../Header/Logo';
import HeaderNav from './HeaderNav';
import UserProfile from './UserProfile';

const Header = () => {
  return (
    <div className="header-blocks">
      <header>
        <Sitelogo />
        <HeaderNav />
        <UserProfile />
      </header>
      <div className="header-bottom-block"></div>
    </div>
  );
};

export default Header;
