import qs from 'qs';
import instance from './APIConfig/Interceptor';
import { URL } from './APIConfig/Interceptor';

export const API = {
  //login and authentication
  LOGIN: '/login',
  XEROLOGIN: 'login/xero',
  XERO_SIGNUP: 'signup/xero',
  LOGOUT: '/logout',
  REFRESH: '/refresh',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  GOOGLE_2FA_REGISTER: '/google-2fa-register',
  GOOGLE_2FA_VERIFY: '/google-2fa-verify',
  VERIFY_EMAIL_AUTHENTICATION: '/verify-email-authentication',
  EMAIL_AUTHENTICATION: '/email-authentication',
  VERIFY_BACKUP_EMAIL: '/verify-backup-email',
  ADD_BACKUP_EMAIL: '/add-backup-email',
  DELETE_2FA: '/delete-2fa',

  // stripe
  // STRIPE_KEY:
  //   'pk_test_51KXEMNAxLKfFDAVGx2Sn0gMEcz4QZ8wmTAMkvi4sMrkGbKN6m3lMxOIG3qyPiAecl1UKgMUccICfQK1aMgPcxfyN006398LCLn',
  STRIPE_KEY:
    'pk_live_51KXEMNAxLKfFDAVG6dmXbeb71pRFpluzkRzfRq3DPPkGpIA4nTQKLQCf3fg6Lptgd5yukWaEGVUI4WkufMcJNfcu00QR9vZUFI',

  // user profile
  GET_USER_PROFILE: '/my-profile',
  UPDATE_PROFILE: '/update-profile',
  CHANGE_PASSWORD: '/change-password',

  //customers
  GET_CUSTOMERLIST: '/customer',
  CREATE_CUSTOMER: '/customer/store',
  CUSTOMER_DETAILS: '/customer/show',
  SUPPLIER_DETAILS: '/suppliers',
  CUSTOMER_REFUND_HISTORY: '/customer/refund-history',
  CUSTOMER_EDIT: '/customer/edit',
  IMPORT_CUSTOMER: 'customers/import',
  DOWNLOAD_CUSTOMER: '/customers/download-template',
  GET_CONSENT: 'get-consent',
  GET_AUTHORITY_LETTER: '/get-authority-letter',
  GET_ABA_RECIEPT: '/receipt-template',
  UPDATE_ABA_RECIEPT: '/receipt-template/update',
  UPDATE_CONSENT: 'update-consent',
  VIEW_STATEMENT: '/customer/view-statement',
  REGENERATE_ABA: '/regenerate-aba',
  GET_DUPLICATE_CUSTOMERLIST: '/customers/unused-clients',
  DELETE_DUPLICATE_CUSTOMER: '/customers/delete-unused-clients',
  PERMANETALY_DELETE_ARCHIVED_CUSTOMER: '/customers/delete-unused-clients',
  DOWNLOAD_TFN_CUSTOMER: '/customers/download-template-tfn',
  IMPORT_TFN_CUSTOMER: '/customers/update-tfn',

  //Payments
  PAYMENTS: '/payments',

  //Reciept
  CREATE_RECIEPT: '/receipts',
  GET_RECEIPTS: '/receipts',
  RECIEPT: '/receipts',

  // All CLient
  GET_ALL_CLIENT: '/all-contacts',
  GET_CUSTOMER_WITH_TRANSACTION: '/customer-with-transactions',

  //Opening Balance
  OPENING_BALANCE: '/opening-balance',

  //Import Logs
  IMPORT_LOGS: '/customer-import-logs',

  //Payments
  GET_PAYMENT_LIST: '/payments',

  //ATO Store
  MANUAL_ATOS: '/atos/store',
  IMPORT_ATOS: '/atos/import',
  ATO_HISTORY: '/atos',
  ATO_DETAILS: '/atos',
  DOWNLOAD_TEMP_ATOS: '/atos/download-template',
  ATOS_RECORDS: '/atos/ato-record',
  ATO: '/ato',
  SEARCH_ATO_RECEIPT: '/search-ato-receipt',
  UPDATE_ATO: '/atos/update',
  ATO_SYNC: '/atos/sync-customers',

  //TAX Software
  MANUAL_TS_HISTORY: '/tax-softwares/store',
  IMPORT_TAX_SOFTWARE: '/tax-softwares/import',
  DOWNLOAD_TEMP_TAX_SOFTWARE: '/tax-softwares/download-template',
  TAX_SOFTWARES: '/tax-softwares',
  OVERWRITE_TAX_SOFTWARES: '/tax-softwares/overwrite',
  TAX_EXPAND_SEARCH: '/batch-tax-software/',
  CREATE_MULTIPE_CUSTOMER: 'tax-softwares/create-multiple-customers/',

  //PROCESS Refund
  PROCESS_REFUND: '/process-refund',
  MULTI_PROCESS_REFUND: '/process-refund/multiple',
  MATCH_UNMATCH_REVIEW: '/process-refund/',
  SINGLE_PROCESS_REFUND: '/single-process-refund/',
  SAVE_SEARCH_PROCESS_REFUND: '/process-refund/save-search',
  SEARCH_PROCESS_REFUND: '/search-process-refund',
  SEARCH_ABA: '/search-aba',
  PROCESS_REFUND_UPDATE_FEES: '/process-refund/update-fees',

  //user-role-permission
  GET_USER_ROLE_PERMISSION: '/role-permission-list',
  UPDATE_USER_ROLE_PERMISSION: '/role-permission-update',

  //GENERAL Setting
  GET_TASKLIST: '/task',
  GET_JOBLIST: '/job',
  DELETE_TASK: '/task/',
  DELETE_JOB: '/job/',
  DELETE_DISCOUNT: '/discount/',
  DELETE_CHART_ACCOUNT: '/chart-account/',
  GET_DISCOUNTLIST: '/discount',
  EDIT_TASK: 'task/:id',
  GET_CHARTACCOUNT: '/chart-accounts',
  GET_CHARTACCOUNT_LIST: '/chart-account',
  UPDATE_FIRM_SETTING: '/update-firm-info',
  GET_FIRM_INFO: '/firm-info',
  GENERAL_SETTING_INTEGRATION: '/general-setting-integration',
  SALESFORCE_AUTH_CALLBACK: '/sf_oauth_callback',
  SALESFORCE_DISCONNECT: '/sf-disconnect',
  SALESFORCE_ADMIN_DISCONNECT: '/admin/registered-firms/sf-disconnect',
  SYNC_SF_CONTACTS: '/customer/sync-sf-contacts',
  GET_BENEFICIARIES_DATA: '/beneficiaries',
  GET_BENEFICIARY_DATA: '/beneficiaries',
  BENEFICIARY: '/beneficiaries',

  //xero
  XERO_AUTH: '/manage/xero',
  XERO_REFRESH: '/xero/refresh',
  XERO_OAUTHREDIRECT: '/xero/oauthredirect',
  XERO_TENANTS: '/xero/tenants',
  XERO_CLIENTS: '/xero/get-clients',
  XERO_DISCONNECT: '/xero/remove-connections',
  XERO_ADMIN_DISCONNECT: '/admin/registered-firms/xero/remove-connections',

  // User console
  GET_USER_LIST: '/users',
  GET_USER_DETAIL: '/user/show',
  CREATE_USER: '/user/store',
  UPDATE_USER: '/user',
  DELETE_USER: '/user',
  UNDO_USER: '/user/undo',
  USER_LIST_BY_ROLE: '/users/type',
  USER_RESEND_LINK: '/user/resend-set-password-link',
  USER_SUPPORT: '/create-support-user',

  // Role
  GET_ROLE_FIRM_LIST: '/roles',
  GET_ROLE_LIST: '/admin/roles',
  GET_ROLE_DETAIL: '/admin/roles',
  CREATE_ROLE: '/admin/roles',
  UPDATE_ROLE: '/admin/roles',
  DELETE_ROLE: '/admin/roles',

  // Support User Add
  GET_ADMIN_USER_LIST: '/admin/users',
  GET_ADMIN_USER_DETAIL: '/admin/users',
  CREATE_ADMIN_USER: '/admin/users',
  UPDATE_ADMIN_USER: '/admin/users',
  DELETE_ADMIN_USER: '/admin/users',

  // Activity Logs
  GET_ACTIVITY_LIST: '/activities',

  // ABA
  GET_ABA_LIST: '/aba-list',
  GENERATE_ABA: '/generate-aba',
  UNDO_ABA: '/aba/undo',
  ABA_FILE_LIST: '/aba-file-list',
  DOWNLOAD_ABA_FILE: '/download-aba-file',
  ABA_TRANSACTION_FILES: '/aba-file-customer',
  RECEIPT_PREVIEW: '/receipt-preview',
  GENERATE_ABA_MERGED_FEES: '/generate-aba-merged-fees',

  //firm management
  FIRM_SIGNUP: '/firm-signup/tmp-signup',
  FIRM_SUBSCRIPTION: '/stripe/firm-subscription',
  GET_SUBSCRIPTION: '/stripe/get-subscription',
  CANCEL_SUBSCRIPTION: 'stripe/cancel-subscription',

  //state management
  GET_STATE_LIST: '/state-list',

  //suburb management
  GET_SUBURB_LIST: '/suburb-list',

  //office management
  GET_OFFICE_LIST: '/office',
  CREATE_OFFICE: '/office/store',
  UPDATE_OFFICE: '/office',
  DELETE_OFFICE: '/office/',

  //Supplier management
  SUPPLIERS: '/suppliers',

  //Global search
  GLOBAL_SEARCH: '/global-search',

  // Dashboard
  DASHBOARD: '/dashboard',
  PARTNER_STAFF_LIST: '/dashboard/staff-list',
  ASSIGN_STAFF: '/dashboard/assign-staff',

  // Front page
  Subscription: '/stripe/products',

  //bank statement
  BANK_STATEMENT_LIST: '/bank-statement',
  DOWNLOAD_BANK_STATEMENT_TEMPLATE: '/bank-statement/download-template',
  BANK_STATEMENT_IMPORT: '/bank-statement/import',
  BANK_AUDIT_STATEMENT_LIST: '/bank-statement-batches',
  DELETED_BANK_STATEMENT: '/deleted-bank-statement',
  BANK_AUDIT_STATEMENT_SHOW: '/bank-statement-batches/show',
  DELETE_BANK_STATEMENT: '/bank-statement/',
  // GET_BANK_ACCOUNTS: '/basiq/accounts',
  GET_BANK_ACCOUNTS: '/yodlee/accounts',
  BULK_DELETE_BANK_STATEMENT: '/bank-statements/bulk-delete',
  PERMANETALY_DELETE_BANK_STATEMENT: '/bank-statements/delete-permanent',

  //bank-reconcilation
  BANK_RECONCILIATION_LIST: '/bank-reconciliations',
  UPDATE_BANK_RECONCILIATION: '/bank-reconciliations/update/',
  NEW_BANK_RECONCILIATION: '/bank-reconciliations',
  BANK_ABBREVIATION: '/abbreviation',
  BANK_RECONCILIATION_BULK: '/bank-reconciliations/bulk',
  UNDO_BANK_RECONCILIATION: '/bank-reconciliations/undo',

  //report
  CUSTOMER_TRANSACTION_REPORT: '/customer-transaction-report',
  DELETED_REPORT: '/deleted-client',
  CUSTOMER_REPORT: '/customer-report',
  CLIENT_WITHOUT_BANK: '/nobank-client',
  CLIENT_REQUIRED_UPDATE: '/clients-required-update',
  SEARCH_CUSTOMER: '/search-customer',
  CA_TRIAL_BALANCE_REPORT: 'ca-trial-balance-report',
  CA_LEDGER_ENTRIES_REPORT: '/ca-ledger-entries-report',
  UNDO_CLIENT: '/customer/undo',
  CA_TRUST_TRIAL_BALANCE_REPORT: '/ca-trust-trial-balance-report',
  MONTHLY_BANK_RECONCILIATION_REPORT: '/monthly-bank-reconciliation-report',
  MONTHLY_BANK_RECONCILIATION_REPORT_ENTRIES: '/reports/monthly-bank-reconciliation-entries-report',
  EXPORT_CUSTOMER_TRANSACTION_REPORT: '/export-customer-transaction-report',
  CHANGE_MONTHLY_BANK_RECONCILIATION_BALANCE: '/monthly-balances',

  // Withdrawal Account
  WITHDRAWAL_ACCOUNT: '/withdrawal-account',

  //Basiq integration
  BASIQ_GET_USER: '/basiq/get-user',
  BASIQ_CREATE_USER: '/basiq/create-user',
  BASIQ_GET_TRANSACTIONS: '/basiq/get-transactions',
  BASIQ_DISCONNECT: '/basiq/disconnect',

  //Yodlee integration
  YODLEE_GET_TOKEN: '/yodlee/get-token',
  SAVE_ACCOUNTS: '/yodlee/accounts',
  SAVE_PROVIDERS_ACCOUNTS: '/yodlee/providers',
  REQUEST_CLIENT_TO_CONNECT: '/yodlee/request-client-to-connect',
  REQUEST_BUILDER_TO_CONNECT: '/yodlee/request-builder-to-connect',
  YODLEE_BANK_ACCOUNT_CONNECTED: '/yodlee/connectedAccounts',
  YODLEE_BANK_ACCOUNT_DISCONNECT: '/yodlee/disconnectSingleAccount',

  //Providers(Bank)
  GET_PROVIDERS_BANK: '/yodlee/providers',
  PROVIDER_BANK_DISCONNECT: '/yodlee/disconnect',
  YODLEE_GET_TRANSACTIONS: '/yodlee/transactions',

  //Registered Firm - ADMIN Module
  ADMIN_DASHBOARD: '/admin/dashboard',
  REGISTERED_FIRM_LIST: '/admin/registered-firms',
  PARTIAL_REGISTERED_FIRM_LIST: '/admin/partial-registered-firms',
  EXPAND_REGISTERD_FIRM_LIST: '/admin/registered-firms/show/',
  // ADMIN_CUSTOMER_REPORT: '/admin/users',
  ADMIN_CUSTOMER_PARTIAL: '/admin/partial-registered-firms',
  RESEND_LINK: '/admin/registered-firms/resend-set-password-link',
  ADMIN_ACTIVITY_LOGS: '/admin/registered-firms/activity-logs/',
  EXPAND_REGISTERD_FIRM_INVOICE_LIST: '/admin/registered-firms/subscriptions/',

  // INVOICE AMOUNT
  INVOICE_AMOUNT: '/invoice_amounts',
  GET_PAYMENTMETHOD_LIST: '/stripe/payment-methods',

  // Support Ticket
  SUPPORT_TICKET: '/support-tickets',
  ADMIN_SUPPORT_TICKET: '/admin/support-tickets'
};

// Idel Time in minutes
export const idleTime = 30;

// Default Pagination Limit value
export const defaultPaginationLimit = 50;

export const imageDefaultPrefixPathLocal = `${URL}/public`;

// export const imageDefaultPrefixPath = 'https://api.trusteasy.com.au/public';
export const imageDefaultPrefixPath =
  'https://trust-easy-file-storage.s3.ap-southeast-2.amazonaws.com/public/';
export const IV = '6d49gdf7hf4h26gf';
export const SECRETKEY = 'VahHC3EdXsqFuB5UKwux7Ww6eDQcJVaP';

export function get(endPoint, params = {}) {
  return instance.get(endPoint, params);
}

export function deleteCall(endPoint) {
  return instance.delete(endPoint);
}

export function putCall(endPoint, params) {
  return instance.put(endPoint, params);
}

export function post(endPoint, params, isStringify = true) {
  return instance.post(
    endPoint,
    params && (isStringify ? JSON.stringify(params) : qs.stringify(params))
  );
}

export function fileUpload(endPoint, params, config) {
  return instance.post(endPoint, params, config);
}

export const maskTFN = (tfn) => {
  if (tfn) {
    /** Condition will only executes if tfn is *not* undefined, null, empty, false or 0*/
    const tfnlength = tfn.length;
    const maskedLength = tfnlength - 4; /** Modify the length as per your wish */
    let newString = tfn;
    for (let i = 0; i < tfnlength; i++) {
      if (i < maskedLength) {
        newString = newString.replace(tfn[i], '*');
      }
    }
    return newString;
  } else return; /**Will handle if no string is passed */
};

//Yodlee
export const yodleeFastLink =
  // 'https://fl4.sandbox.yodlee.com.au/authenticate/anzdevexsandbox/fastlink/';
  // 'https://fl4.preprod.yodlee.com.au/authenticate/ANZDevexPreProd-152/fastlink?channelAppName=anzdevexpreprod';
  'https://fl4.prod.yodlee.com.au/authenticate/ANZDevexProd-248/fastlink?channelAppName=anzdevexprod';
export const yodleeConfigName = 'TE_Aggregation';
// export const yodleeobAppName = 'TrustEasy';
export const yodleeDefaultImg =
  'https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/plus-512.png';
