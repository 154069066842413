import React from "react";
import Sitelogo from "./Logo";
import HeaderNav from "./HeaderNav";
import UserProfile from "./UserProfile";
import SearchBar from "./SearchBar";

const Header = ({ setIsVisible, setSelectedCustomer }) => {
  return (
    <div className="header-blocks">
      <header>
        <Sitelogo />
        <HeaderNav />
        <UserProfile />
      </header>
      <div className="header-bottom-block">
        <SearchBar
          setIsVisible={setIsVisible}
          setSelectedCustomer={setSelectedCustomer}
        />
        {/* <SelectTimePeriod /> */}
      </div>
    </div>
  );
};

export default Header;
