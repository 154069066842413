import React from 'react';
import { Input, Button } from 'antd';
import Form from '@component/Form';
// import { useRelatedTo } from './OpeningBalanceHelper';
// import { FullNameGetterWithoutChartAt } from '../../helper/fullNameGetter';
import { API, fileUpload } from '../../config';
import { toast } from 'react-toastify';
import UserSelect from '../../components/User/UserSelect';
import { useState } from 'react';
import { formatNumber } from '../../APIConfig/Interceptor';
// import { useEffect } from 'react';

const AddEditOpeningBalance = ({ data, onClose, type }) => {
  const [form] = Form.useForm();
  const [customerId, setCustomerId] = useState(data?.id ? data?.id : '');
  // const { relatedLoading, relatedList = [] } = useRelatedTo();

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('type', type ? type : '');
      formData.append('opening_balance', values?.opening_balance ? values?.opening_balance : '');
      formData.append('_method', 'PUT');

      const { data } = await fileUpload(
        `${API.OPENING_BALANCE}/update/${customerId ? customerId : ''}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      toast.success(data.message);
      onClose();
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleSubmit1 = async (values) => {
    try {
      const formData = new FormData();
      formData.append('type', type ? type : '');
      formData.append('opening_balance', values?.opening_balance ? values?.opening_balance : '');
      formData.append('_method', 'PUT');

      const data1 = await fileUpload(`${API.OPENING_BALANCE}/update/${data?.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      data1?.data.message && toast.success(data1?.data.message);
      onClose();
    } catch (e) {
      return e;
      //   const errors = e.response?.data?.errors;
      //   Object.keys(errors).forEach((key) => {
      //     toast.error(errors[key][0]);
      //   });
    }
  };

  // const handleSearchCustomerValue = async (customerId) => {
  // const customerDetailRes = await getCustomerDetail(customerId);
  // setSearchValue(options && options.find((el) => el.id == customerId));
  // navigate(`/process-refund-individual/${customerId}`);
  // setCustomerSearchData(customerDetailRes);
  // };
  const onSelect = (value) => {
    setCustomerId(value);
  };

  // function onSearch() {}

  return (
    <div className="content-details">
      {type === 'firm' ? (
        <Form
          form={form}
          initialValues={{
            firm_business_name: data?.business_name ? data?.business_name : '',
            opening_balance: data?.opening_balance ? data?.opening_balance : '0.00'
          }}
          name="supplierForm"
          onFinish={handleSubmit1}
          layout="vertical"
        >
          <div className="input-boxes">
            <Form.Item
              /*className="half-width"*/
              className="opening-balance"
              label="Firm Business Name"
              name="firm_business_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter Opening Balance'
                }
              ]}
            >
              <Input disabled placeholder="Firm Businessn Name" name="firm_business_name" />
            </Form.Item>

            <Form.Item
              /*className="half-width"*/
              className="opening-balance"
              label="Opening Balance"
              name="opening_balance"
              rules={[
                {
                  required: true,
                  message: 'Please enter Opening Balance'
                },
                {
                  pattern: new RegExp(/^\d*\.?\d*$/),
                  message: 'Opening Balance should be number'
                }
              ]}
            >
              <Input placeholder="Opening Balance" name="opening_balance" />
            </Form.Item>
          </div>
          <div className="action-box">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              className="reset"
              variant="secondary"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      ) : (
        <Form
          form={form}
          initialValues={{
            customer: data?.id ? data?.id : '',
            opening_balance: data?.opening_balance ? formatNumber(data?.opening_balance) : ''
          }}
          name="supplierForm"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className="input-boxes">
            <Form.Item /*className="half-width"*/
              className="opening-balance"
              label="Customer"
              name="customer"
            >
              {/* <Select
                showSearch
                name="customer"
                optionFilterProp="children"
                onSearch={onSearch}
                loading={relatedLoading}
                placeholder="Please select customer"
                disabled={data?.id ? true : false}
              >
                {relatedList.map((item, i) => (
                  <Select.Option key={i} value={item.id}>
                    {FullNameGetterWithoutChartAt(item)}
                  </Select.Option>
                ))}
              </Select> */}
              <UserSelect
                formStyle={{ width: '100%' }}
                customerId={customerId}
                onCustomerSelectionChange={onSelect}
                disabled={data?.id ? true : false}
                placeholder="Select Customer"
                className="customer-select"
              />
            </Form.Item>

            <Form.Item
              /*className="half-width"*/
              className="opening-balance"
              label="Opening Balance"
              name="opening_balance"
              rules={[
                {
                  required: true,
                  message: 'Please enter Opening Balance'
                },
                {
                  pattern: new RegExp(/^\d*\.?\d*$/),
                  message: 'Opening Balance should be number'
                }
              ]}
            >
              <Input placeholder="Opening Balance" name="opening_balance" />
            </Form.Item>
          </div>
          <div className="action-box">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              className="reset"
              variant="secondary"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};
export default AddEditOpeningBalance;
