import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { toast } from 'react-toastify';
import { API, fileUpload } from '../../config.js';

const UserResendLink = ({ userId, toggleResendLinkModal, handleDelete }) => {
  const [loading, setLoading] = useState(false);
  const handleUserResendLink = async () => {
    const formData = new FormData();
    try {
      setLoading(true);
      formData.append('user_id', userId);
      const { data } = await fileUpload(`${API.USER_RESEND_LINK}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoading(false);
      toast.success(data.message);
      toggleResendLinkModal();
      handleDelete();
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  return (
    <div>
      <Modal
        className="remove-popup"
        width={500}
        closable
        centered
        open
        footer={null}
        onCancel={toggleResendLinkModal}
      >
        <div className="remove-content">
          <p>Are you sure you want to resend the password link?</p>
          <div className="modal-action">
            <Button className="cancel" onClick={toggleResendLinkModal}>
              Cancel
            </Button>
            <Button onClick={handleUserResendLink} loading={loading}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserResendLink;
