import React, { useState, useEffect, useCallback } from 'react';
import { API, fileUpload, get } from '../../config.js';
import { Button, Input } from 'antd';
import { toast } from 'react-toastify';
import '../users/users.module.css';
import CancelButton from '../../components/Form/CancelButton.js';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import AbaRecieptModal from './AbaRecieptModal.js';
import Form from '@component/Form';
import moment from 'moment';

const ABAReciept = () => {
  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState(null);

  const [recieptData, setrecieptData] = useState(null);
  const [subject, setSubject] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const date = new Date();
  const year = moment(date).format('YYYY');

  // const toggleShowModal = (text, setShowPreview, setPreview) => {
  //   if (text?.transaction_id) {
  //     setShowPreview(true);
  //     setPreview(text);
  //   }
  // };
  // const togglePrintModal = () => {
  //   setrecieptData(!recieptData);
  // };
  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const getReciept = (html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const value = EditorState.createWithContent(contentState) || null;
    return value;
  };
  const getRecieptData = useCallback(async () => {
    try {
      const { data } = await get(API.GET_ABA_RECIEPT);
      const recieptObj = data;
      const html = recieptObj?.data?.receipt_template;
      let recieptValue = null;
      if (html) {
        recieptValue = getReciept(html);
      }
      setSubject(recieptObj?.data?.receipt_subject ? recieptObj?.data?.receipt_subject : `Income Tax Refund for the year ${year}`);
      form.setFieldsValue({
        receipt_subject: recieptObj?.data?.receipt_subject ? recieptObj?.data?.receipt_subject : `Income Tax Refund for the year ${year}`
      });
      setEditorState(recieptValue);
    } catch (e) {
      setEditorState(null);
    }
  }, []);

  useEffect(() => {
    getRecieptData();
  }, [getRecieptData, recieptData]);

  const handleFinish = async () => {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    try {
      const formData = new FormData();
      formData.append('receipt_subject', subject);
      formData.append('receipt_template', value);
      const { data } = await fileUpload(API.UPDATE_ABA_RECIEPT, formData, {});
      toast.success(data.message);
      getRecieptData();
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  const printReciept = async () => {
    const { data } = await get(API.GET_ABA_RECIEPT);
    setrecieptData(data?.data);
    setShowPreview(true);
  };

  return (
    <div className="content-details">
      <div className="full-card">
        <Form
          form={form}
          initialValues={{
            receipt_subject: ''
          }}
        >
          <div className="form-box">
            <Form.Item
              className="manual_receipt_form"
              label="Subject"
              name="receipt_subject"
            >
              <Input placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
            </Form.Item>
          </div>
        </Form>

        <Editor
          editorState={editorState}
          toolbar={{
            options: ['inline', 'fontSize', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline']
            }
          }}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />
        <div className="action-box">
          <Button type="primary" onClick={() => printReciept()} style={{ marginRight: 'auto' }}>
            <FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
            Preview
          </Button>
          <Button type="primary" onClick={handleFinish}>
            Save
          </Button>
          <CancelButton />
          {recieptData && (
            <AbaRecieptModal
              recieptData={recieptData}
              setShowPreview={setShowPreview}
              showPreview={showPreview}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ABAReciept;
