/* eslint-disable no-nested-ternary */
import { Select, DatePicker, Input, Spin, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import SidebarNav from '../../components/Header/SidebarNav';
import { API, fileUpload, get } from '../../config';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
// import UserSelect from '../../components/User/UserSelect';
import { useNavigate, useLocation } from 'react-router-dom';
import numbro from 'numbro';
import AllClientsSelect from '../../components/User/AllClientsSelect';
import NumberFormat from 'react-number-format';

const { TextArea } = Input;

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const numberformat = (value) => {
  try {
    let format = numbro(value).format({
      // thousandSeparated: true,
      // negative: 'parenthesis',
      mantissa: 2
    });
    return format;
  } catch {
    return '';
  }
};

const RecordReceipt = ({
  bankReconcilation = false,
  reconcileFunction,
  toggleAddModal,
  itemJson
}) => {
  const { state } = useLocation();

  const defaultEntryData = [
    {
      chart_account_id: '',
      id: '',
      amount: itemJson?.amount
        ? numberformat(itemJson?.amount)
        : state?.amount
        ? numberformat(state.amount)
        : '',
      description: itemJson?.description
        ? itemJson?.description
        : state?.description
        ? state.description
        : ''
    }
  ];
  // const newEntryData = {
  //   chart_account_id: '',
  //   id: '',
  //   amount: '',
  //   description: ''
  // };
  const { Option } = Select;
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [fileNote, setFileNote] = useState('');
  const [transactionDate, setTransactionDate] = useState(
    itemJson?.transactionDate
      ? itemJson?.transactionDate
      : state?.transactionDate
      ? state?.transactionDate
      : new Date()
  );
  const [accountLoading, setAccountLoading] = useState(false);
  const [amountErrors, setAmountErrors] = useState({});
  const [finalData, setFinalData] = useState(defaultEntryData);
  const [descriptionErrors, setDescriptionErrors] = useState({});
  const [showPostModal, setShowPostModal] = useState(false);

  const navigate = useNavigate();

  const togglePostModal = () => {
    setShowPostModal(!showPostModal);
  };

  const getAccountData = () => {
    setAccountLoading(true);
    get(`${API.GET_CHARTACCOUNT}?limit=-1`)
      .then((response) => {
        response?.data?.data?.data &&
          response?.data?.data?.data?.length > 0 &&
          response?.data?.data?.data?.map((item) => {
            if (item.code == '3030') {
              setFinalData([
                {
                  ...finalData[0],
                  chart_account_id: item?.id ?? ''
                }
              ]);
            }
          });
        setAccountOptions(response?.data?.data?.data ? response?.data?.data?.data : []);
        response && setAccountLoading(false);
      })
      .catch((e) => {
        setAccountLoading(false);
        toast.error(e?.data?.message);
      });
  };

  useEffect(() => {
    getAccountData();
  }, []);

  const handleNewAccountSelection = (val, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, chart_account_id: val };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleAmount = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, amount: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handleBlurAmount = (e, index) => {
    let errors = amountErrors;
    let regex = new RegExp(/^\d*\.?\d*$/);
    if (e) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index] = 'Enter valid Amount.';
      } else {
        errors[index] = null;
      }
    } else {
      toast.error('Something went wrong.');
    }
    setAmountErrors({ ...errors });
  };

  const handleBlurDescription = (e, index) => {
    let errors = descriptionErrors;
    let regex = new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/);
    if (e) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index] = 'Enter valid Description.';
      } else {
        errors[index] = null;
      }
    } else {
      toast.error('Something went wrong.');
    }
    setDescriptionErrors({ ...errors });
  };

  const handleDescription = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handlePostDisabled = (finalData) => {
    const amountError = Object.values(amountErrors);
    const descriptionError = Object.values(descriptionErrors);
    const checkAmountError = Object.values(
      amountError.filter((item, index) => amountError.indexOf(item) === index)
    );
    const checkDescriptionError = Object.values(
      descriptionError.filter((item, index) => descriptionError.indexOf(item) === index)
    );
    const checkData = finalData?.map((element, index) => {
      if (
        (checkAmountError[index] === null || checkAmountError[index] === undefined) &&
        (checkDescriptionError[index] === null || checkDescriptionError[index] === undefined) &&
        element?.chart_account_id &&
        element?.amount &&
        element?.description
      ) {
        return false;
      } else {
        return true;
      }
    });
    return checkData?.find((item) => item === true) || !transactionDate || !fileNote ? true : false;
  };

  const handlePost = async (finalData, status) => {
    const formData = new FormData();
    formData.append('transaction_date', moment(transactionDate).format('DD-MM-YYYY'));
    formData.append('note', fileNote ? fileNote : '');
    finalData?.forEach((item, index) => {
      if (item) {
        formData.append(
          `receipts[${index}][chart_account_id]`,
          item?.chart_account_id ? item?.chart_account_id : ''
        );
        if (item?.type === 2) {
          formData.append(`receipts[${index}][supplier_id]`, item?.id ? item?.id : '');
        } else {
          formData.append(`receipts[${index}][customer_id]`, item?.id ? item?.id : '');
        }
        formData.append(`receipts[${index}][amount]`, item?.amount ? item?.amount : '');
        formData.append(
          `receipts[${index}][description]`,
          item?.description ? item?.description : ''
        );
      }
    });
    formData.append('send_receipt', status);
    try {
      setPostLoading(true);
      const response = await fileUpload(`${API.CREATE_RECIEPT}`, formData, {});
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        setFinalData([]);
        setTransactionDate(moment());
        setFileNote('');
        if (bankReconcilation === true) {
          reconcileFunction();
          toggleAddModal();
        } else {
          navigate('/receipts');
        }
      }
      setPostLoading(false);
      setShowPostModal(false);
    } catch (error) {
      setPostLoading(false);
      setShowPostModal(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  // const handleAddEntry = (data) => {
  //   setLoading(false);
  //   setFinalData([...data, newEntryData]);
  // };
  // const handleDeleteEntry = (data, index) => {
  //   data.splice(index, 1);
  //   setFinalData([...data]);
  // };

  const handleCLientSelect = (val, index, finalData, options) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        let typeData = options?.find((item) => item.id == val);
        return { ...obj, id: val, type: typeData?.type };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        {bankReconcilation ? <></> : <SidebarNav />}
        <div
          className="full-content-block"
          style={bankReconcilation ? { height: 'auto', margin: '0px' } : {}}
        >
          {bankReconcilation ? (
            <></>
          ) : (
            <h1 className="page-title">
              <BackButton />
              Enter Trust Receipt
            </h1>
          )}
          <div
            className="content-details refund-process"
            style={bankReconcilation ? { padding: '30px', marginTop: '0px' } : {}}
          >
            <div className="table-top-btn"></div>
            <div style={{ width: '100%', height: '89%' }}>
              <div id="grid-wrapper" style={{ width: '100%', height: '100%' }}>
                <div id="myGrid" className="ag-theme-alpine ato-grid">
                  <Spin spinning={loading}>
                    <Form>
                      <div className="form-box">
                        <div className="refund-process-box">
                          <Container>
                            <Container>
                              <Row className="refund-top-details record-payment">
                                <Col xs={12} md={2}>
                                  <strong>Transaction Date</strong>
                                  <DatePicker
                                    format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                                    disabledDate={disabledDate}
                                    defaultValue={moment(transactionDate)}
                                    onChange={(e) => {
                                      e && setTransactionDate(e);
                                    }}
                                    allowClear={false}
                                  />
                                </Col>
                                <Col xs={12} md={3}>
                                  <span
                                    style={{
                                      color: 'red',
                                      marginRight: '4px',
                                      fontSize: ' 18px'
                                    }}
                                  >
                                    *
                                  </span>
                                  <strong>File Note</strong>
                                  <Form.Control
                                    type="text"
                                    className="left-text-align"
                                    required
                                    maxLength={150}
                                    value={fileNote ? fileNote : ''}
                                    rules={[
                                      {
                                        pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                                        message:
                                          'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                                      }
                                    ]}
                                    onChange={(e) => {
                                      setFileNote(e.target.value);
                                    }}
                                  ></Form.Control>
                                </Col>
                                <Col xs={12} md={7}></Col>
                              </Row>
                              <Row className="refund-card-box record-payment">
                                <Col xs={6} md={12}>
                                  <div className="refund-card">
                                    <label>Receipt Details</label>
                                    <div className="refund-card-content">
                                      <div className="refund-card-content-label refund-card-title row col-md-12">
                                        <h6 className="col-md-2 center-align">Account</h6>
                                        <h6 className="col-md-3 center-align">Client Name</h6>
                                        <h6 className="col-md-3 center-align">Amount</h6>
                                        <h6 className="col-md-4 center-align">Description</h6>
                                        {/* <h6 className="col-md-1 center-align"></h6> */}
                                      </div>
                                      <div className="refund-card-content-row">
                                        {finalData &&
                                          finalData?.map((item, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="refund-card-content-label row col-md-12"
                                              >
                                                <div className="col-md-2 refund-column">
                                                  <Select
                                                    showSearch
                                                    style={{
                                                      width: '100%',
                                                      margin: '0'
                                                    }}
                                                    loading={accountLoading}
                                                    onChange={(id) => {
                                                      handleNewAccountSelection(
                                                        id,
                                                        index,
                                                        finalData
                                                      );
                                                    }}
                                                    filterOption={(input, option) =>
                                                      option.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    value={
                                                      item?.chart_account_id &&
                                                      accountOptions?.length > 0 &&
                                                      accountOptions?.find(
                                                        (val) => val?.id === item?.chart_account_id
                                                      ) &&
                                                      item?.chart_account_id
                                                    }
                                                  >
                                                    {accountOptions &&
                                                      accountOptions.map((option) => {
                                                        return (
                                                          <Option key={option.id} value={option.id}>
                                                            {option?.name +
                                                              (option?.account_type?.name
                                                                ? ' (' +
                                                                  option?.account_type?.name +
                                                                  ')'
                                                                : '') +
                                                              (option?.code
                                                                ? ' - ' + option?.code.toString()
                                                                : '') +
                                                              (option?.sub_code
                                                                ? '.' + option?.sub_code.toString()
                                                                : '')}
                                                          </Option>
                                                        );
                                                      })}
                                                  </Select>
                                                </div>
                                                <div className="col-md-3 refund-column">
                                                  <AllClientsSelect
                                                    formStyle={{ width: '100%' }}
                                                    isLoadingCustomerDetail={loading}
                                                    onCustomerSelectionChange={(val, options) => {
                                                      handleCLientSelect(
                                                        val,
                                                        index,
                                                        finalData,
                                                        options
                                                      );
                                                    }}
                                                    customerId={item?.id}
                                                    placeholder="Please select Client"
                                                    className="customer-select"
                                                  />
                                                  {/* <span className="text-danger">
                                                    {clientErrors[index] ? clientErrors[index] : ''}
                                                  </span> */}
                                                </div>
                                                <div className="col-md-3 refund-column">
                                                  <NumberFormat
                                                    value={item?.amount ? item?.amount : ''}
                                                    decimalSeparator="."
                                                    type="text"
                                                    placeholder="Add Amount"
                                                    thousandSeparator={false}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    onChange={(e) => {
                                                      console.log(e);
                                                      handleAmount(e, index, finalData);
                                                      handleBlurAmount(e, index);
                                                    }}
                                                    onBlur={(e) => {
                                                      handleBlurAmount(e, index);
                                                    }}
                                                    style={{
                                                      border: '1px solid #d9d9d9',
                                                      padding: '5px 10px',
                                                      fontSize: '14px',
                                                      width: '100%',
                                                      height: '30px',
                                                      background: '#FFF',
                                                      borderRadius: '30px'
                                                    }}
                                                  />
                                                  <span className="error">
                                                    {amountErrors[index] ? amountErrors[index] : ''}
                                                  </span>
                                                  {/* <Form.Group>
                                                    <Form.Control
                                                      required
                                                      type="text"
                                                      placeholder="Add Amount"
                                                      disabled={item?.amount ? bankReconcilation : false}
                                                      value={item?.amount ? item?.amount : ''}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'Please enter Opening Balance'
                                                        },
                                                        {
                                                          pattern: new RegExp(/^\d*\.?\d*$/),
                                                          message: 'Value should be number'
                                                        }
                                                      ]}
                                                      onChange={(e) => {
                                                        handleAmount(e, index, finalData);
                                                        handleBlurAmount(e, index);
                                                      }}
                                                      onBlur={(e) => {
                                                        handleBlurAmount(e, index);
                                                      }}
                                                      style={{
                                                        border: '1px solid #d9d9d9',
                                                        padding: '5px 10px',
                                                        fontSize: '14px',
                                                        width: '100%'
                                                      }}
                                                    />
                                                    <span className="text-danger">
                                                      {amountErrors[index]
                                                        ? amountErrors[index]
                                                        : ''}
                                                    </span>
                                                  </Form.Group> */}
                                                </div>
                                                <div className="col-md-4 refund-column">
                                                  <Form.Group>
                                                    <TextArea
                                                      name="description"
                                                      placeholder="Add Description"
                                                      rows={2}
                                                      value={
                                                        item?.description ? item?.description : ''
                                                      }
                                                      onChange={(e) => {
                                                        handleDescription(e, index, finalData);
                                                        handleBlurDescription(e, index);
                                                      }}
                                                      onBlur={(e) => {
                                                        handleBlurDescription(e, index);
                                                      }}
                                                    />
                                                    <span className="error">
                                                      {descriptionErrors[index]
                                                        ? descriptionErrors[index]
                                                        : ''}
                                                    </span>
                                                  </Form.Group>
                                                </div>
                                                {/* <div className="col-md-1 receipt-column">
                                                  {index + 1 === finalData?.length ? (
                                                    <>
                                                      <FontAwesomeIcon
                                                        onClick={() => {
                                                          handleAddEntry(finalData, index);
                                                        }}
                                                        icon={faAdd}
                                                        size="1x"
                                                      />
                                                      {index > 0 && (
                                                        <>
                                                          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                                          <FontAwesomeIcon
                                                            onClick={() => {
                                                              handleDeleteEntry(finalData, index);
                                                            }}
                                                            icon={faTrash}
                                                            size="1x"
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      onClick={() => {
                                                        handleDeleteEntry(finalData, index);
                                                      }}
                                                      icon={faTrash}
                                                      size="1x"
                                                    />
                                                  )}
                                                </div> */}
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div className="refund-card-content-btn-small">
                                        <Button
                                          className="btn-small"
                                          disabled={handlePostDisabled(finalData)}
                                          onClick={() => {
                                            togglePostModal();
                                          }}
                                        >
                                          Post
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Container>
                        </div>
                      </div>
                    </Form>
                  </Spin>
                </div>
              </div>
            </div>
            {showPostModal && (
              <Modal
                className="remove-popup confirmModal"
                width={500}
                closable
                centered
                open
                footer={null}
                onCancel={togglePostModal}
              >
                <div className="remove-content" style={{ position: 'relative' }}>
                  {/* <p>Do you want to send the receipt to client ?</p> */}
                  <p>Are your sure you want to proceed with this transaction?</p>
                  <div className="modal-action">
                    <Button
                      className="cancel"
                      // onClick={() => handlePost(finalData, 0)}
                      onClick={() => togglePostModal()}
                      disabled={postLoading ? true : false}
                    >
                      No
                    </Button>
                    <Button
                      onClick={() => handlePost(finalData, 0)}
                      disabled={postLoading ? true : false}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
                {postLoading && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 3
                    }}
                  >
                    <Spin />
                  </div>
                )}
              </Modal>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RecordReceipt;
