import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import '../../App.css';
import '../../assets/css/style.css';
import SidebarNav from '../../components/Header/SidebarNav';
const SubscriptionNotFound = () => {
  const navigate = useNavigate();

  const redirectLogin = () => {
    navigate('/settings?tab=subscription&childTab=more-packages', { replace: true });
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="subscription-notfound full-content-block">
          <h3>Subscription required</h3>
          {/* <p>Please Click on below button to Active your Subscription First</p> */}
          <p>Please click the button below to activate your subscription.</p>

          <Button className="btn-primary btn" onClick={redirectLogin}>
            Check your plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionNotFound;
