import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import SidebarNav from '../components/Header/SidebarNav';
import Footer from '../components/Footer/Index';
// import DiscountCode from './general-setting/DiscountCode/DiscountCode';
// import Tasks from './general-setting/Task/Tasks';
// import Jobs from './general-setting/Job/Jobs';
import ChartAccount from './general-setting/ChartAccount/ChartAccount';
import FirmSetting from './general-setting/FirmSetting/FirmSetting';
import OfficeList from './general-setting/Office/OfficeList';
import SupplierList from './general-setting/Supplier/SupplierList';
import Beneficiaries from './general-setting/Beneficiaries/Beneficiaries';
import BackButton from '../components/Form/BackButton';
import WithdrawalAccount from './general-setting/WithdrawalAccount/WithdrawalAccount';
import '../App.css';
import '../assets/css/style.css';
import Integration from './general-setting/Integration/Integration';
import DialogModal from '../components/Header/DialogModal';
import { helpCenterArray } from './HelpCenter';
// import InvoiceAmounts from './general-setting/InvoiceAmounts/InvoiceAmounts';
import Subscription from './general-setting/Subscription/Subscription';
import { GetCookies } from '../APIConfig/Interceptor';

const tabsKey = {
  // tasks: 1,
  // jobs: 2,
  'chart-of-accounts': 3,
  // 'discount-codes': 4,
  integrations: 5,
  'firm-settings': 1,
  office: 2,
  supplier: 4,
  personal: 6,
  beneficiaries: 7,
  subscription: 9
  // 'invoice-amounts': 8
};

// const { TabPane } = Tabs;
const Setting = () => {
  let subscription = GetCookies('_subscription');
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  const defaultActiveKey = tabsKey[selectedTab];

  const [helpText, setHelpText] = useState('');

  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      if (activeKey === 1) {
        setHelpText(helpCenterArray[29].text);
      } else if (activeKey === 2) {
        setHelpText(helpCenterArray[30].text);
      }
      const queryParams = `tab=${tabName}`;
      // if (activeKey === '3') {
      //   queryParams = `tab=${tabName}&chartAccounts=expenses`;
      // }
      navigate(`/settings?${queryParams}`, {
        replace: true
      });
    }
  };

  const settingsTabItem = [
    {
      key: "1",
      label: "Firm Settings",
      disabled: (subscription == 1 ? false : true),
      children: (subscription == 1 ? <FirmSetting /> : <Subscription />)
    },
    {
      key: "6",
      label: "Withdrawal Account",
      disabled: (subscription == 1 ? false : true),
      children: (subscription == 1 ? <WithdrawalAccount /> : <Subscription />)
    },
    {
      key: "5",
      label: "Integrations",
      disabled: (subscription == 1 ? false : true),
      children: (subscription == 1 ? <Integration /> : <Subscription />)
    },
    {
      key: "2",
      label: "Office",
      disabled: (subscription == 1 ? false : true),
      children: (subscription == 1 ? <OfficeList /> : <Subscription />)
    },
    {
      key: "3",
      label: "Chart of Accounts",
      disabled: (subscription == 1 ? false : true),
      children: (subscription == 1 ? <ChartAccount /> : <Subscription />)
    },
    {
      key: "4",
      label: "Supplier",
      disabled: (subscription == 1 ? false : true),
      children: (subscription == 1 ? <SupplierList /> : <Subscription />)
    },
    {
      key: "7",
      label: "Beneficiaries",
      disabled: (subscription == 1 ? false : true),
      children: (subscription == 1 ? <Beneficiaries /> : <Subscription />)
    },
    {
      key: "9",
      label: "Subscription",
      disabled: (subscription == 1 ? false : true),
      children: (<Subscription />)
    }
  ]
  return (
    <div className="cms-page settings-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton /> Settings
            <DialogModal
              title="Settings"
              // p1={helpCenterArray[31].name}
              p2={helpText}
              // p2={helpCenterArray[29].text}
              // p3={helpCenterArray[38].text}
              // p4={helpCenterArray[45].text}
              // p5={helpCenterArray[48].text}
              // p6={helpCenterArray[49].text}
            />
          </h1>
          <Tabs
            defaultActiveKey={defaultActiveKey?.toString()}
            size="large"
            style={{ marginBottom: 32 }}
            onTabClick={onTabClick}
            items={settingsTabItem}
          >
            {/* <TabPane tab='Tasks' key='1'>
              <Tasks />
            </TabPane>
            <TabPane tab='Jobs' key='2'>
              <Jobs />
            </TabPane> */}
            {/* <TabPane tab='Discount Codes' key='4'>
              <DiscountCode />
            </TabPane> */}
            {/* <TabPane tab="Firm Settings" key="1" disabled={subscription == 1 ? false : true}>
              {subscription == 1 ? <FirmSetting /> : <Subscription />}
            </TabPane>
            <TabPane tab="Office" key="2" disabled={subscription == 1 ? false : true}>
              {subscription == 1 ? <OfficeList /> : <Subscription />}
            </TabPane>
            <TabPane tab="Chart of Accounts" key="3" disabled={subscription == 1 ? false : true}>
              {subscription == 1 ? <ChartAccount /> : <Subscription />}
            </TabPane>
            <TabPane tab="Beneficiaries" key="7" disabled={subscription == 1 ? false : true}>
              {subscription == 1 ? <Beneficiaries /> : <Subscription />}
            </TabPane>
            <TabPane tab="Supplier" key="4" disabled={subscription == 1 ? false : true}>
              {subscription == 1 ? <SupplierList /> : <Subscription />}
            </TabPane>
            <TabPane tab="Integrations" key="5" disabled={subscription == 1 ? false : true}>
              {subscription == 1 ? <Integration /> : <Subscription />}
            </TabPane>
            <TabPane tab="Withdrawal Account" key="6" disabled={subscription == 1 ? false : true}>
              {subscription == 1 ? <WithdrawalAccount /> : <Subscription />}
            </TabPane> */}
            {/* <TabPane tab="Invoice Amounts" key="8">
              <InvoiceAmounts />
            </TabPane> */}
            {/* <TabPane tab="Subscription" key="9">
              <Subscription />
            </TabPane> */}
          </Tabs>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Setting;
