import React, { useState } from "react";
import { getNextPageData, sortData } from "./DashboardHelper";
import ProcessedElement from "./ProcessedElement";
import InfinityScrollComponent from "./InfinityScrollComponent";

const Processed = ({
  returnLodged,
  ATOList,
  returnAssessed,
  underReview,
  processed,
  sortValue,
  updateState,
  filter
}) => {
  const [lastElement, setLastElement] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const processedData = processed?.data;

  const loadMore = async () => {
    setIsLoading(true);
    const nextPageData = await getNextPageData(processed.current_page + 1, 'processed', filter);
    const newObj = {
      ...nextPageData,
      data: [...processedData, ...nextPageData.data]
    };
    const data = sortData(sortValue, returnLodged, ATOList, returnAssessed, underReview, newObj);
    updateState(data);
    setIsLoading(false);
    const isMoreDataExist =
      newObj.data.length < newObj.total &&
      nextPageData?.next_page_url !== '' &&
      nextPageData?.next_page_url !== null;
    setHasMore(isMoreDataExist);
  };

  const isLastItem = (idx) => {
    return idx === processedData?.length - 1;
  };
  const createLists = () => {
    return processedData?.map((item, idx) => {
      return isLastItem(idx) ? (
        <ProcessedElement key={idx} item={item} ref={setLastElement} />
      ) : (
        <ProcessedElement key={idx} item={item} />
      );
    });
  };

  return (
    <>
      <InfinityScrollComponent
        hasMore={hasMore}
        isLoading={isLoading}
        loadMore={loadMore}
        loadOnMount={true}
        lastItemRef={lastElement}
      >
        {createLists()}
      </InfinityScrollComponent>
      {isLoading && <li className="card-box">Loading...</li>}
    </>
  );
};

export default Processed;
