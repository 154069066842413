import React from 'react';
import '../App.css';
import '../assets/css/style.css';
import Header from '../components/Header/Index';
import SidebarNav from '../components/Header/SidebarNav';

class Privacy extends React.Component {
  render() {
    return (
      <div className="cms-page">
        <div className="page-content-block">
          <Header />
          <SidebarNav />
        </div>
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">Privacy</h1>
            <div className="content-details description-block">
              <h3>We respect your privacy</h3>
              <ul className="custom-list">
                <li>
                  Myna Australia Pty Ltd respects your right to privacy and is committed to
                  safeguarding the privacy of our customers and website visitors. We adhere to the
                  Australian Privacy Principles contained in the Privacy Act 1988 (Cth). This policy
                  sets out how we collect and treat your personal information.
                </li>
                <li>
                  “Personal information” is information we hold which is identifiable as being about
                  you.
                </li>
              </ul>
              <h3>Collection of personal information</h3>
              <ul className="custom-list">
                <li>
                  Myna Australia Pty Ltd will, from time to time, receive and store your and your
                  client’s personal information you enter onto our website, provided to us directly
                  or given to us in other forms.
                </li>
                <li>
                  You may provide basic information such as yourself and your business’s name, phone
                  number, address, Tax Agent number, Trust bank account details, your client’s
                  personal and confidential information and email addresses to enable us to send
                  information, provide updates and process your product or service on the website.
                  We may collect additional information at other times, including but not limited
                  to, when you provide feedback, when you provide information about your personal or
                  business affairs, change your content or email preference, respond to surveys
                  and/or promotions, provide financial or credit card information, or communicate
                  with our customer support.
                </li>
                <li>
                  Additionally, we may also collect any other information including your client’s
                  personal information, especially Tax File Numbers, banking and refund data you
                  provide while interacting with us to assist you with managing your trust account.
                  If you prefer not to provide us with personal information, it implies that you
                  can’t use most parts of our websites or services.
                </li>
              </ul>
              <h3>How we collect your personal information</h3>
              <p>
                Myna Australia Pty Ltd collects personal information from you in a variety of ways,
                including when you interact with us electronically or in person, when you access our
                website and when we provide our services to you. We may receive personal information
                from third parties. If we do, we will protect it as set out in this Privacy Policy.
              </p>
              <h3>Use of your personal information</h3>
              <ul className="custom-list">
                <li>
                  Myna Australia Pty Ltd may use personal information collected from you to provide
                  you with information, updates and our services. We may also make you aware of new
                  and additional products, services and opportunities available to you. We may use
                  your personal information to improve our products and services and better
                  understand your needs
                </li>
                <li>
                  Myna Australia Pty Ltd may contact you by a variety of measures including, but not
                  limited to telephone, email, sms, whatsapp, chat or mail.
                </li>
              </ul>
              <h3>Disclosure of your personal information</h3>
              <ul className="custom-list">
                <li>
                  We may disclose your personal information to any of our employees, officers,
                  insurers, professional advisers, agents, suppliers, contractors or subcontractors
                  insofar as reasonably necessary for the purposes set out in this Policy. Personal
                  information is only supplied to a third party when it is required for the delivery
                  of our services.
                </li>
                <li>
                  We may from time to time need to disclose personal information to comply with a
                  legal requirement, such as a law, regulation, court order, subpoena, warrant, in
                  the course of a legal proceeding or in response to a law enforcement agency
                  request.
                </li>
                <li>
                  We may also use your personal information to protect the copyright, trademarks,
                  legal rights, property or safety of Myna Australia Pty Ltd, myna.com.au,
                  trusteasy.com.au, its customers or third parties.
                </li>
                <li>
                  Information that we collect may from time to time be stored, processed in or
                  transferred between parties located in countries outside of Australia.
                </li>
                <li>
                  If there is a change of control in our business or a sale or transfer of business
                  assets, we reserve the right to transfer to the extent permissible at law our user
                  databases, together with any personal information and non-personal information
                  contained in those databases. This information may be disclosed to a potential
                  purchaser under an agreement to maintain confidentiality. We would seek to only
                  disclose information in good faith and where required by any of the above
                  circumstances.
                </li>
                <li>
                  By providing us with personal information, you consent to the terms of this
                  Privacy Policy and the types of disclosure covered by this Policy. Where we
                  disclose your personal information to third parties, we will request that the
                  third party follow this Policy regarding handling your personal information.
                </li>
              </ul>
              <h3>Security of your personal information</h3>
              <ul className="custom-list">
                <li>
                  Myna Australia Pty Ltd is committed to ensuring that the information you provide
                  to us is secure. To prevent unauthorised access or disclosure, we have put in
                  place suitable physical, electronic and managerial procedures to safeguard and
                  secure information and protect it from misuse, interference, loss and unauthorised
                  access, modification and disclosure.
                </li>
                <li>
                  The transmission and exchange of information is carried out at your own risk. We
                  cannot guarantee the security of any information that you transmit to us or
                  receive from us. Although we take measures to safeguard against unauthorised
                  disclosures of information, we cannot assure you that personal information that we
                  collect will not be disclosed in a manner that is inconsistent with this Privacy
                  Policy.
                </li>
              </ul>
              <h3>Access to your personal information</h3>
              <ul className="custom-list">
                <li>
                  You may request details of personal information that we hold about you in
                  accordance with the provisions of the Privacy Act 1988 (Cth). A small
                  administrative fee may be payable for the provision of information. If you would
                  like a copy of the information, which we hold about you or believe that any
                  information we hold on you is inaccurate, out of date, incomplete, irrelevant, or
                  misleading, please email us at dc@myna.com.au.
                </li>
                <li>
                  We reserve the right to refuse to provide you with information that we hold about
                  you, in certain circumstances set out in the Privacy Act.
                </li>
              </ul>
              <h3>Complaints about privacy</h3>
              <p>
                If you have any complaints about our privacy practises, please feel free to send in
                details of your complaints to PO Box 6468, Norwest, 2153. We take complaints very
                seriously and will respond shortly after receiving written notice of your complaint.
              </p>
              <h3>Changes to Privacy Policy</h3>
              <p>
                Please be aware that we may change this Privacy Policy in the future. We may modify
                this Policy at any time, in our sole discretion and all modifications will be
                effective immediately upon our posting of the modifications on our website or notice
                board. Please check back from time to time to review our Privacy Policy.
              </p>
              <h3>Website</h3>
              <ul className="custom-list">
                <li>
                  When you visit our website
                  <br />
                  When you come to our website (myna.com.au) we may collect certain information such
                  as browser type, operating system, parts of the website you visited and for how
                  long, website visited immediately before coming to our site, etc. This information
                  is used in a manner to analyse how people use our site so that we can improve our
                  service.
                </li>
                <li>
                  Cookies
                  <br />
                  We may from time to time use cookies on our website. Cookies are very small files
                  which a website uses to identify you when you come back to the site and to store
                  details about your use of the site. Cookies are not malicious programs that access
                  or damage your computer. Most web browsers automatically accept cookies but you
                  can choose to reject cookies by changing your browser settings. However, this may
                  prevent you from taking full advantage of our website. Our website may from time
                  to time use cookies to analyses website traffic and help us provide a better
                  website visitor experience. In addition, cookies may be used to serve relevant ads
                  to website visitors through third party services such as Google
                  <br />
                  Adwords. These ads may appear on this website or other websites you visit.
                </li>
                <li>
                  Third party sites
                  <br />
                  Our site may from time to time have links to other websites not owned or
                  controlled by us. These links are meant for your convenience only, for example
                  integration with third party CRM systems, Tax Lodgement software, the ATO,
                  financial provider, e-sign providers, or banking data providers. Links to third
                  party websites do not constitute sponsorship or endorsement or approval of these
                  websites. Please be aware that Myna Australia Pty Ltd is not responsible for the
                  privacy practises of other such websites. We encourage our users to be aware, when
                  they leave our website, to read the privacy statements of each and every website
                  that collects personal identifiable information.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
