import React from 'react';
import { Button, Input } from 'antd';
import Form from '@component/Form';

const AddCommentReview = (props) => {
  const [form] = Form.useForm();
  const initialValues = {
    comment: props.commentValue
  };

  const handleFinish = (values) => {
    props.handleCommentFinish(values);
  };

  return (
    <div className="content-details">
      <Form
        form={form}
        name="addCommentReviewForm"
        initialValues={initialValues}
        layout="vertical"
        onFinish={handleFinish}
      >
        <div className="entry-card full-card">
          <div className="form-box">
            <Form.Item label="Comment" name="comment" className="half-width">
              <Input placeholder="Please enter comment/Invoice Number" />
            </Form.Item>
          </div>
        </div>
        <div className="action-box">
          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddCommentReview;
