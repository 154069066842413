/* eslint-disable no-nested-ternary */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { API, get } from '../../config';
import { NegativeAmountConverter } from '../../helper/Conversions';
import { FullNameGetter } from '../../helper/fullNameGetter';
import OpeningActionButtons from './OpeningBalanceActionButtons';
import { Link } from 'react-router-dom';

export const openingBalanceColumns = (handleDelete) => {
  return [
    {
      title: 'Name',
      //   sorter: (a, b) =>
      //     (a.first_name ? a.first_name + a.last_name : a.last_name).localeCompare(
      //       b.first_name ? b.first_name + b.last_name : b.last_name
      //     ),
      key: 'name',
      render: (record) => {
        return (
          <>
            {record?.first_name || record?.last_name ? (
              record?.deleted_at ? (
                <Link
                  to={`/deleted-client?page=1&search=${
                    record?.first_name
                      ? record?.first_name + ' ' + record?.last_name
                      : record?.last_name
                  }`}
                  target="_blank"
                  style={{
                    color: 'red'
                  }}
                >
                  {FullNameGetter(record)}
                </Link>
              ) : (
                FullNameGetter(record)
              )
            ) : record?.deleted_at ? (
              <Link
                to={`/deleted-client?page=1&search=${record?.bussiness_name}`}
                target="_blank"
                style={{
                  color: 'red'
                }}
              >
                {record?.bussiness_name?.charAt(0) + record?.bussiness_name}
              </Link>
            ) : (
              record?.bussiness_name?.charAt(0) + record?.bussiness_name
            )}
          </>
        );
      },
      className: 'fullname',
      width: '50%'
    },
    {
      title: 'Opening Balance',
      // sorter: (a, b) => a.closing_balance - b.closing_balance,
      render: (record) => (
        <>{record?.opening_balance ? NegativeAmountConverter(record?.opening_balance) : '$0.00'}</>
      ),
      // <span>{'$' + record?.opening_balance?.toFixed(2)}</span>,
      key: 'opening_balance',
      className: 'centeralign',
      width: '30%'
    },
    {
      title: 'Action',
      key: 'id',
      width: '20%',
      render: (record) => (
        <OpeningActionButtons
          data={record}
          id={record?.id}
          type="customer"
          handleDelete={handleDelete}
        />
      )
    }
  ];
};

export const useRelatedTo = () => {
  const [relatedList, setRelatedList] = useState([]);
  const [relatedLoading, setRelatedLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRelatedLoading(true);
      const { data } = await get(API.GET_CUSTOMERLIST);
      setRelatedLoading(false);
      setRelatedList(data.data.data);
    } catch (e) {
      setRelatedList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    relatedList,
    relatedLoading
  };
};

export const getOpeningBalanceDetail = async (id) => {
  try {
    const { data } = await get(`${API.OPENING_BALANCE}/` + id);
    return data.data;
  } catch (e) {
    return null;
  }
};
