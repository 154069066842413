import React from "react";
import Form from "../Form";

const MynaForm = (props) => {
    const { formProps, Component } = props

    const getSelectTag = () => {
        return <Form.Item
            {...formProps}
        >
            {Component}
        </Form.Item>
    }

    return (
        getSelectTag()
    );
};

export default MynaForm;
