import React, { useState } from "react";
import { Button, Input, Form } from 'antd';
import { API, post } from "@src/config.js";
import "../../components/Authentication/authentication.css"
import { toast } from 'react-toastify';


const ConfirmAuthentication = (props) => {
    const { submitButton, verifyUrl } = props
    // const [ current, setCurrent ] = useState(0);
    const [ validator, setValidator ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    // const [ enterCode, setEnterCode ] = useState(false);
    const [ verifyCode, setVerifyCode ] = useState("");

    // const next = () => {
    //     setCurrent(current + 1);
    // };

    const changeAuthenticationCode = (event) => {
        if (validator) {
            setValidator(false)
        }
        setVerifyCode(event.target.value)
        // setEnterCode(true)
    }

    const confirmCode = async () => {
        setLoading(true)
        let params = { code: verifyCode }
        if (verifyUrl === API.DELETE_2FA) {
            params.id = 1
        }
        post(verifyUrl, params)
            .then(() => {
                setValidator(false)
                submitButton()
                setLoading(false)
            })
            .catch(() => {
                toast.error("Authentication code is invalid");
                setLoading(false)
                setValidator(true)
            });
    }

    return (
        <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={confirmCode}
            autoComplete="off"
        >
            <p>Enter the 6-digit code found in your authenticator app</p>
            <Form.Item
                label=""
                name="authCode"
                rules={[
                    { required: true, message: 'Please input your authentication code!' },
                    { required: true, message: 'authentication code length should be 6', pattern: new RegExp(/^[0-9]{6}$/) },
                    () => ({
                        validator() {
                            if (validator) {
                                return Promise.reject("Authentication code is invalid")
                            }
                            return Promise.resolve();
                        },
                    })
                ]}
            >
                <Input autoFocus placeholder="Authentication Code" onChange={changeAuthenticationCode} />
            </Form.Item>
            <Form.Item >
                <Button htmlType="submit" loading={loading}>
                    Confirm
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ConfirmAuthentication;
