/* eslint-disable no-nested-ternary */
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../../config.js';
import { checkRole } from '../../../helper/utils.js';

const preocessUserList = (data) => {
  return data.map((user) => {
    return {
      ...user
    };
  });
};
// const roleName = localStorage.getItem('role_name');
const roleType = localStorage.getItem('role_type');

const useSupportList = (currentPage, search) => {
  const navigate = useNavigate();
  const [supportTicket, setSupportTicket] = useState([]);
  const query = new URLSearchParams(location.search);
  const [searchParam, setSearchParam] = useState(search || '');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchSupportTickets = useCallback(
    async (page, limit) => {

      try {
        setIsDataLoading(true);
        const { data } = await get(
          `${
            checkRole(roleType) ? API.ADMIN_SUPPORT_TICKET : API.SUPPORT_TICKET
          }?search=${searchParam}&page=${page || pagingData.current}&limit=${
            limit ? limit : query.get('limit') ? query.get('limit') : defaultPaginationLimit
          }`
        );
        setIsDataLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        const userData = preocessUserList(data.data.data);
        setSupportTicket(userData);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setSupportTicket([]);
        setIsDataLoading(false);
        setPagingData({ current: 0 });
      }
    },
    [pagingData.current, searchParam]
  );

  useEffect(() => {
    fetchSupportTickets();
  }, [fetchSupportTickets]);

  return {
    fetchSupportTickets,
    supportTicket,
    searchParam,
    isDataLoading,
    pagingData,
    onChange: (e) => {
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      if (checkRole(roleType)) {
        navigate(`/admin/support-tickets?search=${value}&page=1`, {
          replace: true
        });
      } else {
        navigate(`/support-tickets?search=${value}&page=1`, {
          replace: true
        });
      }
    },
    onPageChange: (page, limit) => {
      setPagingData({ ...pagingData, current: page });
      if (checkRole(roleType)) {
        navigate(`/admin/support-tickets?search=${searchParam}&page=${page}&limit=${limit}`, {
          replace: true
        });
      } else {
        navigate(`/support-tickets?search=${searchParam}&page=${page}&limit=${limit}`, {
          replace: true
        });
      }
    }
  };
};

export default useSupportList;
