import React, { useState, useEffect } from "react";
import { Steps, Button, message, Image, Input } from 'antd';
import { API, post } from "@src/config.js";
import "./authentication.css"

const { Step } = Steps;


const Authentication = () => {
    const url = localStorage.getItem("myna-auth-email")
    const [ current, setCurrent ] = useState(0);
    const [ enterCode, setEnterCode ] = useState(false);
    const [ enterEmail, setEnterEmail ] = useState(false);
    const [ qrImage, setQrImage ] = useState(null);
    const [ secretKey, setSecretKey ] = useState(null);
    const [ emailVerifyCode, setEmailVerifyCode ] = useState("");
    const [ verifyCode, setVerifyCode ] = useState("");
    const [ backupEmail, setBackupEmail ] = useState("");

    useEffect(() => {
    }, [ enterCode, enterEmail, emailVerifyCode, verifyCode, backupEmail ])

    useEffect(() => {
        post(API.GOOGLE_2FA_REGISTER)
            .then((response) => {
                // navigate("/dashboard", { replace: true });
                setQrImage(response.data.QR_Image)
                setSecretKey(response.data.secret)
            })
            .catch(() => {
            });
    }, [])

    const next = () => {
        setCurrent(current + 1);
    };

    const handleClick = () => {
        setEnterCode(true)
    }

    const changeAuthenticationCode = (event) => {
        setVerifyCode(event.target.value)
        // setEnterCode(true)
    }



    const confirmCode = async () => {
        // const { data } = await post(API.GOOGLE_2FA_VERIFY, { code: verifyCode });
        post(API.GOOGLE_2FA_VERIFY, { code: verifyCode })
            .then(() => {
                // navigate("/dashboard", { replace: true });
                // setQrImage(response.data.QR_Image)
            })
            .catch(() => {
            });
        // // setEnterCode(true)
    }

    const changeEmailCode = (event) => {
        setEmailVerifyCode(event.target.value)
        // setEnterCode(true)
    }

    const confirmEmail = async () => {
        // const { data } = await post(API.GOOGLE_2FA_VERIFY, { code: verifyCode });
        post(API.VERIFY_BACKUP_EMAIL, { code: emailVerifyCode })
            .then(() => {
                // navigate("/dashboard", { replace: true });
                // setQrImage(response.data.QR_Image)
            })
            .catch(() => {
            });
        // // setEnterCode(true)
    }

    const changeBackupEmail = (event) => {
        setBackupEmail(event.target.value)
        // setEnterCode(true)
    }

    const addBackupEmail = async () => {
        post(API.ADD_BACKUP_EMAIL, { backup_email: backupEmail })
            .then(() => {
                // navigate("/dashboard", { replace: true });
                setEnterEmail(true)
                // setQrImage(response.data.QR_Image)
            })
            .catch(() => {
            });
    }

    useEffect(() => {
    }, [ qrImage ])

    const contentStepOne = () => {
        return (<>
            {!enterCode ? (<div className="AntContent">
                <div className="step1"><h5 className="heading-step">Step 1:</h5><p className="content-step1"> Open your Google Authenticator app. </p></div>
                <div className="step2"><h5>Step 2:</h5><p> Add your account to the app by scanning this QR code. </p></div>
                {qrImage &&
                    < Image
                        width={200}
                        src={qrImage.replace('https://www.google.com', 'https://chart.googleapis.com')}
                    />
                }
                <div><b>OR</b></div>
                <p>Enter a setup key: <b>{secretKey}</b></p>
                <div className="step3"><h5>Step 3:</h5><p> {`Enter the 6-digit code found in your authenticator app under ${url}.`} </p></div>
                <Button onClick={handleClick}>Enter Code</Button>
            </div>)
                : (<>
                    <p>Enter the 6-digit code found in your authenticator app</p>
                    <Input autoFocus placeholder="Authentication Code" onChange={changeAuthenticationCode} />
                    <Button onClick={confirmCode}>
                        Confirm
                    </Button>
                </>)}
        </>)
    }

    const contentStepTwo = () => {
        return (<>
            {!enterEmail ? (<div className="AntContent">
                <h3>Add a backup email</h3>
                <p>This email is only used to log into your account if you`&apos;`re locked out or don`&apos;`t have your phone.</p>
                <p>It must be different to the email you log in with.</p>
                <Input autoFocus placeholder="Backup email address" onChange={changeBackupEmail} />
                <Button onClick={addBackupEmail}>
                    Confirm
                </Button>
            </div>)
                :
                (<>
                    <div className="AntContent">
                        <h3>Check your backup email and enter the code</h3>
                        <p>Enter the code sent to graham.feeney@yopmail.com to confirm your email address</p>
                        <Input autoFocus placeholder="Confirmation Code" onChange={changeEmailCode} />
                        <Button onClick={confirmEmail}>
                            Confirm
                        </Button>
                    </div>
                </>)}
        </>)
    }

    const steps = [
        {
            title: 'Set up app',
            content: contentStepOne(),
        },
        {
            title: 'Add a backup',
            content: contentStepTwo(),
        }
    ];





    if (!qrImage) return null
    return (
        <>
            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div className="steps-content">{steps[ current ].content}</div>
            <div className="steps-action">
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => message.success('Processing complete!')}>
                        Done
                    </Button>
                )}
            </div>

        </>
    );
};

export default Authentication;
