import React from "react";
//import { useNavigate } from "react-router-dom";

const Footer = () => {
  //const navigate = useNavigate();
  return (
    <div className="footer-link">
      {/* <Button
        type='button'
        onClick={() => navigate('/terms-of-use', { replace: true })}
        className='linkButton'
      >
        Terms of Use
      </Button>
      <Button
        type='button'
        onClick={() => navigate('/privacy', { replace: true })}
        className='linkButton'
      >
        Privacy
      </Button>
      <Button
        type='button'
        onClick={() => navigate('/help', { replace: true })}
        className='linkButton'
      >
        Help Center
      </Button> */}
    </div>
  );
};

export default Footer;
