import React, { useState, useEffect, useCallback } from 'react';
import { API, fileUpload, get } from '../../config.js';
import { Button, Input, Spin } from 'antd';
import { toast } from 'react-toastify';
import '../users/users.module.css';
import CancelButton from '../../components/Form/CancelButton.js';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Form from '@component/Form';
import ManualReceiptModal from './ManualReceiptModal.js';

const ManualReceipt = () => {
  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState(null);

  const [recieptData, setrecieptData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [subject, setSubject] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const getReciept = (html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const value = EditorState.createWithContent(contentState) || null;
    return value;
  };
  const getRecieptData = useCallback(async () => {
    try {
      const { data } = await get(API.GET_ABA_RECIEPT);
      const recieptObj = data;
      const html = recieptObj?.data?.manual_receipt_template ? recieptObj?.data?.manual_receipt_template : `Please be advised that in accordance with your earlier instructions, the following money has been received by the firm and has been deposited into our Trust Accounts where it will be held in trust for you. Details of the transaction are as follows:`;
      let recieptValue = null;
      if (html) {
        recieptValue = getReciept(html);
      }
      setSubject(recieptObj?.data?.manual_receipt_subject ? recieptObj?.data?.manual_receipt_subject : `Receipt of Monies into Trust Account`);
      form.setFieldsValue({
        manual_receipt_subject: recieptObj?.data?.manual_receipt_subject ? recieptObj?.data?.manual_receipt_subject : `Receipt of Monies into Trust Account`
      });
      setEditorState(recieptValue);
    } catch (e) {
      setEditorState(null);
    }
  }, []);

  useEffect(() => {
    getRecieptData();
  }, [getRecieptData, recieptData]);

  const handleFinish = async () => {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    try {
      const formData = new FormData();
      formData.append('manual_receipt_subject', subject);
      formData.append('manual_receipt_template', value);
      const { data } = await fileUpload(API.UPDATE_ABA_RECIEPT, formData, {});
      toast.success(data.message);
      getRecieptData();
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  const printReciept = async () => {
    setModalLoading(true);
    const { data } = await get(API.GET_ABA_RECIEPT);
    setrecieptData(data?.data);
    setModalLoading(false);
    setShowPreview(true);
  };

  return (
    <>
    <div className="content-details">
      <div className="full-card">
        <Form
          form={form}
          initialValues={{
            manual_receipt_subject: ''
          }}
        >
          <div className="form-box">
            <Form.Item
              className="manual_receipt_form"
              label="Subject"
              name="manual_receipt_subject"
            >
              <Input placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
            </Form.Item>
          </div>
        </Form>

        <Editor
          editorState={editorState}
          toolbar={{
            options: ['inline', 'fontSize', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline']
            }
          }}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />
        <div className="action-box">
          <Button type="primary" onClick={() => printReciept()} style={{ marginRight: 'auto' }}>
            <FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
            Preview
          </Button>
          <Button type="primary" onClick={handleFinish}>
            Save
          </Button>
          <CancelButton />
          {recieptData && (
            <ManualReceiptModal
              recieptData={recieptData}
              setShowPreview={setShowPreview}
              showPreview={showPreview}
            />
          )}
        </div>
      </div>
    </div>
    {modalLoading && <Spin />}
    </>
  );
};

export default ManualReceipt;
